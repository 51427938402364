import actionCreatorFactory from "typescript-fsa";
import {
  ApiLoginResponse,
  GetUserDetailsParams,
  GetUserDetailsResponse
} from "./types";

const actionCreator = actionCreatorFactory("AUTH");

export const apiLogin = actionCreator.async<void, ApiLoginResponse, unknown>(
  "API_LOGIN"
);

export const apiLogout = actionCreator.async<void, void, unknown>("API_LOGOUT");

export const apiRelogin = actionCreator.async<void, void, unknown>(
  "API_RELOGIN"
);

export const logout = actionCreator.async<void, void, unknown>("LOGOUT");

export const startRefreshApiSessionPolling = actionCreator(
  "START_REFRESH_API_SESSION_POLLING"
);

export const stopRefreshApiSessionPolling = actionCreator(
  "STOP_REFRESH_API_SESSION_POLLING"
);

export const getUserDetails = actionCreator.async<
  GetUserDetailsParams,
  GetUserDetailsResponse,
  unknown
>("GET_USER_DETAILS");
