import { formatRegion } from "utils/formatRegion";
import { RootReducer } from "../../reducers";
import { KeyPair, TableKeyPair } from "./types";
import { createSelector } from "reselect";

// export const keyPairsSelector = (state: RootReducer): TableKeyPair[] | null =>
//   state.security.keyPairs?.map((keypair) => ({
//     ...keypair,
//     id: keypair.name,
//     region: formatRegion(keypair.region)
//   })) || null;

const getKeyPairs = (state: RootReducer) => state.security.keyPairs;
export const keyPairsSelector = createSelector([getKeyPairs], (keyPairs) => {
  return keyPairs
    ? keyPairs.map((keypair) => ({
        ...keypair,
        id: keypair.name,
        region: formatRegion(keypair.region)
      }))
    : null;
});

export const keyPairSelector = (state: RootReducer): KeyPair | null =>
  state.security.keyPair;

export const areKeyPairsLoadingSelector = (state: RootReducer): boolean =>
  state.security.areKeyPairsLoading;

export const isKeyPairLoadingSelector = (state: RootReducer): boolean =>
  state.security.isKeyPairLoading;

export const isKeyPairCreatingSelector = (state: RootReducer): boolean =>
  state.security.isKeyPairCreating;

export const isKeyPairDeletingSelector = (state: RootReducer): boolean =>
  state.security.isKeyPairDeleting;

export const isPublicKeyAddingSelector = (state: RootReducer): boolean =>
  state.security.isPublicKeyAdding;
