import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { TAB_TITLES as SECURITY_TAB_TITLES } from "components/Security";
import { TABS as SECURITY_TABS } from "components/Security/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import {
  firewallRuleSelector,
  firewallSelector,
  isFirewallRuleDeletingSelector
} from "modules/networks/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { formatProtocol } from "utils/formatProtocol";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { ROUTES } from "../../constants";
import * as s from "./styles";

const POLL_ID_PREFIX = "FIREWALL_RULE";

const POLL_IDS = {
  firewallRule: "FIREWALL_RULE",
  firewall: "FIREWALL"
};

export const FirewallRule: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    firewallId: string;
    firewallRuleId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const firewall = useSelector(firewallSelector);
  const firewallRule = useSelector(firewallRuleSelector);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const isFirewallRuleDeleting = useSelector(isFirewallRuleDeletingSelector);
  const previousIsFirewallRuleDeleting = usePrevious(isFirewallRuleDeleting);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpened(false);
  }, []);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Firewalls",
      url: `${generatePath(ROUTES.SECURITY, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })}?${generateSearchString({
        tab: SECURITY_TAB_TITLES[SECURITY_TABS.FIREWALLS]
      })}`
    },
    {
      text: firewall?.name || "",
      url: generatePath(ROUTES.FIREWALL, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        firewallId: matchParams.firewallId
      })
    },
    {
      text: "Firewall rules",
      url: generatePath(ROUTES.FIREWALL, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        firewallId: matchParams.firewallId
      })
    },
    {
      text: firewallRule?.id || "",
      url: generatePath(ROUTES.FIREWALL_RULE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        firewallId: matchParams.firewallId,
        firewallRuleId: matchParams.firewallRuleId
      })
    }
  ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.firewallRule}`,
        action: networksActions.getFirewallRule.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.firewallRuleId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.firewall}`,
        action: networksActions.getFirewall.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.firewallId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(networksActions.clear());
  });

  useEffect(() => {
    if (
      previousIsFirewallRuleDeleting &&
      !isFirewallRuleDeleting &&
      !firewallRule
    ) {
      history(getParentPath(location.pathname));
    }
  }, [
    firewallRule,
    history,
    previousIsFirewallRuleDeleting,
    isFirewallRuleDeleting
  ]);

  const handleDeleteFirewallRuleButtonClick = useCallback(() => {
    setIsDialogOpened(true);
  }, []);

  const handleConfirmDeleteFirewallRule = useCallback(() => {
    dispatch(
      networksActions.deleteFirewallRule.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.firewallRuleId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId,
    matchParams.firewallRuleId
  ]);

  const title = firewallRule?.id;

  return (
    <>
      <Head title={title} />
      {firewallRule ? (
        <>
          {organization && project && firewall && (
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          )}
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              {title}
            </s.Title>
            <s.ActionsContainer>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteFirewallRuleButtonClick}
                    color={"inherit"}
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>
          <s.ContentContainer variant={"outlined"}>
            <Typography>ID: {firewallRule?.id}</Typography>
            <Typography>Direction: {firewallRule?.direction}</Typography>
            <Typography>
              Protocol:{" "}
              {firewallRule?.protocol
                ? formatProtocol(firewallRule.protocol)
                : ""}
            </Typography>
            <Typography>Description: {firewallRule?.description}</Typography>
            <Typography>Ether Type: {firewallRule?.ethertype}</Typography>
            <Typography>
              Port Range: {firewallRule?.port_range_min} -{" "}
              {firewallRule?.port_range_max}
            </Typography>
            <Typography>
              Remote IP prefix: {firewallRule?.remote_ip_prefix}
            </Typography>
          </s.ContentContainer>
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
      <FormDialog
        isOpened={isDialogOpened}
        onConfirm={handleConfirmDeleteFirewallRule}
        title={`Are you sure you want to delete "${
          firewallRule?.id ?? "selected"
        }" firewall rule?`}
        confirmButtonLabel={"Delete"}
        onCancel={handleCloseDialog}
      />
    </>
  );
};
