import { DRAWER_WIDTH } from "components/Container/styles";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(8)};
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

export const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const UsernameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

export const Container = styled.div`
  display: flex;
  width: calc(100% - ${DRAWER_WIDTH}px);
  margin-left: ${DRAWER_WIDTH}px;
  padding: ${({ theme }) => theme.spacing(0, 2, 2)};
  flex-direction: column;
`;

export const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 135px;
`;

export const ModalContentBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: ${({ theme }) => theme.palette.background.default};
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.16),
    rgba(255, 255, 255, 0.16)
  );
  padding: 20pt;
  outline: unset;
  border-radius: 5pt;
`;

export const ModalTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
`;

export const SupportInfoLink = styled(Link)`
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;
