import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styled from "styled-components";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextAreasContainer = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const TextArea = styled(TextField)`
  &:last-child {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }
`;

export const GetPredictionButton = styled(Button)`
  align-self: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
