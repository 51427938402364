import SvgIcon from "@mui/material/SvgIcon";
import { FC } from "react";

export const StorageIcon: FC = () => (
  <SvgIcon viewBox={"0 0 105.1 122.9"}>
    <path
      fillRule={"evenodd"}
      d={
        "M52.5 0c28.9 0 52.3 11 52.3 24.5S81.4 48.9 52.5 48.9C23.7 49 .3 38 .3 24.5.3 11 23.7 0 52.5 0zM.3 81.8v18.8c9.3 33 101.1 26.7 104.5-1.7V80.2c-4.6 31-97.2 33.3-104.5 1.6zM0 33v18.4c9.3 32.2 101.7 27.9 105 .2V33.2c-4.5 30.4-97.7 30.7-105-.3zm0 23.7v18.8c9.3 33 101.7 28.6 105 .2V57C100.6 88 7.4 88.3 0 56.6z"
      }
      clipRule={"evenodd"}
    />
  </SvgIcon>
);
