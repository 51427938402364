import SvgIcon from "@mui/material/SvgIcon";
import { FC } from "react";

export const FedoraIcon: FC = () => (
  <SvgIcon
    width={"25"}
    height={"25"}
    viewBox={"0 0 256 256"}
    style={{ pointerEvents: "none" }}
  >
    <g>
      <path
        d="M256,128.004 C256,57.31 198.691,0 127.998,0 C57.336,0 0.05,57.262 0,127.914 L0,226.968 C0.038,243.01 13.049,255.997 29.101,255.997 L128.05,255.997 C198.72,255.969 256,198.679 256,128.004"
        fill="#294172"
      ></path>
      <path
        d="M165.58,30.307 C132.471,30.307 105.535,57.242 105.535,90.352 L105.535,122.222 L73.797,122.222 C40.688,122.222 13.752,149.159 13.752,182.268 C13.752,215.376 40.688,242.313 73.797,242.313 C106.906,242.313 133.842,215.376 133.842,182.268 L133.842,150.397 L165.58,150.397 C198.689,150.397 225.626,123.461 225.626,90.352 C225.626,57.242 198.689,30.307 165.58,30.307 L165.58,30.307 Z M105.757,182.268 C105.757,199.89 91.42,214.227 73.797,214.227 C56.174,214.227 41.837,199.89 41.837,182.268 C41.837,164.645 56.174,150.308 73.797,150.308 L105.535,150.308 L105.535,150.397 L105.757,150.397 L105.757,182.268 L105.757,182.268 Z M165.58,122.312 L133.842,122.312 L133.842,122.222 L133.621,122.222 L133.621,90.352 C133.621,72.729 147.958,58.392 165.58,58.392 C183.202,58.392 197.54,72.729 197.54,90.352 C197.54,107.975 183.202,122.312 165.58,122.312 L165.58,122.312 Z"
        fill="#3C6EB4"
      ></path>
      <path
        d="M178.851,32.128 C174.191,30.91 170.613,30.342 165.58,30.342 C132.403,30.342 105.505,57.241 105.505,90.416 L105.505,122.258 L80.345,122.258 C72.5,122.258 66.16,128.423 66.165,136.254 C66.165,144.036 72.435,150.227 80.197,150.227 L101.028,150.231 C103.501,150.231 105.507,152.231 105.507,154.7 L105.507,182.253 C105.476,199.744 91.288,213.912 73.797,213.912 C67.872,213.912 66.405,213.136 62.36,213.136 C53.863,213.136 48.178,218.832 48.178,226.664 C48.18,233.143 53.732,238.713 60.526,240.491 C65.186,241.709 68.764,242.278 73.797,242.278 C106.974,242.278 133.872,215.379 133.872,182.203 L133.872,150.362 L159.032,150.362 C166.877,150.362 173.217,144.197 173.212,136.366 C173.212,128.583 166.942,122.393 159.18,122.393 L138.349,122.389 C135.876,122.389 133.87,120.388 133.87,117.919 L133.87,90.366 C133.901,72.875 148.089,58.707 165.58,58.707 C171.505,58.707 172.972,59.484 177.017,59.484 C185.514,59.484 191.199,53.787 191.199,45.956 C191.197,39.476 185.645,33.906 178.851,32.128"
        fill="#FFFFFF"
      ></path>
    </g>
  </SvgIcon>
);
