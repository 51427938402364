import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetEmotionsPredictionParams,
  GetEmotionsPredictionResponse
} from "./types";

const serviceApiPath = `${window.origin}/process/`;

export function* getEmotionsPreditionSaga(
  action: Action<GetEmotionsPredictionParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetEmotionsPredictionResponse> = yield call(
      axiosInstance.post,
      serviceApiPath,
      {
        articles: [{ text: action.payload.text }]
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getEmotionsPrediction.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getEmotionsPrediction.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get emotions prediction data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getEmotionsPrediction.started, getEmotionsPreditionSaga)
  ]);
}
