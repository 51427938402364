import { FC } from "react";
import {
  Modal,
  IconButton,
  Tooltip,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link
} from "@mui/material";
import * as s from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import { FooterContactSupportModalProps } from "./types";

export const FooterContactSupportDialog: FC<FooterContactSupportModalProps> = ({
  isOpened,
  onClose,
  supportEmail,
  supportPhones
}) => {
  const phoneNumbers = supportPhones
    ? supportPhones.split(";").map((phone) => (
        <Typography variant="body1" key={phone} color="secondary" gutterBottom>
          {phone}
        </Typography>
      ))
    : "";

  return (
    <Modal
      open={isOpened}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <s.ModalContentBox>
        <s.TitleContainer>
          <s.ModalTypography variant="h5" gutterBottom>
            How can we help?
          </s.ModalTypography>
          <Tooltip title={"Close"}>
            <span>
              <IconButton onClick={onClose} color={"inherit"}>
                <CloseIcon />
              </IconButton>
            </span>
          </Tooltip>
        </s.TitleContainer>

        <Typography variant="h6" component="h2" gutterBottom>
          Please, describe your problem the way you prefer...
        </Typography>

        <ListItem>
          <ListItemIcon>
            <ForwardToInboxOutlinedIcon color="action" />
          </ListItemIcon>
          {supportEmail ? (
            <ListItemText>
              <Link color="secondary" href={`mailto:${supportEmail}`}>
                {supportEmail}
              </Link>
            </ListItemText>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Support email is not available.
            </Typography>
          )}
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <PhoneForwardedOutlinedIcon color="action" />
          </ListItemIcon>
          <ListItemText>
            {phoneNumbers || (
              <Typography variant="body2" color="textSecondary">
                Phone numbers are not available.
              </Typography>
            )}
          </ListItemText>
        </ListItem>
      </s.ModalContentBox>
    </Modal>
  );
};
