export enum TABS {
  VOLUMES,
  SNAPSHOTS,
  IMAGES,
  OBJECT_STORAGE
}

export enum DIALOG_TYPES {
  CREATE_VOLUME,
  EDIT_VOLUME,
  EXTEND_VOLUME,
  ATTACH_VOLUME,
  DETACH_VOLUME,
  DELETE_VOLUME,
  DELETE_VOLUMES,
  CREATE_VOLUME_FROM_SNAPSHOT,
  CREATE_SNAPSHOT,
  EDIT_SNAPSHOT,
  DELETE_SNAPSHOT,
  SHOW_CREDENTIALS,
  CREATE_CREDENTIALS,
  REGENERATE_CREDENTIALS,
  DELETE_CREDENTIALS
}
