import { AxiosResponse } from "axios";
import * as enterprisesActions from "modules/enterprises/actions";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import {
  all,
  AllEffect,
  call,
  CallEffect,
  delay,
  ForkEffect,
  put,
  race,
  RaceEffect,
  select,
  take,
  TakeEffect,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import { keycloak } from "../../keycloak";
import * as actions from "./actions";
import { apiSessionSelector, userSelector } from "./selectors";
import {
  ApiLoginResponse,
  ApiSession,
  GetUserDetailsParams,
  KeycloakUser
} from "./types";

const serviceApiPath = `gotham-echo/method/`;

// TODO: fix types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* apiReloginSaga() {
  try {
    yield* apiLogoutSaga();
    yield* apiLoginSaga();
    yield put(actions.apiRelogin.done({}));
  } catch (e) {
    yield put(actions.apiRelogin.failed({ error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to refresh user session",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// TODO: fix types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* apiLoginSaga() {
  try {
    yield put(actions.apiLogin.started());
    const response: AxiosResponse<ApiLoginResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}api-login`
    );
    yield put(
      actions.apiLogin.done({
        result: response.data
      })
    );
  } catch (e) {
    yield put(actions.apiLogin.failed({ error: e }));
  }
}

// TODO: fix types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* apiLogoutSaga() {
  try {
    yield put(actions.apiLogout.started());
    yield call(axiosInstance.get, `${serviceApiPath}api-logout`);
    yield put(actions.apiLogout.done({}));
  } catch (e) {
    yield put(actions.apiLogout.failed({ error: e }));
  }
}

export function* logoutSaga(): SagaIterator<void> {
  try {
    yield call(apiLogoutSaga);
    yield call(keycloak.logout);
    yield put(actions.logout.done({}));
    yield put(actions.stopRefreshApiSessionPolling());
  } catch (e) {
    yield put(actions.logout.failed({ error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to log out",
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

function* refreshApiSessionPollingWorkerSaga() {
  while (true) {
    const apiSession: ApiSession | null = yield select(apiSessionSelector);
    const POLLING_INTERVAL = 30 * 1000; // in milliseconds

    if (apiSession) {
      const tokenRemainingLifeTime = apiSession.exp * 1000 - Date.now();
      if (tokenRemainingLifeTime <= POLLING_INTERVAL) {
        yield put(actions.apiRelogin.started());
      }
      yield delay(POLLING_INTERVAL);
    }
  }
}

function* refreshApiSessionPollingWatcherSaga() {
  while (true) {
    yield take(actions.startRefreshApiSessionPolling);
    yield race({
      task: call(refreshApiSessionPollingWorkerSaga),
      cancel: take(actions.stopRefreshApiSessionPolling)
    });
  }
}

export function* getUserDetailsSaga(
  action: Action<GetUserDetailsParams>
): SagaIterator<void> {
  try {
    const user: KeycloakUser = yield call(keycloak.loadUserProfile);
    user.id = keycloak.subject;
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    const userDetails = yield select(userSelector);
    if (!isPollStopped) {
      yield put(
        actions.getUserDetails.done({
          params: action.payload,
          result: user
        })
      );
    }
    const hubspotCookie = document.cookie
      .split("; ")
      .find((cookie) => cookie.startsWith("hubspotutk"));
    const hutk = hubspotCookie?.slice(hubspotCookie.indexOf("=") + 1);

    if (!userDetails && !user.attributes?.["hs:submission"] && hutk) {
      yield put(
        enterprisesActions.submitHubSpotForm.started({
          hutk
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getUserDetails.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get user profile",
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): Generator<
  AllEffect<
    | Generator<
        TakeEffect | RaceEffect<TakeEffect | CallEffect<void>>,
        void,
        unknown
      >
    | ForkEffect<never>
  >,
  void,
  unknown
> {
  yield all([
    refreshApiSessionPollingWatcherSaga(),
    takeLatest(actions.apiRelogin.started, apiReloginSaga),
    takeLeading(actions.getUserDetails.started, getUserDetailsSaga),
    takeLatest(actions.logout.started, logoutSaga)
  ]);
}
