export enum NOTIFICATION_TYPES {
  SUCCESS = "success",
  ERROR = "error",
  PROGRESS = "progress",
  INFO = "info"
}

export type Notification = {
  text?: string;
  title: string;
  type: NOTIFICATION_TYPES;
};
