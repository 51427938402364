export enum DIALOG_TYPES {
  EDIT_NETWORK,
  DELETE_NETWORK,
  CREATE_SUBNET,
  EDIT_SUBNET,
  DELETE_SUBNET
}

export enum TABS {
  SUBNETS
}
