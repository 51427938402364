import { useKeycloak } from "@react-keycloak/web";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../../constants";

export const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { keycloak } = useKeycloak();

  if (!keycloak.authenticated) {
    return <Navigate to={ROUTES.ROOT} replace />;
  }

  return <>{children}</>;
};
