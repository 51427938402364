import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { StaticTimePicker, StaticTimePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
// import { Link, LinkProps } from "react-router-dom";
import styled from "styled-components";

export const Dialog = styled(MuiDialog)`
  & .MuiDialog-paper {
    width: 600px;
  }
`;

export const FormDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
`;

export const StepArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const StepButton = styled(IconButton)`
  padding: 0;
`;

export const ToggleButtonGroupStyled = styled(ToggleButtonGroup)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  /* use when you have long list of buttons */
  display: flex;
  flex-wrap: wrap;
  flex-basis: auto;
`;

export const ToggleButtonStyled = styled(ToggleButton)`
  flex: 1 1 0;
  width: fit-content;
  /* max-width: "fit-content" for making small buttons */
`;

export const ArrowUpIcon = styled(ArrowUpward)`
  font-size: small;
`;

export const ArrowDownIcon = styled(ArrowDownward)`
  font-size: small;
`;

export const LabelFieldText = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  /* margin-bottom: ${({ theme }) => theme.spacing(1)}; */
  overflow-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9rem;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(9)};
`;

export const UserNotesContainer = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(0)};
  margin-bottom: ${({ theme }) => theme.spacing(0)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: smaller;
  line-height: 0.9;
  min-height: 0.9em;
  white-space: pre-line;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};
  word-wrap: break-word;
  &:hover {
    text-decoration: underline;
  }
`;

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > * {
    transform: scale(0.75);
    margin: -5%;
  }
`;

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const DividerText = styled(Typography)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
  font-size: smaller;
`;

export const StyledDivider = styled.hr`
  flex-grow: 1;
  border: none;
  height: 1px;
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.dark
      : theme.palette.grey[100]};
`;

type StyledStaticTimePickerProps = {
  content?: string;
} & StaticTimePickerProps<Date | Dayjs>;

export const StyledStaticTimePicker = styled(
  StaticTimePicker
)<StyledStaticTimePickerProps>`
  background: inherit;
  & .MuiTypography-root.MuiTypography-overline {
    display: none;
  }
  &:after {
    content: ${({ content }) => (content ? `"${content}"` : "Select Time")};
    display: inline;
    text-align: center;
    font-size: larger;
    margin-top: 5%;
    margin-bottom: -10%;
    color: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? theme.palette.text.primary
        : theme.palette.text.secondary};
  }
`;

// export const StyledLink = styled(Link)<LinkProps>`
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
//   display: block;
//   text-decoration: none;
//   color: ${({ theme }) => theme.palette.primary.main};
//   &:hover {
//     text-decoration: underline;
//   }
// `;
