import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import copy from "copy-to-clipboard";
import * as notficationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { DatabasePasswordDialogProps } from "./types";

export const DatabasePasswordDialog: FC<DatabasePasswordDialogProps> = ({
  isOpened,
  name,
  password,
  text,
  onClose
}: DatabasePasswordDialogProps) => {
  const dispatch = useDispatch();

  const handleCopyToClipboardButtonClick = useCallback(
    (password: string) => () => {
      copy(password);
      dispatch(
        notficationsActions.showNotification({
          type: NOTIFICATION_TYPES.INFO,
          title: "Password has been copied to clipboard."
        })
      );
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpened}>
      <DialogTitle>Save password for the &quot;{name}&quot; user</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
        <TextField
          title={"User password"}
          inputProps={{ readOnly: true }}
          fullWidth={true}
          value={password}
          variant={"standard"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={"secondary"}
          onClick={handleCopyToClipboardButtonClick(password)}
        >
          Copy to clipboard
        </Button>
        <Button color={"secondary"} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
