// works correctly if:
// - the objects passed to this function must be of type T, which should have a property that returns a string representing the version of the object;
// - the version string should have the format "vx.y.z", where the first character is ignored and "x", "y", "z" are integers.

export const filterVersions = <T>(
  currentVersion: string,
  templates: T[] | null,
  getName: (template: T) => string
): T[] => {
  if (!templates) {
    return [];
  }
  const currentVersionNumbers = currentVersion.slice(1).split(".").map(Number);
  return templates.filter((template) => {
    const templateVersion = getName(template).slice(1).split(".").map(Number);
    for (
      let i = 0;
      i < Math.min(templateVersion.length, currentVersionNumbers.length);
      i++
    ) {
      if (templateVersion[i] !== currentVersionNumbers[i]) {
        return templateVersion[i] > currentVersionNumbers[i];
      }
    }
    return templateVersion.length >= currentVersionNumbers.length;
  });
};
