import { IconButton, TextField } from "@mui/material";
import styled from "styled-components";

export const UploaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 10px;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsTitle = styled.span`
  font-size: xx-small;
  border: 1px solid gray;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(1.5)};
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

export const ClearButton = styled(IconButton)`
  margin-left: ${({ theme }) => theme.spacing(0.2)};
  font-size: xx-small;
`;

export const StyledTextField = styled(TextField)`
  width: 150px;
`;
