import SvgIcon from "@mui/material/SvgIcon";
import { FC } from "react";

export const CentosIcon: FC = () => (
  <SvgIcon
    width={"25"}
    height={"25"}
    viewBox={"0 0 256 256"}
    style={{ pointerEvents: "none" }}
  >
    <g>
      <path
        d="M107.860446,118.641168 L117.088645,127.817911 L107.860446,136.99288 L42.9010976,136.99288 L42.9010976,167.564282 L3.28602642,127.817911 L42.9010976,88.7386823 L42.9010976,118.641168 L107.860446,118.641168 L107.860446,118.641168 L107.860446,118.641168 L107.860446,118.641168 Z M136.414454,40.5732 L215.342964,40.5732 L215.342964,119.503484 L136.414454,119.503484 L136.414454,40.5732 L136.414454,40.5732 L136.414454,40.5732 L136.414454,40.5732 Z"
        fill="#932279"
      ></path>
      <path
        d="M137.274996,107.860446 L128.100027,117.088645 L118.925058,107.860446 L118.925058,42.9010976 L88.3518821,42.9010976 L128.100027,3.28602642 L167.177481,42.9010976 L137.274996,42.9010976 L137.274996,107.860446 L137.274996,107.860446 L137.274996,107.860446 L137.274996,107.860446 Z M136.414454,136.414454 L215.342964,136.414454 L215.342964,215.344738 L136.414454,215.344738 L136.414454,136.414454 L136.414454,136.414454 L136.414454,136.414454 L136.414454,136.414454 Z"
        fill="#EFA724"
      ></path>
      <path
        d="M148.057492,137.274996 L138.827519,128.100027 L148.057492,118.925058 L213.015067,118.925058 L213.015067,88.3518821 L252.631911,128.100027 L213.015067,167.177481 L213.015067,137.274996 L148.057492,137.274996 L148.057492,137.274996 L148.057492,137.274996 L148.057492,137.274996 Z M40.5732,136.414454 L119.501709,136.414454 L119.501709,215.344738 L40.5732,215.344738 L40.5732,136.414454 L40.5732,136.414454 L40.5732,136.414454 L40.5732,136.414454 Z"
        fill="#262577"
      ></path>
      <path
        d="M118.641168,148.057492 L127.816137,138.827519 L136.99288,148.057492 L136.99288,213.016841 L167.564282,213.016841 L127.816137,252.631911 L88.7404565,213.016841 L118.641168,213.016841 L118.641168,148.057492 L118.641168,148.057492 L118.641168,148.057492 L118.641168,148.057492 Z M40.5732,40.5732 L119.501709,40.5732 L119.501709,119.503484 L40.5732,119.503484 L40.5732,40.5732 L40.5732,40.5732 L40.5732,40.5732 L40.5732,40.5732 Z"
        fill="#9CCD2A"
      ></path>
      <path
        d="M37.7538176,37.7538176 L122.321092,37.7538176 L122.321092,122.321092 L37.7538176,122.321092 L37.7538176,37.7538176 L37.7538176,37.7538176 L37.7538176,37.7538176 L37.7538176,37.7538176 L37.7538176,37.7538176 Z M43.3908078,116.684101 L116.682327,116.684101 L116.682327,43.3925822 L43.3908078,43.3925822 L43.3908078,116.684101 L43.3908078,116.684101 L43.3908078,116.684101 L43.3908078,116.684101 Z M133.596846,37.7538176 L218.164121,37.7538176 L218.164121,122.321092 L133.596846,122.321092 L133.596846,37.7538176 L133.596846,37.7538176 L133.596846,37.7538176 L133.596846,37.7538176 Z M139.233837,116.684101 L212.525356,116.684101 L212.525356,43.3925822 L139.233837,43.3925822 L139.233837,116.684101 L139.233837,116.684101 L139.233837,116.684101 L139.233837,116.684101 Z M133.596846,133.596846 L218.164121,133.596846 L218.164121,218.165895 L133.596846,218.165895 L133.596846,133.596846 L133.596846,133.596846 L133.596846,133.596846 L133.596846,133.596846 Z M139.233837,212.525356 L212.525356,212.525356 L212.525356,139.233837 L139.233837,139.233837 L139.233837,212.525356 L139.233837,212.525356 L139.233837,212.525356 L139.233837,212.525356 Z M37.7538176,133.596846 L122.321092,133.596846 L122.321092,218.165895 L37.7538176,218.165895 L37.7538176,133.596846 L37.7538176,133.596846 L37.7538176,133.596846 L37.7538176,133.596846 Z M43.3908078,212.525356 L116.682327,212.525356 L116.682327,139.233837 L43.3908078,139.233837 L43.3908078,212.525356 L43.3908078,212.525356 L43.3908078,212.525356 L43.3908078,212.525356 L43.3908078,212.525356 Z"
        fill="#FFFFFF"
      ></path>
      <path
        d="M60.1882226,187.75773 L0.388574398,127.958082 L60.1864484,68.160208 L119.986097,127.958082 L60.1882226,187.75773 L60.1882226,187.75773 L60.1882226,187.75773 L60.1882226,187.75773 L60.1882226,187.75773 L60.1882226,187.75773 Z M8.36233399,127.958082 L60.1882226,179.78397 L112.012337,127.958082 L60.1864484,76.1339675 L8.36233399,127.958082 L8.36233399,127.958082 L8.36233399,127.958082 L8.36233399,127.958082 Z M127.958082,119.986097 L68.160208,60.1882226 L127.958082,0.388574398 L187.755956,60.1882226 L127.958082,119.986097 L127.958082,119.986097 L127.958082,119.986097 L127.958082,119.986097 Z M76.1339675,60.1882226 L127.958082,112.012337 L179.78397,60.1882226 L127.958082,8.36233399 L76.1339675,60.1882226 L76.1339675,60.1882226 L76.1339675,60.1882226 L76.1339675,60.1882226 Z M195.729716,187.75773 L135.931841,127.958082 L195.729716,68.160208 L255.527589,127.958082 L195.729716,187.75773 L195.729716,187.75773 L195.729716,187.75773 L195.729716,187.75773 Z M143.903827,127.958082 L195.729716,179.78397 L247.553829,127.958082 L195.729716,76.1339675 L143.903827,127.958082 L143.903827,127.958082 L143.903827,127.958082 L143.903827,127.958082 Z M127.958082,255.527589 L68.160208,195.729716 L127.958082,135.931841 L187.755956,195.729716 L127.958082,255.527589 L127.958082,255.527589 L127.958082,255.527589 L127.958082,255.527589 Z M76.1339675,195.729716 L127.958082,247.553829 L179.78397,195.729716 L127.958082,143.905601 L76.1339675,195.729716 L76.1339675,195.729716 L76.1339675,195.729716 L76.1339675,195.729716 L76.1339675,195.729716 L76.1339675,195.729716 Z"
        fill="#FFFFFF"
      ></path>
    </g>
  </SvgIcon>
);
