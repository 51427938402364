import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import copy from "copy-to-clipboard";
import * as notficationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AccessSecretDialogProps } from "./types";

export const AccessSecretDialog: FC<AccessSecretDialogProps> = ({
  isOpened,
  accessSecret,
  onClose
}: AccessSecretDialogProps) => {
  const dispatch = useDispatch();

  const handleCopyToClipboardButtonClick = useCallback(
    (accessSecret: string) => () => {
      copy(accessSecret);
      dispatch(
        notficationsActions.showNotification({
          type: NOTIFICATION_TYPES.INFO,
          title: "Access secret has been copied to clipboard."
        })
      );
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpened}>
      <DialogTitle>Save access secret</DialogTitle>
      <DialogContent>
        <Typography>
          Please save the access secret for the further use.
        </Typography>
        <TextField
          title={"Access secret"}
          inputProps={{ readOnly: true }}
          fullWidth={true}
          value={accessSecret}
          variant={"standard"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={"secondary"}
          onClick={handleCopyToClipboardButtonClick(accessSecret)}
        >
          Copy to clipboard
        </Button>
        <Button color={"secondary"} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
