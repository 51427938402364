import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  Firewall,
  FirewallRule,
  Interface,
  Network,
  NetworkLimits,
  Router,
  Subnet,
  FloatingIP
} from "./types";

export type Reducer = {
  networks: Network[] | null;
  network: Network | null;
  floatingIP: FloatingIP | null;
  floatingIPs: FloatingIP[] | null;
  areFloatingIPsLoading: boolean;
  isFloatingIPCreating: boolean;
  isFloatingIPUpdating: boolean;
  isFloatingIPDeleting: boolean;
  routers: Router[] | null;
  router: Router | null;
  interfaces: Interface[] | null;
  routerInterfaces: Interface[] | null;
  isNetworkLoading: boolean;
  areNetworksLoading: boolean;
  isNetworkCreating: boolean;
  isNetworkUpdating: boolean;
  isNetworkDeleting: boolean;
  isInterfaceFirewallsEditing: boolean;
  areRoutersLoading: boolean;
  isRouterCreating: boolean;
  isRouterUpdating: boolean;
  isRouterDeleting: boolean;
  isRouterLoading: boolean;
  areInterfacesLoading: boolean;
  areRouterInterfacesLoading: boolean;
  isRouterInterfaceAdding: boolean;
  isRouterInterfaceDeleting: boolean;
  networkLimits: NetworkLimits | null;
  areNetworkLimitsLoading: boolean;
  subnets: Subnet[] | null;
  networkSubnets: Subnet[] | null;
  subnet: Subnet | null;
  isSubnetLoading: boolean;
  areSubnetsLoading: boolean;
  isSubnetCreating: boolean;
  isSubnetUpdating: boolean;
  isSubnetDeleting: boolean;
  firewalls: Firewall[] | null;
  firewall: Firewall | null;
  isFirewallLoading: boolean;
  areFirewallsLoading: boolean;
  isFirewallCreating: boolean;
  isFirewallUpdating: boolean;
  isFirewallDeleting: boolean;
  firewallRules: FirewallRule[] | null;
  firewallRule: FirewallRule | null;
  isFirewallRuleLoading: boolean;
  areFirewallRulesLoading: boolean;
  isFirewallRuleCreating: boolean;
  isFirewallRuleDeleting: boolean;
  isRouteAdding: boolean;
  isRouteDeleting: boolean;
  areNetworkSubnetsLoading: boolean;
};

const initialState: Reducer = {
  networks: null,
  network: null,
  floatingIP: null,
  floatingIPs: null,
  areFloatingIPsLoading: false,
  isFloatingIPCreating: false,
  isFloatingIPUpdating: false,
  isFloatingIPDeleting: false,
  routers: null,
  router: null,
  interfaces: null,
  routerInterfaces: null,
  isNetworkLoading: false,
  areNetworksLoading: false,
  isNetworkCreating: false,
  isNetworkUpdating: false,
  isNetworkDeleting: false,
  isRouterLoading: false,
  areRoutersLoading: false,
  isRouterCreating: false,
  isRouterUpdating: false,
  isRouterDeleting: false,
  areInterfacesLoading: false,
  areRouterInterfacesLoading: false,
  isRouterInterfaceAdding: false,
  isRouterInterfaceDeleting: false,
  isInterfaceFirewallsEditing: false,
  networkLimits: null,
  areNetworkLimitsLoading: false,
  subnets: null,
  networkSubnets: null,
  subnet: null,
  isSubnetLoading: false,
  areSubnetsLoading: false,
  isSubnetCreating: false,
  isSubnetUpdating: false,
  isSubnetDeleting: false,
  firewalls: null,
  firewall: null,
  isFirewallLoading: false,
  areFirewallsLoading: false,
  isFirewallCreating: false,
  isFirewallUpdating: false,
  isFirewallDeleting: false,
  firewallRules: null,
  firewallRule: null,
  isFirewallRuleLoading: false,
  areFirewallRulesLoading: false,
  isFirewallRuleCreating: false,
  isFirewallRuleDeleting: false,
  isRouteAdding: false,
  isRouteDeleting: false,
  areNetworkSubnetsLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getNetwork.started,
    (state): Reducer => ({
      ...state,
      isNetworkLoading: true
    })
  )
  .case(
    actions.getNetwork.done,
    (state, payload): Reducer => ({
      ...state,
      network: payload.result,
      isNetworkLoading: false
    })
  )
  .case(
    actions.getNetwork.failed,
    (state): Reducer => ({
      ...state,
      isNetworkLoading: false
    })
  )
  .case(
    actions.getNetworks.started,
    (state): Reducer => ({
      ...state,
      areNetworksLoading: true
    })
  )
  .case(
    actions.getNetworks.done,
    (state, payload): Reducer => ({
      ...state,
      networks: payload.result,
      areNetworksLoading: false
    })
  )
  .case(
    actions.getNetworks.failed,
    (state): Reducer => ({
      ...state,
      areNetworksLoading: false
    })
  )
  .case(
    actions.createNetwork.started,
    (state): Reducer => ({
      ...state,
      isNetworkCreating: true
    })
  )
  .case(
    actions.createNetwork.done,
    (state): Reducer => ({
      ...state,
      isNetworkCreating: false
    })
  )
  .case(
    actions.createNetwork.failed,
    (state): Reducer => ({
      ...state,
      isNetworkCreating: false
    })
  )
  .case(
    actions.updateNetwork.started,
    (state): Reducer => ({
      ...state,
      isNetworkUpdating: true
    })
  )
  .case(
    actions.updateNetwork.done,
    (state): Reducer => ({
      ...state,
      isNetworkUpdating: false
    })
  )
  .case(
    actions.updateNetwork.failed,
    (state): Reducer => ({
      ...state,
      isNetworkUpdating: false
    })
  )
  .case(
    actions.deleteNetwork.started,
    (state): Reducer => ({
      ...state,
      isNetworkDeleting: true
    })
  )
  .case(
    actions.deleteNetwork.done,
    (state): Reducer => ({
      ...state,
      isNetworkDeleting: false,
      network: null
    })
  )
  .case(
    actions.deleteNetwork.failed,
    (state): Reducer => ({
      ...state,
      isNetworkDeleting: false
    })
  )
  .case(
    actions.getRouter.started,
    (state): Reducer => ({
      ...state,
      isRouterLoading: true
    })
  )
  .case(
    actions.getRouter.done,
    (state, payload): Reducer => ({
      ...state,
      router: payload.result,
      isRouterLoading: false
    })
  )
  .case(
    actions.getRouter.failed,
    (state): Reducer => ({
      ...state,
      isRouterLoading: false
    })
  )
  .case(
    actions.getRouters.started,
    (state): Reducer => ({
      ...state,
      areRoutersLoading: true
    })
  )
  .case(
    actions.getRouters.done,
    (state, payload): Reducer => ({
      ...state,
      routers: payload.result,
      areRoutersLoading: false
    })
  )
  .case(
    actions.getRouters.failed,
    (state): Reducer => ({
      ...state,
      areRoutersLoading: false
    })
  )
  .case(
    actions.createRouter.started,
    (state): Reducer => ({
      ...state,
      isRouterCreating: true
    })
  )
  .case(
    actions.createRouter.done,
    (state): Reducer => ({
      ...state,
      isRouterCreating: false
    })
  )
  .case(
    actions.createRouter.failed,
    (state): Reducer => ({
      ...state,
      isRouterCreating: false
    })
  )
  .case(
    actions.updateRouter.started,
    (state): Reducer => ({
      ...state,
      isRouterUpdating: true
    })
  )
  .case(
    actions.updateRouter.done,
    (state): Reducer => ({
      ...state,
      isRouterUpdating: false
    })
  )
  .case(
    actions.updateRouter.failed,
    (state): Reducer => ({
      ...state,
      isRouterUpdating: false
    })
  )
  .case(
    actions.deleteRouter.started,
    (state): Reducer => ({
      ...state,
      isRouterDeleting: true
    })
  )
  .case(
    actions.deleteRouter.done,
    (state): Reducer => ({
      ...state,
      isRouterDeleting: false,
      router: null
    })
  )
  .case(
    actions.deleteRouter.failed,
    (state): Reducer => ({
      ...state,
      isRouterDeleting: false
    })
  )
  .case(
    actions.getInterfaces.started,
    (state): Reducer => ({
      ...state,
      areInterfacesLoading: true
    })
  )
  .case(
    actions.getInterfaces.done,
    (state, payload): Reducer => ({
      ...state,
      interfaces: payload.result,
      areRouterInterfacesLoading: false
    })
  )
  .case(
    actions.getInterfaces.failed,
    (state): Reducer => ({
      ...state,
      areInterfacesLoading: false
    })
  )
  .case(
    actions.getRouterInterfaces.started,
    (state): Reducer => ({
      ...state,
      areRouterInterfacesLoading: true
    })
  )
  .case(
    actions.getRouterInterfaces.done,
    (state, payload): Reducer => ({
      ...state,
      routerInterfaces: payload.result,
      areRouterInterfacesLoading: false
    })
  )
  .case(
    actions.getRouterInterfaces.failed,
    (state): Reducer => ({
      ...state,
      areRouterInterfacesLoading: false
    })
  )
  .case(
    actions.addRouterInterface.started,
    (state): Reducer => ({
      ...state,
      isRouterInterfaceAdding: true
    })
  )
  .case(
    actions.addRouterInterface.done,
    (state): Reducer => ({
      ...state,
      isRouterInterfaceAdding: false
    })
  )
  .case(
    actions.addRouterInterface.failed,
    (state): Reducer => ({
      ...state,
      isRouterInterfaceAdding: false
    })
  )
  .case(
    actions.deleteRouterInterface.started,
    (state): Reducer => ({
      ...state,
      isRouterInterfaceDeleting: true
    })
  )
  .case(
    actions.deleteRouterInterface.done,
    (state): Reducer => ({
      ...state,
      isRouterInterfaceDeleting: false
    })
  )
  .case(
    actions.deleteRouterInterface.failed,
    (state): Reducer => ({
      ...state,
      isRouterInterfaceDeleting: false
    })
  )
  .case(
    actions.editInterfaceFirewalls.started,
    (state): Reducer => ({
      ...state,
      isInterfaceFirewallsEditing: true
    })
  )
  .case(
    actions.editInterfaceFirewalls.done,
    (state): Reducer => ({
      ...state,
      isInterfaceFirewallsEditing: false
    })
  )
  .case(
    actions.editInterfaceFirewalls.failed,
    (state): Reducer => ({
      ...state,
      isInterfaceFirewallsEditing: false
    })
  )
  .case(
    actions.getNetworkLimits.started,
    (state): Reducer => ({
      ...state,
      areNetworkLimitsLoading: true
    })
  )
  .case(
    actions.getNetworkLimits.done,
    (state, payload): Reducer => ({
      ...state,
      networkLimits: payload.result,
      areNetworkLimitsLoading: false
    })
  )
  .case(
    actions.getNetworkLimits.failed,
    (state): Reducer => ({
      ...state,
      areNetworkLimitsLoading: false
    })
  )
  .case(
    actions.getSubnet.started,
    (state): Reducer => ({
      ...state,
      isSubnetLoading: true
    })
  )
  .case(
    actions.getSubnet.done,
    (state, payload): Reducer => ({
      ...state,
      subnet: payload.result,
      isSubnetLoading: false
    })
  )
  .case(
    actions.getSubnet.failed,
    (state): Reducer => ({
      ...state,
      isSubnetLoading: false
    })
  )
  .case(
    actions.getSubnets.started,
    (state): Reducer => ({
      ...state,
      areSubnetsLoading: true
    })
  )
  .case(
    actions.getSubnets.done,
    (state, payload): Reducer => ({
      ...state,
      subnets: payload.result,
      areSubnetsLoading: false
    })
  )
  .case(
    actions.getSubnets.failed,
    (state): Reducer => ({
      ...state,
      areSubnetsLoading: false
    })
  )
  .case(
    actions.getNetworkSubnets.started,
    (state): Reducer => ({
      ...state,
      areNetworkSubnetsLoading: true
    })
  )
  .case(
    actions.getNetworkSubnets.done,
    (state, payload): Reducer => ({
      ...state,
      networkSubnets: payload.result,
      areNetworkSubnetsLoading: false
    })
  )
  .case(
    actions.getNetworkSubnets.failed,
    (state): Reducer => ({
      ...state,
      areNetworkSubnetsLoading: false
    })
  )
  .case(
    actions.createSubnet.started,
    (state): Reducer => ({
      ...state,
      isSubnetCreating: true
    })
  )
  .case(
    actions.createSubnet.done,
    (state): Reducer => ({
      ...state,
      isSubnetCreating: false
    })
  )
  .case(
    actions.createSubnet.failed,
    (state): Reducer => ({
      ...state,
      isSubnetCreating: false
    })
  )
  .case(
    actions.updateSubnet.started,
    (state): Reducer => ({
      ...state,
      isSubnetUpdating: true
    })
  )
  .case(
    actions.updateSubnet.done,
    (state): Reducer => ({
      ...state,
      isSubnetUpdating: false
    })
  )
  .case(
    actions.updateSubnet.failed,
    (state): Reducer => ({
      ...state,
      isSubnetUpdating: false
    })
  )
  .case(
    actions.deleteSubnet.started,
    (state): Reducer => ({
      ...state,
      isSubnetDeleting: true
    })
  )
  .case(
    actions.deleteSubnet.done,
    (state): Reducer => ({
      ...state,
      isSubnetDeleting: false
    })
  )
  .case(
    actions.deleteSubnet.failed,
    (state): Reducer => ({
      ...state,
      isSubnetDeleting: false
    })
  )
  .case(
    actions.getFirewall.started,
    (state): Reducer => ({
      ...state,
      isFirewallLoading: true
    })
  )
  .case(
    actions.getFirewall.done,
    (state, payload): Reducer => ({
      ...state,
      firewall: payload.result,
      isFirewallLoading: false
    })
  )
  .case(
    actions.getFirewall.failed,
    (state): Reducer => ({
      ...state,
      isFirewallLoading: false
    })
  )
  .case(
    actions.getFirewalls.started,
    (state): Reducer => ({
      ...state,
      areFirewallsLoading: true
    })
  )
  .case(
    actions.getFirewalls.done,
    (state, payload): Reducer => ({
      ...state,
      firewalls: payload.result,
      areFirewallsLoading: false
    })
  )
  .case(
    actions.getFirewalls.failed,
    (state): Reducer => ({
      ...state,
      areFirewallsLoading: false
    })
  )
  .case(
    actions.createFirewall.started,
    (state): Reducer => ({
      ...state,
      isFirewallCreating: true
    })
  )
  .case(
    actions.createFirewall.done,
    (state): Reducer => ({
      ...state,
      isFirewallCreating: false
    })
  )
  .case(
    actions.createFirewall.failed,
    (state): Reducer => ({
      ...state,
      isFirewallCreating: false
    })
  )
  .case(
    actions.updateFirewall.started,
    (state): Reducer => ({
      ...state,
      isFirewallUpdating: true
    })
  )
  .case(
    actions.updateFirewall.done,
    (state): Reducer => ({
      ...state,
      isFirewallUpdating: false
    })
  )
  .case(
    actions.updateFirewall.failed,
    (state): Reducer => ({
      ...state,
      isFirewallUpdating: false
    })
  )
  .case(
    actions.deleteFirewall.started,
    (state): Reducer => ({
      ...state,
      isFirewallDeleting: true
    })
  )
  .case(
    actions.deleteFirewall.done,
    (state): Reducer => ({
      ...state,
      isFirewallDeleting: false,
      firewall: null
    })
  )
  .case(
    actions.deleteFirewall.failed,
    (state): Reducer => ({
      ...state,
      isFirewallDeleting: false
    })
  )
  .case(
    actions.getFirewallRule.started,
    (state): Reducer => ({
      ...state,
      isFirewallRuleLoading: true
    })
  )
  .case(
    actions.getFirewallRule.done,
    (state, payload): Reducer => ({
      ...state,
      firewallRule: payload.result,
      isFirewallRuleLoading: false
    })
  )
  .case(
    actions.getFirewallRule.failed,
    (state): Reducer => ({
      ...state,
      isFirewallRuleLoading: false
    })
  )
  .case(
    actions.getFirewallRules.started,
    (state): Reducer => ({
      ...state,
      areFirewallRulesLoading: true
    })
  )
  .case(
    actions.getFirewallRules.done,
    (state, payload): Reducer => ({
      ...state,
      firewallRules: payload.result,
      areFirewallRulesLoading: false
    })
  )
  .case(
    actions.getFirewallRules.failed,
    (state): Reducer => ({
      ...state,
      areFirewallRulesLoading: false
    })
  )
  .case(
    actions.createFirewallRule.started,
    (state): Reducer => ({
      ...state,
      isFirewallRuleCreating: true
    })
  )
  .case(
    actions.createFirewallRule.done,
    (state): Reducer => ({
      ...state,
      isFirewallRuleCreating: false
    })
  )
  .case(
    actions.createFirewallRule.failed,
    (state): Reducer => ({
      ...state,
      isFirewallRuleCreating: false
    })
  )
  .case(
    actions.deleteFirewallRule.started,
    (state): Reducer => ({
      ...state,
      isFirewallRuleDeleting: true
    })
  )
  .case(
    actions.deleteFirewallRule.done,
    (state): Reducer => ({
      ...state,
      isFirewallRuleDeleting: false,
      firewallRule: null
    })
  )
  .case(
    actions.deleteFirewallRule.failed,
    (state): Reducer => ({
      ...state,
      isFirewallRuleDeleting: false
    })
  )
  .case(
    actions.addRoute.started,
    (state): Reducer => ({
      ...state,
      isFirewallRuleCreating: true
    })
  )
  .case(
    actions.addRoute.done,
    (state): Reducer => ({
      ...state,
      isFirewallRuleCreating: false
    })
  )
  .case(
    actions.addRoute.failed,
    (state): Reducer => ({
      ...state,
      isFirewallRuleCreating: false
    })
  )
  .case(
    actions.deleteRoute.started,
    (state): Reducer => ({
      ...state,
      isFirewallRuleDeleting: true
    })
  )
  .case(
    actions.deleteRoute.done,
    (state): Reducer => ({
      ...state,
      isFirewallRuleDeleting: false,
      firewallRule: null
    })
  )
  .case(
    actions.deleteRoute.failed,
    (state): Reducer => ({
      ...state,
      isFirewallRuleDeleting: false
    })
  )
  .case(
    actions.getFloatingIPs.started,
    (state): Reducer => ({
      ...state,
      areFloatingIPsLoading: true
    })
  )
  .case(
    actions.getFloatingIPs.done,
    (state, payload): Reducer => ({
      ...state,
      floatingIPs: payload.result,
      areFloatingIPsLoading: false
    })
  )
  .case(
    actions.getFloatingIPs.failed,
    (state): Reducer => ({
      ...state,
      areFloatingIPsLoading: false
    })
  )
  .case(
    actions.createFloatingIP.started,
    (state): Reducer => ({
      ...state,
      isFloatingIPCreating: true
    })
  )
  .case(
    actions.createFloatingIP.done,
    (state): Reducer => ({
      ...state,
      isFloatingIPCreating: false
    })
  )
  .case(
    actions.createFloatingIP.failed,
    (state): Reducer => ({
      ...state,
      isFloatingIPCreating: false
    })
  )
  .case(
    actions.updateFloatingIP.started,
    (state): Reducer => ({
      ...state,
      isFloatingIPUpdating: true
    })
  )
  .case(
    actions.updateFloatingIP.done,
    (state): Reducer => ({
      ...state,
      isFloatingIPUpdating: false
    })
  )
  .case(
    actions.updateFloatingIP.failed,
    (state): Reducer => ({
      ...state,
      isFloatingIPUpdating: false
    })
  )
  .case(
    actions.deleteFloatingIP.started,
    (state): Reducer => ({
      ...state,
      isFloatingIPDeleting: true
    })
  )
  .case(
    actions.deleteFloatingIP.done,
    (state): Reducer => ({
      ...state,
      isFloatingIPDeleting: false,
      network: null
    })
  )
  .case(
    actions.deleteNetwork.failed,
    (state): Reducer => ({
      ...state,
      isFloatingIPDeleting: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
