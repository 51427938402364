import MuiGlobalStyles from "@mui/material/GlobalStyles";
import { FC } from "react";
import "../../../assets/styles.css";

export const GlobalStyles: FC = () => (
  <MuiGlobalStyles
    styles={`
      html, body, #root {
        height: 100%;
      }

      body {
        div#hubspot-messages-iframe-container {
          bottom: 35px!important;
        }
      }
    `}
  />
);
