import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { ApiSession, KeycloakUser } from "./types";

export type Reducer = {
  isApiLoggingIn: boolean;
  isApiLoggingOut: boolean;
  isApiReloggingIn: boolean;
  isLoggingOut: boolean;
  isUserLoading: boolean;
  user: KeycloakUser | null;
  apiSession: ApiSession | null;
};

const initialState: Reducer = {
  apiSession: null,
  isApiLoggingIn: false,
  isApiLoggingOut: false,
  isApiReloggingIn: false,
  isLoggingOut: false,
  isUserLoading: false,
  user: null
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getUserDetails.started,
    (state): Reducer => ({
      ...state,
      isUserLoading: true
    })
  )
  .case(
    actions.getUserDetails.done,
    (state, payload): Reducer => ({
      ...state,
      isUserLoading: false,
      user: payload.result
    })
  )
  .case(
    actions.getUserDetails.failed,
    (state): Reducer => ({
      ...state,
      isUserLoading: false
    })
  )
  .case(
    actions.apiLogin.started,
    (state): Reducer => ({
      ...state,
      isApiLoggingIn: true
    })
  )
  .case(
    actions.apiLogin.done,
    (state, payload): Reducer => ({
      ...state,
      isApiLoggingIn: false,
      apiSession: payload.result
    })
  )
  .case(
    actions.apiLogin.failed,
    (state): Reducer => ({
      ...state,
      isApiLoggingIn: false
    })
  )
  .case(
    actions.apiLogout.started,
    (state): Reducer => ({
      ...state,
      isApiLoggingOut: true
    })
  )
  .case(
    actions.apiLogout.done,
    (state): Reducer => ({
      ...state,
      isApiLoggingOut: false
    })
  )
  .case(
    actions.apiLogout.failed,
    (state): Reducer => ({
      ...state,
      isApiLoggingOut: false
    })
  )
  .case(
    actions.apiRelogin.started,
    (state): Reducer => ({
      ...state,
      isApiReloggingIn: true
    })
  )
  .case(
    actions.apiRelogin.done,
    (state): Reducer => ({
      ...state,
      isApiReloggingIn: false
    })
  )
  .case(
    actions.apiRelogin.failed,
    (state): Reducer => ({
      ...state,
      isApiReloggingIn: false
    })
  )
  .case(
    actions.logout.started,
    (state): Reducer => ({
      ...state,
      isLoggingOut: true
    })
  )
  .case(
    actions.logout.done,
    (state): Reducer => ({
      ...state,
      isLoggingOut: false
    })
  )
  .case(
    actions.logout.failed,
    (state): Reducer => ({
      ...state,
      isLoggingOut: false
    })
  );
