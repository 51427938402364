import { TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const DetailsTitle = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.9rem;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-size: 0.9rem;
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1, 0, 0, 0)};
`;

export const TagPublic = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(0)};
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
`;

export const ProSearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

export const ProSearchTextField = styled(TextField)<{ $expanded?: boolean }>`
  width: ${({ $expanded }) => ($expanded ? "200px" : "52px")};
  /* overflow: hidden; */
  /* opacity: ${({ $expanded }) => ($expanded ? "1" : "0")}; */
  transition:
    width 0.3s ease,
    opacity 0.3s ease;
`;
