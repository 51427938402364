import { Action as ReduxAction } from "redux";
import { SagaIterator } from "redux-saga";
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery
} from "redux-saga/effects";
import { isObject } from "typeGuards/isObject";
import { Action } from "typescript-fsa";
import * as actions from "./actions";
import { pendingPollsSelector } from "./selectors";
import { Duration, StartPollingParams } from "./types";

function* pollingWorkerSaga(action: Action<Record<string, unknown>>) {
  const DEFAULT_POLLING_INTERVAL = 10 * 1000; // in milliseconds
  const POLLING_INTERVAL =
    (action?.payload?.interval as Duration) || DEFAULT_POLLING_INTERVAL;
  while (true) {
    if (!document.hidden) {
      yield put(action);
    }
    yield delay(POLLING_INTERVAL);
  }
}

function* pollingWatcherSaga(action: Action<StartPollingParams>) {
  const actionToPoll = {
    ...action.payload.action,
    payload: {
      ...action.payload.action.payload,
      pollingId: action.payload.id,
      interval: action.payload.interval
    }
  };
  yield race({
    task: call(pollingWorkerSaga, actionToPoll),
    cancel: take(
      (stopAction: ReduxAction) =>
        stopAction.type === actions.stopPolling.type &&
        isObject((stopAction as Action<unknown>).payload) &&
        action.payload.id ===
          (stopAction as Action<Record<string, unknown>>).payload.id
    )
  });
}

export function* isPollStoppedSaga(
  action: Action<Record<string, unknown>>
): SagaIterator<boolean> {
  const pendingPolls: string[] = yield select(pendingPollsSelector);
  return Boolean(
    action.payload.pollingId &&
      !pendingPolls.includes(action.payload.pollingId as string)
  );
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([takeEvery(actions.startPolling, pollingWatcherSaga)]);
}
