import { capitalize } from "utils/capitalize";
import { formatDate } from "utils/formatDate";
import { formatProtocol } from "utils/formatProtocol";
import { formatRegion } from "utils/formatRegion";
import { DATE_FORMATS } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  Firewall,
  FirewallRule,
  FloatingIP,
  Interface,
  Network,
  NetworkLimits,
  Router,
  Subnet,
  TableFirewall,
  TableFirewallRule,
  TableFloatingIP,
  TableInterface,
  TableNetwork,
  TableRouter
} from "./types";
import { createSelector } from "reselect";

const getNetworks = (state: RootReducer) => state.networks.networks;
export const tableNetworksSelector = createSelector(
  [getNetworks],
  (networks) => {
    return (
      networks?.map((network) => ({
        id: network.id,
        name: network.name,
        region: formatRegion(network.region),
        shared: network.shared,
        status: network.status,
        updatedAt: formatDate(
          new Date(network.updated_at),
          DATE_FORMATS.DATETIME
        ),
        description: network.description
      })) || null
    );
  }
);

// export const tableNetworksSelector = (
//   state: RootReducer
// ): TableNetwork[] | null =>
//   state.networks.networks?.map((network) => ({
//     id: network.id,
//     name: network.name,
//     region: formatRegion(network.region),
//     shared: network.shared,
//     status: network.status,
//     updatedAt: formatDate(new Date(network.updated_at), DATE_FORMATS.DATETIME),
//     description: network.description
//   })) || null;

export const networkSelector = (state: RootReducer): Network | null =>
  state.networks.network;

export const floatingIPsSelector = (state: RootReducer): FloatingIP[] | null =>
  state.networks.floatingIPs;

export const tableFloatingIPsSelector = (
  state: RootReducer
): TableFloatingIP[] | null =>
  state.networks.floatingIPs?.map((floatingIP) => ({
    id: floatingIP.id,
    ipAddress: floatingIP.floating_ip_address,
    fixedIPAddress: floatingIP.port_ip_address,
    descriptions: floatingIP.description,
    status: floatingIP.status
  })) || null;

export const areFloatingIPsLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areFloatingIPsLoading;

export const isFloatingIPCreating = (state: RootReducer): boolean =>
  state.networks.isFloatingIPCreating;

export const isFloatingIPUpdatingSelector = (state: RootReducer): boolean =>
  state.networks.isFloatingIPUpdating;

export const isFloatingIPDeletingSelector = (state: RootReducer): boolean =>
  state.networks.isFloatingIPDeleting;

export const routerSelector = (state: RootReducer): Router | null =>
  state.networks.router;

export const networkSubnetsSelector = (state: RootReducer): Subnet[] | null =>
  state.networks.networkSubnets;

export const subnetsSelector = (state: RootReducer): Subnet[] | null =>
  state.networks.subnets;

export const isFirewallLoadingSelector = (state: RootReducer): boolean =>
  state.networks.isFirewallLoading;

export const isFirewallCreatingSelector = (state: RootReducer): boolean =>
  state.networks.isFirewallCreating;

export const isFirewallUpdatingSelector = (state: RootReducer): boolean =>
  state.networks.isFirewallUpdating;

export const isFirewallDeletingSelector = (state: RootReducer): boolean =>
  state.networks.isFirewallDeleting;

export const firewallsSelector = (state: RootReducer): Firewall[] | null =>
  state.networks.firewalls;

export const tableFirewallsSelector = createSelector(
  [firewallsSelector],
  (firewalls) => {
    return (
      firewalls?.map((firewall) => ({
        name: firewall.name,
        id: firewall.id,
        description: firewall.description
      })) || null
    );
  }
);
// export const tableFirewallsSelector = (
//   state: RootReducer
// ): TableFirewall[] | null =>
//   state.networks.firewalls?.map((firewall) => ({
//     name: firewall.name,
//     id: firewall.id,
//     description: firewall.description
//   })) || null;

export const firewallSelector = (state: RootReducer): Firewall | null =>
  state.networks.firewall;

export const firewallRuleSelector = (state: RootReducer): FirewallRule | null =>
  state.networks.firewallRule;

export const tableFirewallRulesSelector = (
  state: RootReducer
): TableFirewallRule[] | null =>
  state.networks.firewallRules?.map((firewallRule) => ({
    id: firewallRule.id,
    direction: capitalize(firewallRule.direction),
    protocol: formatProtocol(firewallRule.protocol),
    ipVersion: firewallRule.ethertype,
    description: firewallRule.description,
    portRange: firewallRule.port_range,
    ipRanges: firewallRule.remote_ip_prefix,
    remoteFirewall:
      state.networks.firewalls?.find(
        (firewall) => firewall.id === firewallRule.remote_group_id
      )?.name || ""
  })) || null;

export const isNetworkCreatingSelector = (state: RootReducer): boolean =>
  state.networks.isNetworkCreating;

export const isNetworkUpdatingSelector = (state: RootReducer): boolean =>
  state.networks.isNetworkUpdating;

export const isNetworkDeletingSelector = (state: RootReducer): boolean =>
  state.networks.isNetworkDeleting;

export const isNetworkLoadingSelector = (state: RootReducer): boolean =>
  state.networks.isNetworkLoading;

export const areNetworksLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areNetworksLoading;

export const networkLimitsSelector = (
  state: RootReducer
): NetworkLimits | null => state.networks.networkLimits;

export const areNetworkLimitsLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areNetworkLimitsLoading;

export const areSubnetsLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areSubnetsLoading;

export const areNetworkSubnetsLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areNetworkSubnetsLoading;

export const isSubnetCreatingSelector = (state: RootReducer): boolean =>
  state.networks.isSubnetCreating;

export const isSubnetUpdatingSelector = (state: RootReducer): boolean =>
  state.networks.isSubnetUpdating;

export const isSubnetDeletingSelector = (state: RootReducer): boolean =>
  state.networks.isSubnetDeleting;

export const areFirewallsLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areFirewallsLoading;

export const areFirewallRulesLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areFirewallRulesLoading;

export const isFirewallRuleLoadingSelector = (state: RootReducer): boolean =>
  state.networks.isFirewallRuleLoading;

export const isFirewallRuleCreatingSelector = (state: RootReducer): boolean =>
  state.networks.isFirewallRuleCreating;

export const isFirewallRuleDeletingSelector = (state: RootReducer): boolean =>
  state.networks.isFirewallRuleDeleting;

export const isRouterLoadingSelector = (state: RootReducer): boolean =>
  state.networks.isRouterLoading;

export const isRouterCreatingSelector = (state: RootReducer): boolean =>
  state.networks.isRouterCreating;

export const isRouterUpdatingSelector = (state: RootReducer): boolean =>
  state.networks.isRouterUpdating;

export const isRouterDeletingSelector = (state: RootReducer): boolean =>
  state.networks.isRouterDeleting;

export const areFloatingIPLoadingSelector = (state: RootReducer): boolean =>
  state.networks.areFloatingIPsLoading;

export const isFloatingIPCreatingSelector = (state: RootReducer): boolean =>
  state.networks.isFloatingIPCreating;

export const isRouterInterfaceAddingSelector = (state: RootReducer): boolean =>
  state.networks.isRouterInterfaceAdding;

export const isRouterInterfaceDeletingSelector = (
  state: RootReducer
): boolean => state.networks.isRouterInterfaceDeleting;

export const tableRoutersSelector = (
  state: RootReducer
): TableRouter[] | null =>
  state.networks.routers?.map((router) => ({
    id: router.id,
    name: router.name,
    status: router.status,
    externalGateway: router.external_gateway_info.external_fixed_ips
      ? "Enabled"
      : "",
    description: router.description
  })) || null;

// export const interfacesSelector = (state: RootReducer): Interface[] | null =>
//   state.networks.interfaces?.map((routerInterface) => ({
//     id: routerInterface.id,
//     status: routerInterface.status,
//     admin_state_up: routerInterface.admin_state_up,
//     mac_address: routerInterface.mac_address,
//     fixed_ips: routerInterface.fixed_ips,
//     network_id: routerInterface.network_id,
//     security_groups: routerInterface.security_groups,
//     device_id: routerInterface.device_id
//   })) || null;

const getInterfaces = (state: RootReducer) => state.networks.interfaces;
export const interfacesSelector = createSelector(
  [getInterfaces],
  (interfaces) => {
    return (
      interfaces?.map((routerInterface) => ({
        id: routerInterface.id,
        status: routerInterface.status,
        admin_state_up: routerInterface.admin_state_up,
        mac_address: routerInterface.mac_address,
        fixed_ips: routerInterface.fixed_ips,
        network_id: routerInterface.network_id,
        security_groups: routerInterface.security_groups,
        device_id: routerInterface.device_id
      })) || null
    );
  }
);

// export const tableInterfacesSelector = (
//   state: RootReducer
// ): TableInterface[] | null =>
//   state.networks.routerInterfaces?.map((routerInterface) => ({
//     id: routerInterface.id,
//     status: routerInterface.status,
//     admin_state_up: routerInterface.admin_state_up,
//     ip: routerInterface.fixed_ips
//       ? routerInterface.fixed_ips[0].ip_address
//       : "",
//     subnetId: routerInterface.fixed_ips
//       ? routerInterface.fixed_ips[0].subnet_id
//       : ""
//   })) || null;

const getRouterInterfaces = (state: RootReducer) =>
  state.networks.routerInterfaces;

export const tableInterfacesSelector = createSelector(
  [getRouterInterfaces],
  (routerInterfaces) => {
    return (
      routerInterfaces?.map((routerInterface) => ({
        id: routerInterface.id,
        status: routerInterface.status,
        admin_state_up: routerInterface.admin_state_up,
        ip: routerInterface.fixed_ips
          ? routerInterface.fixed_ips[0].ip_address
          : "",
        subnetId: routerInterface.fixed_ips
          ? routerInterface.fixed_ips[0].subnet_id
          : ""
      })) || null
    );
  }
);
