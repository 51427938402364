import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Notification } from "./types";

export type Reducer = {
  notification: Notification | null;
};

const initialState: Reducer = {
  notification: null
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.showNotification,
    (state, payload): Reducer => ({
      ...state,
      notification: payload
    })
  )
  .case(actions.clear, (): Reducer => initialState);
