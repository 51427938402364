import { formatRegion } from "utils/formatRegion";
import { DATE_FORMATS, PROJECT_STATUS_LABELS } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  BILLING_RESOURCES_TYPES,
  BILLING_RESOURCES_TYPES_TITLES,
  OrganizationBillingData,
  ProjectBillingDatabasesData,
  ProjectBillingInstancesData,
  ProjectBillingS3Data,
  ProjectBillingVolumesData,
  TableBillingDatabasesRecord,
  TableBillingInstancesRecord,
  TableBillingVolumesRecord,
  TableOrganizationProjectBillingData,
  TableTotalBillingDatabasesRecord,
  TableTotalBillingInstancesRecord,
  TableTotalBillingVolumesRecord
} from "./types";
import { formatDate } from "utils/formatDate";
import { createSelector } from "reselect";

const formatCosts = (costsData?: Record<string, number>) => {
  return costsData
    ? Object.keys(costsData).reduce(
        (acc, cur) => ({
          ...acc,
          [`cost-${cur}`]: costsData[cur].toFixed(2)
        }),
        {}
      )
    : {};
};

export const isOrganizationBillingDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isOrganizationBillingDataLoading;

export const organizationBillingDataSelector = (
  state: RootReducer
): OrganizationBillingData | null => state.billing.organizationBillingData;

export const tableOrganizationProjectsBillingDataSelector = (
  state: RootReducer
): TableOrganizationProjectBillingData[] | null =>
  state.billing.organizationBillingData?.projects.map((project) => ({
    id: project.id,
    name: project.name,
    status: PROJECT_STATUS_LABELS[project.status],
    region: formatRegion(project.region),
    ...Object.keys(project.costs).reduce(
      (acc, cur) => ({
        ...acc,
        [`cost-${cur}`]: project.costs[cur].toFixed(2)
      }),
      {}
    )
  })) || null;

export const tableOrganizationTotalBillingDataSelector = (
  state: RootReducer
): TableOrganizationProjectBillingData | null =>
  state.billing.organizationBillingData &&
  state.billing.organizationBillingData.projects.length > 0
    ? {
        id: "__total",
        name: "Total",
        status: "",
        region: "",
        ...Object.keys(state.billing.organizationBillingData.costs).reduce(
          (acc, cur) => ({
            ...acc,
            [`cost-${cur}`]:
              state.billing.organizationBillingData?.costs[cur].toFixed(2)
          }),
          {}
        )
      }
    : null;

export const isProjectBillingInstancesDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isProjectBillingInstancesDataLoading;

export const isProjectBillingVolumesDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isProjectBillingVolumesDataLoading;

export const isProjectBillingDatabasesDataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isProjectBillingDatabasesDataLoading;

export const isProjectBillingS3DataLoadingSelector = (
  state: RootReducer
): boolean => state.billing.isProjectBillingS3DataLoading;

export const projectBillingInstancesDataSelector = (
  state: RootReducer
): ProjectBillingInstancesData | null =>
  state.billing.projectBillingInstancesData;

export const tableProjectBillingInstancesRecordsSelector = createSelector(
  [projectBillingInstancesDataSelector],
  (projectBillingInstancesData) => {
    return (
      projectBillingInstancesData?.items.map((record) => ({
        id: record.id,
        type: record.type,
        name: record.name,
        status: record.deleted ? "deleted" : "available",
        deleted: record.deleted
          ? formatDate(new Date(record.deleted), DATE_FORMATS.DATETIME)
          : "",
        itemFlavors: record.itemFlavors.map((flavor) => ({
          name: flavor.name,
          description: flavor.description,
          ...formatCosts(flavor.costs)
        })),
        ...formatCosts(record.costs)
      })) || null
    );
  }
);

// export const tableProjectBillingInstancesRecordsSelector = (
//   state: RootReducer
// ): TableBillingInstancesRecord[] | null =>
//   state.billing.projectBillingInstancesData?.items.map((record) => ({
//     id: record.id,
//     type: record.type,
//     name: record.name,
//     status: record.deleted ? "deleted" : "available",
//     deleted: record.deleted
//       ? formatDate(new Date(record.deleted), DATE_FORMATS.DATETIME)
//       : "",
//     itemFlavors: record.itemFlavors.map((flavor) => ({
//       name: flavor.name,
//       description: flavor.description,
//       ...formatCosts(flavor.costs)
//     })),
//     ...formatCosts(record.costs)
//   })) || null;

export const tableProjectBillingInstancesRecordsTotalSelector = (
  state: RootReducer
): TableTotalBillingInstancesRecord | null =>
  state.billing.projectBillingInstancesData &&
  state.billing.projectBillingInstancesData.items.length > 0
    ? {
        id: "Total",
        name: "",
        deleted: "",
        itemFlavors: "",
        status: "",
        type: "",
        ...formatCosts(state.billing.projectBillingInstancesData.costs)
      }
    : null;

// volumes tab
export const projectBillingVolumesDataSelector = (
  state: RootReducer
): ProjectBillingVolumesData | null => state.billing.projectBillingVolumesData;

export const tableProjectBillingVolumesRecordsSelector = createSelector(
  [projectBillingVolumesDataSelector],
  (projectBillingVolumesData) => {
    return (
      projectBillingVolumesData?.items.map((record) => ({
        id: record.id,
        name: record.name ? record.name : "",
        type: record.type,
        deleted: record.deleted, // "true" or "false"
        status: record.deleted === "true" ? "deleted" : "available",
        itemFlavors: record.itemFlavors.map((flavor) => ({
          name: flavor.name,
          description: flavor.description,
          ...formatCosts(flavor.costs)
        })),
        ...formatCosts(record.costs)
      })) || null
    );
  }
);

// export const tableProjectBillingVolumesRecordsSelector = (
//   state: RootReducer
// ): TableBillingVolumesRecord[] | null =>
//   state.billing.projectBillingVolumesData?.items.map((record) => ({
//     id: record.id,
//     name: record.name ? record.name : "",
//     type: record.type,
//     deleted: record.deleted, // "true" or "false"
//     status: record.deleted === "true" ? "deleted" : "available",
//     itemFlavors: record.itemFlavors.map((flavor) => ({
//       name: flavor.name,
//       description: flavor.description,
//       ...formatCosts(flavor.costs)
//     })),
//     ...formatCosts(record.costs)
//   })) || null;

export const tableProjectBillingVolumesRecordsTotalSelector = (
  state: RootReducer
): TableTotalBillingVolumesRecord | null =>
  state.billing.projectBillingVolumesData &&
  state.billing.projectBillingVolumesData.items.length > 0
    ? {
        id: "Total",
        deleted: "",
        status: "",
        name: "",
        itemFlavors: "",
        type: "",
        ...formatCosts(state.billing.projectBillingVolumesData.costs)
      }
    : null;

// databases tab
export const projectBillingDatabasesDataSelector = (
  state: RootReducer
): ProjectBillingDatabasesData | null =>
  state.billing.projectBillingDatabasesData;

export const tableProjectBillingDatabasesRecordsSelector = createSelector(
  [projectBillingDatabasesDataSelector],
  (projectBillingDatabasesData) => {
    return (
      projectBillingDatabasesData?.items.map((record) => ({
        id: record.id,
        name: record.name,
        type: record.type,
        status: record.deleted === "true" ? "deleted" : "available",
        deleted: record.deleted,
        itemFlavors: record.itemFlavors.map((flavor) => ({
          name: flavor.name,
          ...formatCosts(flavor.costs)
        })),
        ...formatCosts(record.costs)
      })) || null
    );
  }
);

// export const tableProjectBillingDatabasesRecordsSelector = (
//   state: RootReducer
// ): TableBillingDatabasesRecord[] | null =>
//   state.billing.projectBillingDatabasesData?.items.map((record) => ({
//     id: record.id,
//     name: record.name,
//     type: record.type,
//     status: record.deleted === "true" ? "deleted" : "available",
//     deleted: record.deleted,
//     itemFlavors: record.itemFlavors.map((flavor) => ({
//       name: flavor.name,
//       ...formatCosts(flavor.costs)
//     })),
//     ...formatCosts(record.costs)
//   })) || null;

const getProjectBillingDatabasesData = (state: RootReducer) =>
  state.billing.projectBillingDatabasesData;
export const tableProjectBillingDatabasesRecordsTotalSelector = createSelector(
  [getProjectBillingDatabasesData],
  (projectBillingDatabasesData) => {
    return projectBillingDatabasesData &&
      projectBillingDatabasesData.items.length > 0
      ? {
          id: "Total",
          name: "",
          deleted: "",
          status: "",
          itemFlavors: "",
          type: "",
          ...formatCosts(projectBillingDatabasesData.costs)
        }
      : null;
  }
);

// export const tableProjectBillingDatabasesRecordsTotalSelector = (
//   state: RootReducer
// ): TableTotalBillingDatabasesRecord | null =>
//   state.billing.projectBillingDatabasesData &&
//   state.billing.projectBillingDatabasesData.items.length > 0
//     ? {
//         id: "Total",
//         name: "",
//         deleted: "",
//         status: "",
//         itemFlavors: "",
//         type: "",
//         ...formatCosts(state.billing.projectBillingDatabasesData.costs)
//       }
//     : null;

// s3 tab
export const projectBillingS3DataSelector = (
  state: RootReducer
): ProjectBillingS3Data | null => state.billing.projectBillingS3Data;

export const tableProjectBillingS3RecordsSelector = createSelector(
  [projectBillingS3DataSelector],
  (projectBillingS3Data) => {
    return (
      projectBillingS3Data?.items.map((record) => ({
        id: record.id,
        name: record.name,
        type: record.type,
        status: record.deleted === "true" ? "deleted" : "available",
        deleted: record.deleted,
        itemFlavors:
          record.itemFlavors?.map((flavor) => ({
            name: flavor.name,
            ...formatCosts(flavor.costs)
          })) || null,
        ...formatCosts(record.costs)
      })) || null
    );
  }
);

const getProjectBillingS3Data = (state: RootReducer) =>
  state.billing.projectBillingS3Data;
export const tableProjectBillingS3RecordsTotalSelector = createSelector(
  [getProjectBillingS3Data],
  (projectBillingS3Data) => {
    return projectBillingS3Data && projectBillingS3Data.items.length > 0
      ? {
          id: "Total",
          name: "",
          deleted: "",
          status: "",
          itemFlavors: "",
          type: "",
          ...formatCosts(projectBillingS3Data.costs)
        }
      : null;
  }
);

// summary tab

const getInstancesCosts = (state: RootReducer) =>
  state.billing.projectBillingInstancesData?.costs;
const getVolumesCosts = (state: RootReducer) =>
  state.billing.projectBillingVolumesData?.costs;
const getDatabasesCosts = (state: RootReducer) =>
  state.billing.projectBillingDatabasesData?.costs;
const getS3Costs = (state: RootReducer) =>
  state.billing.projectBillingS3Data?.costs;

export const tableProjectBillingSummaryRecordsSelector = createSelector(
  [getInstancesCosts, getVolumesCosts, getDatabasesCosts, getS3Costs],
  (instancesCosts, volumesCosts, databasesCosts, s3Costs) => {
    return [
      {
        id: BILLING_RESOURCES_TYPES_TITLES[BILLING_RESOURCES_TYPES.INSTANCES],
        ...formatCosts(instancesCosts)
      },
      {
        id: BILLING_RESOURCES_TYPES_TITLES[BILLING_RESOURCES_TYPES.VOLUMES],
        ...formatCosts(volumesCosts)
      },
      {
        id: BILLING_RESOURCES_TYPES_TITLES[BILLING_RESOURCES_TYPES.DATABASES],
        ...formatCosts(databasesCosts)
        // Uncomment and update this part if you need to add collapsed data to the summary tab
        // items: state.billing.projectBillingDatabasesData?.items.map((item) => ({
        //   id: item.id,
        //   ...formatCosts(item.costs)
        // })),
      },
      {
        id: BILLING_RESOURCES_TYPES_TITLES[BILLING_RESOURCES_TYPES.S3],
        ...formatCosts(s3Costs)
      }
    ];
  }
);

export const tableProjectBillingSummaryRecordsTotalSelector = createSelector(
  [getInstancesCosts, getVolumesCosts, getDatabasesCosts, getS3Costs],
  (instancesCosts, volumesCosts, databasesCosts, s3Costs) => {
    const totalCosts = { chf: 0, eur: 0 };

    [instancesCosts, volumesCosts, databasesCosts, s3Costs].forEach((costs) => {
      if (costs) {
        Object.keys(totalCosts).forEach((currency) => {
          totalCosts[currency] += costs[currency] || 0;
        });
      }
    });

    return {
      id: "Total",
      ...formatCosts(totalCosts)
    };
  }
);
