import actionCreatorFactory from "typescript-fsa";
import {
  AddFirewallParams,
  AddFirewallResponse,
  AttachVolumeParams,
  AttachVolumeResponse,
  ConfirmResizeInstanceParams,
  ConfirmResizeInstanceResponse,
  CreateInstanceParams,
  CreateInstanceResponse,
  CreateInterfaceParams,
  CreateInterfaceResponse,
  DeleteInstanceParams,
  DeleteInstanceResponse,
  DeleteInterfaceParams,
  DeleteInterfaceResponse,
  DetachVolumeParams,
  DetachVolumeResponse,
  GetFlavorParams,
  GetFlavorResponse,
  GetFlavorsParams,
  GetFlavorsResponse,
  GetImageParams,
  GetImageResponse,
  GetImagesParams,
  GetImagesResponse,
  GetInstanceActionLogsParams,
  GetInstanceActionLogsResponse,
  GetInstanceFirewallsParams,
  GetInstanceFirewallsResponse,
  GetInstanceLimitsParams,
  GetInstanceLimitsResponse,
  GetInstanceLogParams,
  GetInstanceLogResponse,
  GetInstanceMetricParams,
  GetInstanceMetricResponse,
  GetInstanceParams,
  GetInstanceResponse,
  GetInstancesParams,
  GetInstancesResponse,
  GetInterfaceParams,
  GetInterfaceResponse,
  GetInterfacesParams,
  GetInterfacesResponse,
  GetRemoteConsoleURLParams,
  GetRemoteConsoleURLResponse,
  RemoveFirewallParams,
  RemoveFirewallResponse,
  ResizeInstanceParams,
  ResizeInstanceResponse,
  RestartInstanceParams,
  RevertResizeInstanceParams,
  RevertResizeInstanceResponse,
  StartInstanceParams,
  StopInstanceParams,
  UpdateInstanceParams,
  UpdateInstanceResponse
} from "./types";

const actionCreator = actionCreatorFactory("INSTANCES");

export const getInstance = actionCreator.async<
  GetInstanceParams,
  GetInstanceResponse,
  unknown
>("GET_INSTANCE");

export const getInstances = actionCreator.async<
  GetInstancesParams,
  GetInstancesResponse,
  unknown
>("GET_INSTANCES");

export const createInstance = actionCreator.async<
  CreateInstanceParams,
  CreateInstanceResponse,
  unknown
>("CREATE_INSTANCE");

export const updateInstance = actionCreator.async<
  UpdateInstanceParams,
  UpdateInstanceResponse,
  unknown
>("UPDATE_INSTANCE");

export const resizeInstance = actionCreator.async<
  ResizeInstanceParams,
  ResizeInstanceResponse,
  unknown
>("RESIZE_INSTANCE");

export const confirmResizeInstance = actionCreator.async<
  ConfirmResizeInstanceParams,
  ConfirmResizeInstanceResponse,
  unknown
>("CONFIRM_RESIZE_INSTANCE");

export const revertResizeInstance = actionCreator.async<
  RevertResizeInstanceParams,
  RevertResizeInstanceResponse,
  unknown
>("REVERT_RESIZE_INSTANCE");

export const deleteInstance = actionCreator.async<
  DeleteInstanceParams,
  DeleteInstanceResponse,
  unknown
>("DELETE_INSTANCE");

export const clear = actionCreator<void>("CLEAR");

export const getFlavor = actionCreator.async<
  GetFlavorParams,
  GetFlavorResponse,
  unknown
>("GET_FLAVOR");

export const getFlavors = actionCreator.async<
  GetFlavorsParams,
  GetFlavorsResponse,
  unknown
>("GET_FLAVORS");

export const startInstance = actionCreator.async<
  StartInstanceParams,
  void,
  unknown
>("START_INSTANCE");

export const restartInstance = actionCreator.async<
  RestartInstanceParams,
  void,
  unknown
>("RESTART_INSTANCE");

export const stopInstance = actionCreator.async<
  StopInstanceParams,
  void,
  unknown
>("STOP_INSTANCE");

export const getImage = actionCreator.async<
  GetImageParams,
  GetImageResponse,
  unknown
>("GET_IMAGE");

export const getImages = actionCreator.async<
  GetImagesParams,
  GetImagesResponse,
  unknown
>("GET_IMAGES");

export const getInterface = actionCreator.async<
  GetInterfaceParams,
  GetInterfaceResponse,
  unknown
>("GET_INTERFACE");

export const getInterfaces = actionCreator.async<
  GetInterfacesParams,
  GetInterfacesResponse,
  unknown
>("GET_INTERFACES");

export const createInterface = actionCreator.async<
  CreateInterfaceParams,
  CreateInterfaceResponse,
  unknown
>("CREATE_INTERFACE");

export const deleteInterface = actionCreator.async<
  DeleteInterfaceParams,
  DeleteInterfaceResponse,
  unknown
>("DELETE_INTERFACE");

export const clearInterfaces = actionCreator<void>("CLEAR_INTERFACES");

export const getInstanceLimits = actionCreator.async<
  GetInstanceLimitsParams,
  GetInstanceLimitsResponse,
  unknown
>("GET_INSTANCE_LIMITS");

export const attachVolume = actionCreator.async<
  AttachVolumeParams,
  AttachVolumeResponse,
  unknown
>("ATTACH_VOLUME");

export const detachVolume = actionCreator.async<
  DetachVolumeParams,
  DetachVolumeResponse,
  unknown
>("DETACH_VOLUME");

export const addFirewall = actionCreator.async<
  AddFirewallParams,
  AddFirewallResponse,
  unknown
>("ADD_FIREWALL");

export const removeFirewall = actionCreator.async<
  RemoveFirewallParams,
  RemoveFirewallResponse,
  unknown
>("REMOVE_FIREWALL");

export const getInstanceFirewalls = actionCreator.async<
  GetInstanceFirewallsParams,
  GetInstanceFirewallsResponse,
  unknown
>("GET_INSTANCE_FIREWALLS");

export const clearInstanceFirewalls = actionCreator<void>(
  "CLEAR_INSTANCE_FIREWALLS"
);

export const getRemoteConsoleURL = actionCreator.async<
  GetRemoteConsoleURLParams,
  GetRemoteConsoleURLResponse,
  unknown
>("GET_REMOTE_CONSOLE");

export const getInstanceLog = actionCreator.async<
  GetInstanceLogParams,
  GetInstanceLogResponse,
  unknown
>("GET_INSTANCE_LOG");

export const getInstanceMetrics = actionCreator.async<
  GetInstanceMetricParams,
  GetInstanceMetricResponse,
  unknown
>("GET_INSTANCE_METRICS");

export const getInstanceActionLogs = actionCreator.async<
  GetInstanceActionLogsParams,
  GetInstanceActionLogsResponse,
  unknown
>("GET_INSTANCE_ACTION_LOG");
