import { formatDate } from "utils/formatDate";
import { formatSize } from "utils/formatSize";
import { DATE_FORMATS, SPECIAL_NAMES } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  CorsPolicy,
  Credentials,
  S3Bucket,
  TableCredentials,
  TableS3Buckets,
  TableS3Files
} from "./types";
import { createSelector } from "reselect";

export const tableCredentialsListSelector = (
  state: RootReducer
): TableCredentials[] | null =>
  state.objectStorage.credentialsList?.map((credentials) => ({
    id: credentials.id,
    accessKey: credentials.access_key,
    accessSecret: credentials.access_secret,
    endpoint: credentials.endpoint
  })) || null;

export const credentialsListSelector = (
  state: RootReducer
): Credentials[] | null => state.objectStorage.credentialsList;

export const credentialsSelector = (state: RootReducer): Credentials | null =>
  state.objectStorage.credentials;

export const isCredentialsListLoadingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isCredentialsListLoading;

export const areCredentialsLoadingSelector = (state: RootReducer): boolean =>
  state.objectStorage.areCredentialsLoading;

export const areCredentialsCreatingSelector = (state: RootReducer): boolean =>
  state.objectStorage.areCredentialsCreating;

export const areCredentialsRegeneratingSelector = (
  state: RootReducer
): boolean => state.objectStorage.areCredentialsRegenerating;

export const areCredentialsDeletingSelector = (state: RootReducer): boolean =>
  state.objectStorage.areCredentialsDeleting;

// export const tableBucketsListSelector = (
//   state: RootReducer
// ): TableS3Buckets[] | null =>
//   state.objectStorage.bucketsList?.map((bucket) => ({
//     id: bucket.name,
//     name: bucket.name,
//     visibility: bucket.visibility,
//     url: bucket.url,
//     creationDate: formatDate(
//       new Date(bucket.creation_date),
//       DATE_FORMATS.DATETIME
//     )
//   })) || null;

const bucketsListSelector = (state: RootReducer) =>
  state.objectStorage.bucketsList;
export const tableBucketsListSelector = createSelector(
  [bucketsListSelector],
  (bucketsList) => {
    if (!bucketsList) return null;
    return bucketsList.map((bucket) => ({
      id: bucket.name,
      name: bucket.name,
      visibility: bucket.visibility,
      url: bucket.url,
      creationDate: formatDate(
        new Date(bucket.creation_date),
        DATE_FORMATS.DATETIME
      )
    }));
  }
);

export const bucketSelector = (state: RootReducer): S3Bucket | null =>
  state.objectStorage.bucket
    ? {
        name: state.objectStorage.bucket.name,
        visibility: state.objectStorage.bucket.visibility,
        url:
          state.objectStorage.bucket.url === "public"
            ? `⚠️ ${state.objectStorage.bucket.url}`
            : state.objectStorage.bucket.url,
        creation_date: formatDate(
          new Date(state.objectStorage.bucket.creation_date),
          DATE_FORMATS.DATETIME
        )
      }
    : null;

export const isBucketCreatingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isBucketCreating;

export const isBucketDeletingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isBucketDeleting;

export const tableFlatFilesListSelector = (
  state: RootReducer
): TableS3Files[] | null => {
  const filesList = state.objectStorage.filesList?.objects;

  if (!filesList) return null;

  return filesList.map((file) => ({
    id: file.name,
    name: file.key,
    key: file.key,
    isFolder: file.is_folder,
    visibility: file.visibility,
    url: file.url,
    size: formatSize(Number(file.size)),
    lastModified: file.last_modified
      ? formatDate(new Date(file.last_modified), DATE_FORMATS.DATETIME)
      : ""
  }));
};

export const tableFilesListSelector = (
  state: RootReducer
): TableS3Files[] | null => {
  const filesList = state.objectStorage.filesList?.objects;

  if (!filesList) return null;

  return filesList.map((file) => {
    // Check if the file name is "../"
    if (file.name === SPECIAL_NAMES.FOLDER) {
      return {
        id: file.name,
        name: file.name,
        key: file.key,
        isFolder: file.is_folder,
        visibility: file.visibility,
        url: file.url,
        size: "",
        lastModified: ""
      };
    }

    // If the file name is not "../", include "size" and "lastModified"
    return {
      id: file.name,
      name: file.name,
      key: file.key,
      isFolder: file.is_folder,
      visibility: file.visibility,
      url: file.url,
      size: formatSize(Number(file.size)),
      lastModified: file.last_modified
        ? formatDate(new Date(file.last_modified), DATE_FORMATS.DATETIME)
        : ""
    };
  });
};

// export const filesTotalSelector = (state: RootReducer): number | null =>
//   state.objectStorage.filesList
//     ? state.objectStorage.filesList.total_keys
//     : null;

export const filesTotalSelector = (state: RootReducer): number | null => {
  if (state.objectStorage.filesList) {
    return state.objectStorage.filesList.total_keys || null;
  } else {
    return null;
  }
};

export const isFlatListSelector = (state: RootReducer): boolean | null =>
  state.objectStorage.filesList
    ? state.objectStorage.filesList.is_flat_list
    : null;

export const isFlatFilesListLoadingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isFlatFilesListLoading;

export const isFlatFilesListByPrefixLoadingSelector = (
  state: RootReducer
): boolean => state.objectStorage.isFlatFilesListByPrefixLoading;

export const isFlatFilesListRefreshingSelector = (
  state: RootReducer
): boolean => state.objectStorage.isFlatFilesListRefreshing;

export const pageMapSelector = (
  state: RootReducer
): { [key: number]: string } | null => {
  return state.objectStorage.filesList
    ? state.objectStorage.filesList.page_map
    : null;
};

export const isNextPageAvailableSelector = (state: RootReducer): boolean =>
  state.objectStorage.filesList?.is_next_page_available || false;

export const isFileUploadingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isFileUploading;

export const isFileDeletingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isFileDeleting;

export const areFilesDeletingSelector = (state: RootReducer): boolean =>
  state.objectStorage.areFilesDeleting;

export const isFolderCreatingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isFolderCreating;

export const ifFileSignedDownloadLinkGeneratingSelector = (
  state: RootReducer
): boolean => state.objectStorage.ifFileSignedDownloadLinkGenerating;

export const isFileDownloadingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isFileDownloading;

export const uploadProgressSelector = (state: RootReducer): number =>
  state.objectStorage.uploadProgress;

export const isUploadSuccessfulSelector = (state: RootReducer): boolean =>
  state.objectStorage.isUploadSuccessful;

export const isBucketVisibilityChangingSelector = (
  state: RootReducer
): boolean => state.objectStorage.isBucketVisibilityChanging;

export const isCorsPolicyCheckingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isCorsPolicyChecking;

export const isCorsPolicySettingSelector = (state: RootReducer): boolean =>
  state.objectStorage.isCorsPolicySetting;

export const corsPolicySelector = (state: RootReducer): CorsPolicy | null =>
  state.objectStorage.corsPolicy || null;
