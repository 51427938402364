import actionCreatorFactory from "typescript-fsa";
import {
  GetEmotionsPredictionParams,
  GetEmotionsPredictionResponse
} from "./types";

const actionCreator = actionCreatorFactory("NLP");

export const getEmotionsPrediction = actionCreator.async<
  GetEmotionsPredictionParams,
  GetEmotionsPredictionResponse,
  unknown
>("GET_EMOTIONS_PREDICTION");

export const clear = actionCreator<void>("CLEAR_EMOTIONS_PREDICTION");
