import DeleteIcon from "@mui/icons-material/Delete";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";
import LinkIcon from "@mui/icons-material/Link";
import PublicIcon from "@mui/icons-material/Public";
import PublicOffIcon from "@mui/icons-material/PublicOff";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Divider,
  InputAdornment,
  SelectChangeEvent
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import PopupBanner from "components/common/PopupBanner";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableMultiActionsItem,
  TableRowActionsMenuItem,
  TableTitleActionsMenuItem
} from "components/common/Table/types";
import { Uploader } from "components/common/Uploader";
import copy from "copy-to-clipboard";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import * as objectStorageActions from "modules/objectStorage/actions";
import {
  areFilesDeletingSelector,
  bucketSelector,
  credentialsSelector,
  filesTotalSelector,
  isBucketDeletingSelector,
  isBucketVisibilityChangingSelector,
  isCorsPolicySettingSelector,
  isFileDeletingSelector,
  isFileDownloadingSelector,
  isFileUploadingSelector,
  isFlatFilesListByPrefixLoadingSelector,
  isFlatFilesListLoadingSelector,
  isFlatFilesListRefreshingSelector,
  isFlatListSelector,
  isFolderCreatingSelector,
  isNextPageAvailableSelector,
  pageMapSelector,
  tableFilesListSelector,
  tableFlatFilesListSelector
} from "modules/objectStorage/selectors";
import { TableS3Files } from "modules/objectStorage/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";
import { isNumber } from "typeGuards/isNumber";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { number, string } from "yup";
import {
  DEFAULT_PAGINATION_LIMIT_FOR_S3,
  ERROR_MESSAGES,
  MAX_PAGINATION_LIMIT,
  REGEX,
  ROUTES,
  SPECIAL_NAMES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "S3_FILES";

const POLL_IDS = {
  bucket: "BUCKET",
  corsPolicy: "CORS_POLICY",
  filesList: "FILES_LIST"
};

const tableColumns: TableColumn<TableS3Files>[] = [
  { key: "name", label: "Name" },
  { key: "visibility", label: "Visibility" },
  { key: "size", label: "Size" },
  {
    key: "lastModified",
    label: "Modified",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

export const S3Bucket: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    credsId: string;
    bucketName: string;
  }>();
  const query = new URLSearchParams(location.search);
  const path = query.get("path") || "";
  const previousPath = usePrevious(path);
  const pageParam = Number(query.get("page"));
  const [page, setPage] = useState<number>(
    Number.isInteger(pageParam) && pageParam > 0 ? pageParam - 1 : 0
  );
  const previousPage = usePrevious(page);
  const itemsParam = Number(query.get("items"));
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number.isInteger(itemsParam) &&
      itemsParam > 0 &&
      itemsParam <= MAX_PAGINATION_LIMIT
      ? itemsParam
      : DEFAULT_PAGINATION_LIMIT_FOR_S3
  );
  const previousRowsPerPage = usePrevious(rowsPerPage);

  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const credentials = useSelector(credentialsSelector);
  const bucket = useSelector(bucketSelector);
  const filesList = useSelector(tableFilesListSelector);
  const flatFilesList = useSelector(tableFlatFilesListSelector);
  const filesTotal = useSelector(filesTotalSelector);
  const isFlatList = useSelector(isFlatListSelector);
  const pageMap = useSelector(pageMapSelector);
  const isFlatFilesListLoading = useSelector(isFlatFilesListLoadingSelector);
  const prevIsFlatList = usePrevious(isFlatList);
  const isFlatFilesListRefreshing = useSelector(
    isFlatFilesListRefreshingSelector
  );
  const isFlatFilesListByPrefixLoading = useSelector(
    isFlatFilesListByPrefixLoadingSelector
  );

  const isCorsPolicySetting = useSelector(isCorsPolicySettingSelector);
  const prevIsCorsPolicySetting = usePrevious(isCorsPolicySetting);

  const isBucketDeleting = useSelector(isBucketDeletingSelector);
  const isBucketVisibilityChanging = useSelector(
    isBucketVisibilityChangingSelector
  );
  const isBucketOperationInProgress =
    isBucketDeleting || isBucketVisibilityChanging;
  const previousIsBucketOperationInProgress = usePrevious(
    isBucketOperationInProgress
  );

  const isFolderCreating = useSelector(isFolderCreatingSelector);
  const isFileUploading = useSelector(isFileUploadingSelector);
  const isFileDownloading = useSelector(isFileDownloadingSelector);
  const prevIsFileDownloading = usePrevious(isFileDownloading);
  const isFileDeleting = useSelector(isFileDeletingSelector);
  const prevIsFileDeleting = usePrevious(isFileDeleting);
  const areFilesDeleting = useSelector(areFilesDeletingSelector);
  const prevAreFilesDeleting = usePrevious(areFilesDeleting);
  const isFileOperationInProgress =
    isFileDeleting || areFilesDeleting || isFolderCreating || isFileUploading;
  const previousIsFileOperationInProgress = usePrevious(
    isFileOperationInProgress
  );

  const isNextPageAvailable = useSelector(isNextPageAvailableSelector);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [resetCheckboxes, setResetCheckboxes] = useState(false);
  const handleSwitchOffResetCheckboxes = useCallback(
    () => setResetCheckboxes(false),
    []
  );

  // to add name to the confirmation windows
  const deletingFilesNames = filesList
    ?.filter((file) => checkedRows?.includes(file.name))
    .map((file) => file.name);
  const deletingFilesKeys = filesList
    ?.filter((file) => checkedRows?.includes(file.name))
    .map((file) => ({
      key: file.key,
      is_folder: file.isFolder
    }));
  const previousSelectedItemId = usePrevious(selectedItemId);
  const selectedFileId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const selectedItemKey = filesList?.find(
    (file) => file.name === selectedFileId
  )?.key;
  const selectedItemURL = filesList?.find(
    (file) => file.name === selectedFileId
  )?.url;
  const selectedItemName = filesList?.find(
    (file) => file.name === selectedFileId
  )?.name;

  // search by prefix
  const [prefix, setPrefix] = useState("");
  const prevPrefix = usePrevious(prefix);
  const handlePrefixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrefix(event.target.value);
  };
  const [expandedFilterField, setExpandedFilterField] = useState(true);
  const handleExpandFilterField = () => {
    setExpandedFilterField(!expandedFilterField);
  };

  const handleSearchByPrefixKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      handleSearchByPrefix();
    }
  };

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.DELETE_FILE, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const handleBannerClose = useCallback(() => {
    setIsBannerVisible(false);
  }, []);

  const handleChangePage = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const handleChangeRowsPerPage = useCallback(
    (
      event:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<number>
    ) => {
      setRowsPerPage(Number(event.target.value));
      setPage(0);
    },
    []
  );

  const handleSearchByPrefix = useCallback(() => {
    setPage(0);
    dispatch(
      objectStorageActions.getFlatFilesByPrefix.started({
        regionId: matchParams.regionId!,
        projId: matchParams.projectId!,
        credsId: matchParams.credsId!,
        bucketName: matchParams.bucketName!,
        max_keys: rowsPerPage,
        page_map: {},
        page: 1,
        prefix,
        is_flat_list: isFlatList !== null ? isFlatList : true
      })
    );
    handleCloseDialog();
  }, [
    prefix,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId,
    matchParams.bucketName,
    rowsPerPage,
    isFlatList
  ]);

  const title = `Bucket - ${matchParams.bucketName}`;
  const titleDetails = (
    <>
      <s.SummaryRow>
        <s.DetailsTitle>Visibility: </s.DetailsTitle>
        <s.DetailsInfo>
          {bucket ? (
            <s.TagPublic key={"visibility"} label={bucket.visibility} />
          ) : (
            <Loader size={24} />
          )}
        </s.DetailsInfo>
      </s.SummaryRow>
      {path && (
        <s.SummaryRow>
          <s.DetailsTitle>Folder: </s.DetailsTitle>
          <s.DetailsInfo>{path}</s.DetailsInfo>
        </s.SummaryRow>
      )}
    </>
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Storage",
      url: generatePath(ROUTES.STORAGE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "S3-Buckets",
      url: generatePath(ROUTES.S3_BUCKETS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        credsId: matchParams.credsId
      })
    },
    {
      text: matchParams.bucketName || "",
      url: generatePath(ROUTES.S3_BUCKET, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        credsId: matchParams.credsId,
        bucketName: matchParams.bucketName,
        path: ""
      })
    }
  ];

  const handleCreateFolderButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_FOLDER,
      isOpened: true
    });
  }, []);

  const handleMakePrivateBucketMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.MAKE_BUCKET_PRIVATE,
      isOpened: true
    });
  }, []);

  const handleMakePublicBucketMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.MAKE_BUCKET_PUBLIC,
      isOpened: true
    });
  }, []);

  const handleShowBucketUrlMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.SHOW_BUCKET_URL,
      isOpened: true
    });
  }, []);

  const handleDeleteBucketMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_BUCKET,
      isOpened: true
    });
  }, []);

  const handleShowFileUrlMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.SHOW_FILE_URL,
      isOpened: true
    });
  }, []);

  const handleDeleteFileMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_FILE,
      isOpened: true
    });
  }, []);

  const handleMultiDeleteFilesMenuItemClick = useCallback((ids: string[]) => {
    setCheckedRows(ids);
    setDialog({
      type: DIALOG_TYPES.DELETE_FILES,
      isOpened: true
    });
  }, []);

  const handleDownloadFileMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DOWNLOAD_FILE,
      isOpened: true
    });
  }, []);

  const handleGetFileSignedDownloadLinkMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.GET_SIGNED_DOWNLOADING_LINK,
        isOpened: true
      });
    },
    []
  );

  const handleConfirmRefreshBucket = useCallback(() => {
    setPage(0);
    dispatch(
      objectStorageActions.getFlatFilesRefreshed.started({
        regionId: matchParams.regionId!,
        projId: matchParams.projectId!,
        credsId: matchParams.credsId!,
        bucketName: matchParams.bucketName!,
        max_keys: rowsPerPage,
        page_map: {},
        page: 1,
        prefix,
        is_flat_list: true
      })
    );
  }, [
    dispatch,
    prefix,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId,
    matchParams.bucketName,
    rowsPerPage
  ]);

  const tableActions: TableRowActionsMenuItem<TableS3Files>[] = [
    {
      label: "Download",
      handler: handleDownloadFileMenuItemClick,
      isDisabled: (file) => file.name == SPECIAL_NAMES.FOLDER || file.isFolder
    },
    {
      label: "Show URL",
      isDisabled: (file) =>
        file.visibility !== "public" || file.name == SPECIAL_NAMES.FOLDER,
      handler: handleShowFileUrlMenuItemClick
    },
    // {
    //   label: "Get signed downloading link",
    //   handler: handleGetFileSignedDownloadLinkMenuItemClick
    // },
    {
      label: "Delete",
      handler: handleDeleteFileMenuItemClick,
      isDisabled: (file) => file.name == SPECIAL_NAMES.FOLDER
    }
  ];

  const filesTableMultiActions: TableMultiActionsItem<TableS3Files>[] = [
    {
      label: "Delete objects",
      handler: handleMultiDeleteFilesMenuItemClick,
      icon: <DeleteIcon />
    }
  ];

  const bucketTableTitleActions: TableTitleActionsMenuItem[] = [
    {
      label: "Refresh bucket",
      handler: handleConfirmRefreshBucket,
      isDisabled: !isFlatList,
      icon: <RefreshIcon />
    },
    {
      label: "Show bucket URL",
      isDisabled: bucket?.visibility !== "public",
      handler: handleShowBucketUrlMenuItemClick,
      icon: <LinkIcon />
    },
    {
      label: "Make bucket public",
      isDisabled: bucket?.visibility === "public",
      handler: handleMakePublicBucketMenuItemClick,
      icon: <PublicIcon />
    },
    {
      label: "Make bucket private",
      isDisabled: bucket?.visibility !== "public",
      handler: handleMakePrivateBucketMenuItemClick,
      icon: <PublicOffIcon />
    },
    {
      label: "Delete bucket",
      handler: handleDeleteBucketMenuItemClick,
      icon: <FolderDeleteIcon />
    }
  ];

  const handleCopyBucketUrl = useCallback(() => {
    if (bucket) {
      copy(bucket.url);
      dispatch(
        notificationsActions.showNotification({
          type: NOTIFICATION_TYPES.INFO,
          title: "Bucket URL has been copied to clipboard."
        })
      );
    }
  }, [bucket, dispatch]);

  const handleCopyFileUrl = useCallback(() => {
    if (selectedItemURL) {
      copy(selectedItemURL);
      dispatch(
        notificationsActions.showNotification({
          type: NOTIFICATION_TYPES.INFO,
          title: "Object URL has been copied to clipboard."
        })
      );
    }
  }, [dispatch, selectedItemURL]);

  const handleConfirmChangeBucketVisibility = useCallback(
    (visibility) => {
      {
        dispatch(
          objectStorageActions.changeBucketVisibility.started({
            regionId: matchParams.regionId!,
            projId: matchParams.projectId!,
            credsId: matchParams.credsId!,
            bucketName: matchParams.bucketName!,
            visibility
          })
        );
      }
      handleCloseDialog();
    },
    [
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.credsId,
      matchParams.bucketName
    ]
  );

  const handleConfirmDeleteBucket = useCallback(() => {
    dispatch(
      objectStorageActions.deleteBucket.started({
        regionId: matchParams.regionId!,
        projId: matchParams.projectId!,
        credsId: matchParams.credsId!,
        name: matchParams.bucketName!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId,
    matchParams.bucketName,
    handleCloseDialog
  ]);

  const handleConfirmDeleteFile = useCallback(() => {
    let id = "";
    if (selectedItemKey) {
      id = selectedItemKey;
    } else if (deletingFilesKeys && deletingFilesKeys.length > 0) {
      id = deletingFilesKeys[0].key;
    }
    if (id) {
      dispatch(
        objectStorageActions.deleteFile.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!,
          fileName: id
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemKey,
    deletingFilesKeys,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId,
    matchParams.bucketName
  ]);

  const handleConfirmDeleteFiles = useCallback(() => {
    if (checkedRows.length > 0) {
      dispatch(
        objectStorageActions.deleteFiles.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!,
          objects: deletingFilesKeys
        })
      );
    }
    handleCloseDialog();
  }, [
    checkedRows.length,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId,
    matchParams.bucketName,
    deletingFilesKeys
  ]);

  const handleConfirmGetFileSignedDownloadLink = useCallback(
    (data: { duration: number }) => {
      if (selectedItemKey) {
        dispatch(
          objectStorageActions.getFileSignedDownloadLink.started({
            regionId: matchParams.regionId!,
            projId: matchParams.projectId!,
            credsId: matchParams.credsId!,
            bucketName: matchParams.bucketName!,
            fileName: selectedItemKey,
            duration: data.duration * 60 // in seconds
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemKey,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.credsId,
      matchParams.bucketName
    ]
  );

  const handleConfirmDownloadFile = useCallback(() => {
    if (selectedItemKey) {
      dispatch(
        objectStorageActions.downloadFile.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!,
          fileName: selectedItemKey
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemKey,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId,
    matchParams.bucketName
  ]);

  const handleConfirmCreateFolder = useCallback(
    (data: { name: string }) => {
      dispatch(
        objectStorageActions.createFolder.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!,
          folderName: `${path}${data.name}`
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.bucketName,
      matchParams.credsId,
      matchParams.projectId,
      matchParams.regionId,
      path
    ]
  );

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      objectStorageActions.getCredentials.started({
        regionId: matchParams.regionId!,
        projId: matchParams.projectId!,
        credsId: matchParams.credsId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.bucket}`,
        action: objectStorageActions.getBucket.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!
        })
      })
    );
    dispatch(
      objectStorageActions.checkCorsPolicy.started({
        regionId: matchParams.regionId!,
        projId: matchParams.projectId!,
        credsId: matchParams.credsId!,
        bucketName: matchParams.bucketName!
      })
    );
    dispatch(
      objectStorageActions.getFiles.started({
        regionId: matchParams.regionId!,
        projId: matchParams.projectId!,
        credsId: matchParams.credsId!,
        bucketName: matchParams.bucketName!,
        folderName: path,
        offset: page * rowsPerPage,
        limit: rowsPerPage
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(objectStorageActions.clear()); // except file uploading
  });

  useEffect(() => {
    if (isNumber(filesTotal) && rowsPerPage * page > filesTotal) {
      setPage(0);
    }
  }, [filesTotal, rowsPerPage, page]);

  useEffect(() => {
    if ((isFlatList && !prevIsFlatList) || isFlatFilesListRefreshing) {
      setPage(0);
      setIsBannerVisible(true);
    }
  }, [isFlatList, prevIsFlatList, isFlatFilesListRefreshing]);

  useEffect(() => {
    if (
      (isFlatList &&
        !isFlatFilesListByPrefixLoading &&
        !isFlatFilesListRefreshing) ||
      // (isFlatList && prevPrefix !== prefix) ||
      (isFlatList &&
        !isFlatFilesListByPrefixLoading &&
        !isFlatFilesListRefreshing &&
        previousPage !== page) ||
      (isFlatList &&
        !isFlatFilesListByPrefixLoading &&
        !isFlatFilesListRefreshing &&
        previousRowsPerPage !== rowsPerPage)
    ) {
      let currentPageMap = pageMap;
      if (rowsPerPage !== previousRowsPerPage) {
        currentPageMap = {};
      }
      // history({
      //   search: generateSearchString({
      //     page: String(page + 1),
      //     items: String(rowsPerPage)
      //   })
      // });
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.filesList}`
        })
      );

      dispatch(
        objectStorageActions.getFlatFiles.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!,
          max_keys: rowsPerPage,
          page_map: currentPageMap ? currentPageMap : {},
          page: page + 1,
          prefix,
          is_flat_list: isFlatList
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.credsId,
    matchParams.bucketName,
    page,
    // prefix,
    rowsPerPage,
    isFlatList
  ]);

  useEffect(() => {
    if (
      (isFlatList !== null && !isFlatList && previousPath !== path) ||
      (isFlatList !== null && !isFlatList && previousPage !== page) ||
      (isFlatList !== null &&
        !isFlatList &&
        previousRowsPerPage !== rowsPerPage)
    ) {
      history({
        search: generateSearchString(
          {
            page: String(page + 1),
            items: String(rowsPerPage),
            ...(path ? { path: path } : {})
          },
          false // toLowercase=false
        )
      });
      const pollId = `${POLL_ID_PREFIX}/${POLL_IDS.filesList}`;
      dispatch(
        pollingActions.stopPolling({
          id: pollId
        })
      );
      // Clear filesList before starting a new polling operation
      dispatch(objectStorageActions.clearFiles());
      dispatch(
        pollingActions.startPolling({
          id: pollId,
          action: objectStorageActions.getFiles.started({
            regionId: matchParams.regionId!,
            projId: matchParams.projectId!,
            credsId: matchParams.credsId!,
            bucketName: matchParams.bucketName!,
            folderName: path,
            offset: page * rowsPerPage,
            limit: rowsPerPage
          })
        })
      );
      setCheckedRows([]);
      setResetCheckboxes(true);
    }
  }, [
    path,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId,
    matchParams.bucketName,
    previousPath,
    page,
    rowsPerPage,
    history,
    isFlatList,
    previousPage,
    previousRowsPerPage
  ]);

  useEffect(() => {
    if (
      previousIsFileOperationInProgress &&
      !isFileOperationInProgress &&
      !isFlatList
    ) {
      dispatch(
        objectStorageActions.getFiles.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!,
          folderName: path,
          offset: page * rowsPerPage,
          limit: rowsPerPage
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.credsId,
    matchParams.bucketName,
    previousIsFileOperationInProgress,
    isFileOperationInProgress,
    path,
    page,
    rowsPerPage,
    isFlatList
  ]);

  useEffect(() => {
    if (previousIsBucketOperationInProgress && !isBucketOperationInProgress) {
      if (!bucket) {
        history(getParentPath(location.pathname, 2));
      } else {
        dispatch(
          objectStorageActions.getBucket.started({
            regionId: matchParams.regionId!,
            projId: matchParams.projectId!,
            credsId: matchParams.credsId!,
            bucketName: matchParams.bucketName!
          })
        );
      }
    }
  }, [
    history,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsBucketOperationInProgress,
    isBucketOperationInProgress,
    bucket,
    matchParams.credsId,
    matchParams.bucketName,
    location.pathname
  ]);

  useEffect(() => {
    if (prevIsCorsPolicySetting && !isCorsPolicySetting) {
      dispatch(
        objectStorageActions.checkCorsPolicy.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          bucketName: matchParams.bucketName!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.credsId,
    matchParams.bucketName,
    prevIsCorsPolicySetting,
    isCorsPolicySetting
  ]);

  useEffect(() => {
    if (
      (!areFilesDeleting && prevAreFilesDeleting) ||
      (!isFileDeleting && prevIsFileDeleting) ||
      (!isFileDownloading && prevIsFileDownloading)
    ) {
      setCheckedRows([]);
      setResetCheckboxes(true);
    }
  }, [
    areFilesDeleting,
    isFileDeleting,
    prevAreFilesDeleting,
    prevIsFileDeleting,
    prevIsFileDownloading,
    isFileDownloading
  ]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_FOLDER]: {
      onConfirm: handleConfirmCreateFolder,
      title: "Create folder",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .matches(REGEX.FOLDER_NAME, ERROR_MESSAGES.FOLDER_NAME)
            .trim("Name cannot start or end with a space")
            .max(63, ERROR_MESSAGES.MAX_BUCKET_NAME)
            .test(
              "sameNameCheckFormat",
              "This name already exists",
              (value) => {
                const name = filesList?.find((file) => file.id === value)?.name;
                return Boolean(value && !name);
              }
            )
        }
      ]
    },
    [DIALOG_TYPES.MAKE_BUCKET_PRIVATE]: {
      onConfirm: () => {
        handleConfirmChangeBucketVisibility("private");
      },
      title: `Are you sure you want to make current bucket private?`,
      confirmButtonLabel: "Make Private"
    },
    [DIALOG_TYPES.MAKE_BUCKET_PUBLIC]: {
      onConfirm: () => {
        handleConfirmChangeBucketVisibility("public");
      },
      title: `Are you sure you want to make current bucket public?`,
      confirmButtonLabel: "Make Public"
    },
    [DIALOG_TYPES.SHOW_BUCKET_URL]: {
      onConfirm: handleCopyBucketUrl,
      title: `Bucket URL`,
      fields: [
        {
          name: "url",
          type: FIELD_TYPES.LABEL,
          label: "Bucket url",
          link: `${bucket?.url ?? ""}`
        }
      ],
      confirmButtonLabel: "Copy to clipboard"
    },
    [DIALOG_TYPES.DELETE_BUCKET]: {
      onConfirm: handleConfirmDeleteBucket,
      title: `Are you sure you want to delete current bucket?`,
      fields: [
        {
          name: "nonEmptyWarning",
          type: FIELD_TYPES.NOTES,
          label: "⚠️ Deletion of bucket is only possible if it is empty."
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DOWNLOAD_FILE]: {
      onConfirm: handleConfirmDownloadFile,
      title: `Are you sure you want to download next file?`,
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.LABEL,
          label: `\u2794\u00A0\u00A0${selectedItemName ?? "selected"}`
        }
      ],
      confirmButtonLabel: "Download"
    },
    [DIALOG_TYPES.SHOW_FILE_URL]: {
      onConfirm: handleCopyFileUrl,
      title: `Object URL`,
      fields: [
        {
          name: "url",
          type: FIELD_TYPES.LABEL,
          label: "Object url",
          link: `${selectedItemURL ?? ""}`
        }
      ],
      confirmButtonLabel: "Copy to clipboard"
    },
    [DIALOG_TYPES.GET_SIGNED_DOWNLOADING_LINK]: {
      onConfirm: handleConfirmGetFileSignedDownloadLink,
      title: `Get signed downloading link of the "${
        selectedItemName ?? "selected"
      }" object?`,
      fields: [
        {
          name: "duration",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 3,
          label: "Link lifetime",
          suffix: "min",
          min: 1,
          step: 5,
          rules: number().integer().min(1).required()
        }
      ],
      confirmButtonLabel: "Get Link"
    },
    [DIALOG_TYPES.DELETE_FILE]: {
      onConfirm: handleConfirmDeleteFile,
      title: `Are you sure you want to delete next object?`,
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.LABEL,
          label: `\u2794\u00A0\u00A0${selectedItemName ?? "selected"}`
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_FILES]: {
      onConfirm:
        checkedRows?.length > 1
          ? handleConfirmDeleteFiles
          : handleConfirmDeleteFile,
      title: `Are you sure you want to delete next objects?`,
      fields: [
        {
          name: "names",
          type: FIELD_TYPES.LABEL,
          label: deletingFilesNames
            ? deletingFilesNames
                .map((name) => `\u2794\u00A0\u00A0${name}`)
                .join("\n")
            : ""
          // label: deletingFilesNames?.join(";\n\n") ?? ""
        }
      ],
      confirmButtonLabel: "Delete"
    }
  };

  const generateTableItemURL = useCallback(
    (name: string) => {
      const key = filesList?.find((file) => file.name === name)?.key;
      return (
        generatePath(ROUTES.S3_BUCKET, {
          organizationId: matchParams.organizationId,
          regionId: matchParams.regionId,
          projectId: matchParams.projectId,
          credsId: matchParams.credsId,
          bucketName: matchParams.bucketName,
          page: String(page + 1),
          items: String(rowsPerPage)
        }) + `?path=${key ? key : ""}`
      );
    },
    [
      filesList,
      matchParams.organizationId,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.credsId,
      matchParams.bucketName,
      page,
      rowsPerPage
    ]
  );

  return (
    <>
      {isBannerVisible && <PopupBanner onClose={handleBannerClose} />}
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Table
        title={title}
        titleDetails={titleDetails}
        isPaginationEnabled={true} // default
        isAdvancedPaginationEnabled={isFlatList} // for flat list
        count={filesTotal || 0}
        isNextPageAvailable={isNextPageAvailable}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        isSearchEnabled={!isFlatList}
        isSortingEnabled={true}
        isSelectingEnabled={true}
        isRowSelectable={(row) => row.name !== "../"}
        resetCheckboxes={resetCheckboxes}
        onResetCheckboxes={handleSwitchOffResetCheckboxes}
        multiActions={filesTableMultiActions}
        titleActions={bucket && bucketTableTitleActions}
        rows={isFlatList ? flatFilesList || [] : filesList || []}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL,
          isEnabled: (file) => !isFlatList && file.isFolder
        }}
        columns={tableColumns}
        actions={tableActions}
        isLoading={
          isFlatList
            ? isFlatFilesListLoading ||
              isFlatFilesListByPrefixLoading ||
              isFlatFilesListRefreshing
            : !filesList
        }
        toolbarItems={
          credentials && (
            <>
              <Button
                onClick={handleCreateFolderButtonClick}
                variant={"contained"}
                style={{ marginRight: "10px" }}
                disabled={false}
              >
                Create Folder
              </Button>
              <Divider orientation="vertical" flexItem />
              <Uploader
                region={matchParams.regionId!}
                projectId={matchParams.projectId!}
                credsId={matchParams.credsId!}
                bucket={matchParams.bucketName!}
                accessKey={credentials.access_key}
                secretAccessKey={credentials.access_secret}
                s3Url={credentials.endpoint}
                folder={path}
              />
              {isFlatList && (
                <s.ProSearchContainer>
                  {/* <IconButton onClick={handleExpandFilterField} color={"inherit"}>
                  <FilterAltIcon />
                </IconButton> */}
                  <s.ProSearchTextField
                    $expanded={expandedFilterField}
                    variant="outlined"
                    label={"Search by prefix"}
                    size={"small"}
                    value={prefix}
                    onChange={handlePrefixChange}
                    fullWidth
                    onKeyDown={handleSearchByPrefixKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={handleSearchByPrefix}
                          position="end"
                        >
                          <IconButton
                            edge="end"
                            color={prefix ? "primary" : "inherit"}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                      // startAdornment: (
                      //   <InputAdornment
                      //     onClick={handleExpandFilterField}
                      //     position="start"
                      //   >
                      //     <IconButton edge="start" color={"default"}>
                      //       <FilterAltIcon />
                      //     </IconButton>
                      //   </InputAdornment>
                      // )
                    }}
                  />
                </s.ProSearchContainer>
              )}
            </>
          )
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
