import { SelectOption } from "components/common/FormDialog/types";
import { isNull } from "typeGuards/isNull";

export const getSelectDefaultValue = <T extends { id: string }>(
  options: T[] | null,
  id: string | null,
  labelKey: keyof T,
  valueKey: keyof T = "id"
): SelectOption | null => {
  if (isNull(id)) {
    return null;
  }

  const option = options?.find((option) => option.id === id);

  if (!option) {
    return null;
  }

  return {
    label: String(option[labelKey]),
    value: String(option[valueKey])
  };
};
