export enum VIRTUAL_MACHINE_ACTIONS {
  START,
  SOFT_REBOOT,
  HARD_REBOOT,
  STOP,
  REMOTE_CONSOLE,
  RESIZE,
  CONFIRM_RESIZE,
  REVERT_RESIZE
}

export enum PROTOCOLS {
  TCP = "tcp",
  UDP = "udp",
  ICMP = "icmp",
  ANY = "any"
}
