import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GenerateBillingDataReportParams,
  GetOrganizationBillingDataParams,
  GetOrganizationBillingDataResponse,
  GetProjectBillingDatabasesDataParams,
  GetProjectBillingDatabasesDataResponse,
  GetProjectBillingInstancesDataParams,
  GetProjectBillingInstancesDataResponse,
  GetProjectBillingS3DataParams,
  GetProjectBillingS3DataResponse,
  GetProjectBillingVolumesDataParams,
  GetProjectBillingVolumesDataResponse
} from "./types";

const serviceApiPath = `gotham-wayne-tower/method/`;

export function* getOrganizationBillingDataSaga(
  action: Action<GetOrganizationBillingDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, id } = action.payload;
    const response: AxiosResponse<GetOrganizationBillingDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}billing/organizations/${id}`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizationBillingData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizationBillingData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getProjectBillingInstancesDataSaga(
  action: Action<GetProjectBillingInstancesDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, organizationId, projectId, regionId } =
      action.payload;
    const response: AxiosResponse<GetProjectBillingInstancesDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}/instances`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectBillingInstancesData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectBillingInstancesData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project instances billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getProjectBillingVolumesDataSaga(
  action: Action<GetProjectBillingVolumesDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, organizationId, projectId, regionId } =
      action.payload;
    const response: AxiosResponse<GetProjectBillingVolumesDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}/volumes`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectBillingVolumesData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectBillingVolumesData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project volumes billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getProjectBillingDatabasesDataSaga(
  action: Action<GetProjectBillingDatabasesDataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, organizationId, projectId, regionId } =
      action.payload;
    const response: AxiosResponse<GetProjectBillingDatabasesDataResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}/databases`,
        {
          params: {
            start: startDate,
            end: endDate
          }
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectBillingDatabasesData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectBillingDatabasesData.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project databases billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getProjectBillingS3DataSaga(
  action: Action<GetProjectBillingS3DataParams>
): SagaIterator<void> {
  try {
    const { startDate, endDate, organizationId, projectId, regionId } =
      action.payload;
    const response: AxiosResponse<GetProjectBillingS3DataResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}/s3`,
      {
        params: {
          start: startDate,
          end: endDate
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectBillingS3Data.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectBillingS3Data.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project Object Storage billing data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* generateBillingDataReportSaga(
  action: Action<GenerateBillingDataReportParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to generate report...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { startDate, endDate, regionId, organizationId, projectId } =
      action.payload;
    yield call(
      axiosInstance.post,
      `${serviceApiPath}billing/organizations/${organizationId}/regions/${regionId}/projects/${projectId}?start=${startDate}&end=${endDate}`
      // {
      //   params: {
      //     start: startDate,
      //     end: endDate
      //   }
      // }
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to generate report has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
    yield put(
      actions.generateBillingDataReport.done({
        params: action.payload
      })
    );
  } catch (e) {
    yield put(
      actions.generateBillingDataReport.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to generate report",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    // takeLeading(
    //   actions.getProjectBillingData.started,
    //   getProjectBillingDataSaga
    // ),
    takeLatest(
      actions.getProjectBillingInstancesData.started,
      getProjectBillingInstancesDataSaga
    ),
    takeLatest(
      actions.getProjectBillingVolumesData.started,
      getProjectBillingVolumesDataSaga
    ),
    takeLatest(
      actions.getProjectBillingDatabasesData.started,
      getProjectBillingDatabasesDataSaga
    ),
    takeLatest(
      actions.getOrganizationBillingData.started,
      getOrganizationBillingDataSaga
    ),
    takeLatest(
      actions.getProjectBillingS3Data.started,
      getProjectBillingS3DataSaga
    ),
    takeEvery(
      actions.generateBillingDataReport.started,
      generateBillingDataReportSaga
    )
  ]);
}
