export enum DIALOG_TYPES {
  CREATE,
  EDIT,
  RESIZE,
  DELETE,
  DOWNLOAD_RDP_FILE,
  STOP,
  SOFT_REBOOT,
  ASSOCIATE_FLOATING_IP,
  DISASSOCIATE_FLOATING_IP
}
