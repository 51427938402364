import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  OrganizationBillingData,
  ProjectBillingDatabasesData,
  ProjectBillingInstancesData,
  ProjectBillingS3Data,
  ProjectBillingVolumesData
} from "./types";

export type Reducer = {
  organizationBillingData: OrganizationBillingData | null;
  isOrganizationBillingDataLoading: boolean;
  projectBillingInstancesData: ProjectBillingInstancesData | null;
  isProjectBillingInstancesDataLoading: boolean;
  projectBillingVolumesData: ProjectBillingVolumesData | null;
  isProjectBillingVolumesDataLoading: boolean;
  projectBillingDatabasesData: ProjectBillingDatabasesData | null;
  isProjectBillingDatabasesDataLoading: boolean;
  projectBillingS3Data: ProjectBillingS3Data | null;
  isProjectBillingS3DataLoading: boolean;
  isBillingDataReportGenerating: boolean;
};

const initialState: Reducer = {
  organizationBillingData: null,
  isOrganizationBillingDataLoading: false,
  projectBillingInstancesData: null,
  isProjectBillingInstancesDataLoading: false,
  projectBillingVolumesData: null,
  isProjectBillingVolumesDataLoading: false,
  projectBillingDatabasesData: null,
  isProjectBillingDatabasesDataLoading: false,
  projectBillingS3Data: null,
  isProjectBillingS3DataLoading: false,
  isBillingDataReportGenerating: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getOrganizationBillingData.started,
    (state): Reducer => ({
      ...state,
      isOrganizationBillingDataLoading: true
    })
  )
  .case(
    actions.getOrganizationBillingData.done,
    (state, payload): Reducer => ({
      ...state,
      organizationBillingData: payload.result,
      isOrganizationBillingDataLoading: false
    })
  )
  .case(
    actions.getOrganizationBillingData.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationBillingDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingInstancesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingInstancesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingInstancesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingInstancesData: payload.result,
      isProjectBillingInstancesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingInstancesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingInstancesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingVolumesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingVolumesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingVolumesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingVolumesData: payload.result,
      isProjectBillingVolumesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingVolumesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingVolumesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingDatabasesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingDatabasesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingDatabasesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingDatabasesData: payload.result,
      isProjectBillingDatabasesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingDatabasesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingDatabasesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingS3Data.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingS3DataLoading: true
    })
  )
  .case(
    actions.getProjectBillingS3Data.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingS3Data: payload.result,
      isProjectBillingS3DataLoading: false
    })
  )
  .case(
    actions.getProjectBillingS3Data.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingS3DataLoading: false
    })
  )

  .case(
    actions.generateBillingDataReport.started,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: true
    })
  )
  .case(
    actions.generateBillingDataReport.done,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: false
    })
  )
  .case(
    actions.generateBillingDataReport.failed,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: false
    })
  )

  .case(actions.clear, (): Reducer => initialState);
