import { isAxiosError } from "typeGuards/isAxiosError";
import { isObject } from "typeGuards/isObject";
import { isUndefined } from "typeGuards/isUndefined";

export function getAxiosErrorMessage<T>(e: unknown): string {
  return isAxiosError<T>(e) && !isUndefined(e.response)
    ? isObject(e.response.data)
      ? JSON.stringify(e.response.data)
      : String(e.response.data)
    : "";
}
