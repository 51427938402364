import Typography from "@mui/material/Typography";
import { FC } from "react";
import * as s from "./styles";
import { LoaderProps } from "./types";

export const Loader: FC<LoaderProps> = ({ text, ...rest }: LoaderProps) => (
  <s.Container>
    <s.Spinner title={text} {...rest} />
    <Typography>{text}</Typography>
  </s.Container>
);
