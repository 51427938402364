export enum DIALOG_TYPES {
  EDIT,
  DELETE,
  RESET_PASSWORD,
  DELETE_REPLICA,
  RESTORE_BACKUP,
  CREATE_BACKUP,
  CREATE_REPLICA,
  CRON,
  DELETE_CRON,
  LINK_S3,
  EDIT_CUSTOM_OPTION,
  CREATE_DATABASE_CHILD,
  DELETE_DATABASE_CHILD,
  CREATE_DATABASE_USER,
  DELETE_DATABASE_USER,
  SET_PERMISSIONS
}

export enum TABS {
  DATABASES,
  USERS,
  REPLICAS,
  CRON,
  BACKUPS,
  CUSTOM_OPTIONS
}
