import MuiLink, { LinkProps as MuiLinkProps } from "@mui/material/Link";
import { LinkProps as RouterLinkProps } from "react-router-dom";
import styled from "styled-components";

export const Link = styled(MuiLink)<MuiLinkProps & RouterLinkProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({ theme }) => theme.spacing(40)};
  display: block;
  white-space: nowrap;
`;
