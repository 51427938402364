import { S3, S3ClientConfig } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { pendingPollsSelector } from "modules/polling/selectors";
import { EventChannel, SagaIterator, eventChannel } from "redux-saga";
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { downloadFile } from "utils/downloadFile";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { appConfig } from "../../appConfig";
import { axiosInstance } from "../../axios";
import { MAX_FILE_SIZE } from "../../constants";
import * as actions from "./actions";
import {
  ChangeBucketVisibilityParams,
  CheckCorsPolicyParams,
  CheckCorsPolicyResponse,
  CreateBucketParams,
  CreateBucketResponse,
  CreateCredentialsParams,
  CreateCredentialsResponse,
  CreateFolderParams,
  DeleteBucketParams,
  DeleteCredentialsParams,
  DeleteFileParams,
  DeleteFilesParams,
  DownloadFileParams,
  DownloadFileResponse,
  GetBucketParams,
  GetBucketResponse,
  GetBucketsParams,
  GetBucketsResponse,
  GetCredentialsListParams,
  GetCredentialsListResponse,
  GetCredentialsParams,
  GetCredentialsResponse,
  GetFileSignedDownloadLinkParams,
  GetFileSignedDownloadLinkResponse,
  GetFilesParams,
  GetFilesResponse,
  GetFlatFilesParams,
  RegenerateCredentialsParams,
  RegenerateCredentialsResponse,
  SetCorsPolicyParams,
  UploadFileParams
} from "./types";

export function* getCredentialsSaga(
  action: Action<GetCredentialsParams>
): SagaIterator<void> {
  try {
    const { regionId, projId, credsId } = action.payload;
    const response: AxiosResponse<GetCredentialsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-identity/method/${projId}/creds/${credsId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getCredentials.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getCredentials.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get credentials data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getCredentialsListSaga(
  action: Action<GetCredentialsListParams>
): SagaIterator<void> {
  try {
    const { regionId, projId } = action.payload;
    const response: AxiosResponse<GetCredentialsListResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-identity/method/${projId}/creds`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getCredentialsList.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getCredentialsList.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get credentials data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createCredentialsSaga(
  action: Action<CreateCredentialsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the credentials...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId } = action.payload;
    const response: AxiosResponse<CreateCredentialsResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-identity/method/${projId}/creds`
    );
    yield put(
      actions.createCredentials.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Credentials have been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createCredentials.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create credentials",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* regenerateCredentialsSaga(
  action: Action<RegenerateCredentialsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Re-generating the credentials...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId } = action.payload;
    const response: AxiosResponse<RegenerateCredentialsResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-identity/method/${projId}/creds/${credsId}`
    );
    yield put(
      actions.regenerateCredentials.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Credentials have been successfully re-generated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.regenerateCredentials.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to re-generate credentials",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteCredentialsSaga(
  action: Action<DeleteCredentialsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the credentials...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-identity/method/${projId}/creds/${credsId}`
    );
    yield put(
      actions.deleteCredentials.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Credentials have been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteCredentials.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete credentials",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getBucketsListSaga(
  action: Action<GetBucketsParams>
): SagaIterator<void> {
  try {
    const { regionId, projId, credsId } = action.payload;
    const response: AxiosResponse<GetBucketsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getBuckets.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getBuckets.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get buckets list",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getBucketSaga(
  action: Action<GetBucketParams>
): SagaIterator<void> {
  try {
    const { regionId, projId, credsId, bucketName } = action.payload;
    const response: AxiosResponse<GetBucketResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getBucket.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getBucket.failed({
        params: action.payload,
        error: e
      })
    );
    const pendingPolls: string[] = yield select(pendingPollsSelector);
    if (pendingPolls.includes("S3_FILES/BUCKET")) {
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get bucket",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* createBucketSaga(
  action: Action<CreateBucketParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the bucket...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, data } = action.payload;
    const response: AxiosResponse<CreateBucketResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/bucket/${data.name}`
    );
    yield put(
      actions.createBucket.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Bucket have been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createBucket.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create bucket",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* changeBucketVisibilitySaga(
  action: Action<ChangeBucketVisibilityParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Changing bucket visibility...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, bucketName, visibility } =
      action.payload;
    yield call(
      axiosInstance.post,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/visibility?visibility=${visibility}`
    );
    yield put(
      actions.changeBucketVisibility.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Bucket visibility has been changed.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.changeBucketVisibility.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to change bucket visibility",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteBucketSaga(
  action: Action<DeleteBucketParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the bucket...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, name } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${name}`
    );
    yield put(
      actions.deleteBucket.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Bucket has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteBucket.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete bucket",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteNonEmptyBucketSaga(
  action: Action<DeleteBucketParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the bucket...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, name } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${name}/force`
    );
    yield put(
      actions.deleteBucket.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Bucket has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteBucket.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete bucket",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFilesListSaga(
  action: Action<GetFilesParams>
): SagaIterator<void> {
  try {
    const { regionId, projId, credsId, bucketName, folderName, offset, limit } =
      action.payload;
    const response: AxiosResponse<GetFilesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/folders?path=${
        folderName || ""
      }&offset=${offset}&limit=${limit}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFiles.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFiles.failed({
        params: action.payload,
        error: e
      })
    );
    const pendingPolls: string[] = yield select(pendingPollsSelector);
    if (pendingPolls.includes("S3_FILES/BUCKET")) {
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get objects list",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getFlatFilesListSaga(
  action: Action<GetFlatFilesParams>
): SagaIterator<void> {
  try {
    const {
      regionId,
      projId,
      credsId,
      bucketName,
      max_keys,
      page_map,
      page,
      is_flat_list,
      prefix,
      delimiter
    } = action.payload;
    const response: AxiosResponse<GetFilesResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/flat`,
      {
        max_keys,
        page_map,
        // delimiter: "/",
        page,
        is_flat_list,
        delimiter,
        prefix
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFlatFiles.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFlatFiles.failed({
        params: action.payload,
        error: e
      })
    );
    const pendingPolls: string[] = yield select(pendingPollsSelector);
    if (pendingPolls.includes("S3_FILES/BUCKET")) {
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get flat objects list",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getFlatFilesListRefreshedSaga(
  action: Action<GetFlatFilesParams>
): SagaIterator<void> {
  try {
    const {
      regionId,
      projId,
      credsId,
      bucketName,
      max_keys,
      page_map,
      page,
      is_flat_list,
      prefix,
      delimiter
    } = action.payload;
    const response: AxiosResponse<GetFilesResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/flat`,
      {
        max_keys,
        page_map,
        page,
        is_flat_list,
        delimiter,
        prefix
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFlatFilesRefreshed.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFlatFilesRefreshed.failed({
        params: action.payload,
        error: e
      })
    );
    const pendingPolls: string[] = yield select(pendingPollsSelector);
    if (pendingPolls.includes("S3_FILES/BUCKET")) {
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get flat objects list after refresh",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getFlatFilesListByPrefixSaga(
  action: Action<GetFlatFilesParams>
): SagaIterator<void> {
  try {
    const {
      regionId,
      projId,
      credsId,
      bucketName,
      max_keys,
      page_map,
      page,
      is_flat_list,
      prefix,
      delimiter
    } = action.payload;
    const response: AxiosResponse<GetFilesResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/flat`,
      {
        max_keys,
        page_map,
        page,
        is_flat_list,
        delimiter,
        prefix
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFlatFilesByPrefix.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFlatFilesByPrefix.failed({
        params: action.payload,
        error: e
      })
    );
    const pendingPolls: string[] = yield select(pendingPollsSelector);
    if (pendingPolls.includes("S3_FILES/BUCKET")) {
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get flat objects list by prefix",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* uploadFileSaga(
  action: Action<UploadFileParams>
): SagaIterator<void> {
  const {
    regionId,
    accessKey,
    accessSecret,
    endpoint,
    projId,
    bucketName,
    file,
    folder
  } = action.payload;

  try {
    const s3Config: S3ClientConfig = {
      endpoint: endpoint,
      region: regionId,
      credentials: {
        accessKeyId: accessKey,
        secretAccessKey: accessSecret
      }
    };

    const s3 = new S3(s3Config);

    if (file.size > MAX_FILE_SIZE) {
      yield put(
        actions.uploadFile.failed({
          params: action.payload,
          error: "The file size is over 5 GiB."
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Please, select a file smaller than 5 GiB.",
          type: NOTIFICATION_TYPES.ERROR
        })
      );
      return;
    }

    const params = {
      Bucket: `${projId}:${bucketName}`,
      Key: folder + file.name,
      Body: file
    };

    const parallelUploads3 = new Upload({
      client: s3,
      params: params
    });

    const progressChannel: EventChannel<number> = yield call(
      eventChannel,
      (emit) => {
        const uploadProgressHandler = (progress: {
          loaded?: number;
          total?: number;
        }) => {
          if (progress.loaded && progress.total) {
            const percentageProgress = Math.round(
              (progress.loaded / progress.total) * 100
            );
            emit(percentageProgress);
          }
        };

        parallelUploads3.on("httpUploadProgress", uploadProgressHandler);

        return () => {
          parallelUploads3.off("httpUploadProgress", uploadProgressHandler);
        };
      }
    );

    yield fork(function* () {
      while (true) {
        const progress = yield take(progressChannel);
        if (typeof progress === "number") {
          yield put(actions.uploadProgress(progress));
        } else {
          break;
        }
      }
    });

    yield call([parallelUploads3, "done"]);
    progressChannel.close();

    yield put(
      notificationsActions.showNotification({
        title: "File has been successfully uploaded.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );

    yield put(
      actions.uploadFile.done({
        params: action.payload,
        result: { message: "File uploaded successfully" }
      })
    );
  } catch (e) {
    yield put(actions.uploadFile.failed({ params: action.payload, error: e }));

    yield put(
      notificationsActions.showNotification({
        title: "Failed to upload file",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteFileSaga(
  action: Action<DeleteFileParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the object...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, bucketName, fileName } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/files?file=${fileName}`
    );
    yield put(
      actions.deleteFile.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "The request has been successfully sent",
        text: "Object will be deleted in a few seconds.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.deleteFile.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete object",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteFilesSaga(
  action: Action<DeleteFilesParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the objects...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, bucketName, objects } = action.payload;
    yield call(
      axiosInstance.put,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/files/delete`,
      { objects }
    );
    yield put(
      actions.deleteFiles.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "The request has been successfully sent.",
        text: "Objects will be deleted in a few seconds.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.deleteFiles.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete object",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFileSignedDownloadLinkSaga(
  action: Action<GetFileSignedDownloadLinkParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Generating the object downloaded link...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, bucketName, fileName, duration } =
      action.payload;
    const response: AxiosResponse<GetFileSignedDownloadLinkResponse> =
      yield call(
        axiosInstance.get,
        `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/${fileName}/link?file=${fileName}`,
        {
          params: {
            duration
          }
        }
      );

    yield put(
      actions.getFileSignedDownloadLink.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: `Open to see generated link...`,
        text: `${response.data}`,
        type: NOTIFICATION_TYPES.INFO
      })
    );
  } catch (e) {
    yield put(
      actions.getFileSignedDownloadLink.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to generate link",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* downloadFileSaga(
  action: Action<DownloadFileParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Downloading...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, bucketName, fileName } = action.payload;
    const response: AxiosResponse<DownloadFileResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/link?file=${fileName}`
    );

    downloadFile(response.data);
    yield put(
      actions.downloadFile.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "The request to download file has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.downloadFile.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to download file",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* checkCorsPolicySaga(
  action: Action<CheckCorsPolicyParams>
): SagaIterator<void> {
  try {
    const { regionId, projId, credsId, bucketName } = action.payload;
    const response: AxiosResponse<CheckCorsPolicyResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/cors-policy?origin=${appConfig.consoleDomain}`
    );
    yield put(
      actions.checkCorsPolicy.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.checkCorsPolicy.failed({ params: action.payload, error: e })
    );
  }
}

export function* setCorsPolicySaga(
  action: Action<SetCorsPolicyParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Setting the Cors policy..",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, bucketName } = action.payload;
    yield call(
      axiosInstance.put,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/cors-policy?origin=${appConfig.consoleDomain}`
    );
    yield put(
      actions.setCorsPolicy.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Access to the bucket has been allowed.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.setCorsPolicy.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to open access to the bucket.",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createFolderSaga(
  action: Action<CreateFolderParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating folder...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projId, credsId, bucketName, folderName } =
      action.payload;
    yield call(
      axiosInstance.post,
      `gotham-${regionId}-object-storage/method/${projId}/credentials/${credsId}/buckets/${bucketName}/folders?folder=${folderName}`
    );
    yield put(
      actions.createFolder.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "The folder has been created",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createFolder.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create a folder",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getCredentials.started, getCredentialsSaga),
    takeLeading(actions.getCredentialsList.started, getCredentialsListSaga),
    takeLeading(actions.getBuckets.started, getBucketsListSaga),
    takeLeading(actions.getBucket.started, getBucketSaga),
    takeLatest(actions.getFiles.started, getFilesListSaga),
    takeLatest(actions.getFlatFiles.started, getFlatFilesListSaga),
    takeEvery(
      actions.getFlatFilesByPrefix.started,
      getFlatFilesListByPrefixSaga
    ),
    takeEvery(
      actions.getFlatFilesRefreshed.started,
      getFlatFilesListRefreshedSaga
    ),
    takeEvery(actions.checkCorsPolicy.started, checkCorsPolicySaga),
    takeEvery(actions.setCorsPolicy.started, setCorsPolicySaga),
    takeEvery(actions.createCredentials.started, createCredentialsSaga),
    takeEvery(actions.regenerateCredentials.started, regenerateCredentialsSaga),
    takeEvery(actions.deleteCredentials.started, deleteCredentialsSaga),
    takeEvery(actions.createBucket.started, createBucketSaga),
    takeEvery(
      actions.changeBucketVisibility.started,
      changeBucketVisibilitySaga
    ),
    takeEvery(actions.deleteBucket.started, deleteBucketSaga),
    takeEvery(actions.uploadFile.started, uploadFileSaga),
    takeEvery(actions.deleteFile.started, deleteFileSaga),
    takeEvery(actions.deleteFiles.started, deleteFilesSaga),
    takeEvery(actions.downloadFile.started, downloadFileSaga),
    takeEvery(actions.createFolder.started, createFolderSaga),
    takeEvery(
      actions.getFileSignedDownloadLink.started,
      getFileSignedDownloadLinkSaga
    )
  ]);
}
