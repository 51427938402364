import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import * as supportActions from "modules/support/actions";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { string } from "yup";
import { ContactSupportDialogProps } from "./types";

export const ContactSupportDialog: FC<ContactSupportDialogProps> = ({
  isOpened,
  onClose
}) => {
  const dispatch = useDispatch();

  const handleContactFormSubmit = useCallback(
    (data: { subject: string; details: string }) => {
      dispatch(
        supportActions.submitTicket.started({
          subject: data.subject.trim(),
          details: data.details.trim()
        })
      );
      onClose();
    },
    [dispatch, onClose]
  );

  const dialogProps: Omit<FormDialogProps, "isOpened" | "onCancel"> = {
    onConfirm: handleContactFormSubmit,
    title: "Contact support",
    confirmButtonLabel: "Submit ticket",
    fields: [
      {
        name: "subject",
        type: FIELD_TYPES.TEXT,
        label: "Subject",
        rules: string().required()
      },
      {
        name: "details",
        type: FIELD_TYPES.MULTILINE_TEXT,
        label: "Details",
        rules: string().required()
      }
    ]
  };

  return (
    <FormDialog
      isOpened={isOpened}
      onCancel={onClose}
      fields={dialogProps.fields}
      onConfirm={dialogProps.onConfirm}
      title={dialogProps.title}
      confirmButtonLabel={dialogProps.confirmButtonLabel}
    />
  );
};
