import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { EmotionsPrediction } from "./types";

export type Reducer = {
  emotionsPredictions: EmotionsPrediction | null;
  areEmotionsPreditionsLoading: boolean;
};

const initialState: Reducer = {
  emotionsPredictions: null,
  areEmotionsPreditionsLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getEmotionsPrediction.started,
    (state): Reducer => ({
      ...state,
      areEmotionsPreditionsLoading: true
    })
  )
  .case(
    actions.getEmotionsPrediction.done,
    (state, payload): Reducer => ({
      ...state,
      emotionsPredictions: payload.result,
      areEmotionsPreditionsLoading: false
    })
  )
  .case(
    actions.getEmotionsPrediction.failed,
    (state): Reducer => ({
      ...state,
      areEmotionsPreditionsLoading: false
    })
  )
  // clear store
  .case(actions.clear, (): Reducer => initialState);
