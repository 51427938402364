import actionCreatorFactory, { ActionCreatorFactory } from "typescript-fsa";
import { Notification } from "./types";

const actionCreator: ActionCreatorFactory =
  actionCreatorFactory("NOTIFICATIONS");

export const showNotification =
  actionCreator<Notification>("SHOW_NOTIFICATION");

export const clear = actionCreator<void>("CLEAR");
