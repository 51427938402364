export enum VOLUME_STATUSES {
  AVAILABLE = "available",
  IN_USE = "in-use"
}

export type ImageFromVolume = {
  checksum: string;
  container_format: string;
  disk_format: string;
  image_id: string;
  image_name: string;
  os_distro: string;
  os_platform: string;
  os_default_user?: string;
  size: string;
};

export type Volume = {
  attachments: {
    attachment_id: string;
    device: string;
    host_name: string;
    id: string;
    server_id: string;
    volume_id: string;
  }[];
  availability_zone: string;
  backup_id: string;
  bootable: boolean;
  consistencygroup_id: string;
  created_at: string;
  description: string;
  encrypted: boolean;
  id: string;
  metadata: Record<string, string>;
  multiattach: boolean;
  name: string;
  region: string;
  replication_status: string;
  size: number;
  snapshot_id: string;
  status: VOLUME_STATUSES;
  updated_at: string;
  user_id: string;
  volume_image_metadata?: ImageFromVolume;
  volume_type: string;
};

export type TableVolume = {
  name: string;
  id: string;
  status: VOLUME_STATUSES;
  size: number;
  sizeString: string;
  createdAt: string;
  bootable: boolean;
  serverId?: string;
  detailedName: string;
  description: string;
};

export type GetVolumeParams = {
  regionId: string;
  projectId: string;
  id: string;
  pollingId?: string;
};

export type GetVolumeResponse = Volume;

export type GetVolumesParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};

export type GetVolumesResponse = Volume[];

export type CreateVolumeParams = {
  regionId: string;
  projectId: string;
  data: {
    description?: string;
    name: string;
    size?: number;
    volume_type?: string;
    snapshot_id?: string;
  };
};

export type CreateVolumeResponse = Volume;

export type UpdateVolumeParams = {
  regionId: string;
  projectId: string;
  id: string;
  data: {
    bootable?: boolean;
    description?: string;
    name?: string;
  };
};

export type UpdateVolumeResponse = Volume;

export type ExtendVolumeParams = {
  regionId: string;
  projectId: string;
  id: string;
  data: {
    new_size: number;
  };
};

export type ExtendVolumeResponse = Volume;

export type DeleteVolumeParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export type DeleteVolumeResponse = { message: string };

export type DeleteVolumesParams = {
  regionId: string;
  projectId: string;
  data: string[];
};

export type DeleteVolumesResponse = { message: string };

export type Snapshot = {
  created_at: string;
  description: string;
  id: string;
  metadata: Record<string, unknown>;
  name: string;
  region: string;
  size: number;
  status: string;
  updated_at: string;
  volume_id: string;
};

export type TableSnapshot = {
  id: string;
  name: string;
  status: string;
  size: string;
  createdAt: string;
  description: string;
};

export type GetSnapshotParams = {
  regionId: string;
  projectId: string;
  id: string;
  pollingId?: string;
};

export type GetSnapshotResponse = Snapshot;

export type GetSnapshotsParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};

export type GetSnapshotsResponse = Snapshot[];

export type CreateSnapshotParams = {
  regionId: string;
  projectId: string;
  data: {
    name: string;
    description?: string;
    volume_id: string;
  };
};

export type CreateSnapshotResponse = Snapshot;

export type UpdateSnapshotParams = {
  regionId: string;
  projectId: string;
  id: string;
  data: {
    name?: string;
    description?: string;
  };
};

export type UpdateSnapshotResponse = Snapshot;

export type DeleteSnapshotParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export type DeleteSnapshotResponse = { message: string };

export type VolumeType = {
  description: string;
  extra_specs: Record<string, unknown>;
  id: string;
  is_public: boolean;
  name: string;
  os_volume_type_access_is_public: string;
  qos_specs_id: string;
  region: string;
};

export type GetVolumeTypeParams = {
  regionId: string;
  projectId: string;
  id: string;
};

export type GetVolumeTypeResponse = VolumeType;

export type GetVolumeTypesParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};

export type GetVolumeTypesResponse = VolumeType[];

export type VolumeLimits = {
  absolute: {
    maxTotalBackupGigabytes: number;
    maxTotalBackups: number;
    maxTotalSnapshots: number;
    maxTotalVolumeGigabytes: number;
    maxTotalVolumes: number;
    totalBackupGigabytesUsed: number;
    totalBackupsUsed: number;
    totalGigabytesUsed: number;
    totalSnapshotsUsed: number;
    totalVolumesUsed: number;
  };
  rate: [];
};

export type GetVolumeLimitsParams = {
  regionId: string;
  projectId: string;
  pollingId?: string;
};

export type GetVolumeLimitsResponse = VolumeLimits;
