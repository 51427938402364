export const splitMultilineCommaSeparatedList = (value: string): string[] => {
  if (!value.trim()) {
    return [];
  }
  // Split the string on commas and line breaks
  return value
    .trim()
    .split(/[\s,]+/)
    .filter(Boolean);
};
