import { RootReducer } from "../../reducers";
import { EmotionsPredictionResult } from "./types";

export const EmotionsPredictionValueSelector = (
  state: RootReducer
): EmotionsPredictionResult | null =>
  state.nlp.emotionsPredictions
    ? state.nlp.emotionsPredictions.result[0].ents[0]
    : null;

export const areEmotionsPreditionsLoadingSelector = (
  state: RootReducer
): boolean => state.nlp.areEmotionsPreditionsLoading;
