import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

export const InvitationMessage = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const HighlightedText = styled(Typography)<
  TypographyProps<"span", { component: "span" }>
>`
  font-weight: 500;
`;
