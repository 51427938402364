import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const Metrics = styled(Paper)`
  flex-grow: 1;
  position: relative;
  padding: ${({ theme }) => theme.spacing(1, 2, 0, 0)};
  flex-basis: 50%;

  #bottom-container {
    justify-content: center;
    ul {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export const MetricsFallback = styled(Typography)`
  position: absolute;
  top: 33%;
  left: 0;
  right: 0;
  pointer-events: none;
  text-align: center;
  margin: auto;
`;
