import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import * as routerActions from "modules/networks/actions";
import {
  isRouterInterfaceAddingSelector,
  isRouterInterfaceDeletingSelector,
  isRouterDeletingSelector,
  isRouterUpdatingSelector,
  routerSelector,
  subnetsSelector,
  tableInterfacesSelector
} from "modules/networks/selectors";
import { TableInterface, TableRouterRoute } from "modules/networks/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getParentPath } from "utils/getParentPath";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import { string } from "yup";
import { ENTITY_NAME_LENGTH, ROUTES } from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";
import { TAB_TITLES as NETWORKING_TAB_TITLES } from "components/Networking";
import { TABS as NETWORKING_TABS } from "components/Networking/types";
import { generateSearchString } from "utils/generateSearchString";

const POLL_ID_PREFIX = "ROUTER";
const POLL_IDS = {
  routers: "ROUTERS",
  router: "ROUTER",
  networks: "NETWORKS",
  tableInterfaces: "INTERFACES",
  subnets: "SUBNETS"
};

const interfaceTableColumns: TableColumn<TableInterface>[] = [
  { key: "id", label: "ID" },
  { key: "ip", label: "IP" },
  { key: "status", label: "Status" },
  { key: "admin_state_up", label: "Admin state" }
];

const routesTableColumns: TableColumn<TableRouterRoute>[] = [
  { key: "destination", label: "Destination CIDR" },
  { key: "nexthop", label: "Next Hop" }
];

export const Router: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    routerId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const router = useSelector(routerSelector);
  const subnets = useSelector(subnetsSelector);
  const tableInterfaces = useSelector(tableInterfacesSelector);
  const isInterfaceCreating = useSelector(isRouterInterfaceAddingSelector);
  const isInterfaceDeleting = useSelector(isRouterInterfaceDeletingSelector);
  const isInterfaceOperationInProgress =
    isInterfaceCreating || isInterfaceDeleting;
  const previousIsInterfaceOperationInProgress = usePrevious(
    isInterfaceOperationInProgress
  );
  const isRouterUpdating = useSelector(isRouterUpdatingSelector);
  const isRouterDeleting = useSelector(isRouterDeletingSelector);
  const isRouterOperationInProgress = isRouterUpdating || isRouterDeleting;
  const previousIsRouterOperationInProgress = usePrevious(
    isRouterOperationInProgress
  );
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
  }, [dialog]);

  const handleDeleteInterfaceMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_INTERFACE,
      isOpened: true
    });
  }, []);

  const handleDeleteRouteMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_ROUTE,
      isOpened: true
    });
  }, []);

  const interfaceTableActions: TableRowActionsMenuItem<TableInterface>[] = [
    {
      label: "Delete",
      handler: handleDeleteInterfaceMenuItemClick
    }
  ];

  const routerRouteTableActions: TableRowActionsMenuItem<TableRouterRoute>[] = [
    {
      label: "Delete",
      handler: handleDeleteRouteMenuItemClick
    }
  ];

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Networking",
      url: generatePath(ROUTES.NETWORKING, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Routers",
      url: `${generatePath(ROUTES.NETWORKING, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })}?${generateSearchString({
        tab: NETWORKING_TAB_TITLES[NETWORKING_TABS.ROUTERS]
      })}`
    },
    {
      text: router?.name || "",
      url: generatePath(ROUTES.ROUTER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        routerId: matchParams.routerId
      })
    }
  ];

  const handleAddInterfaceButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ADD_INTERFACE,
      isOpened: true
    });
  }, []);

  const handleEditRouterButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteRouterButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const handleAddRouteButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ADD_ROUTE,
      isOpened: true
    });
  }, []);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.router}`,
        action: networksActions.getRouter.started({
          projectId: matchParams.projectId!,
          regionId: matchParams.regionId!,
          id: matchParams.routerId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.tableInterfaces}`,
        action: networksActions.getRouterInterfaces.started({
          projectId: matchParams.projectId!,
          regionId: matchParams.regionId!,
          routerId: matchParams.routerId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.routers}`,
        action: networksActions.getRouters.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.tableInterfaces}`,
        action: networksActions.getSubnets.started({
          projectId: matchParams.projectId!,
          regionId: matchParams.regionId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(networksActions.clear());
  });

  useEffect(() => {
    if (
      previousIsInterfaceOperationInProgress &&
      !isInterfaceOperationInProgress
    ) {
      dispatch(
        networksActions.getRouter.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.routerId!
        })
      );
    }
  }, [
    previousIsInterfaceOperationInProgress,
    isInterfaceOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.routerId
  ]);

  useEffect(() => {
    if (previousIsRouterOperationInProgress && !isRouterOperationInProgress) {
      if (!router) {
        history(getParentPath(location.pathname, 2));
      } else {
        dispatch(
          networksActions.getRouter.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: matchParams.routerId!
          })
        );
      }
    }
  }, [
    router,
    history,
    previousIsRouterOperationInProgress,
    isRouterOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.routerId
  ]);

  const handleConfirmEditRouter = useCallback(
    (data: { name: string; description: string }) => {
      dispatch(
        routerActions.updateRouter.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.routerId!,
          data
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.routerId,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteRouter = useCallback(() => {
    dispatch(
      routerActions.deleteRouter.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.routerId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.routerId,
    handleCloseDialog
  ]);

  const handleConfirmAddInterface = useCallback(
    (data: { subnet_id: SelectOption }) => {
      dispatch(
        networksActions.addRouterInterface.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          routerId: matchParams.routerId!,
          data: { ...data, subnet_id: data.subnet_id.value }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId,
      matchParams.routerId
    ]
  );

  const handleConfirmDeleteInterface = useCallback(() => {
    const subnetId = tableInterfaces?.find(
      (item) => item.id === selectedItemId
    )?.subnetId;
    if (selectedItemId && subnetId) {
      dispatch(
        networksActions.deleteRouterInterface.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          routerId: matchParams.routerId!,
          id: selectedItemId,
          data: {
            subnet_id: subnetId
          }
        })
      );
    }
    handleCloseDialog();
  }, [
    tableInterfaces,
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.routerId
  ]);

  const handleConfirmAddRoute = useCallback(
    (data) => {
      dispatch(
        networksActions.addRoute.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          routerId: matchParams.routerId!,
          data
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId,
      matchParams.routerId
    ]
  );

  const handleConfirmDeleteRoute = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.deleteRoute.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          routerId: matchParams.routerId!,
          data: {
            destination_cidr: selectedItemId // TODO check out if it works!
          }
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.routerId
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingInterfaceId = tableInterfaces?.find(
    (item) => item.id === deletingItemId
  )?.id;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditRouter,
      title: "Edit router",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: router?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: router?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteRouter,
      title: `Are you sure you want to delete "${
        router?.name ?? "selected"
      }" router?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_INTERFACE]: {
      onConfirm: handleConfirmDeleteInterface,
      title: `Are you sure you want to delete "${
        deletingInterfaceId ?? "selected"
      }" interface from the router?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.ADD_INTERFACE]: {
      onConfirm: handleConfirmAddInterface,
      title: "Add interface",
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "subnet_id",
          type: FIELD_TYPES.SELECT,
          label: "Subnet ID",
          options: subnets?.map((subnet) =>
            getSelectOption(subnet, "name", "id")
          ),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.ADD_ROUTE]: {
      onConfirm: handleConfirmAddRoute,
      title: "Add route",
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "destination",
          type: FIELD_TYPES.TEXT,
          label: "Destination CIDR"
        },
        {
          name: "nexthop",
          type: FIELD_TYPES.TEXT,
          label: "Next Hop"
        }
      ]
    },
    [DIALOG_TYPES.DELETE_ROUTE]: {
      onConfirm: handleConfirmDeleteRoute,
      title: "Are you sure you want to delete route from the router?",
      confirmButtonLabel: "Delete"
    }
  };

  const tableRouterRoutes: TableRouterRoute[] = [];
  router?.routes.forEach((route) => {
    tableRouterRoutes.push({
      id: route.destination,
      destination: route.destination,
      nexthop: route.nexthop
    });
  });
  const title = router?.name;
  return (
    <>
      <Head title={title} />
      {router ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title title={title} variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>{router?.id}</s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Status: </s.DetailsTitle>
                <s.Tag label={router?.status} />
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>External gateway: </s.DetailsTitle>
                <s.Tag
                  label={
                    router?.external_gateway_info.external_fixed_ips
                      ? "ENABLED"
                      : "DISABLED"
                  }
                />
              </s.SummaryRow>
              {router?.external_gateway_info.external_fixed_ips && (
                <s.SummaryRow>
                  <s.DetailsTitle>External IP: </s.DetailsTitle>
                  <s.DetailsInfoColored>
                    {
                      router.external_gateway_info.external_fixed_ips[0]
                        .ip_address
                    }
                  </s.DetailsInfoColored>
                </s.SummaryRow>
              )}
              {router?.description && (
                <s.SummaryRow>
                  <s.DetailsTitle>Description: </s.DetailsTitle>
                  <s.DetailsInfo>{router?.description}</s.DetailsInfo>
                </s.SummaryRow>
              )}
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditRouterButtonClick}
                    color={"inherit"}
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteRouterButtonClick}
                    color={"inherit"}
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>

          <Table
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={tableInterfaces || []}
            columns={interfaceTableColumns}
            actions={interfaceTableActions}
            isLoading={!tableInterfaces}
            toolbarItems={
              <Button
                onClick={handleAddInterfaceButtonClick}
                variant={"contained"}
              >
                Add interface
              </Button>
            }
          />
          <Table
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={tableRouterRoutes || []}
            columns={routesTableColumns}
            actions={routerRouteTableActions}
            isLoading={!tableRouterRoutes}
            toolbarItems={
              <Button onClick={handleAddRouteButtonClick} variant={"contained"}>
                Add route
              </Button>
            }
          />
          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
