import { InstanceActionLogs, InstanceMetrics } from "modules/instances/types";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  Flavor,
  Image,
  Instance,
  InstanceFirewall,
  InstanceLimits,
  InstanceLog,
  Interface,
  RemoteConsoleURL
} from "./types";

export type Reducer = {
  instanceActionLogs: InstanceActionLogs[] | null;
  areInstanceActionLogsLoading: boolean;
  instances: Instance[] | null;
  instance: Instance | null;
  flavors: Flavor[] | null;
  flavor: Flavor | null;
  isFlavorLoading: boolean;
  areFlavorsLoading: boolean;
  isInstanceLoading: boolean;
  areInstancesLoading: boolean;
  isInstanceCreating: boolean;
  isInstanceUpdating: boolean;
  isInstanceResizing: boolean;
  isInstanceResizeConfirming: boolean;
  isInstanceResizeReverting: boolean;
  isInstanceDeleting: boolean;
  isInstanceStarting: boolean;
  isInstanceRestarting: boolean;
  isInstanceStopping: boolean;
  images: Image[] | null;
  image: Image | null;
  areImagesLoading: boolean;
  isImageLoading: boolean;
  isVolumeAttaching: boolean;
  isVolumeDetaching: boolean;
  isFirewallAdding: boolean;
  isFirewallRemoving: boolean;
  interfaces: Interface[] | null;
  interface: Interface | null;
  isInterfaceLoading: boolean;
  areInterfacesLoading: boolean;
  isInterfaceCreating: boolean;
  isInterfaceDeleting: boolean;
  instanceLimits: InstanceLimits[] | null;
  areInstanceLimitsLoading: boolean;
  instanceFirewalls: InstanceFirewall[] | null;
  areInstanceFirewallsLoading: boolean;
  remoteConsoleURL: RemoteConsoleURL | null;
  isRemoteConsoleURLLoading: boolean;
  instanceLog: InstanceLog | null;
  isInstanceLogLoading: boolean;
  instanceMetrics: InstanceMetrics | null;
  areInstanceMetricsLoading: boolean;
};

const initialState: Reducer = {
  instances: null,
  instance: null,
  flavors: null,
  flavor: null,
  isFlavorLoading: false,
  areFlavorsLoading: false,
  isInstanceLoading: false,
  areInstancesLoading: false,
  isInstanceCreating: false,
  isInstanceUpdating: false,
  isInstanceResizing: false,
  isInstanceResizeConfirming: false,
  isInstanceResizeReverting: false,
  isInstanceDeleting: false,
  isInstanceStarting: false,
  isInstanceRestarting: false,
  isInstanceStopping: false,
  images: null,
  image: null,
  areImagesLoading: false,
  isImageLoading: false,
  isVolumeAttaching: false,
  isVolumeDetaching: false,
  isFirewallAdding: false,
  isFirewallRemoving: false,
  interfaces: null,
  interface: null,
  isInterfaceLoading: false,
  areInterfacesLoading: false,
  isInterfaceCreating: false,
  isInterfaceDeleting: false,
  instanceLimits: null,
  areInstanceLimitsLoading: false,
  instanceFirewalls: null,
  areInstanceFirewallsLoading: false,
  remoteConsoleURL: null,
  isRemoteConsoleURLLoading: false,
  instanceLog: null,
  isInstanceLogLoading: false,
  instanceActionLogs: null,
  areInstanceActionLogsLoading: false,
  instanceMetrics: null,
  areInstanceMetricsLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getFlavor.started,
    (state): Reducer => ({
      ...state,
      isFlavorLoading: true
    })
  )
  .case(
    actions.getFlavor.done,
    (state, payload): Reducer => ({
      ...state,
      flavor: payload.result,
      isFlavorLoading: false
    })
  )
  .case(
    actions.getFlavor.failed,
    (state): Reducer => ({
      ...state,
      isFlavorLoading: false
    })
  )
  .case(
    actions.getFlavors.started,
    (state): Reducer => ({
      ...state,
      areFlavorsLoading: true
    })
  )
  .case(
    actions.getFlavors.done,
    (state, payload): Reducer => ({
      ...state,
      flavors: payload.result,
      areFlavorsLoading: false
    })
  )
  .case(
    actions.getFlavors.failed,
    (state): Reducer => ({
      ...state,
      areFlavorsLoading: false
    })
  )
  .case(
    actions.getInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceLoading: true
    })
  )
  .case(
    actions.getInstance.done,
    (state, payload): Reducer => ({
      ...state,
      instance: payload.result,
      isInstanceLoading: false
    })
  )
  .case(
    actions.getInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceLoading: false
    })
  )
  .case(
    actions.getInstances.started,
    (state): Reducer => ({
      ...state,
      areInstancesLoading: true
    })
  )
  .case(
    actions.getInstances.done,
    (state, payload): Reducer => ({
      ...state,
      instances: payload.result,
      areInstancesLoading: false
    })
  )
  .case(
    actions.getInstances.failed,
    (state): Reducer => ({
      ...state,
      areInstancesLoading: false
    })
  )
  .case(
    actions.createInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceCreating: true
    })
  )
  .case(
    actions.createInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceCreating: false
    })
  )
  .case(
    actions.createInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceCreating: false
    })
  )
  .case(
    actions.updateInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceUpdating: true
    })
  )
  .case(
    actions.updateInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceUpdating: false
    })
  )
  .case(
    actions.updateInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceUpdating: false
    })
  )
  .case(
    actions.resizeInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceResizing: true
    })
  )
  .case(
    actions.resizeInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceResizing: false
    })
  )
  .case(
    actions.resizeInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceResizing: false
    })
  )
  .case(
    actions.confirmResizeInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceResizeConfirming: true
    })
  )
  .case(
    actions.confirmResizeInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceResizeConfirming: false
    })
  )
  .case(
    actions.confirmResizeInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceResizeConfirming: false
    })
  )
  .case(
    actions.revertResizeInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceResizeReverting: true
    })
  )
  .case(
    actions.revertResizeInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceResizeReverting: false
    })
  )
  .case(
    actions.revertResizeInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceResizeReverting: false
    })
  )
  .case(
    actions.deleteInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceDeleting: true
    })
  )
  .case(
    actions.deleteInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceDeleting: false,
      instance: null
    })
  )
  .case(
    actions.deleteInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceDeleting: false
    })
  )
  .case(
    actions.startInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceStarting: true
    })
  )
  .case(
    actions.startInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceStarting: false
    })
  )
  .case(
    actions.startInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceStarting: false
    })
  )
  .case(
    actions.restartInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceRestarting: true
    })
  )
  .case(
    actions.restartInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceRestarting: false
    })
  )
  .case(
    actions.restartInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceRestarting: false
    })
  )
  .case(
    actions.stopInstance.started,
    (state): Reducer => ({
      ...state,
      isInstanceStopping: true
    })
  )
  .case(
    actions.stopInstance.done,
    (state): Reducer => ({
      ...state,
      isInstanceStopping: false
    })
  )
  .case(
    actions.stopInstance.failed,
    (state): Reducer => ({
      ...state,
      isInstanceStopping: false
    })
  )
  .case(
    actions.getImages.started,
    (state): Reducer => ({
      ...state,
      areImagesLoading: true
    })
  )
  .case(
    actions.getImages.done,
    (state, payload): Reducer => ({
      ...state,
      images: payload.result,
      areImagesLoading: false
    })
  )
  .case(
    actions.getImages.failed,
    (state): Reducer => ({
      ...state,
      areImagesLoading: false
    })
  )
  .case(
    actions.getImage.started,
    (state): Reducer => ({
      ...state,
      isImageLoading: true
    })
  )
  .case(
    actions.getImage.done,
    (state, payload): Reducer => ({
      ...state,
      image: payload.result,
      isImageLoading: false
    })
  )
  .case(
    actions.getImage.failed,
    (state): Reducer => ({
      ...state,
      isImageLoading: false
    })
  )
  .case(
    actions.attachVolume.started,
    (state): Reducer => ({
      ...state,
      isVolumeAttaching: true
    })
  )
  .case(
    actions.attachVolume.done,
    (state): Reducer => ({
      ...state,
      isVolumeAttaching: false
    })
  )
  .case(
    actions.attachVolume.failed,
    (state): Reducer => ({
      ...state,
      isVolumeAttaching: false
    })
  )
  .case(
    actions.detachVolume.started,
    (state): Reducer => ({
      ...state,
      isVolumeDetaching: true
    })
  )
  .case(
    actions.detachVolume.done,
    (state): Reducer => ({
      ...state,
      isVolumeDetaching: false
    })
  )
  .case(
    actions.detachVolume.failed,
    (state): Reducer => ({
      ...state,
      isVolumeDetaching: false
    })
  )
  .case(
    actions.addFirewall.started,
    (state): Reducer => ({
      ...state,
      isFirewallAdding: true
    })
  )
  .case(
    actions.addFirewall.done,
    (state): Reducer => ({
      ...state,
      isFirewallAdding: false
    })
  )
  .case(
    actions.addFirewall.failed,
    (state): Reducer => ({
      ...state,
      isFirewallAdding: false
    })
  )
  .case(
    actions.removeFirewall.started,
    (state): Reducer => ({
      ...state,
      isFirewallRemoving: true
    })
  )
  .case(
    actions.removeFirewall.done,
    (state): Reducer => ({
      ...state,
      isFirewallRemoving: false
    })
  )
  .case(
    actions.removeFirewall.failed,
    (state): Reducer => ({
      ...state,
      isFirewallRemoving: false
    })
  )
  .case(
    actions.getInterface.started,
    (state): Reducer => ({
      ...state,
      isInterfaceLoading: true
    })
  )
  .case(
    actions.getInterface.done,
    (state, payload): Reducer => ({
      ...state,
      interface: payload.result,
      isInterfaceLoading: false
    })
  )
  .case(
    actions.getInterface.failed,
    (state): Reducer => ({
      ...state,
      isInterfaceLoading: false
    })
  )
  .case(
    actions.getInterfaces.started,
    (state): Reducer => ({
      ...state,
      areInterfacesLoading: true
    })
  )
  .case(
    actions.getInterfaces.done,
    (state, payload): Reducer => ({
      ...state,
      interfaces: payload.result,
      areInterfacesLoading: false
    })
  )
  .case(
    actions.getInterfaces.failed,
    (state): Reducer => ({
      ...state,
      areInterfacesLoading: false
    })
  )
  .case(
    actions.createInterface.started,
    (state): Reducer => ({
      ...state,
      isInterfaceCreating: true
    })
  )
  .case(
    actions.createInterface.done,
    (state): Reducer => ({
      ...state,
      isInterfaceCreating: false
    })
  )
  .case(
    actions.createInterface.failed,
    (state): Reducer => ({
      ...state,
      isInterfaceCreating: false
    })
  )
  .case(
    actions.deleteInterface.started,
    (state): Reducer => ({
      ...state,
      isInterfaceDeleting: true
    })
  )
  .case(
    actions.deleteInterface.done,
    (state): Reducer => ({
      ...state,
      isInterfaceDeleting: false
    })
  )
  .case(
    actions.deleteInterface.failed,
    (state): Reducer => ({
      ...state,
      isInterfaceDeleting: false
    })
  )
  .case(
    actions.clearInterfaces,
    (state): Reducer => ({
      ...state,
      interfaces: initialState.interfaces
    })
  )
  .case(
    actions.getInstanceLimits.started,
    (state): Reducer => ({
      ...state,
      areInstanceLimitsLoading: true
    })
  )
  .case(
    actions.getInstanceLimits.done,
    (state, payload): Reducer => ({
      ...state,
      instanceLimits: payload.result,
      areInstanceLimitsLoading: false
    })
  )
  .case(
    actions.getInstanceLimits.failed,
    (state): Reducer => ({
      ...state,
      areInstanceLimitsLoading: false
    })
  )
  .case(
    actions.getInstanceFirewalls.done,
    (state, payload): Reducer => ({
      ...state,
      instanceFirewalls: payload.result,
      areInstanceFirewallsLoading: false
    })
  )
  .case(
    actions.getInstanceFirewalls.failed,
    (state): Reducer => ({
      ...state,
      areInstanceFirewallsLoading: false
    })
  )
  .case(
    actions.clearInstanceFirewalls,
    (state): Reducer => ({
      ...state,
      instanceFirewalls: initialState.instanceFirewalls
    })
  )
  .case(
    actions.getRemoteConsoleURL.started,
    (state): Reducer => ({
      ...state,
      isRemoteConsoleURLLoading: true
    })
  )
  .case(
    actions.getRemoteConsoleURL.done,
    (state, payload): Reducer => ({
      ...state,
      remoteConsoleURL: payload.result,
      isRemoteConsoleURLLoading: false
    })
  )
  .case(
    actions.getRemoteConsoleURL.failed,
    (state): Reducer => ({
      ...state,
      isRemoteConsoleURLLoading: false
    })
  )
  .case(
    actions.getInstanceLog.started,
    (state): Reducer => ({
      ...state,
      isInstanceLogLoading: true
    })
  )
  .case(
    actions.getInstanceLog.done,
    (state, payload): Reducer => ({
      ...state,
      instanceLog: payload.result,
      isInstanceLogLoading: false
    })
  )
  .case(
    actions.getInstanceLog.failed,
    (state): Reducer => ({
      ...state,
      isInstanceLogLoading: false
    })
  )
  .case(
    actions.getInstanceMetrics.started,
    (state): Reducer => ({
      ...state,
      areInstanceMetricsLoading: true
    })
  )
  .case(
    actions.getInstanceMetrics.done,
    (state, payload): Reducer => ({
      ...state,
      instanceMetrics: payload.result,
      areInstanceMetricsLoading: false
    })
  )
  .case(
    actions.getInstanceMetrics.failed,
    (state): Reducer => ({
      ...state,
      areInstanceMetricsLoading: false
    })
  )
  .case(
    actions.getInstanceActionLogs.started,
    (state): Reducer => ({
      ...state,
      areInstanceActionLogsLoading: true
    })
  )
  .case(
    actions.getInstanceActionLogs.done,
    (state, payload): Reducer => ({
      ...state,
      instanceActionLogs: payload.result,
      areInstanceActionLogsLoading: false
    })
  )
  .case(
    actions.getInstanceActionLogs.failed,
    (state): Reducer => ({
      ...state,
      areInstanceActionLogsLoading: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
