import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const ContactSupportLinkText = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 800;
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.secondary.light
      : theme.palette.primary.dark};
  line-height: 1.4em;
`;
