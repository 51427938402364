import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  AddPublicKeyParams,
  AddPublicKeyResponse,
  CreateKeyPairParams,
  CreateKeyPairResponse,
  DeleteKeyPairParams,
  DeleteKeyPairResponse,
  GetKeyPairsParams,
  GetKeyPairsResponse
} from "./types";

export function* getKeyPairsSaga(
  action: Action<GetKeyPairsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetKeyPairsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/keypairs`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getKeyPairs.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getKeyPairs.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get key pairs data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createKeyPairSaga(
  action: Action<CreateKeyPairParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the key pair...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateKeyPairResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/keypairs`,
      data
    );
    yield put(
      actions.createKeyPair.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Key pair has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createKeyPair.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create key pair",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* addPublicKeySaga(
  action: Action<AddPublicKeyParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Adding the public key...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<AddPublicKeyResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/keypairs`,
      data
    );
    yield put(
      actions.addPublicKey.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Public key has been successfully added.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.addPublicKey.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to add public key",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteKeyPairSaga(
  action: Action<DeleteKeyPairParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete key pair...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, name } = action.payload;
    const response: AxiosResponse<DeleteKeyPairResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-compute/method/${projectId}/keypairs/${name}`
    );
    yield put(
      actions.deleteKeyPair.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete key pair has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteKeyPair.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete key pair",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getKeyPairs.started, getKeyPairsSaga),
    takeEvery(actions.createKeyPair.started, createKeyPairSaga),
    takeEvery(actions.addPublicKey.started, addPublicKeySaga),
    takeEvery(actions.deleteKeyPair.started, deleteKeyPairSaga)
  ]);
}
