import { Flavor, InstanceMetrics, Metric } from "modules/instances/types";
import { ReactNode } from "react";

export interface MetricsProps {
  value: keyof InstanceMetrics;
  data: Metric[];
  flavor: Flavor;
  children?: ReactNode;
  className?: string;
}

export interface ChartOptions {
  valueFormat?: () => (tick: string) => string;
  valueDomain?: () => number[];
}

export const CPU_METRICS = "cpu_metrics";
export const MEMORY_METRICS = "memory_metrics";
export const TIMESTAMP_METRICS = "time_stamp";
