import axios, { AxiosError } from "axios";
import axiosRetry from "axios-retry";

export const API_PATH = `/v1.0/invoke/`;

export const axiosInstance = axios.create({
  baseURL: API_PATH,
  withCredentials: true
});

axiosRetry(axiosInstance, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: (err) => {
    const response = err.response;

    return Boolean(
      response &&
        response.config.method === "get" &&
        ([404, 500, 502, 503].includes(response.status) ||
          (response.status === 400 &&
            ["invalid state", "code not found"].includes(
              String(response.data)
            )))
    );
  }
});

const API_LOGIN_URL = "gotham-echo/method/api-login";
const API_LOGOUT_URL = "gotham-echo/method/api-logout";

const isReloginURL = (url: string) =>
  [API_LOGIN_URL, API_LOGOUT_URL].includes(url);

let isReloginInProgress = false;
let pendingRequests = 0;

axiosInstance.interceptors.request.use(
  (config) => {
    const isReloginRequest = config.url && isReloginURL(config.url);

    return new Promise((resolve) => {
      const interval = (window as Window).setInterval(() => {
        if (isReloginRequest && !pendingRequests) {
          isReloginInProgress = true;
          clearInterval(interval);
          resolve(config);
        } else if (!isReloginRequest && !isReloginInProgress) {
          clearInterval(interval);
          pendingRequests++;
          resolve(config);
        }
      }, 0);
    });
  },
  (error) => Promise.reject(error)
);

const handleResponse = (url?: string) => {
  if (url) {
    if (url === API_LOGIN_URL) {
      isReloginInProgress = false;
    }

    if (!isReloginURL(url)) {
      pendingRequests--;
    }
  }
};

axiosInstance.interceptors.response.use(
  (response) => {
    handleResponse(response.config.url);
    return Promise.resolve(response);
  },
  (error: AxiosError) => {
    handleResponse(error.config?.url);
    return Promise.reject(error);
  }
);
