export enum TABS {
  NETWORKS,
  VOLUMES,
  SNAPSHOTS,
  LOG
}

export enum DIALOG_TYPES {
  EDIT,
  DELETE,
  DELETE_INTERFACE,
  ADD_INTERFACE,
  ADD_ROUTE,
  DELETE_ROUTE
}
