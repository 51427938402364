import { Badge } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { DatabasePasswordDialog } from "components/common/DatabasePasswordDialog";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as databasesActions from "modules/databases/actions";
import {
  DATABASES_DEFAULT_PORTS,
  DATABASES_LABELS,
  DBServicesParamsSelector,
  databaseSelector,
  isDatabaseCreatingSelector,
  isDatabaseDeletingSelector,
  isDatabaseStatusChangingSelector,
  isDatabaseUpdatingSelector,
  tableDatabasesSelector
} from "modules/databases/selectors";
import {
  CHANGE_STATUS_TYPES,
  DATABASES,
  DATABASE_STATUSES,
  TableDatabase
} from "modules/databases/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import {
  subnetsSelector,
  tableNetworksSelector
} from "modules/networks/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { isSelectOption } from "typeGuards/isSelectOption";
import { isString } from "typeGuards/isString";
import { isUndefined } from "typeGuards/isUndefined";
import { getSelectDefaultValue } from "utils/getSelectDefaultValue";
import { getSelectOption } from "utils/getSelectOption";
import { splitMultilineCommaSeparatedList } from "utils/splitMultilineCommaSeparatedList";
import { NumberSchema, Schema, StringSchema, mixed, number, string } from "yup";
import {
  ERROR_MESSAGES,
  MAX_PORT,
  MIN_PORT,
  REGEX,
  ROUTES
} from "../../constants";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "DATABASES";

const POLL_IDS = {
  databases: "DATABASES",
  networks: "NETWORKS",
  subnets: "SUBNETS"
};

const IPv4ToInt = (ip: string) =>
  ip.split(".").reduce((int, oct) => (int << 8) + parseInt(oct, 10), 0) >>> 0;

const isIPv4InCIDR = (ip: string, cidr: string) => {
  const CIDRSubstrings = cidr.split("/");
  const range = CIDRSubstrings[0];
  const bits = CIDRSubstrings[1];
  const mask = ~(2 ** (32 - Number(bits)) - 1);
  return (IPv4ToInt(ip) & mask) === (IPv4ToInt(range) & mask);
};

const tableColumns: TableColumn<TableDatabase>[] = [
  { key: "name", label: "Name" },
  { key: "service_status", label: "Status" },
  { key: "mode", label: "Mode" },
  { key: "connectionString", label: "Connection string" },
  { key: "versionString", label: "Version" },
  { key: "cpuString", label: "vCPUs", sortingType: TABLE_SORTING_TYPES.NUMBER },
  {
    key: "memoryString",
    label: "Memory",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  },
  { key: "diskString", label: "Disk", sortingType: TABLE_SORTING_TYPES.NUMBER },
  { key: "ageString", label: "Created" }
];

const title = "Databases";

const databasePasswordTextAfterCreation =
  "Database has been successfully created. Please save the password for further use.";

export const Databases: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const database = useSelector(databaseSelector);
  const subnets = useSelector(subnetsSelector);
  const networks = useSelector(tableNetworksSelector);
  const DBParams = useSelector(DBServicesParamsSelector);
  const [isDatabasePasswordDialogOpened, setIsDatabasePasswordDialogOpened] =
    useState(false);
  const databases = useSelector(tableDatabasesSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [
    selectedDatabasePasswordDialogText,
    setSelectedDatabasePasswordDialogText
  ] = useState<string>(databasePasswordTextAfterCreation);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const isDatabaseCreating = useSelector(isDatabaseCreatingSelector);
  const isDatabaseUpdating = useSelector(isDatabaseUpdatingSelector);
  const isDatabaseDeleting = useSelector(isDatabaseDeletingSelector);
  const isDatabaseStatusChanging = useSelector(
    isDatabaseStatusChangingSelector
  );

  const isOperationInProgress =
    isDatabaseCreating ||
    isDatabaseUpdating ||
    isDatabaseDeleting ||
    isDatabaseStatusChanging;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const generateTableItemURL = useCallback(
    (id: string) => {
      const databaseServiceName = databases?.find(
        (database) => database.id === id
      )?.serviceName;

      if (databaseServiceName) {
        return generatePath(ROUTES.DATABASE, {
          organizationId: matchParams.organizationId,
          regionId: matchParams.regionId,
          projectId: matchParams.projectId,
          serviceName: databaseServiceName,
          databaseId: id
        });
      }
    },
    [
      databases,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Databases",
      url: generatePath(ROUTES.DATABASES, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  const handleCreateDatabaseButtonClick = useCallback(() => {
    setSelectedDatabasePasswordDialogText(databasePasswordTextAfterCreation);
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleEditDatabaseMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteDatabaseMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const handleChangeDatabaseStatusButtonClick = useCallback(
    (id: string) => {
      const serviceName = databases?.find(
        (database) => database.id === id
      )?.serviceName;

      if (serviceName) {
        dispatch(
          databasesActions.changeDatabaseStatus.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            id: id,
            serviceName,
            type: CHANGE_STATUS_TYPES.START
          })
        );
      }
    },
    [databases, dispatch, matchParams.organizationId, matchParams.projectId]
  );

  const tableActions: TableRowActionsMenuItem<TableDatabase>[] = [
    {
      label: "Start",
      handler: handleChangeDatabaseStatusButtonClick,
      isDisabled: (database) =>
        database.service_status !== DATABASE_STATUSES.STOPPED
    },
    {
      label: "Edit",
      handler: handleEditDatabaseMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteDatabaseMenuItemClick
    }
  ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.databases}`,
        action: databasesActions.getDatabases.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.networks}`,
        action: networksActions.getNetworks.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.subnets}`,
        action: networksActions.getSubnets.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(databasesActions.getDBServicesParams.started({}));
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(databasesActions.clear());
    dispatch(networksActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        databasesActions.getDatabases.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.organizationId,
    matchParams.projectId
  ]);

  const handleConfirmCreateDatabase = useCallback(
    (data: {
      name: string;
      user: string;
      ipACL: string;
      cpu: SelectOption;
      memory: SelectOption;
      disk: string;
      port: string;
      version: SelectOption | null;
      serviceName: SelectOption;
      enable_ilb: boolean;
      network_id: SelectOption | null;
      subnet_id: SelectOption | null;
      private_static_ip: string;
    }) => {
      dispatch(
        databasesActions.createDatabase.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          serviceName: data.serviceName.value,
          data: {
            name: data.name,
            ...(data.user ? { user: data.user } : {}),
            ...(data.version?.value ? { version: data.version.value } : {}),
            cpu: Number(data.cpu.value),
            memory: Number(data.memory.value),
            ...(data.port ? { port: Number(data.port) } : {}),
            disk: Number(data.disk),
            ...(data.ipACL
              ? {
                  ip_acl: splitMultilineCommaSeparatedList(data.ipACL)
                }
              : {}),
            ...(data.subnet_id ? { subnet_id: data.subnet_id.value } : {}),
            ...(data.private_static_ip
              ? { private_static_ip: data.private_static_ip }
              : {})
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.organizationId
    ]
  );

  const handleConfirmEditDatabase = useCallback(
    (data: {
      ipACL: string;
      cpu: SelectOption;
      memory: SelectOption;
      port: string;
      version: SelectOption;
    }) => {
      if (selectedItemId) {
        const serviceName = databases?.find(
          (database) => database.id === selectedItemId
        )?.serviceName;

        if (serviceName) {
          dispatch(
            databasesActions.updateDatabase.started({
              orgId: matchParams.organizationId!,
              projId: matchParams.projectId!,
              serviceName,
              id: selectedItemId,
              data: {
                version: data.version.value,
                cpu: Number(data.cpu.value),
                memory: Number(data.memory.value),
                port: Number(data.port),
                ip_acl: splitMultilineCommaSeparatedList(data.ipACL)
              }
            })
          );
        }
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      databases
    ]
  );

  const handleConfirmDeleteDatabase = useCallback(() => {
    if (selectedItemId) {
      const serviceName = databases?.find(
        (database) => database.id === selectedItemId
      )?.serviceName;

      if (serviceName) {
        dispatch(
          databasesActions.deleteDatabase.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            serviceName,
            id: selectedItemId
          })
        );
      }

      handleCloseDialog();
    }
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    matchParams.projectId,
    databases,
    handleCloseDialog
  ]);
  const handleConfirmDatabasePasswordDialog = useCallback(() => {
    if (selectedItemId) {
      const serviceName = databases?.find(
        (database) => database.id === selectedItemId
      )?.serviceName;

      if (serviceName) {
        dispatch(
          databasesActions.resetDatabasePassword.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            serviceName,
            id: selectedItemId
          })
        );
      }

      handleCloseDialog();
    }
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    matchParams.projectId,
    databases,
    handleCloseDialog
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingDatabaseName = databases?.find(
    (database) => database.id === deletingItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateDatabase,
      title: "Create database",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "serviceName",
          type: FIELD_TYPES.SELECT,
          label: "Service name",
          options: Object.keys(DATABASES_LABELS).map((x) => ({
            label: DATABASES_LABELS[x],
            value: x
          })),
          rules: selectOptionSchema
        },
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .matches(
              REGEX.NAME_STARTS_WITH_LETTER,
              ERROR_MESSAGES.NAME_STARTS_WITH_LETTER
            )
            .matches(REGEX.DATABASE_NAME, ERROR_MESSAGES.DATABASE_NAME)
        },
        {
          name: "version",
          type: FIELD_TYPES.SELECT,
          label: "Version",
          options: (fieldValues) =>
            DBParams?.find(
              (params) =>
                isSelectOption(fieldValues.serviceName) &&
                params.service_name === fieldValues.serviceName.value
            )?.versions.map((x) => ({
              label: x,
              value: x
            })),

          rules: selectOptionSchema.nullable(),
          isHidden: (fieldValues) => !fieldValues.serviceName
        },
        {
          name: "user",
          type: FIELD_TYPES.TEXT,
          label: "DB user",
          rules: string()
            .matches(REGEX.NAME_STARTS_WITH_LETTER, {
              message: ERROR_MESSAGES.NAME_STARTS_WITH_LETTER,
              excludeEmptyString: true
            })
            .matches(REGEX.DATABASE_NAME, {
              message: ERROR_MESSAGES.DATABASE_NAME,
              excludeEmptyString: true
            })
        },
        {
          name: "port",
          type: FIELD_TYPES.NUMBER,
          label: "Port",
          defaultValue: (fieldValues: Record<string, unknown>) =>
            isSelectOption(fieldValues.serviceName) &&
            Number(
              DATABASES_DEFAULT_PORTS[
                fieldValues.serviceName.value as DATABASES
              ]
            ),
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`),
          isHidden: (fieldValues) => !fieldValues.serviceName
        },
        {
          name: "cpu",
          type: FIELD_TYPES.SELECT,
          label: "vCPUs",
          options: (fieldValues) =>
            DBParams?.find(
              (params) =>
                isSelectOption(fieldValues.serviceName) &&
                params.service_name === fieldValues.serviceName.value
            )?.cpu.map((x) => ({
              label: `${Number(x) / 1000} vCPU`,
              value: x
            })),
          rules: selectOptionSchema,
          isHidden: (fieldValues) => !fieldValues.serviceName
        },
        {
          name: "memory",
          type: FIELD_TYPES.SELECT,
          label: "Memory",
          options: (fieldValues) =>
            DBParams?.find(
              (params) =>
                isSelectOption(fieldValues.serviceName) &&
                params.service_name === fieldValues.serviceName.value
            )?.memory.map((x) => ({
              label: `${Number(x) / 1024} GiB`,
              value: x
            })),
          rules: selectOptionSchema,
          isHidden: (fieldValues) => !fieldValues.serviceName
        },
        {
          name: "disk",
          type: FIELD_TYPES.NUMBER,
          label: "Disk",
          suffix: "MiB",
          min: (fieldValues) =>
            DBParams?.find(
              (params) =>
                isSelectOption(fieldValues.serviceName) &&
                params.service_name === fieldValues.serviceName.value
            ) &&
            Number(
              DBParams?.find(
                (params) =>
                  isSelectOption(fieldValues.serviceName) &&
                  params.service_name === fieldValues.serviceName.value
              )?.disk_min
            ),
          max: (fieldValues) =>
            DBParams?.find(
              (params) =>
                isSelectOption(fieldValues.serviceName) &&
                params.service_name === fieldValues.serviceName.value
            ) &&
            Number(
              DBParams?.find(
                (params) =>
                  isSelectOption(fieldValues.serviceName) &&
                  params.service_name === fieldValues.serviceName.value
              )?.disk_max
            ),
          defaultValue: (fieldValues: Record<string, unknown>) =>
            DBParams?.find(
              (params) =>
                isSelectOption(fieldValues.serviceName) &&
                params.service_name === fieldValues.serviceName.value
            ) &&
            Number(
              DBParams?.find(
                (params) =>
                  isSelectOption(fieldValues.serviceName) &&
                  params.service_name === fieldValues.serviceName.value
              )?.disk_min
            ),
          rules: number()
            .integer()
            .when(
              "serviceName",
              // (value: SelectOption | null, schema: NumberSchema) => {
              (value: SelectOption[], schema: NumberSchema) => {
                const min = DBParams?.find(
                  (params) => params.service_name === value[0]?.value[0]
                )?.disk_min;

                let extendedSchema = schema;

                if (!isUndefined(min)) {
                  extendedSchema = extendedSchema.concat(
                    schema.min(
                      Number(min),
                      `Disk size must be greater than or equals to ${min} MiB.`
                    )
                  );
                }

                const max = DBParams?.find(
                  (params) => params.service_name === value[0]?.value
                )?.disk_max;

                if (!isUndefined(max)) {
                  extendedSchema = extendedSchema.concat(
                    schema.max(
                      Number(max),
                      `Disk size must be less than ${max} MiB.`
                    )
                  );
                }

                return extendedSchema;
              }
            ),
          isHidden: (fieldValues) => !fieldValues.serviceName
        },
        {
          name: "ipACL",
          type: FIELD_TYPES.MULTILINE_TEXT,
          label: "IP ACL",
          rules: string().test(
            "IPACLValidFormat",
            'IP must have the valid format (e.g., "192.168.1.101/24") and one entry per line',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value).every((record) =>
                    REGEX.IP_ADDRESS_WITH_NET_MASK.test(record)
                  )
              )
          )
        },
        {
          name: "enable_ilb",
          type: FIELD_TYPES.CHECKBOX,
          label: "Provision in private network"
        },
        {
          name: "network_id",
          type: FIELD_TYPES.SELECT,
          label: "Network",
          options: networks?.map((network) =>
            getSelectOption(network, "name", "id")
          ),
          rules: selectOptionSchema.when(
            "enable_ilb",
            (value: boolean[], schema: Schema<SelectOption>) =>
              value[0] ? schema : mixed().notRequired()
          ),
          isHidden: (fieldValues) => !fieldValues.enable_ilb
        },
        {
          name: "subnet_id",
          type: FIELD_TYPES.SELECT,
          label: "Subnet",
          options: (fieldValues) =>
            subnets
              ?.filter(
                (subnet) =>
                  subnet.network_id ===
                  networks?.find(
                    (network) =>
                      isSelectOption(fieldValues.network_id) &&
                      network.id === fieldValues.network_id.value
                  )?.id
              )
              .map((subnet) => getSelectOption(subnet, "name", "id")),
          rules: selectOptionSchema.when(
            "enable_ilb",
            (value: boolean[], schema: Schema<SelectOption>) =>
              value[0] ? schema : mixed().notRequired()
          ),
          isHidden: (fieldValues) => !fieldValues.network_id
        },
        {
          name: "private_static_ip",
          type: FIELD_TYPES.TEXT,
          rules: string().when(
            "subnet_id",
            (subnet_id: SelectOption[], schema: StringSchema) =>
              subnet_id[0]
                ? schema
                    .matches(REGEX.IP_ADDRESS, {
                      message:
                        'IP must have the valid format (e.g., "192.168.1.101")',
                      excludeEmptyString: true
                    })
                    .test(
                      "privateStaticIpValidFormat",
                      "IP must be in subnet range",
                      (value) => {
                        const cidr = subnets?.find(
                          (subnet) => subnet.id === subnet_id[0]?.value
                        )?.cidr;
                        return Boolean(
                          value && cidr && isIPv4InCIDR(value, cidr)
                        );
                      }
                    )
                : schema
          ),
          isHidden: (fieldValues) => !fieldValues.network_id,
          label: "Private static IP"
        }
      ]
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditDatabase,
      title: "Edit database",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "version",
          type: FIELD_TYPES.SELECT,
          label: "Version",
          defaultValue: getSelectDefaultValue(
            databases,
            selectedItemId,
            "version",
            "version"
          ),
          options: DBParams?.find(
            (params) =>
              params.service_name ===
              databases?.find((database) => database.id === selectedItemId)
                ?.serviceName
          )
            ?.versions.filter((version) => {
              if (!selectedItemId) {
                return false;
              }

              const selectedDatabase = databases?.find(
                (database) => database.id === selectedItemId
              );
              if (!selectedDatabase) {
                return false;
              }
              return (
                Number(version.slice(0, version.indexOf("."))) >=
                Number(selectedDatabase.version.slice(0, version.indexOf(".")))
              );
            })
            .map((x) => ({
              label: x,
              value: x
            })),
          rules: selectOptionSchema
        },
        {
          name: "port",
          type: FIELD_TYPES.NUMBER,
          label: "Port",
          defaultValue: databases?.find(
            (database) => database.id === selectedItemId
          )
            ? databases?.find((database) => database.id === selectedItemId)
                ?.port
            : MIN_PORT,
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`)
        },
        {
          name: "cpu",
          type: FIELD_TYPES.SELECT,
          label: "vCPUs",
          defaultValue: getSelectDefaultValue(
            databases,
            selectedItemId,
            "cpuString",
            "cpu"
          ),
          options: DBParams?.find(
            (params) =>
              params.service_name ===
              databases?.find((database) => database.id === selectedItemId)
                ?.serviceName
          )?.cpu.map((x) => ({
            label: `${Number(x) / 1000} vCPU`,
            value: x
          })),
          rules: selectOptionSchema
        },
        {
          name: "memory",
          type: FIELD_TYPES.SELECT,
          label: "Memory",
          defaultValue: getSelectDefaultValue(
            databases,
            selectedItemId,
            "memoryString",
            "memory"
          ),
          options: DBParams?.find(
            (params) =>
              params.service_name ===
              databases?.find((database) => database.id === selectedItemId)
                ?.serviceName
          )?.memory.map((x) => ({
            label: `${Number(x) / 1024} GiB`,
            value: x
          })),
          rules: selectOptionSchema
        },
        {
          name: "ipACL",
          type: FIELD_TYPES.MULTILINE_TEXT,
          label: "IP ACL",
          defaultValue: databases
            ?.find((database) => database.id === selectedItemId)
            ?.ipACL.join(",\n"),
          rules: string().test(
            "IPACLValidFormat",
            'IP must have the valid format (e.g., "192.168.1.101/24") and one entry per line',
            (value) => {
              // Check if the value is empty or contains only whitespace
              if (!value || !value.trim()) {
                return true; // Empty value is allowed
              }
              // Split the value into individual records and validate each one
              return splitMultilineCommaSeparatedList(value).every((record) =>
                REGEX.IP_ADDRESS_WITH_NET_MASK.test(record)
              );
            }
          )
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteDatabase,
      title: `Are you sure you want to delete the "${
        deletingDatabaseName ?? "selected"
      }" database?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the database name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Database name does not match",
              test: function (value) {
                return value === deletingDatabaseName;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.RESET_PASSWORD]: {
      onConfirm: handleConfirmDatabasePasswordDialog,
      title: "Are you sure you want to reset password?",
      confirmButtonLabel: "Reset"
    }
  };

  const handleCloseDatabasePasswordDialog = useCallback(() => {
    setIsDatabasePasswordDialogOpened(false);
    dispatch(databasesActions.clearDatabaseResetPassword());
    dispatch(databasesActions.clearDatabase());
  }, [dispatch]);

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        <Badge
          color="warning"
          badgeContent={
            <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              beta
            </span>
          }
          style={{ paddingRight: "20px" }}
        >
          {title}
        </Badge>
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={databases || []}
        columns={tableColumns}
        actions={tableActions}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!databases}
        toolbarItems={
          <Button
            onClick={handleCreateDatabaseButtonClick}
            variant={"contained"}
            disabled={!DBParams}
          >
            Create database
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
      {database && isString(database.password) && (
        <DatabasePasswordDialog
          isOpened={isDatabasePasswordDialogOpened}
          name={database.name}
          text={selectedDatabasePasswordDialogText}
          password={database.password}
          onClose={handleCloseDatabasePasswordDialog}
        />
      )}
    </>
  );
};
