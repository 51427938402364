import { isNumber } from "typeGuards/isNumber";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { DEFAULT_PAGINATION_LIMIT } from "../../constants";
import * as actions from "./actions";
import { NewsFeedPost } from "./types";

export type Reducer = {
  posts: { data: NewsFeedPost[]; hasMore: boolean } | null;
  arePostsLoading: boolean;
  arePostsMarkingAsRead: boolean;
};

const initialState: Reducer = {
  posts: null,
  arePostsLoading: false,
  arePostsMarkingAsRead: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(actions.getNewsFeedPosts.started, (state) => ({
    ...state,
    arePostsLoading: true
  }))
  .case(
    actions.getNewsFeedPosts.done,
    (state, payload): Reducer => ({
      ...state,
      posts: {
        data: [
          ...((isNumber(payload.params.offset) ||
            isNumber(payload.params.lastId)) &&
          state.posts
            ? state.posts.data
            : []),
          ...payload.result.data
        ],
        hasMore:
          payload.result.data.length >=
          (payload.params.limit || DEFAULT_PAGINATION_LIMIT)
      },
      arePostsLoading: false
    })
  )
  .case(
    actions.getNewsFeedPosts.failed,
    (state): Reducer => ({
      ...state,
      arePostsLoading: false
    })
  )
  .case(
    actions.markNewsFeedPostsAsRead.started,
    (state): Reducer => ({
      ...state,
      arePostsMarkingAsRead: true
    })
  )
  .case(
    actions.markNewsFeedPostsAsRead.done,
    (state): Reducer => ({
      ...state,
      arePostsMarkingAsRead: false
    })
  )
  .case(
    actions.markNewsFeedPostsAsRead.failed,
    (state): Reducer => ({
      ...state,
      arePostsMarkingAsRead: false
    })
  )
  .case(actions.clear, () => initialState);
