import SvgIcon from "@mui/material/SvgIcon";
import { FC } from "react";

export const RedhatIcon: FC = () => (
  <SvgIcon
    width={"25"}
    height={"25"}
    viewBox={"0 0 256 256"}
    style={{ pointerEvents: "none" }}
  >
    <g>
      <path
        d="M235.463,197.578 C230.528,196.443 225.294,195.736 219.998,195.736 C210.947,195.736 202.712,197.302 196.632,199.828 C195.961,200.162 195.486,200.862 195.486,201.657 C195.486,201.944 195.56,202.243 195.672,202.486 C196.389,204.571 195.207,206.831 189.329,208.125 C180.611,210.038 175.107,219.032 171.954,222.023 C168.256,225.533 157.814,227.692 159.384,225.6 C160.613,223.963 165.315,218.856 168.175,213.336 C170.732,208.404 173.009,207.003 176.145,202.297 C177.068,200.916 180.635,196.069 181.672,192.232 C182.838,188.487 182.444,183.791 182.892,181.858 C183.532,179.069 186.166,173.012 186.365,169.597 C186.479,167.66 178.294,172.352 174.41,172.352 C170.526,172.352 166.74,170.031 163.271,169.859 C158.976,169.657 156.217,173.171 152.33,172.557 C150.11,172.207 148.244,170.253 144.364,170.105 C138.843,169.903 132.096,173.171 119.427,172.766 C106.961,172.362 95.448,157.016 93.874,154.577 C92.035,151.71 89.785,151.71 87.333,153.957 C84.881,156.204 81.859,154.442 80.997,152.936 C79.363,150.073 74.991,141.709 68.221,139.958 C58.86,137.533 54.117,145.142 54.733,151.198 C55.357,157.346 59.331,159.067 61.174,162.334 C63.01,165.605 63.953,167.717 67.412,169.165 C69.868,170.183 70.781,171.699 70.05,173.713 C69.41,175.468 66.856,175.869 65.179,175.949 C61.612,176.118 59.112,175.151 57.287,173.986 C55.168,172.638 53.443,170.759 51.591,167.569 C49.448,164.052 46.077,162.52 42.146,162.52 C40.273,162.52 38.521,163.015 36.965,163.816 C30.808,167.016 23.475,168.916 15.582,168.916 L6.683,168.92 C2.348,156.072 0,142.312 0,128.003 C0,57.307 57.31,0 128.003,0 C198.696,0 256,57.307 256,128.003 C256,153.654 248.455,177.546 235.463,197.578"
        fill="#000000"
      ></path>
      <path
        d="M235.463,197.575 C230.528,196.44 225.294,195.725 219.998,195.725 C210.947,195.725 202.712,197.295 196.632,199.825 C195.961,200.155 195.486,200.859 195.486,201.654 C195.486,201.944 195.56,202.24 195.672,202.486 C196.389,204.568 195.207,206.824 189.329,208.118 C180.611,210.035 175.107,219.028 171.954,222.013 C168.256,225.529 157.814,227.688 159.384,225.593 C160.613,223.96 165.315,218.853 168.175,213.336 C170.732,208.404 173.009,206.996 176.145,202.294 C177.068,200.916 180.635,196.066 181.672,192.229 C182.838,188.487 182.444,183.788 182.892,181.854 C183.532,179.062 186.166,173.009 186.365,169.587 C186.479,167.656 178.294,172.349 174.41,172.349 C170.526,172.349 166.74,170.024 163.271,169.856 C158.976,169.654 156.217,173.167 152.33,172.557 C150.11,172.2 148.244,170.243 144.364,170.099 C138.843,169.9 132.096,173.167 119.427,172.76 C106.961,172.355 95.448,157.009 93.874,154.567 C92.035,151.707 89.785,151.707 87.333,153.954 C84.881,156.2 81.859,154.435 80.997,152.926 C79.363,150.07 74.991,141.706 68.221,139.951 C58.86,137.526 54.117,145.138 54.733,151.195 C55.357,157.342 59.331,159.064 61.174,162.331 C63.01,165.602 63.953,167.71 67.412,169.155 C69.868,170.179 70.781,171.692 70.05,173.71 C69.41,175.461 66.856,175.862 65.179,175.943 C61.612,176.115 59.112,175.144 57.287,173.986 C55.168,172.628 53.443,170.755 51.591,167.562 C49.448,164.045 46.077,162.506 42.146,162.506 C40.273,162.506 38.521,163.008 36.965,163.806 C30.808,167.016 23.475,168.913 15.582,168.913 L6.683,168.916 C23.747,219.544 71.619,256 128.003,256 C173.042,256 212.648,232.741 235.463,197.575"
        fill="#FFFFFF"
      ></path>
      <path
        d="M156.194,185.839 C156.85,186.482 157.986,188.642 156.598,191.384 C155.82,192.835 154.984,193.859 153.486,195.055 C151.687,196.497 148.167,198.164 143.34,195.102 C140.743,193.455 140.588,192.903 137.004,193.368 C134.444,193.701 133.426,191.117 134.346,188.968 C135.266,186.826 139.048,185.091 143.751,187.847 C145.863,189.09 149.164,191.71 152.051,189.389 C153.246,188.429 153.964,187.789 155.624,185.869 C155.699,185.789 155.803,185.742 155.921,185.742 C156.025,185.742 156.123,185.778 156.194,185.839"
        fill="#000000"
      ></path>
      <path
        d="M200.232,93.945 C198.969,98.196 197.171,103.636 189.167,107.746 C188.002,108.339 187.557,107.362 188.096,106.442 C191.117,101.298 191.656,100.012 192.536,97.981 C193.765,95.016 194.408,90.796 191.963,81.991 C187.15,64.667 177.105,41.512 169.809,34.001 C162.762,26.752 149.999,24.711 138.462,27.672 C134.215,28.763 125.901,33.085 110.484,29.612 C83.806,23.602 79.852,36.962 78.323,42.782 C76.79,48.603 73.115,65.145 73.115,65.145 C71.889,71.879 70.285,83.594 111.71,91.483 C131.008,95.161 131.992,100.146 132.844,103.737 C134.376,110.168 136.825,113.846 139.581,115.682 C142.339,117.521 139.581,119.043 136.522,119.357 C128.303,120.209 97.927,111.498 79.956,101.288 C65.253,92.301 65.004,84.21 68.369,77.346 C46.161,74.944 29.494,79.431 26.472,89.944 C21.285,107.988 66.146,138.809 117.224,154.274 C170.829,170.506 225.96,159.175 232.088,125.484 C234.873,110.174 221.979,98.85 200.232,93.945 M118.043,60.952 C103.256,62.023 101.72,63.619 98.951,66.567 C95.047,70.727 89.903,61.171 89.903,61.171 C86.814,60.52 83.072,55.545 85.093,50.897 C87.08,46.299 90.755,47.68 91.907,49.112 C93.309,50.853 96.296,53.703 100.177,53.602 C104.057,53.497 108.534,52.682 114.779,52.682 C121.105,52.682 125.359,55.043 125.598,57.074 C125.804,58.806 125.086,60.443 118.043,60.952 M133.575,36.52 C133.551,36.524 133.528,36.527 133.507,36.527 C133.278,36.527 133.093,36.349 133.093,36.136 C133.093,35.982 133.187,35.847 133.326,35.786 C136.192,34.27 140.47,33.068 145.364,32.569 C146.833,32.418 148.268,32.34 149.649,32.327 C149.891,32.327 150.134,32.33 150.38,32.333 C158.585,32.519 165.154,35.779 165.056,39.616 C164.958,43.453 158.232,46.413 150.026,46.228 C147.368,46.168 144.876,45.784 142.73,45.164 C142.477,45.1 142.292,44.881 142.292,44.625 C142.292,44.369 142.477,44.15 142.737,44.089 C147.857,42.904 151.309,40.967 151.067,39.138 C150.744,36.716 144.047,35.395 136.111,36.194 C135.242,36.281 134.393,36.392 133.575,36.52"
        fill="#CC0000"
      ></path>
    </g>
  </SvgIcon>
);
