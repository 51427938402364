import React from "react";
import * as s from "./styles";
import { Button } from "@mui/material";

const PopupBanner: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <s.PopUpContainer>
      <s.PopUpContent>
        <strong>{"Notice"}:</strong>{" "}
        {
          "Due to the large number of files (exceeding 5,000), expect a flat file list and limited folder navigation. Utilize the search function for better file management."
        }
      </s.PopUpContent>
      <Button onClick={onClose}>{"Close"}</Button>
    </s.PopUpContainer>
  );
};

export default PopupBanner;
