import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { ContactSupportDialog } from "components/common/ContactSupportDialog";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { userSelector } from "modules/auth/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  isOrganizationCreatingSelector,
  isOrganizationDeletingSelector,
  isOrganizationUpdatingSelector,
  tableOrganizationsSelector
} from "modules/enterprises/selectors";
import { ROLES, TableOrganization } from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import { validateName } from "utils/validateName";
import { string } from "yup";
import { appConfig } from "../../appConfig";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  REGEX,
  ROLE_LABELS,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";
import { FooterContactSupportDialog } from "components/common/FooterContactSupportModal";

const POLL_ID_PREFIX = "ORGANIZATIONS";

const POLL_IDS = {
  organizations: "ORGANIZATIONS"
};

const breadcrumbs: Breadcrumb[] = [
  { text: "Organizations", url: ROUTES.ORGANIZATIONS }
];

const tableColumns: TableColumn<TableOrganization>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "role", label: "Role" }
];

const title = "Organizations";

export const Organizations: FC = () => {
  const dispatch = useDispatch();
  const organizations = useSelector(tableOrganizationsSelector);
  const isOrganizationCreating = useSelector(isOrganizationCreatingSelector);
  const isOrganizationUpdating = useSelector(isOrganizationUpdatingSelector);
  const isOrganizationDeleting = useSelector(isOrganizationDeletingSelector);
  const user = useSelector(userSelector);
  const isOperationInProgress =
    isOrganizationCreating || isOrganizationUpdating || isOrganizationDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [isContactSupportDialogOpened, setIsContactSupportDialogOpened] =
    useState(false);

  const [
    isFooterContactSupportDialogOpened,
    setIsFooterContactSupportDialogOpened
  ] = useState(false);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleCreateOrganizationButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleEditOrganizationMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteOrganizationMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const handleLeaveOrganizationMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.LEAVE,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem<TableOrganization>[] = [
    {
      label: "Edit",
      handler: handleEditOrganizationMenuItemClick,
      isDisabled: (organization) =>
        ![ROLE_LABELS[ROLES.OWNER]].includes(organization.role)
    },
    {
      label: "Delete",
      handler: handleDeleteOrganizationMenuItemClick,
      isDisabled: (organization) =>
        ![ROLE_LABELS[ROLES.OWNER]].includes(organization.role)
    },
    {
      label: "Leave",
      handler: handleLeaveOrganizationMenuItemClick,
      isDisabled: (organization) =>
        [ROLE_LABELS[ROLES.OWNER]].includes(organization.role)
    }
  ];

  const handleConfirmCreateOrganization = useCallback(
    (data: { name: string }) => {
      dispatch(
        enterprisesActions.createOrganization.started({
          data
        })
      );
      handleCloseDialog();
    },
    [dispatch, handleCloseDialog]
  );

  const handleConfirmEditOrganization = useCallback(
    (data: { name: string }) => {
      if (selectedItemId) {
        dispatch(
          enterprisesActions.updateOrganization.started({
            id: selectedItemId,
            data
          })
        );
      }
      handleCloseDialog();
    },
    [dispatch, selectedItemId, handleCloseDialog]
  );

  const handleConfirmDeleteOrganization = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.deleteOrganization.started({
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const handleConfirmLeaveOrganization = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.leaveOrganization.started({
          organizationId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentOrganizationName = organizations?.find(
    (organization) => organization.id === currentItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateOrganization,
      title: "Create organization",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.ORGANIZATION_NAME, ERROR_MESSAGES.ORGANIZATION_NAME)
        }
      ]
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditOrganization,
      title: "Edit organization",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            organizations?.find(
              (organization) => organization.id === selectedItemId
            )?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.ORGANIZATION_NAME, ERROR_MESSAGES.ORGANIZATION_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteOrganization,
      title: `Are you sure you want to delete "${
        currentOrganizationName ?? "selected"
      }" organization?`,
      confirmButtonLabel: "Delete",
      fields: [
        {
          name: "deletionInfo",
          type: FIELD_TYPES.NOTES,
          label: `\nℹ️ All associated projects must be deleted before this organization can be removed.`
        }
      ]
    },
    [DIALOG_TYPES.LEAVE]: {
      onConfirm: handleConfirmLeaveOrganization,
      title: `Are you sure you want to leave "${
        currentOrganizationName ?? "selected"
      }" organization?`,
      confirmButtonLabel: "Leave"
    }
  };

  const generateTableItemURL = useCallback(
    (id: string) => generatePath(ROUTES.ORGANIZATION, { organizationId: id }),
    []
  );

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizations}`,
        action: enterprisesActions.getOrganizations.started({})
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(enterprisesActions.getOrganizations.started({}));
    }
  }, [dispatch, previousIsOperationInProgress, isOperationInProgress]);

  const userOwnedOrgs = organizations?.filter(
    (org) => org.role == ROLE_LABELS[ROLES.OWNER]
  );

  const orgLimit = user?.attributes?.["allowed_org_number"]
    ? Number(user.attributes["allowed_org_number"][0])
    : appConfig.defaultAllowedOrgNumber;

  const isOrgLimitExceeded = Boolean(
    userOwnedOrgs && userOwnedOrgs.length >= orgLimit
  );

  const handleSupportLinkClick = useCallback(() => {
    switch (appConfig.supportMod) {
      case "dialog":
        setIsContactSupportDialogOpened(true);
        break;
      case "modal":
        setIsFooterContactSupportDialogOpened(true);
        break;
      case "link":
        window.location.href = `mailto:${appConfig.supportEmail || ""}`;
        break;
      default:
        break;
    }
  }, []);

  const handleContactSupportDialogClose = useCallback(() => {
    setIsContactSupportDialogOpened(false);
  }, []);

  const handleFooterContactSupportDialogClose = useCallback(() => {
    setIsFooterContactSupportDialogOpened(false);
  }, []);

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        actions={tableActions}
        rows={organizations || []}
        columns={tableColumns}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!organizations}
        toolbarItems={
          <Tooltip
            arrow
            title={
              isOrgLimitExceeded ? (
                <>
                  Organization count limit has been exceeded or isn&apos;t set.
                  Please contact{" "}
                  <Link component={"button"} onClick={handleSupportLinkClick}>
                    <s.ContactSupportLinkText>support</s.ContactSupportLinkText>
                  </Link>{" "}
                  to increase it.
                </>
              ) : (
                ""
              )
            }
          >
            <span>
              <Button
                onClick={handleCreateOrganizationButtonClick}
                variant={"contained"}
                disabled={!organizations || !user || isOrgLimitExceeded}
              >
                Create organization
              </Button>
            </span>
          </Tooltip>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
      <ContactSupportDialog
        isOpened={isContactSupportDialogOpened}
        onClose={handleContactSupportDialogClose}
      />
      <FooterContactSupportDialog
        isOpened={isFooterContactSupportDialogOpened}
        onClose={handleFooterContactSupportDialogClose}
        supportEmail={appConfig.supportEmail}
        supportPhones={appConfig.supportPhones}
      />
    </>
  );
};
