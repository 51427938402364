import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Spinner = styled(CircularProgress)`
  margin: ${({ theme }) => theme.spacing(0, 2)};
`;
