import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Snapshot, Volume, VolumeLimits, VolumeType } from "./types";

export type Reducer = {
  volumes: Volume[] | null;
  volume: Volume | null;
  isVolumeLoading: boolean;
  areVolumesLoading: boolean;
  isVolumeCreating: boolean;
  isVolumeUpdating: boolean;
  isVolumeExtending: boolean;
  isVolumeDeleting: boolean;
  areVolumesDeleting: boolean;
  snapshots: Snapshot[] | null;
  snapshot: Snapshot | null;
  isSnapshotLoading: boolean;
  areSnapshotsLoading: boolean;
  isSnapshotCreating: boolean;
  isSnapshotUpdating: boolean;
  isSnapshotDeleting: boolean;
  volumeTypes: VolumeType[] | null;
  volumeType: VolumeType | null;
  isVolumeTypeLoading: boolean;
  areVolumeTypesLoading: boolean;
  volumeLimits: VolumeLimits | null;
  areVolumeLimitsLoading: boolean;
};

const initialState: Reducer = {
  volumes: null,
  volume: null,
  isVolumeLoading: false,
  areVolumesLoading: false,
  isVolumeCreating: false,
  isVolumeUpdating: false,
  isVolumeExtending: false,
  isVolumeDeleting: false,
  areVolumesDeleting: false,
  snapshots: null,
  snapshot: null,
  isSnapshotLoading: false,
  areSnapshotsLoading: false,
  isSnapshotCreating: false,
  isSnapshotUpdating: false,
  isSnapshotDeleting: false,
  volumeTypes: null,
  volumeType: null,
  isVolumeTypeLoading: false,
  areVolumeTypesLoading: false,
  volumeLimits: null,
  areVolumeLimitsLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getVolume.started,
    (state): Reducer => ({
      ...state,
      isVolumeLoading: true
    })
  )
  .case(
    actions.getVolume.done,
    (state, payload): Reducer => ({
      ...state,
      volume: payload.result,
      isVolumeLoading: false
    })
  )
  .case(
    actions.getVolume.failed,
    (state): Reducer => ({
      ...state,
      isVolumeLoading: false
    })
  )
  .case(
    actions.getVolumes.started,
    (state): Reducer => ({
      ...state,
      areVolumesLoading: true
    })
  )
  .case(
    actions.getVolumes.done,
    (state, payload): Reducer => ({
      ...state,
      volumes: payload.result,
      areVolumesLoading: false
    })
  )
  .case(
    actions.getVolumes.failed,
    (state): Reducer => ({
      ...state,
      areVolumesLoading: false
    })
  )
  .case(
    actions.createVolume.started,
    (state): Reducer => ({
      ...state,
      isVolumeCreating: true
    })
  )
  .case(
    actions.createVolume.done,
    (state): Reducer => ({
      ...state,
      isVolumeCreating: false
    })
  )
  .case(
    actions.createVolume.failed,
    (state): Reducer => ({
      ...state,
      isVolumeCreating: false
    })
  )
  .case(
    actions.updateVolume.started,
    (state): Reducer => ({
      ...state,
      isVolumeUpdating: true
    })
  )
  .case(
    actions.updateVolume.done,
    (state): Reducer => ({
      ...state,
      isVolumeUpdating: false
    })
  )
  .case(
    actions.updateVolume.failed,
    (state): Reducer => ({
      ...state,
      isVolumeUpdating: false
    })
  )
  .case(
    actions.extendVolume.started,
    (state): Reducer => ({
      ...state,
      isVolumeExtending: true
    })
  )
  .case(
    actions.extendVolume.done,
    (state): Reducer => ({
      ...state,
      isVolumeExtending: false
    })
  )
  .case(
    actions.extendVolume.failed,
    (state): Reducer => ({
      ...state,
      isVolumeExtending: false
    })
  )
  .case(
    actions.deleteVolume.started,
    (state): Reducer => ({
      ...state,
      isVolumeDeleting: true
    })
  )
  .case(
    actions.deleteVolume.done,
    (state): Reducer => ({
      ...state,
      isVolumeDeleting: false
    })
  )
  .case(
    actions.deleteVolume.failed,
    (state): Reducer => ({
      ...state,
      isVolumeDeleting: false
    })
  )
  .case(
    actions.deleteVolumes.started,
    (state): Reducer => ({
      ...state,
      areVolumesDeleting: true
    })
  )
  .case(
    actions.deleteVolumes.done,
    (state): Reducer => ({
      ...state,
      areVolumesDeleting: false
    })
  )
  .case(
    actions.deleteVolumes.failed,
    (state): Reducer => ({
      ...state,
      areVolumesDeleting: false
    })
  )
  .case(
    actions.getVolumeLimits.started,
    (state): Reducer => ({
      ...state,
      areVolumeLimitsLoading: true
    })
  )
  .case(
    actions.getVolumeLimits.done,
    (state, payload): Reducer => ({
      ...state,
      volumeLimits: payload.result,
      areVolumeLimitsLoading: false
    })
  )
  .case(
    actions.getVolumeLimits.failed,
    (state): Reducer => ({
      ...state,
      areVolumeLimitsLoading: false
    })
  )
  .case(
    actions.getSnapshot.started,
    (state): Reducer => ({
      ...state,
      isSnapshotLoading: true
    })
  )
  .case(
    actions.getSnapshot.done,
    (state, payload): Reducer => ({
      ...state,
      snapshot: payload.result,
      isSnapshotLoading: false
    })
  )
  .case(
    actions.getSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isSnapshotLoading: false
    })
  )
  .case(
    actions.getSnapshots.started,
    (state): Reducer => ({
      ...state,
      areSnapshotsLoading: true
    })
  )
  .case(
    actions.getSnapshots.done,
    (state, payload): Reducer => ({
      ...state,
      snapshots: payload.result,
      areSnapshotsLoading: false
    })
  )
  .case(
    actions.getSnapshots.failed,
    (state): Reducer => ({
      ...state,
      areSnapshotsLoading: false
    })
  )
  .case(
    actions.createSnapshot.started,
    (state): Reducer => ({
      ...state,
      isSnapshotCreating: true
    })
  )
  .case(
    actions.createSnapshot.done,
    (state): Reducer => ({
      ...state,
      isSnapshotCreating: false
    })
  )
  .case(
    actions.createSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isSnapshotCreating: false
    })
  )
  .case(
    actions.updateSnapshot.started,
    (state): Reducer => ({
      ...state,
      isSnapshotUpdating: true
    })
  )
  .case(
    actions.updateSnapshot.done,
    (state): Reducer => ({
      ...state,
      isSnapshotUpdating: false
    })
  )
  .case(
    actions.updateSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isSnapshotUpdating: false
    })
  )
  .case(
    actions.deleteSnapshot.started,
    (state): Reducer => ({
      ...state,
      isSnapshotDeleting: true
    })
  )
  .case(
    actions.deleteSnapshot.done,
    (state): Reducer => ({
      ...state,
      isSnapshotDeleting: false
    })
  )
  .case(
    actions.deleteSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isSnapshotDeleting: false
    })
  )
  .case(
    actions.getVolumeType.started,
    (state): Reducer => ({
      ...state,
      isVolumeTypeLoading: true
    })
  )
  .case(
    actions.getVolumeType.done,
    (state, payload): Reducer => ({
      ...state,
      volumeType: payload.result,
      isVolumeTypeLoading: false
    })
  )
  .case(
    actions.getVolumeType.failed,
    (state): Reducer => ({
      ...state,
      isVolumeTypeLoading: false
    })
  )
  .case(
    actions.getVolumeTypes.started,
    (state): Reducer => ({
      ...state,
      areVolumeTypesLoading: true
    })
  )
  .case(
    actions.getVolumeTypes.done,
    (state, payload): Reducer => ({
      ...state,
      volumeTypes: payload.result,
      areVolumeTypesLoading: false
    })
  )
  .case(
    actions.getVolumeTypes.failed,
    (state): Reducer => ({
      ...state,
      areVolumeTypesLoading: false
    })
  )
  .case(
    actions.clearVolumes,
    (state): Reducer => ({
      ...state,
      volumes: initialState.volumes
    })
  )
  .case(
    actions.clearSnapshots,
    (state): Reducer => ({
      ...state,
      snapshots: initialState.snapshots
    })
  )
  .case(actions.clear, (): Reducer => initialState);
