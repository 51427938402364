import { RootReducer } from "../../reducers";
import { NewsFeedPost } from "./types";

const EMPTY_ARRAY: NewsFeedPost[] = [];

export const postsSelector = (state: RootReducer): NewsFeedPost[] =>
  state.news.posts?.data || EMPTY_ARRAY;

export const hasMorePostsSelector = (state: RootReducer): boolean =>
  state.news.posts?.hasMore || false;

export const arePostsLoadingSelector = (state: RootReducer): boolean =>
  state.news.arePostsLoading;

export const arePostsMarkingAsReadSelector = (state: RootReducer): boolean =>
  state.news.arePostsMarkingAsRead;
