import actionCreatorFactory from "typescript-fsa";
import {
  AddRouterInterfaceParams,
  AddRouterInterfaceResponse,
  AddRouteParams,
  AddRouteResponse,
  CreateFirewallParams,
  CreateFirewallResponse,
  CreateFirewallRuleParams,
  CreateFirewallRuleResponse,
  CreateNetworkParams,
  CreateFloatingIPParams,
  CreateFloatingIPResponse,
  CreateNetworkResponse,
  CreateRouterParams,
  CreateRouterResponse,
  CreateSubnetParams,
  CreateSubnetResponse,
  DeleteFirewallParams,
  DeleteFirewallResponse,
  DeleteFirewallRuleParams,
  DeleteFirewallRuleResponse,
  DeleteRouterInterfaceParams,
  DeleteRouterInterfaceResponse,
  DeleteNetworkParams,
  DeleteFloatingIPParams,
  DeleteFloatingIPResponse,
  DeleteNetworkResponse,
  DeleteRouteParams,
  DeleteRouteResponse,
  DeleteRouterParams,
  DeleteRouterResponse,
  DeleteSubnetParams,
  DeleteSubnetResponse,
  EditInterfaceFirewallsParams,
  EditInterfaceFirewallsResponse,
  GetFirewallParams,
  GetFirewallResponse,
  GetFirewallRuleParams,
  GetFirewallRuleResponse,
  GetFirewallRulesParams,
  GetFirewallRulesResponse,
  GetFirewallsParams,
  GetFirewallsResponse,
  GetInterfacesParams,
  GetInterfacesResponse,
  GetRouterInterfacesParams,
  GetRouterInterfacesResponse,
  GetNetworkLimitsParams,
  GetNetworkLimitsResponse,
  GetNetworkParams,
  GetNetworkResponse,
  GetFloatingIPsParams,
  GetFloatingIPsResponse,
  GetNetworksParams,
  GetNetworksResponse,
  GetNetworkSubnetsParams,
  GetNetworkSubnetsResponse,
  GetRouterParams,
  GetRouterResponse,
  GetRoutersParams,
  GetRoutersResponse,
  GetSubnetParams,
  GetSubnetResponse,
  GetSubnetsParams,
  GetSubnetsResponse,
  UpdateFirewallParams,
  UpdateFirewallResponse,
  UpdateNetworkParams,
  UpdateNetworkResponse,
  UpdateFloatingIPParams,
  UpdateFloatingIPResponse,
  UpdateRouterParams,
  UpdateRouterResponse,
  UpdateSubnetParams,
  UpdateSubnetResponse
} from "./types";

const actionCreator = actionCreatorFactory("NETWORKS");

export const getNetwork = actionCreator.async<
  GetNetworkParams,
  GetNetworkResponse,
  unknown
>("GET_NETWORK");

export const getNetworks = actionCreator.async<
  GetNetworksParams,
  GetNetworksResponse,
  unknown
>("GET_NETWORKS");

export const createNetwork = actionCreator.async<
  CreateNetworkParams,
  CreateNetworkResponse,
  unknown
>("CREATE_NETWORK");

export const updateNetwork = actionCreator.async<
  UpdateNetworkParams,
  UpdateNetworkResponse,
  unknown
>("UPDATE_NETWORK");

export const deleteNetwork = actionCreator.async<
  DeleteNetworkParams,
  DeleteNetworkResponse,
  unknown
>("DELETE_NETWORK");

export const getFloatingIPs = actionCreator.async<
  GetFloatingIPsParams,
  GetFloatingIPsResponse,
  unknown
>("GET_FLOATING_IPS");

export const createFloatingIP = actionCreator.async<
  CreateFloatingIPParams,
  CreateFloatingIPResponse,
  unknown
>("CREATE_FLOATING_IP");

export const updateFloatingIP = actionCreator.async<
  UpdateFloatingIPParams,
  UpdateFloatingIPResponse,
  unknown
>("UPDATE_FLOATING_IP");

export const deleteFloatingIP = actionCreator.async<
  DeleteFloatingIPParams,
  DeleteFloatingIPResponse,
  unknown
>("DELETE_FLOATING_IP");

export const getRouter = actionCreator.async<
  GetRouterParams,
  GetRouterResponse,
  unknown
>("GET_ROUTER");

export const getRouters = actionCreator.async<
  GetRoutersParams,
  GetRoutersResponse,
  unknown
>("GET_ROUTERS");

export const createRouter = actionCreator.async<
  CreateRouterParams,
  CreateRouterResponse,
  unknown
>("CREATE_ROUTER");

export const updateRouter = actionCreator.async<
  UpdateRouterParams,
  UpdateRouterResponse,
  unknown
>("UPDATE_ROUTER");

export const deleteRouter = actionCreator.async<
  DeleteRouterParams,
  DeleteRouterResponse,
  unknown
>("DELETE_ROUTER");

export const getRouterInterfaces = actionCreator.async<
  GetRouterInterfacesParams,
  GetRouterInterfacesResponse,
  unknown
>("GET_ROUTER_INTERFACES");

export const addRouterInterface = actionCreator.async<
  AddRouterInterfaceParams,
  AddRouterInterfaceResponse,
  unknown
>("ADD_ROUTER_INTERFACE");

export const deleteRouterInterface = actionCreator.async<
  DeleteRouterInterfaceParams,
  DeleteRouterInterfaceResponse,
  unknown
>("DELETE_ROUTER_INTERFACE");

export const getNetworkLimits = actionCreator.async<
  GetNetworkLimitsParams,
  GetNetworkLimitsResponse,
  unknown
>("GET_NETWORK_LIMITS");

export const getSubnet = actionCreator.async<
  GetSubnetParams,
  GetSubnetResponse,
  unknown
>("GET_SUBNET");

export const getSubnets = actionCreator.async<
  GetSubnetsParams,
  GetSubnetsResponse,
  unknown
>("GET_SUBNETS");

export const getNetworkSubnets = actionCreator.async<
  GetNetworkSubnetsParams,
  GetNetworkSubnetsResponse,
  unknown
>("GET_NETWORK_SUBNETS");

export const createSubnet = actionCreator.async<
  CreateSubnetParams,
  CreateSubnetResponse,
  unknown
>("CREATE_SUBNET");

export const updateSubnet = actionCreator.async<
  UpdateSubnetParams,
  UpdateSubnetResponse,
  unknown
>("UPDATE_SUBNET");

export const deleteSubnet = actionCreator.async<
  DeleteSubnetParams,
  DeleteSubnetResponse,
  unknown
>("DELETE_SUBNET");

export const getFirewall = actionCreator.async<
  GetFirewallParams,
  GetFirewallResponse,
  unknown
>("GET_FIREWALL");

export const getFirewalls = actionCreator.async<
  GetFirewallsParams,
  GetFirewallsResponse,
  unknown
>("GET_FIREWALLS");

export const createFirewall = actionCreator.async<
  CreateFirewallParams,
  CreateFirewallResponse,
  unknown
>("CREATE_FIREWALL");

export const updateFirewall = actionCreator.async<
  UpdateFirewallParams,
  UpdateFirewallResponse,
  unknown
>("UPDATE_FIREWALL");

export const deleteFirewall = actionCreator.async<
  DeleteFirewallParams,
  DeleteFirewallResponse,
  unknown
>("DELETE_FIREWALL");

export const editInterfaceFirewalls = actionCreator.async<
  EditInterfaceFirewallsParams,
  EditInterfaceFirewallsResponse,
  unknown
>("EDIT_INTERFACE_FIREWALL");

export const getFirewallRule = actionCreator.async<
  GetFirewallRuleParams,
  GetFirewallRuleResponse,
  unknown
>("GET_FIREWALL_RULE");

export const getFirewallRules = actionCreator.async<
  GetFirewallRulesParams,
  GetFirewallRulesResponse,
  unknown
>("GET_FIREWALL_RULES");

export const createFirewallRule = actionCreator.async<
  CreateFirewallRuleParams,
  CreateFirewallRuleResponse,
  unknown
>("CREATE_FIREWALL_RULE");

export const deleteFirewallRule = actionCreator.async<
  DeleteFirewallRuleParams,
  DeleteFirewallRuleResponse,
  unknown
>("DELETE_FIREWALL_RULE");

export const addRoute = actionCreator.async<
  AddRouteParams,
  AddRouteResponse,
  unknown
>("ADD_ROUTE");

export const deleteRoute = actionCreator.async<
  DeleteRouteParams,
  DeleteRouteResponse,
  unknown
>("DELETE_ROUTE");

export const getInterfaces = actionCreator.async<
  GetInterfacesParams,
  GetInterfacesResponse,
  unknown
>("GET_INTERFACES");
export const clear = actionCreator<void>("CLEAR");
