export enum DIALOG_TYPES {
  EDIT,
  DELETE,
  GENERATE_BILLING_REPORT
}

export enum DATE_TYPES {
  START,
  END
}

export enum TABS {
  SUMMARY,
  INSTANCES,
  VOLUMES,
  DATABASES,
  OBJECT_STORAGE
}
