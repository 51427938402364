import { showNotification } from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import { SubmitTicketParams } from "./types";

const serviceApiPath = `gotham-asylum/method`;

export function* submitTicketSaga(
  action: Action<SubmitTicketParams>
): SagaIterator<void> {
  try {
    yield call(axiosInstance.post, `${serviceApiPath}/create-ticket`, {
      subject: action.payload.subject,
      comment: { body: action.payload.details }
    });
    yield put(actions.submitTicket.done({ params: action.payload }));
    yield put(
      showNotification({
        title: "Ticket has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.submitTicket.failed({ params: action.payload, error: e })
    );
    yield put(
      showNotification({
        title: "Failed to create ticket.",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([takeEvery(actions.submitTicket.started, submitTicketSaga)]);
}
