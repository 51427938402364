import { DRAWER_WIDTH } from "components/Container/styles";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Box } from "@mui/material";

export const ModalContentBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: ${({ theme }) => theme.palette.background.default};
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.16),
    rgba(255, 255, 255, 0.16)
  );
  padding: 20pt;
  outline: unset;
  border-radius: 5pt;
`;

export const ModalTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 600;
`;

export const SupportInfoLink = styled(Link)`
  cursor: pointer;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`;
