// export const generateSearchString = (
//   params: Record<string, string>
// ): string => {
//   const formattedParams = Object.keys(params).reduce(
//     (obj, key) => ({
//       ...obj,
//       [key]: params[key].toLowerCase()
//     }),
//     {}
//   );
//   return new URLSearchParams(formattedParams).toString();
// };

export const generateSearchString = (
  params: Record<string, string>,
  lowercase: boolean = true // by default lower
): string => {
  const formattedParams = Object.keys(params).reduce(
    (obj, key) => ({
      ...obj,
      [key]: lowercase ? params[key].toLowerCase() : params[key]
    }),
    {}
  );
  return new URLSearchParams(formattedParams).toString();
};
