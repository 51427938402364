import {
  ArgumentScale,
  EventTracker,
  ValueScale
} from "@devexpress/dx-react-chart";
import {
  ArgumentAxis,
  Chart,
  Legend,
  Tooltip as DXTooltip,
  ValueAxis
} from "@devexpress/dx-react-chart-material-ui";
import { scaleTime } from "d3-scale";
import { sub } from "date-fns";
import { FC } from "react";
import { formatDate } from "utils/formatDate";
import { DATE_FORMATS } from "../../../constants";
import * as s from "./styles";
import {
  ChartOptions,
  CPU_METRICS,
  MEMORY_METRICS,
  MetricsProps,
  TIMESTAMP_METRICS
} from "./types";
export { CPU_METRICS, MEMORY_METRICS, TIMESTAMP_METRICS } from "./types";

export const Metrics: FC<MetricsProps> = (props) => {
  const { data, value, flavor } = props;

  const currentDate = new Date();

  const endDateMetric =
    data.length < 2 ? currentDate : data[data.length - 1][TIMESTAMP_METRICS];

  const startDateMetric =
    data.length < 2
      ? sub(currentDate, { hours: 1 })
      : data[0][TIMESTAMP_METRICS];

  const domain = () => [startDateMetric, endDateMetric];

  const argumentFormat = () => (tick: string) => {
    return `${formatDate(new Date(tick), DATE_FORMATS.TIME)}`;
  };

  const chartOptions: ChartOptions = {};

  if (value === CPU_METRICS) {
    chartOptions.valueDomain = () => [0, 100];
    chartOptions.valueFormat = () => (tick) => {
      return `${tick} %`;
    };
  }

  if (value === MEMORY_METRICS) {
    chartOptions.valueDomain = () => [0, flavor.ram / 1024];
    chartOptions.valueFormat = () => (tick) => {
      return `${parseFloat(tick).toFixed(2)} GiB`;
    };
  }

  return (
    <s.Metrics className={props.className} variant={"outlined"}>
      <Chart height={250} data={data.length < 2 ? [] : data}>
        <ArgumentScale factory={scaleTime} modifyDomain={domain} />
        <ValueAxis
          showLine={true}
          showTicks={true}
          tickFormat={chartOptions.valueFormat}
        />
        <ValueScale modifyDomain={chartOptions.valueDomain} />
        <ArgumentAxis showLine={true} tickFormat={argumentFormat} />
        {props.children}
        <EventTracker />
        <DXTooltip />
        <Legend position={"bottom"} />
        {data.length < 2 && (
          <s.MetricsFallback variant={"caption"} color={"textSecondary"}>
            No data
          </s.MetricsFallback>
        )}
      </Chart>
    </s.Metrics>
  );
};
