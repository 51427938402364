import styled from "styled-components";
import { DRAWER_WIDTH } from "components/Container/styles";

export const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - ${DRAWER_WIDTH}px);
  margin-left: ${DRAWER_WIDTH}px;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
  z-index: 1000;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
`;

export const PopUpContent = styled.div`
  flex: 1;
`;
