import { formatDate } from "utils/formatDate";
import { DATE_FORMATS } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  ImageFromVolume,
  TableSnapshot,
  TableVolume,
  Volume,
  VolumeLimits,
  VolumeType
} from "./types";
import { createSelector } from "reselect";

const getVolumes = (state: RootReducer) => state.storage.volumes;
export const tableVolumesSelector = createSelector([getVolumes], (volumes) => {
  return volumes
    ? volumes.map((volume) => ({
        name: volume.name,
        id: volume.id,
        status: volume.status,
        size: volume.size,
        sizeString: `${volume.size} GiB`,
        createdAt: formatDate(
          new Date(volume.created_at),
          DATE_FORMATS.DATETIME
        ),
        bootable: volume.bootable,
        serverId: volume.attachments[0]?.server_id,
        detailedName: `${volume.name} (status: ${volume.status})`,
        description: volume.description
      }))
    : null;
});

// export const tableVolumesSelector = (
//   state: RootReducer
// ): TableVolume[] | null =>
//   state.storage.volumes?.map((volume) => ({
//     name: volume.name,
//     id: volume.id,
//     status: volume.status,
//     size: volume.size,
//     sizeString: `${volume.size} GiB`,
//     createdAt: formatDate(new Date(volume.created_at), DATE_FORMATS.DATETIME),
//     bootable: volume.bootable,
//     serverId: volume.attachments[0]?.server_id,
//     detailedName: `${volume.name} (status: ${volume.status})`,
//     description: volume.description
//   })) || null;

const getSnapshots = (state: RootReducer) => state.storage.snapshots;
export const tableSnapshotsSelector = createSelector(
  [getSnapshots],
  (snapshots) => {
    return snapshots
      ? snapshots.map((snapshot) => ({
          id: snapshot.id,
          name: snapshot.name,
          status: snapshot.status,
          size: `${snapshot.size} GiB`,
          createdAt: formatDate(
            new Date(snapshot.created_at),
            DATE_FORMATS.DATETIME
          ),
          description: snapshot.description
        }))
      : null;
  }
);

// export const tableSnapshotsSelector = (
//   state: RootReducer
// ): TableSnapshot[] | null =>
//   state.storage.snapshots?.map((snapshot) => ({
//     id: snapshot.id,
//     name: snapshot.name,
//     status: snapshot.status,
//     size: `${snapshot.size} GiB`,
//     createdAt: formatDate(new Date(snapshot.created_at), DATE_FORMATS.DATETIME),
//     description: snapshot.description
//   })) || null;

const getCurrentInstanceId = (state: RootReducer) =>
  state.instances.instance?.id;
export const tableVirtualMachineVolumesSelector = createSelector(
  [getVolumes, getCurrentInstanceId],
  (volumes, currentInstanceId) => {
    return (
      volumes
        ?.filter((volume) =>
          volume.attachments.some(
            (attachment) => attachment.server_id === currentInstanceId
          )
        )
        .map((volume) => ({
          name: volume.name,
          id: volume.id,
          status: volume.status,
          size: volume.size,
          sizeString: `${volume.size} GiB`,
          createdAt: formatDate(
            new Date(volume.created_at),
            DATE_FORMATS.DATETIME
          ),
          bootable: volume.bootable,
          detailedName: `${volume.name} (status: ${volume.status})`,
          description: volume.description
        })) || null
    );
  }
);

// export const tableVirtualMachineVolumesSelector = (
//   state: RootReducer
// ): TableVolume[] | null =>
//   state.storage.volumes
//     ?.filter((volume) =>
//       volume.attachments.some(
//         (attachment) => attachment.server_id === state.instances.instance?.id
//       )
//     )
//     .map((volume) => ({
//       name: volume.name,
//       id: volume.id,
//       status: volume.status,
//       size: volume.size,
//       sizeString: `${volume.size} GiB`,
//       createdAt: formatDate(new Date(volume.created_at), DATE_FORMATS.DATETIME),
//       bootable: volume.bootable,
//       detailedName: `${volume.name} (status: ${volume.status})`,
//       description: volume.description
//     })) || null;

export const tableVirtualMachineSnapshotsSelector = createSelector(
  [getSnapshots, getVolumes, getCurrentInstanceId],
  (snapshots, volumes, currentInstanceId) => {
    const virtualMachineVolumeIds = volumes
      ?.filter((volume) =>
        volume.attachments.some(
          (attachment) => attachment.server_id === currentInstanceId
        )
      )
      .map((volume) => volume.id);

    return (
      snapshots
        ?.filter((snapshot) =>
          virtualMachineVolumeIds?.includes(snapshot.volume_id)
        )
        .map((snapshot) => ({
          id: snapshot.id,
          name: snapshot.name,
          status: snapshot.status,
          size: `${snapshot.size} GiB`,
          createdAt: formatDate(
            new Date(snapshot.created_at),
            DATE_FORMATS.DATETIME
          ),
          description: snapshot.description
        })) || null
    );
  }
);
// export const tableVirtualMachineSnapshotsSelector = (
//   state: RootReducer
// ): TableSnapshot[] | null => {
//   const virtualMachineVolumeIds = state.storage.volumes
//     ?.filter((volume) =>
//       volume.attachments.some(
//         (attachment) => attachment.server_id === state.instances.instance?.id
//       )
//     )
//     .map((volume) => volume.id);

//   return (
//     state.storage.snapshots
//       ?.filter(
//         (snapshot) => virtualMachineVolumeIds?.includes(snapshot.volume_id)
//       )
//       .map((snapshot) => ({
//         id: snapshot.id,
//         name: snapshot.name,
//         status: snapshot.status,
//         size: `${snapshot.size} GiB`,
//         createdAt: formatDate(
//           new Date(snapshot.created_at),
//           DATE_FORMATS.DATETIME
//         ),
//         description: snapshot.description
//       })) || null
//   );
// };

export const isVolumeLoadingSelector = (state: RootReducer): boolean =>
  state.storage.isVolumeLoading;

export const isVolumeCreatingSelector = (state: RootReducer): boolean =>
  state.storage.isVolumeCreating;

export const isVolumeUpdatingSelector = (state: RootReducer): boolean =>
  state.storage.isVolumeUpdating;

export const isVolumeExtendingSelector = (state: RootReducer): boolean =>
  state.storage.isVolumeExtending;

export const isVolumeDeletingSelector = (state: RootReducer): boolean =>
  state.storage.isVolumeDeleting;

export const areVolumesDeletingSelector = (state: RootReducer): boolean =>
  state.storage.areVolumesDeleting;

export const volumeLimitsSelector = (state: RootReducer): VolumeLimits | null =>
  state.storage.volumeLimits;

export const areVolumeLimitsLoadingSelector = (state: RootReducer): boolean =>
  state.storage.areVolumeLimitsLoading;

export const isSnapshotLoadingSelector = (state: RootReducer): boolean =>
  state.storage.isSnapshotLoading;

export const isSnapshotCreatingSelector = (state: RootReducer): boolean =>
  state.storage.isSnapshotCreating;

export const isSnapshotUpdatingSelector = (state: RootReducer): boolean =>
  state.storage.isSnapshotUpdating;

export const isSnapshotDeletingSelector = (state: RootReducer): boolean =>
  state.storage.isSnapshotDeleting;

export const volumeTypesSelector = (state: RootReducer): VolumeType[] | null =>
  state.storage.volumeTypes;

export const areVolumesLoadingSelector = (state: RootReducer): boolean =>
  state.storage.areVolumesLoading;

export const areSnapshotsLoadingSelector = (state: RootReducer): boolean =>
  state.storage.areSnapshotsLoading;

export const areVolumeTypesLoadingSelector = (state: RootReducer): boolean =>
  state.storage.areVolumeTypesLoading;

export const volumeSelector = (state: RootReducer): Volume | null =>
  state.storage.volume;

export const imageFromVolumeSelector = (
  state: RootReducer
): ImageFromVolume | undefined => state.storage.volume?.volume_image_metadata;
