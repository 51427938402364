import { SelectOption } from "components/common/FormDialog/types";

export const getSelectOption = <T extends Record<string, unknown>>(
  option: T,
  labelKey: keyof T,
  valueKey: keyof T
): SelectOption => ({
  label: String(option[labelKey]),
  value: String(option[valueKey])
});
