import { LineSeries } from "@devexpress/dx-react-chart-material-ui";
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Launch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import StopIcon from "@mui/icons-material/Stop";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Menu } from "components/common/Menu";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";

import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import {
  areInstanceMetricsLoadingSelector,
  flavorsSelector,
  imagesSelector,
  instanceActionLogsSelector,
  instanceLogSelector,
  instanceMetricsSelector,
  instanceSelector,
  isInstanceDeletingSelector,
  isInstanceLogLoadingSelector,
  isInstanceResizeConfirmingSelector,
  isInstanceResizeRevertingSelector,
  isInstanceResizingSelector,
  isInstanceRestartingSelector,
  isInstanceStartingSelector,
  isInstanceStoppingSelector,
  isInstanceUpdatingSelector,
  isInterfaceCreatingSelector,
  isInterfaceDeletingSelector,
  isVolumeAttachingSelector,
  isVolumeDetachingSelector,
  selectOptionFlavorsSelector,
  tableInterfacesSelector
} from "modules/instances/selectors";
import {
  FLAVOR_TYPE,
  INSTANCE_STATUSES,
  ImageInfo,
  RESTART_TYPE,
  TableInstanceActionLogs,
  TableInterface
} from "modules/instances/types";
import * as networksActions from "modules/networks/actions";
import {
  floatingIPsSelector,
  isFloatingIPUpdatingSelector,
  tableFirewallsSelector,
  tableNetworksSelector
} from "modules/networks/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import * as storageActions from "modules/storage/actions";
import {
  imageFromVolumeSelector,
  isVolumeExtendingSelector,
  isVolumeUpdatingSelector,
  tableVirtualMachineSnapshotsSelector,
  tableVirtualMachineVolumesSelector,
  tableVolumesSelector
} from "modules/storage/selectors";
import {
  TableSnapshot,
  TableVolume,
  VOLUME_STATUSES
} from "modules/storage/types";
import {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isNull } from "typeGuards/isNull";
import { isSelectOption } from "typeGuards/isSelectOption";
import { downloadFile } from "utils/downloadFile";
import { formatRegion } from "utils/formatRegion";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { getSelectDefaultValue } from "utils/getSelectDefaultValue";
import { getSelectOption } from "utils/getSelectOption";
import { splitMultilineCommaSeparatedList } from "utils/splitMultilineCommaSeparatedList";
import { validateName } from "utils/validateName";
import { array, number, string } from "yup";
import { API_PATH } from "../../axios";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  MAX_SERVER_TAG_COUNT,
  MIN_VOLUME_SIZE,
  OS_PLATFORMS,
  REGEX,
  ROUTES,
  SPECIAL_NAMES,
  VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES,
  VIRTUAL_MACHINE_ACTIONS_NOT_ALLOWED_STATUSES,
  VOLUME_SIZE_STEP
} from "../../constants";
import { VIRTUAL_MACHINE_ACTIONS } from "../../types";
import {
  CPU_METRICS,
  MEMORY_METRICS,
  Metrics,
  TIMESTAMP_METRICS
} from "./Metrics";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";

const DEFAULT_LOG_LENGTH = 30;
const POLL_ID_PREFIX = "VIRTUAL_MACHINE";

const POLL_IDS = {
  instance: "INSTANCE",
  flavors: "FLAVORS",
  snapshots: "SNAPSHOTS",
  volumes: "VOLUMES",
  networks: "NETWORKS",
  interfaces: "INTERFACES",
  firewalls: "FIREWALLS",
  subnets: "SUBNETS",
  metrics: "METRICS",
  floatingIPs: "FLOATING_IPs"
};

const snapshotTableColumns: TableColumn<TableSnapshot>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "status", label: "Status" },
  { key: "size", label: "Size", sortingType: TABLE_SORTING_TYPES.NUMBER },
  { key: "description", label: "Description" },
  {
    key: "createdAt",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const volumeTableColumns: TableColumn<TableVolume>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "status", label: "Status" },
  { key: "sizeString", label: "Size", sortingType: TABLE_SORTING_TYPES.NUMBER },
  {
    key: "createdAt",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const networkTableColumns: TableColumn<TableInterface>[] = [
  { key: "id", label: "Interface ID" },
  { key: "networkName", label: "Network" },
  { key: "name", label: "Subnet" },
  { key: "ip", label: "IP Address" },
  { key: "firewalls", label: "Firewalls" },
  { key: "macAddr", label: "MAC Address" },
  { key: "status", label: "Status" }
];

const actionLogsTableColumns: TableColumn<TableInstanceActionLogs>[] = [
  { key: "user_id", label: "User ID" },
  { key: "email", label: "User e-mail" },
  { key: "action", label: "Action" },
  { key: "start_time", label: "Start Time" }
  // { key: "id", label: "Request ID" }
];

const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.NETWORKS]: "Networks & Security",
  [TABS.VOLUMES]: "Volumes",
  [TABS.SNAPSHOTS]: "Snapshots",
  [TABS.LOG]: "Log",
  [TABS.ACTION_LOG]: "Action Log"
};

export const VirtualMachine: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    virtualMachineId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const virtualMachine = useSelector(instanceSelector);
  const previousVirtualMachine = usePrevious(virtualMachine);
  const imageFromVolume = useSelector(imageFromVolumeSelector);
  const images = useSelector(imagesSelector);
  const flavors = useSelector(flavorsSelector);
  const instanceMetrics = useSelector(instanceMetricsSelector);
  const instanceActionLogs = useSelector(instanceActionLogsSelector);
  const areInstanceMetricsLoading = useSelector(
    areInstanceMetricsLoadingSelector
  );
  const flavorSelectOptions = useSelector(selectOptionFlavorsSelector);
  const rebootButtonRef = useRef<HTMLButtonElement | null>(null);
  const interfaces = useSelector(tableInterfacesSelector);
  const networks = useSelector(tableNetworksSelector);
  const floatingIPs = useSelector(floatingIPsSelector);
  const snapshots = useSelector(tableVirtualMachineSnapshotsSelector);
  const attachedVolumes = useSelector(tableVirtualMachineVolumesSelector);
  const volumes = useSelector(tableVolumesSelector);
  const firewalls = useSelector(tableFirewallsSelector);
  const [isRebootMenuOpen, setIsRebootMenuOpen] = useState<boolean>(false);
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.NETWORKS) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);
  const isVirtualMachineStarting = useSelector(isInstanceStartingSelector);
  const isVirtualMachineRebooting = useSelector(isInstanceRestartingSelector);
  const isVirtualMachineStopping = useSelector(isInstanceStoppingSelector);
  const isVirtualMachineUpdating = useSelector(isInstanceUpdatingSelector);
  const isVirtualMachineResizing = useSelector(isInstanceResizingSelector);
  const isVirtualMachineResizeConfirming = useSelector(
    isInstanceResizeConfirmingSelector
  );
  const isVirtualMachineResizeReverting = useSelector(
    isInstanceResizeRevertingSelector
  );
  const isVirtualMachineDeleting = useSelector(isInstanceDeletingSelector);
  const isVolumeUpdating = useSelector(isVolumeUpdatingSelector);
  const isVolumeExtending = useSelector(isVolumeExtendingSelector);
  const isVolumeAttaching = useSelector(isVolumeAttachingSelector);
  const isVolumeDetaching = useSelector(isVolumeDetachingSelector);
  const isInterfaceCreating = useSelector(isInterfaceCreatingSelector);
  const isInterfaceDeleting = useSelector(isInterfaceDeletingSelector);
  const isInstanceLogLoading = useSelector(isInstanceLogLoadingSelector);
  const isFloatingIPUpdating = useSelector(isFloatingIPUpdatingSelector);
  const isVirtualMachineOperationInProgress =
    isVirtualMachineStarting ||
    isVirtualMachineRebooting ||
    isVirtualMachineStopping ||
    isVirtualMachineUpdating ||
    isVirtualMachineResizing ||
    isVirtualMachineResizeConfirming ||
    isVirtualMachineResizeReverting ||
    isVirtualMachineDeleting;
  const previousIsVirtualMachineOperationInProgress = usePrevious(
    isVirtualMachineOperationInProgress
  );
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isVolumeOperationInProgress =
    isVolumeUpdating ||
    isVolumeExtending ||
    isVolumeAttaching ||
    isVolumeDetaching;
  const previousIsVolumeOperationInProgress = usePrevious(
    isVolumeOperationInProgress
  );
  const isInterfaceOperationInProgress =
    isInterfaceCreating || isInterfaceDeleting || isFloatingIPUpdating;
  const previousIsInterfaceOperationInProgress = usePrevious(
    isInterfaceOperationInProgress
  );
  const [isActionsMenuOpened, setIsActionsMenuOpened] =
    useState<boolean>(false);
  const actionsMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const instanceLog = useSelector(instanceLogSelector);
  const [logLength, setLogLength] = useState(DEFAULT_LOG_LENGTH);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
  }, [dialog]);

  const handleActionsMenuButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      actionsMenuButtonRef.current = e.currentTarget;
      setIsActionsMenuOpened(!isActionsMenuOpened);
    },
    [isActionsMenuOpened]
  );

  const handleActionsMenuClose = useCallback(() => {
    setIsActionsMenuOpened(false);
  }, []);

  const handleActionsMenuItemClick = useCallback(
    (callback: () => void) => () => {
      setIsActionsMenuOpened(false);
      callback();
    },
    []
  );

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Virtual machines",
      url: generatePath(ROUTES.VIRTUAL_MACHINES, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: virtualMachine?.name || "",
      url: generatePath(ROUTES.VIRTUAL_MACHINE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        virtualMachineId: matchParams.virtualMachineId
      })
    }
  ];

  const handleStartVirtualMachineButtonClick = useCallback(() => {
    dispatch(
      instancesActions.startInstance.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.virtualMachineId!
      })
    );
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.virtualMachineId,
    matchParams.regionId
  ]);

  const handleResizeClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.RESIZE,
      isOpened: true
    });
  }, []);

  const handleDownloadRDPFileClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DOWNLOAD_RDP_FILE,
      isOpened: true
    });
  }, []);

  const handleConfirmResizeClick = useCallback(() => {
    dispatch(
      instancesActions.confirmResizeInstance.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.virtualMachineId!
      })
    );
  }, [
    dispatch,
    matchParams.virtualMachineId,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleRevertResizeClick = useCallback(() => {
    dispatch(
      instancesActions.revertResizeInstance.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.virtualMachineId!
      })
    );
  }, [
    dispatch,
    matchParams.virtualMachineId,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleConfirmDownloadRDPFileClick = useCallback(
    (data: { ip_address: SelectOption }) => {
      downloadFile(
        `${API_PATH}gotham-${matchParams.regionId}-compute/method/${matchParams.projectId}/instances/${matchParams.virtualMachineId}/rdpfile?ip_address=${data.ip_address.value}`
      );
      handleCloseDialog();
    },
    [
      matchParams.regionId,
      matchParams.projectId,
      matchParams.virtualMachineId,
      handleCloseDialog
    ]
  );

  const handleDeleteVirtualMachineClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const actions = [
    ...(imageFromVolume?.os_platform === OS_PLATFORMS.WINDOWS
      ? [
          {
            label: "Download RDP file",
            handler: handleDownloadRDPFileClick
          }
        ]
      : []),
    ...(virtualMachine &&
    VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
      VIRTUAL_MACHINE_ACTIONS.RESIZE
    ].includes(virtualMachine.status)
      ? [
          {
            label: "Resize",
            handler: handleResizeClick
          }
        ]
      : []),
    {
      label: "Delete",
      handler: handleDeleteVirtualMachineClick
    },
    ...(virtualMachine &&
    VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
      VIRTUAL_MACHINE_ACTIONS.CONFIRM_RESIZE
    ].includes(virtualMachine.status)
      ? [
          {
            label: "Confirm resize",
            handler: handleConfirmResizeClick
          }
        ]
      : []),
    ...(virtualMachine &&
    VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
      VIRTUAL_MACHINE_ACTIONS.REVERT_RESIZE
    ].includes(virtualMachine.status)
      ? [
          {
            label: "Revert resize",
            handler: handleRevertResizeClick
          }
        ]
      : [])
  ];

  const handleGetRemoteConsoleURLButtonClick = useCallback(() => {
    dispatch(
      instancesActions.getRemoteConsoleURL.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        instanceId: matchParams.virtualMachineId!
      })
    );
  }, [
    dispatch,
    matchParams.virtualMachineId,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleStopVirtualMachineButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.STOP,
      isOpened: true
    });
  }, []);

  const handleEditVirtualMachineButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleCreateSnapshotButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_SNAPSHOT,
      isOpened: true
    });
  }, []);

  const getLog = useCallback(
    (length?: number) => {
      dispatch(
        instancesActions.getInstanceLog.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: matchParams.virtualMachineId!,
          logLength: length
        })
      );
    },
    [
      dispatch,
      matchParams.virtualMachineId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const getActionLogs = useCallback(() => {
    dispatch(
      instancesActions.getInstanceActionLogs.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        instanceId: matchParams.virtualMachineId!
      })
    );
  }, [
    dispatch,
    matchParams.virtualMachineId,
    matchParams.projectId,
    matchParams.regionId
  ]);

  const handleGetLogButtonClick = useCallback(() => {
    getLog(logLength);
  }, [getLog, logLength]);

  const handleGetAllLogButtonClick = useCallback(() => {
    getLog();
  }, [getLog]);

  const handleAttachVolumeButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ATTACH_VOLUME,
      isOpened: true
    });
  }, []);

  const handleEditVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_VOLUME,
      isOpened: true
    });
  }, []);

  const handleExtendVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EXTEND_VOLUME,
      isOpened: true
    });
  }, []);

  const handleDetachVolumeMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DETACH_VOLUME,
      isOpened: true
    });
  }, []);

  const handleEditSnapshotMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_SNAPSHOT,
      isOpened: true
    });
  }, []);

  const handleDeleteSnapshotMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_SNAPSHOT,
      isOpened: true
    });
  }, []);

  const handleAddInterfaceButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ADD_INTERFACE,
      isOpened: true
    });
  }, []);

  const handleRemoveInterfaceMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.REMOVE_INTERFACE,
      isOpened: true
    });
  }, []);

  const handleEditInterfaceFirewallsMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.EDIT_INTERFACE_FIREWALLS,
        isOpened: true
      });
    },
    []
  );

  const handleAssociateFloatingIPMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.ASSOCIATE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleDisassociateFloatingIPMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.DISASSOCIATE_FLOATING_IP,
        isOpened: true
      });
    },
    []
  );

  const snapshotTableActions: TableRowActionsMenuItem<TableSnapshot>[] = [
    {
      label: "Edit",
      handler: handleEditSnapshotMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteSnapshotMenuItemClick
    }
  ];

  const volumeTableActions: TableRowActionsMenuItem<TableVolume>[] = [
    {
      label: "Edit",
      handler: handleEditVolumeMenuItemClick
    },
    {
      label: "Extend",
      handler: handleExtendVolumeMenuItemClick
    },
    {
      label: "Detach",
      handler: handleDetachVolumeMenuItemClick
    }
  ];

  const interfaceTableActions: TableRowActionsMenuItem<TableInterface>[] = [
    {
      label: "Associate Floating IP",
      handler: handleAssociateFloatingIPMenuItemClick,
      isDisabled: (tableInterface) => tableInterface.ip.includes(", ")
    },
    {
      label: "Disassociate Floating IP",
      handler: handleDisassociateFloatingIPMenuItemClick,
      isDisabled: (tableInterface) => !tableInterface.ip.includes(", ")
    },
    {
      label: "Edit Firewalls",
      handler: handleEditInterfaceFirewallsMenuItemClick
    },
    {
      label: "Remove",
      handler: handleRemoveInterfaceMenuItemClick
    }
  ];

  const handleRebootMenuButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      rebootButtonRef.current = e.currentTarget;
      setIsRebootMenuOpen(true);
    },
    []
  );

  const handleRebootMenuClose = useCallback(() => {
    setIsRebootMenuOpen(false);
  }, []);

  const handleRebootMenuItemClick = useCallback(
    (type: RESTART_TYPE) => () => {
      if (type === RESTART_TYPE.SOFT) {
        setDialog({
          type: DIALOG_TYPES.SOFT_REBOOT,
          isOpened: true
        });
      }

      if (type === RESTART_TYPE.HARD) {
        setDialog({
          type: DIALOG_TYPES.HARD_REBOOT,
          isOpened: true
        });
      }

      handleRebootMenuClose();
    },
    [handleRebootMenuClose]
  );

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.metrics}`,
        action: instancesActions.getInstanceMetrics.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: matchParams.virtualMachineId!
        }),
        interval: 60 * 1000
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.instance}`,
        action: instancesActions.getInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.virtualMachineId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(storageActions.clear());
    dispatch(projectsActions.clear());
    dispatch(instancesActions.clear());
    dispatch(networksActions.clear());
  });

  useEffect(() => {
    if (previousIsVolumeOperationInProgress && !isVolumeOperationInProgress) {
      dispatch(
        storageActions.getVolumes.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsVolumeOperationInProgress,
    isVolumeOperationInProgress,
    dispatch,
    matchParams.regionId,
    matchParams.projectId
  ]);

  useEffect(() => {
    if (
      previousIsInterfaceOperationInProgress &&
      !isInterfaceOperationInProgress
    ) {
      dispatch(
        instancesActions.getInterfaces.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: matchParams.virtualMachineId!
        })
      );
      dispatch(
        networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsInterfaceOperationInProgress,
    isInterfaceOperationInProgress,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.virtualMachineId
  ]);

  useEffect(() => {
    if (
      previousIsVirtualMachineOperationInProgress &&
      !isVirtualMachineOperationInProgress
    ) {
      if (!virtualMachine) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          instancesActions.getInstance.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: matchParams.virtualMachineId!
          })
        );
      }
    }
  }, [
    virtualMachine,
    history,
    previousIsVirtualMachineOperationInProgress,
    isVirtualMachineOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.virtualMachineId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (isNull(previousVirtualMachine) && virtualMachine) {
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.flavors}`,
          action: instancesActions.getFlavors.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            type: FLAVOR_TYPE.INSTANCE
          })
        })
      );
      dispatch(
        instancesActions.getImages.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousVirtualMachine,
    virtualMachine,
    matchParams.projectId,
    matchParams.organizationId,
    matchParams.regionId,
    dispatch
  ]);

  useEffect(() => {
    if (
      virtualMachine &&
      !isNull(virtualMachine.attached_volumes) &&
      virtualMachine.attached_volumes.length != 0
    ) {
      dispatch(
        storageActions.getVolume.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: virtualMachine.attached_volumes[0].id
        })
      );
    }
  }, [
    virtualMachine,
    matchParams.projectId,
    matchParams.organizationId,
    matchParams.regionId,
    dispatch
  ]);

  useEffect(() => {
    if (previousActiveTabIndex !== activeTabIndex) {
      Object.values([
        POLL_IDS.snapshots,
        POLL_IDS.volumes,
        POLL_IDS.networks,
        POLL_IDS.subnets,
        POLL_IDS.interfaces,
        POLL_IDS.firewalls,
        POLL_IDS.floatingIPs
      ]).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });
      switch (activeTabIndex) {
        case TABS.NETWORKS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.NETWORKS]
            })
          });
          dispatch(networksActions.clear());
          dispatch(instancesActions.clearInterfaces());
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.interfaces}`,
              action: instancesActions.getInterfaces.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!,
                instanceId: matchParams.virtualMachineId!
              })
            })
          );
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.networks}`,
              action: networksActions.getNetworks.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!
              })
            })
          );
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.subnets}`,
              action: networksActions.getSubnets.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!
              })
            })
          );
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.firewalls}`,
              action: networksActions.getFirewalls.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!
              })
            })
          );
          {
            dispatch(
              networksActions.getFloatingIPs.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!
              })
            );
          }
          break;
        case TABS.VOLUMES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.VOLUMES]
            })
          });
          dispatch(storageActions.clearVolumes());
          dispatch(storageActions.clearSnapshots());
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.volumes}`,
              action: storageActions.getVolumes.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!
              })
            })
          );
          break;
        case TABS.SNAPSHOTS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.SNAPSHOTS]
            })
          });
          dispatch(storageActions.clearSnapshots());
          dispatch(storageActions.clearVolumes());
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.snapshots}`,
              action: storageActions.getSnapshots.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!
              })
            })
          );
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.volumes}`,
              action: storageActions.getVolumes.started({
                regionId: matchParams.regionId!,
                projectId: matchParams.projectId!
              })
            })
          );
          break;
        case TABS.LOG:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.LOG]
            })
          });
          getLog(logLength);
          break;
        case TABS.ACTION_LOG:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.ACTION_LOG]
            })
          });
          getActionLogs();
      }
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.virtualMachineId,
    getLog,
    logLength,
    getActionLogs,
    previousActiveTabIndex
  ]);

  useEffect(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [selectedItemId, dispatch, matchParams.regionId, matchParams.projectId]);

  const publicIPs =
    virtualMachine &&
    Object.keys(virtualMachine.networks).includes(SPECIAL_NAMES.NETWORK)
      ? Object.values(virtualMachine.networks)[
          Object.keys(virtualMachine.networks).indexOf(SPECIAL_NAMES.NETWORK)
        ].map((ip) => ip.addr)
      : [];

  const generateNetworksTableItemURL = useCallback(
    (id: string) => {
      const networkId = interfaces?.find((x) => x.id === id)?.networkId;

      if (networkId) {
        return generatePath(ROUTES.NETWORK, {
          organizationId: matchParams.organizationId,
          regionId: matchParams.regionId,
          projectId: matchParams.projectId,
          networkId
        });
      }
    },
    [
      matchParams.projectId,
      matchParams.organizationId,
      matchParams.regionId,
      interfaces
    ]
  );

  const handleLengthFieldChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setLogLength(Number(e.target.value)),
    []
  );

  const isLogLengthValid = logLength > 0 && Number.isInteger(logLength);

  const tabContent = [
    <>
      <Table<TableInterface>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={interfaces || []}
        columns={networkTableColumns}
        actions={interfaceTableActions}
        isLoading={!interfaces}
        toolbarItems={
          <Button
            onClick={handleAddInterfaceButtonClick}
            variant={"contained"}
            disabled={!networks}
          >
            Add interface
          </Button>
        }
        itemLink={{
          column: "networkName",
          getURL: generateNetworksTableItemURL
        }}
      />
    </>,
    <Table<TableVolume>
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"volumeTable"}
      rows={attachedVolumes || []}
      columns={volumeTableColumns}
      actions={volumeTableActions}
      toolbarItems={
        <Button
          onClick={handleAttachVolumeButtonClick}
          variant={"contained"}
          disabled={!volumes}
        >
          Attach volume
        </Button>
      }
      isLoading={!attachedVolumes}
    />,
    <Table<TableSnapshot>
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"snapshotTable"}
      rows={snapshots || []}
      columns={snapshotTableColumns}
      actions={snapshotTableActions}
      toolbarItems={
        <Button
          onClick={handleCreateSnapshotButtonClick}
          variant={"contained"}
          disabled={!attachedVolumes}
        >
          Create snapshot
        </Button>
      }
      isLoading={!snapshots && !volumes}
    />,
    <>
      <s.LogToolbarContainer>
        <s.LengthTextField
          value={logLength}
          onChange={handleLengthFieldChange}
          type={"number"}
          inputProps={{ min: 1, title: "Log length" }}
          label={"Log length"}
          error={!isLogLengthValid}
          helperText={
            isLogLengthValid ? "" : "Log length should be a natural number"
          }
          size={"small"}
        ></s.LengthTextField>
        <s.LogButtonsContainer>
          <s.GetLogButton
            onClick={handleGetLogButtonClick}
            variant={"contained"}
            disabled={isInstanceLogLoading || !isLogLengthValid}
          >
            Get log
          </s.GetLogButton>
          <Button
            onClick={handleGetAllLogButtonClick}
            variant={"contained"}
            disabled={isInstanceLogLoading}
          >
            Get full log
          </Button>
        </s.LogButtonsContainer>
      </s.LogToolbarContainer>
      {isInstanceLogLoading ? (
        <s.LoaderContainer>
          <Loader text={"Loading data..."} />
        </s.LoaderContainer>
      ) : (
        <s.LogContainer variant={"outlined"}>
          {instanceLog?.output === "" ? (
            <s.NoDataText>No data</s.NoDataText>
          ) : (
            <s.LogText>{instanceLog?.output}</s.LogText>
          )}
        </s.LogContainer>
      )}
    </>,
    <Table<TableInstanceActionLogs>
      isSortingEnabled={true}
      key={"actionLogsTable"}
      rows={instanceActionLogs || []}
      columns={actionLogsTableColumns}
      isLoading={!instanceActionLogs}
    />
  ];

  const handleConfirmEditVirtualMachine = useCallback(
    (data: { name: string; tags: [] }) => {
      dispatch(
        instancesActions.updateInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.virtualMachineId!,
          data
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.virtualMachineId,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmResizeVirtualMachine = useCallback(
    (data: { flavor: SelectOption }) => {
      dispatch(
        instancesActions.resizeInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.virtualMachineId!,
          flavorId: data.flavor.value
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.virtualMachineId,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmSoftRebootVirtualMachine = useCallback(() => {
    dispatch(
      instancesActions.restartInstance.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.virtualMachineId!,
        type: RESTART_TYPE.SOFT
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.virtualMachineId,
    matchParams.regionId,
    handleCloseDialog
  ]);

  const handleConfirmHardRebootVirtualMachine = useCallback(() => {
    dispatch(
      instancesActions.restartInstance.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.virtualMachineId!,
        type: RESTART_TYPE.HARD
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.virtualMachineId,
    matchParams.projectId,
    handleCloseDialog
  ]);

  const handleConfirmStopVirtualMachine = useCallback(() => {
    dispatch(
      instancesActions.stopInstance.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.virtualMachineId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.virtualMachineId,
    matchParams.regionId,
    matchParams.projectId,
    handleCloseDialog
  ]);

  const handleConfirmDeleteVirtualMachine = useCallback(() => {
    dispatch(
      instancesActions.deleteInstance.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.virtualMachineId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.virtualMachineId,
    handleCloseDialog
  ]);

  const handleConfirmAttachVolume = useCallback(
    (data: { volume: SelectOption }) => {
      dispatch(
        instancesActions.attachVolume.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: matchParams.virtualMachineId!,
          volumeId: data.volume.value
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId,
      matchParams.virtualMachineId
    ]
  );

  const handleConfirmEditInterfaceFirewalls = useCallback(
    (data: { firewalls: SelectOption[] }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.editInterfaceFirewalls.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            virtualMachineId: matchParams.virtualMachineId!,
            data: {
              firewalls: data.firewalls.map((firewall) => firewall.value)
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.virtualMachineId
    ]
  );

  const handleConfirmAssociateFloatingIP = useCallback(
    (data: { floatingIP: SelectOption }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateFloatingIP.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: data.floatingIP.value,
            data: {
              ...data,
              port_id: selectedItemId
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDisassociateFloatingIP = useCallback(() => {
    if (selectedItemId && floatingIPs) {
      dispatch(
        networksActions.updateFloatingIP.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id:
            floatingIPs.find((fip) => fip.port_id === selectedItemId)?.id || "",
          data: {
            port_id: ""
          }
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    floatingIPs,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmAddInterface = useCallback(
    (data: { fixed_ips: string; network: SelectOption }) => {
      dispatch(
        instancesActions.createInterface.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: matchParams.virtualMachineId!,
          data: {
            ...(data.fixed_ips
              ? {
                  fixed_ips: splitMultilineCommaSeparatedList(
                    data.fixed_ips
                  ).map((ip) => ({
                    ip_address: ip
                  }))
                }
              : {}),
            network_id: data.network.value
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId,
      matchParams.virtualMachineId
    ]
  );

  const handleConfirmDetachInterface = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        instancesActions.deleteInterface.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: matchParams.virtualMachineId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId,
    matchParams.virtualMachineId
  ]);

  const handleConfirmEditVolume = useCallback(
    (data: { name: string; description: string; bootable: boolean }) => {
      if (selectedItemId) {
        dispatch(
          storageActions.updateVolume.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmExtendVolume = useCallback(
    (data: { size: string }) => {
      if (selectedItemId) {
        dispatch(
          storageActions.extendVolume.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              new_size: Number(data.size)
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDetachVolume = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        instancesActions.detachVolume.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: matchParams.virtualMachineId!,
          volumeId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId,
    matchParams.virtualMachineId
  ]);

  const handleConfirmCreateSnapshot = useCallback(
    (data: { name: string; description: string; volume: SelectOption }) => {
      dispatch(
        storageActions.createSnapshot.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            ...data,
            description: data.description.trim(),
            volume_id: data.volume.value
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmEditSnapshot = useCallback(
    (data: { name: string; description: string }) => {
      if (selectedItemId) {
        dispatch(
          storageActions.updateSnapshot.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteSnapshot = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        storageActions.deleteSnapshot.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingSnapshotName = snapshots?.find(
    (snapshot) => snapshot.id === deletingItemId
  )?.name;
  const detachingVolumeName = volumes?.find(
    (volume) => volume.id === deletingItemId
  )?.name;
  const deletingInterfaceId = interfaces?.find(
    (item) => item.id === deletingItemId
  )?.id;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditVirtualMachine,
      title: "Edit virtual machine",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: virtualMachine?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "tags",
          type: FIELD_TYPES.MULTISELECT,
          label: "Tags",
          rules: array(
            string().matches(REGEX.SERVER_TAG, ERROR_MESSAGES.SERVER_TAG)
          ).max(MAX_SERVER_TAG_COUNT, ERROR_MESSAGES.MAX_SERVER_TAG_COUNT),
          freeSolo: true,
          options: [],
          defaultValue: virtualMachine?.tags || [],
          isHidden: () => virtualMachine?.status !== INSTANCE_STATUSES.ACTIVE,
          helperText: 'Press "Enter" to add a new tag'
        }
      ]
    },
    [DIALOG_TYPES.RESIZE]: {
      onConfirm: handleConfirmResizeVirtualMachine,
      title: "Resize virtual machine",
      confirmButtonLabel: "Resize",
      fields: [
        {
          name: "flavor",
          type: FIELD_TYPES.SELECT,
          label: "Flavor",
          defaultValue: getSelectDefaultValue(
            flavorSelectOptions,
            virtualMachine?.flavor_id || null,
            "name"
          ),
          options: flavorSelectOptions?.map((flavor) =>
            getSelectOption(flavor, "name", "id")
          ),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.SOFT_REBOOT]: {
      onConfirm: handleConfirmSoftRebootVirtualMachine,
      title: `Are you sure you want to soft reboot "${
        virtualMachine?.name ?? "selected"
      }" virtual machine?`,
      confirmButtonLabel: "Soft reboot"
    },
    [DIALOG_TYPES.HARD_REBOOT]: {
      onConfirm: handleConfirmHardRebootVirtualMachine,
      title: `Are you sure you want to hard reboot "${
        virtualMachine?.name ?? "selected"
      }" virtual machine?`,
      confirmButtonLabel: "Hard reboot"
    },
    [DIALOG_TYPES.STOP]: {
      onConfirm: handleConfirmStopVirtualMachine,
      title: `Are you sure you want to stop "${
        virtualMachine?.name ?? "selected"
      }" virtual machine?`,
      confirmButtonLabel: "Stop"
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteVirtualMachine,
      title: `Are you sure you want to delete "${
        virtualMachine?.name ?? "selected"
      }" virtual machine?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the virtual machine name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Virtual machine name does not match",
              test: function (value) {
                return value === virtualMachine?.name;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DOWNLOAD_RDP_FILE]: {
      onConfirm: handleConfirmDownloadRDPFileClick,
      title: "Download RDP file",
      confirmButtonLabel: "Download",
      fields: [
        {
          name: "ip_address",
          type: FIELD_TYPES.SELECT,
          label: "IP Address",
          options: interfaces?.map((i) => getSelectOption(i, "ip", "ip")),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.ADD_INTERFACE]: {
      onConfirm: handleConfirmAddInterface,
      title: "Add interface",
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "network",
          type: FIELD_TYPES.SELECT,
          label: "Network",
          options: networks?.map((network) =>
            getSelectOption(network, "name", "id")
          ),
          rules: selectOptionSchema
        },
        {
          name: "fixed_ips",
          type: FIELD_TYPES.MULTILINE_TEXT,
          label: "Fixed IP",
          helperText: "Optional",
          rules: string().test(
            "fixedIPsValidFormat",
            'Fixed IP must have the valid format (e.g., "192.168.1.102")',
            (value) =>
              !value ||
              Boolean(
                value &&
                  splitMultilineCommaSeparatedList(value).every((record) =>
                    REGEX.IP_ADDRESS.test(record)
                  )
              )
          ),
          isHidden: (fieldValues) =>
            !fieldValues.network ||
            (isSelectOption(fieldValues.network) &&
              fieldValues.network.label === SPECIAL_NAMES.NETWORK)
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_INTERFACE]: {
      onConfirm: handleConfirmDetachInterface,
      title: `Are you sure you want to remove interface "${
        deletingInterfaceId ?? "selected"
      }" from virtual machine?`,
      confirmButtonLabel: "Remove"
    },
    [DIALOG_TYPES.ATTACH_VOLUME]: {
      onConfirm: handleConfirmAttachVolume,
      title: "Attach volume",
      confirmButtonLabel: "Attach",
      fields: [
        {
          name: "volume",
          type: FIELD_TYPES.SELECT,
          label: "Volume",
          options: volumes
            ?.filter((volume) => volume.status === VOLUME_STATUSES.AVAILABLE)
            .map((volume) => getSelectOption(volume, "name", "id")),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.EDIT_INTERFACE_FIREWALLS]: {
      onConfirm: handleConfirmEditInterfaceFirewalls,
      title: "Choose firewalls that you want to associate with the interface",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "firewalls",
          type: FIELD_TYPES.MULTISELECT,
          defaultValue:
            interfaces && firewalls
              ? firewalls
                  .filter((fw) => {
                    return interfaces
                      .find((i) => i.id === selectedItemId)
                      ?.firewalls.includes(fw.name);
                  })
                  .map((firewall) => {
                    return getSelectOption(firewall, "name", "id");
                  })
              : undefined,
          label: "Firewalls",
          options: firewalls?.map((firewall) =>
            getSelectOption(firewall, "name", "id")
          ),
          rules: array(selectOptionSchema)
        }
      ]
    },
    [DIALOG_TYPES.ASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmAssociateFloatingIP,
      title: "Associate floating IP with selected interface",
      confirmButtonLabel: "Associate",
      fields: [
        {
          name: "floatingIP",
          type: FIELD_TYPES.SELECT,
          label: "Floating IP",
          options: floatingIPs
            ?.filter((fip) => fip.status === "DOWN")
            .map((fip) => getSelectOption(fip, "floating_ip_address", "id")),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DISASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmDisassociateFloatingIP,
      title:
        "Are you sure you want to disassociate floating IP with this interface?",
      confirmButtonLabel: "Disassociate"
    },
    [DIALOG_TYPES.EDIT_VOLUME]: {
      onConfirm: handleConfirmEditVolume,
      title: "Edit volume",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            volumes?.find((volume) => volume.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            volumes?.find((volume) => volume.id === selectedItemId)
              ?.description || "",
          rules: string()
        },
        {
          name: "bootable",
          type: FIELD_TYPES.CHECKBOX,
          label: "Is volume bootable",
          defaultValue:
            volumes?.find((volume) => volume.id === selectedItemId)?.bootable ||
            false
        }
      ]
    },
    [DIALOG_TYPES.EXTEND_VOLUME]: {
      onConfirm: handleConfirmExtendVolume,
      title: "Extend volume",
      confirmButtonLabel: "Extend",
      fields: [
        {
          name: "size",
          type: FIELD_TYPES.NUMBER,
          defaultValue: volumes?.find((volume) => volume.id === selectedItemId)
            ? volumes?.find((volume) => volume.id === selectedItemId)?.size
            : MIN_VOLUME_SIZE,
          label: "Size",
          suffix: "GiB",
          min: volumes?.find((volume) => volume.id === selectedItemId)
            ? Number(
                volumes?.find((volume) => volume.id === selectedItemId)?.size
              )
            : MIN_VOLUME_SIZE,
          step: VOLUME_SIZE_STEP,
          rules: number()
            .integer()
            .min(
              volumes?.find((volume) => volume.id === selectedItemId)
                ? Number(
                    volumes?.find((volume) => volume.id === selectedItemId)
                      ?.size
                  )
                : MIN_VOLUME_SIZE,
              `Volume size must be greater than or equals to ${
                volumes?.find((volume) => volume.id === selectedItemId)
                  ? Number(
                      volumes?.find((volume) => volume.id === selectedItemId)
                        ?.size
                    )
                  : MIN_VOLUME_SIZE
              } GiB.`
            )
            .test(
              "isMultipleOf",
              `Volume size must be multiple of ${VOLUME_SIZE_STEP}.`,
              (value) => Boolean(value && value % VOLUME_SIZE_STEP === 0)
            )
            .required()
        }
      ]
    },
    [DIALOG_TYPES.DETACH_VOLUME]: {
      onConfirm: handleConfirmDetachVolume,
      title: `Are you sure you want to detach "${
        detachingVolumeName ?? "selected"
      }" volume from virtual machine?`,
      confirmButtonLabel: "Detach"
    },
    [DIALOG_TYPES.CREATE_SNAPSHOT]: {
      onConfirm: handleConfirmCreateSnapshot,
      title: "Create snapshot",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        },
        {
          name: "volume",
          type: FIELD_TYPES.SELECT,
          label: "Volume",
          options: attachedVolumes?.map((volume) =>
            getSelectOption(volume, "detailedName", "id")
          ),
          rules: selectOptionSchema
        },
        {
          name: "inUseWarning",
          type: FIELD_TYPES.NOTES,
          label:
            '⚠️ Snapshot created from the volume with "in-use" status can contain corrupted data.'
        }
      ]
    },
    [DIALOG_TYPES.EDIT_SNAPSHOT]: {
      onConfirm: handleConfirmEditSnapshot,
      title: "Edit snapshot",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            snapshots?.find((snapshot) => snapshot.id === selectedItemId)
              ?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            snapshots?.find((snapshot) => snapshot.id === selectedItemId)
              ?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_SNAPSHOT]: {
      onConfirm: handleConfirmDeleteSnapshot,
      title: `Are you sure you want to delete "${
        deletingSnapshotName ?? "selected"
      }" snapshot?`,
      confirmButtonLabel: "Delete"
    }
  };

  const flavor =
    flavors?.find((flavor) => flavor.id === virtualMachine?.flavor_id) || null;

  const vmImage =
    images?.find((image) => image.id === virtualMachine?.image_id) || null;

  const getImageInfo = useCallback((): ImageInfo | null => {
    let res: ImageInfo = {} as ImageInfo;
    if (
      imageFromVolume &&
      !isNull(imageFromVolume) &&
      virtualMachine?.image_id === imageFromVolume?.image_id
    ) {
      res = {
        disk_format: imageFromVolume?.disk_format || "",
        size: imageFromVolume?.size || "",
        image_id: imageFromVolume?.image_id || "",
        image_name: imageFromVolume?.image_name || "",
        os_distro: imageFromVolume?.os_distro || "",
        os_platform: imageFromVolume?.os_platform || "",
        os_default_user: imageFromVolume?.os_default_user || ""
      };
      if (!res.os_default_user) {
        res.os_default_user = vmImage?.os_default_user || "";
      }
      return res;
    } else if (vmImage && !isNull(vmImage)) {
      res = {
        disk_format: vmImage?.disk_format || "",
        size: vmImage?.size || "",
        image_id: vmImage?.id || "",
        image_name: vmImage?.name || "",
        os_distro: vmImage?.os_distro || "",
        os_platform: vmImage?.os_platform || "",
        os_default_user: vmImage?.os_default_user || ""
      };
      return res;
    } else {
      return null;
    }
  }, [imageFromVolume, vmImage, virtualMachine?.image_id]);

  const imageInfo = getImageInfo();

  const title = virtualMachine?.name;

  return (
    <>
      <Head title={title} />
      {virtualMachine ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title title={title} variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>{virtualMachine.id}</s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>
                  Public IP{publicIPs.length > 1 ? "s" : ""}:{" "}
                </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {publicIPs.length ? publicIPs.join("; ") : "none"}
                </s.DetailsInfoColored>
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Region: </s.DetailsTitle>
                <s.Tag label={formatRegion(virtualMachine.region)} />
              </s.SummaryRow>
              <s.SummaryRow>
                <s.DetailsTitle>Status: </s.DetailsTitle>
                {virtualMachine.status === INSTANCE_STATUSES.VERIFY_RESIZE ? (
                  <>
                    <Tooltip
                      title={"Please, confirm or revert resize"}
                      placement={"top"}
                      arrow
                    >
                      <WarningIcon color="secondary" fontSize="small" />
                    </Tooltip>
                    <s.DetailsInfoGray>
                      {virtualMachine.status}
                    </s.DetailsInfoGray>
                  </>
                ) : (
                  <s.Tag label={virtualMachine.status} />
                )}
              </s.SummaryRow>
              {virtualMachine.fault.message && (
                <s.SummaryRow>
                  <s.DetailsTitle>Message: </s.DetailsTitle>
                  <s.DetailsInfo>{virtualMachine.fault.message}</s.DetailsInfo>
                </s.SummaryRow>
              )}
              <s.SummaryRow>
                {flavor ? (
                  <>
                    <s.DetailsTitle>Memory: </s.DetailsTitle>
                    <s.Tag
                      label={String(flavor && flavor.ram / 1024) + "GiB"}
                    />
                    <s.DetailsTitle>vCPUs: </s.DetailsTitle>
                    <s.Tag label={flavor.vcpus} />
                  </>
                ) : (
                  <Loader size={24} text={"Loading hardware data..."} />
                )}
              </s.SummaryRow>

              {!isNull(imageInfo) && (
                <s.SummaryRow>
                  {imageInfo ? (
                    <>
                      <s.DetailsTitle>Image: </s.DetailsTitle>
                      <s.DetailsInfoGray>
                        {imageInfo.image_name}
                      </s.DetailsInfoGray>
                      {imageInfo.os_default_user && (
                        <>
                          <s.DetailsTitle>Default Username: </s.DetailsTitle>
                          <s.Tag label={imageInfo.os_default_user} />
                        </>
                      )}
                    </>
                  ) : (
                    <Loader size={24} text={"Loading image data..."} />
                  )}
                </s.SummaryRow>
              )}
              {virtualMachine.tags.length > 0 && (
                <s.SummaryRow>
                  <s.DetailsTitle>Tags: </s.DetailsTitle>
                  {virtualMachine.tags.map((tag) => (
                    <s.Tag key={tag} label={tag} />
                  ))}
                </s.SummaryRow>
              )}
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Start"} placement={"top"} arrow>
                <span>
                  <IconButton
                    disabled={
                      !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
                        VIRTUAL_MACHINE_ACTIONS.START
                      ].includes(virtualMachine.status)
                    }
                    onClick={handleStartVirtualMachineButtonClick}
                    color={"inherit"}
                    // title={"Start"}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Reboot"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleRebootMenuButtonClick}
                    ref={rebootButtonRef}
                    disabled={
                      !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
                        VIRTUAL_MACHINE_ACTIONS.SOFT_REBOOT
                      ].includes(virtualMachine.status) &&
                      !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
                        VIRTUAL_MACHINE_ACTIONS.HARD_REBOOT
                      ].includes(virtualMachine.status)
                    }
                    color={"inherit"}
                    // title={"Reboot"}
                  >
                    <ReplayIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Menu
                isOpened={isRebootMenuOpen}
                onClose={handleRebootMenuClose}
                anchorEl={rebootButtonRef.current}
                items={[
                  {
                    label: "Soft reboot",
                    onClick: handleRebootMenuItemClick(RESTART_TYPE.SOFT),
                    isDisabled: !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
                      VIRTUAL_MACHINE_ACTIONS.SOFT_REBOOT
                    ].includes(virtualMachine.status)
                  },
                  {
                    label: "Hard reboot",
                    onClick: handleRebootMenuItemClick(RESTART_TYPE.HARD),
                    isDisabled: !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
                      VIRTUAL_MACHINE_ACTIONS.HARD_REBOOT
                    ].includes(virtualMachine.status)
                  }
                ]}
              />
              <Tooltip title={"Stop"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleStopVirtualMachineButtonClick}
                    disabled={
                      !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
                        VIRTUAL_MACHINE_ACTIONS.STOP
                      ].includes(virtualMachine.status)
                    }
                    color={"inherit"}
                    // title={"Stop"}
                  >
                    <StopIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Edit"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditVirtualMachineButtonClick}
                    color={"inherit"}
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Remote console"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleGetRemoteConsoleURLButtonClick}
                    disabled={VIRTUAL_MACHINE_ACTIONS_NOT_ALLOWED_STATUSES[
                      VIRTUAL_MACHINE_ACTIONS.REMOTE_CONSOLE
                    ].includes(virtualMachine.status)}
                    color={"inherit"}
                    // title={"Remote console"}
                  >
                    <LaunchIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {actions.length > 0 && (
                <Tooltip title={"More actions"} placement={"top"} arrow>
                  <span>
                    <IconButton
                      onClick={handleActionsMenuButtonClick}
                      color={"inherit"}
                      // title={"Actions"}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Menu
                isOpened={isActionsMenuOpened}
                onClose={handleActionsMenuClose}
                anchorEl={actionsMenuButtonRef.current}
                items={actions.map((action) => ({
                  label: action.label,
                  onClick: handleActionsMenuItemClick(action.handler)
                }))}
              />
            </s.ActionsContainer>
          </s.SummaryContainer>

          {imageInfo && !isNull(imageInfo) && (
            <s.MetricsContainer>
              {areInstanceMetricsLoading && instanceMetrics == null ? (
                <Loader text={"Loading metrics data..."} />
              ) : (
                <>
                  {(imageInfo.os_platform as OS_PLATFORMS) !==
                    OS_PLATFORMS.WINDOWS &&
                    flavor && (
                      <s.MemoryMetrics
                        flavor={flavor}
                        value={MEMORY_METRICS}
                        data={instanceMetrics?.memory_metrics || []}
                      >
                        <LineSeries
                          name={"Memory"}
                          valueField={"value"}
                          argumentField={TIMESTAMP_METRICS}
                        />
                      </s.MemoryMetrics>
                    )}
                  {flavor && (
                    <Metrics
                      flavor={flavor}
                      value={CPU_METRICS}
                      data={instanceMetrics?.cpu_metrics || []}
                    >
                      <LineSeries
                        name={"CPU"}
                        valueField={"value"}
                        argumentField={TIMESTAMP_METRICS}
                      />
                    </Metrics>
                  )}
                </>
              )}
            </s.MetricsContainer>
          )}
          <Tabs value={activeTabIndex} onChange={handleChangeTab}>
            {Object.values(TAB_TITLES).map((tabTitle) => (
              <Tab
                key={tabTitle}
                label={tabTitle}
                disabled={
                  tabTitle === "Log" &&
                  virtualMachine?.status === INSTANCE_STATUSES.SHUTOFF
                }
              />
            ))}
          </Tabs>
          {tabContent[activeTabIndex]}
          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
