import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import FileIcon from "@mui/icons-material/FindInPage";
import { Button, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as objectStorageActions from "modules/objectStorage/actions";
import {
  corsPolicySelector,
  isFileUploadingSelector,
  isUploadSuccessfulSelector,
  uploadProgressSelector
} from "modules/objectStorage/selectors";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SmallCircularProgressWithLabel } from "../SmallCircularProgressWithLabel";
import * as s from "./styles";
import { UploaderProps } from "./types";

export const Uploader: FC<UploaderProps> = ({
  region,
  accessKey,
  secretAccessKey,
  s3Url,
  projectId,
  credsId,
  bucket,
  folder
}: UploaderProps) => {
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showCheckIcon, setShowCheckIcon] = useState(false);
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const isCorsDisabled =
    useSelector(corsPolicySelector)?.isAccessToBucketAllowed;

  const uploadProgress = useSelector(uploadProgressSelector);
  const isUploadSuccess = useSelector(isUploadSuccessfulSelector);
  const isFileUploading = useSelector(isFileUploadingSelector);

  const handleClosetDialog = useCallback(() => {
    setIsDialogOpened(false);
  }, []);

  const handleGrandAccessButtonClick = useCallback(() => {
    dispatch(
      objectStorageActions.setCorsPolicy.started({
        regionId: region,
        projId: projectId,
        credsId: credsId,
        bucketName: bucket
      })
    );
    setIsDialogOpened(false);
  }, [bucket, credsId, dispatch, projectId, region]);

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
      e.target.value = "";
    }
  };

  const handleClearFile = () => {
    setSelectedFile(null);
  };

  const handleUpload = useCallback(() => {
    if (selectedFile) {
      dispatch(
        objectStorageActions.uploadFile.started({
          regionId: region,
          accessKey,
          accessSecret: secretAccessKey,
          endpoint: s3Url,
          projId: projectId,
          bucketName: bucket,
          file: selectedFile,
          folder: folder
        })
      );
    }
  }, [
    selectedFile,
    dispatch,
    region,
    accessKey,
    secretAccessKey,
    s3Url,
    projectId,
    bucket,
    folder
  ]);

  useEffect(() => {
    if (isUploadSuccess) {
      setShowCheckIcon(true);
      setSelectedFile(null); // to clear file name after uploading
      const timerId = setTimeout(() => {
        setShowCheckIcon(false);
      }, 2000);
      return () => {
        clearTimeout(timerId);
      };
    }
  }, [isUploadSuccess]);

  useEffect(() => {
    if (!isCorsDisabled && selectedFile) {
      setIsDialogOpened(true);
    }
  }, [isCorsDisabled, selectedFile]);

  useEffect(() => {
    if (!isCorsDisabled) {
      dispatch(
        objectStorageActions.checkCorsPolicy.started({
          regionId: region,
          projId: projectId,
          credsId: credsId,
          bucketName: bucket
        })
      );
    }
  }, [isCorsDisabled, dispatch, region, projectId, credsId, bucket]);

  return (
    <s.UploaderContainer>
      <s.InputContainer>
        <Button
          variant="contained"
          component="label"
          startIcon={<FileIcon fontSize="small" />}
        >
          Select file
          <input
            type={"file"}
            name={"file"}
            onChange={handleFileInput}
            style={{ display: "none" }}
          />
        </Button>
        <s.StyledTextField
          value={selectedFile ? selectedFile.name : "file not selected"}
          label="Selected file"
          size="small"
          InputProps={{
            endAdornment: selectedFile && (
              <s.ClearButton onClick={handleClearFile} size="small">
                <ClearIcon fontSize="inherit" />
              </s.ClearButton>
            ),
            readOnly: true,
            inputProps: { style: { fontSize: "0.9rem" } },
            style: {
              overflowX: "auto",
              whiteSpace: "nowrap"
            }
          }}
        />
      </s.InputContainer>

      <Button
        variant="contained"
        color="primary"
        disabled={!selectedFile || !isCorsDisabled}
        startIcon={<FileUploadRoundedIcon fontSize="small" />}
        onClick={handleUpload}
      >
        Upload to S3
      </Button>
      {isFileUploading && (
        <SmallCircularProgressWithLabel value={uploadProgress} maxValue={100} />
      )}
      {showCheckIcon && (
        <CheckCircleIcon
          fontSize="small"
          color="success"
          sx={{ marginRight: 6 }}
        />
      )}

      {!isCorsDisabled && (
        <Dialog open={isDialogOpened}>
          <DialogTitle>Grant permissions</DialogTitle>
          <DialogContent>
            <Typography>
              To proceed with the file upload, please grant permissions to the
              console to access the {bucket} bucket.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color={"secondary"} onClick={handleGrandAccessButtonClick}>
              Grant Permissions
            </Button>
            <Button color={"secondary"} onClick={handleClosetDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </s.UploaderContainer>
  );
};
