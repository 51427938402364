export type Load = {
  cpu_value: number;
  memory_value: number;
  time_stamp: number;
};

export type HWLoad = {
  load: Load[] | null;
  duration_seconds: number;
  range_minutes: number;
};

export type Database = {
  id: string;
  project_id: string;
  name: string;
  user: string;
  password: string;
  ip_acl: string[];
  connection_string: string;
  hw_load: HWLoad;
  port: number;
  version: string;
  message?: string;
  region: string;
  region_instance: string;
  cpu: number;
  memory: number;
  disk: number;
  volume_capacity_used: number;
  mode: DATABASE_REPLICA_STATUSES;
  service_name: DATABASES;
  service_status: DATABASE_STATUSES;
  custom_parameters: DatabaseCustomOptions[];
  subnet_id: string;
  creation_time?: number;
  org_id: string;
};

export type DatabaseCustomOptions = {
  name: string;
  type: string;
  min_value: string;
  max_value: string;
  default_value: string;
  custom_value?: string;
};

export type TableDatabase = Database & {
  connectionString: string;
  versionString: string;
  cpuString: string;
  memoryString: string;
  diskString: string;
  volumeCapacity: string;
  serviceName: string;
  ageString: string;
  ipACL: string[];
};

export type DatabaseResetPassword = {
  id: string;
  project_id: string;
  user: string;
  password?: string;
};

export type TableReplicaDatabase = TableDatabase;

export type TableCronStatus = {
  id: string;
  status: string;
  date: number;
  dateString: string;
};

export type DatabaseBackupStatus = {
  backup_lastrun_date?: number;
  backup_status?: DATABASE_BACKUP_STATUSES;
  cron_config?: string;
  cron_exist: boolean;
  cron_retention?: string;
  cron_runs?: {
    id: string;
    date: number;
    status: DATABASE_BACKUP_STATUSES;
  }[];
  link_exist: boolean;
  restore_lastrun_date?: number;
  restore_status?: DATABASE_RESTORE_STATUSES;
  s3endpoint?: string;
  s3key?: string;
};

export type DatabaseBackupFile = {
  id: string;
  etag: string;
  file_name: string;
  file_size: number;
};

export type TableDatabaseBackupFiles = DatabaseBackupFile & {
  fileSize: string;
};

export type TableDatabaseCustomOptions = DatabaseCustomOptions & {
  id: string;
  name: string;
  type: string;
  min_value: string;
  max_value: string;
  default_value: string;
  custom_value?: string;
};

export type CronBackupParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    cron: string;
    retention: string;
    timezone: string;
  };
};

export type CronBackupResponse = Database;

export type CronDeleteParams = GetDatabaseParams;

export type CronDeleteResponse = Database;

export type RestoreDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    database: string;
    file: string;
  };
};

export type RestoreDatabaseResponse = Database;

export type GetDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  pollingId?: string;
};

export type CreateBackupDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    timezone: string;
  };
};

export type CreateBackupDatabaseResponse = Database;

export type GetReplicaDatabaseParams = GetDatabaseParams;

export type GetDatabaseCustomOptionsParams = GetDatabaseParams;

export type GetReplicaDatabasesResponse = Database[];

export type GetDatabaseResponse = Database;

export type GetDatabaseCustomOptionsResponse = Database;

export type UpdateDatabaseCustomOptionsResponse = Database;

export type GetDatabaseBackupStatusResponse = DatabaseBackupStatus;

export type GetDatabaseBackupFilesResponse = DatabaseBackupFile[];

export type GetDatabasesParams = {
  orgId: string;
  projId: string;
  pollingId?: string;
};

export type GetDatabasesResponse = Database[];

export type CreateDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  data: {
    name: string;
    user?: string;
    ip_acl?: string[];
    cpu: number;
    memory: number;
    disk: number;
    port?: number;
    version?: string;
    subnet_id?: string;
    private_static_ip?: string;
  };
};

export type UpdateDatabaseCustomOptionsParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    name: string;
    value: string;
  }[];
};

export type CreateDatabaseResponse = Database;

export type DbaasQuotas = {
  max_value_of_instance: number;
  max_value_of_cpu: number;
  max_value_of_memory: number;
  max_value_of_disk: number;
  current_value_of_instance: number;
  current_value_of_cpu: number;
  current_value_of_memory: number;
  current_value_of_disk: number;
};

export type GetDbaasQuotasParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  pollingId?: string;
};

export type GetDbaasQuotasResponse = DbaasQuotas;

export type CreateReplicaDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    project_id: string;
  };
};

export type CreateReplicaDatabaseResponse = Database;

export type UpdateDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    ip_acl: string[];
    cpu?: number;
    memory?: number;
    port?: number;
    version?: string;
  };
};

export type UpdateDatabaseResponse = Database;

export type DeleteDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
};

export type DeleteDatabaseResponse = Database;

export type GetManagementUrlParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
};

export type GetManagementUrlResponse = DBManagementUrl;

export type DBManagementUrl = string;

export type DBServiceParams = {
  service_name: string;
  cpu: string[];
  disk_max: string;
  disk_min: string;
  memory: string[];
  versions: string[];
};

export type GetDBServiceParamsParams = {
  serviceName: string;
  pollingId?: string;
};

export type GetDBServiceParamsResponse = DBServiceParams;

export type GetDBServicesParamsParams = {
  pollingId?: string;
};

export type GetDBServicesParamsResponse = DBServiceParams[];

export enum CHANGE_STATUS_TYPES {
  START = "start",
  STOP = "stop"
}

export type ChangeDatabaseStatusParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  type: CHANGE_STATUS_TYPES;
};

export type ChangeDatabaseStatusResponse = {
  message: string;
};

export type CreateDatabaseUserResponse = Database;

export type CreateDatabaseChildResponse = {
  message: string;
};

export type ResetDatabasePasswordResponse = DatabaseResetPassword;

export type ResetDatabasePasswordParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
};

export type S3LinkDatabaseParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    endpoint: string;
    key: string;
    secret: string;
  };
};

export type S3LinkDatabaseResponse = Database;

export type S3UnlinkDatabaseParams = DeleteDatabaseParams;

export type S3UnlinkDatabaseResponse = Database;

export enum DATABASES {
  MYSQL = "mysql",
  MARIADB = "mariadb",
  POSTGRESQL = "postgresql",
  UNDEFINED = ""
}

export enum DATABASE_STATUSES {
  CREATED = "CREATED",
  STOPPED = "STOPPED",
  ACTIVE = "ACTIVE",
  PROVISIONING = "PROVISIONING",
  DELETING = "DELETING",
  UNDEFINED = ""
}

export enum DATABASE_REPLICA_STATUSES {
  STANDALONE = "Standalone",
  PRIMARY = "Primary",
  REPLICA = "Replica",
  UNDEFINED = ""
}

export enum DATABASE_RESTORE_STATUSES {
  IN_PROGRESS = "inprogress",
  EXECUTING = "executing",
  DOWNLOADING = "downloading",
  COMPLETED = "completed"
}

export enum DATABASE_BACKUP_STATUSES {
  IN_PROGRESS = "inprogress",
  BACKING_UP = "backuping",
  UPLOADING = "uploading",
  COMPLETED = "completed"
}

export type DatabaseChild = {
  name: string;
  charset: string;
  collation: string;
};

export type TableDatabaseChild = DatabaseChild & {
  id: string;
  name: string;
  charset: string;
  collation: string;
};

export type DatabaseUser = {
  name: string;
  permissions: DatabasePermission[];
};

export type TableDatabaseUser = {
  id: string;
  name: string;
  permissions?: string | null;
};

export type DatabasePermission = {
  database: string;
  permission: string;
};

export type GetDatabaseChildrenParams = GetDatabaseParams;
export type GetDatabaseChildrenResponse = DatabaseChild[];

export type GetDatabaseUsersParams = GetDatabaseParams;
export type GetDatabaseUsersResponse = DatabaseUser[];

export type CreateDatabaseChildParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: {
    name: string;
    charset: string;
    collation: string;
  };
};

export type CreateDatabaseUserParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  data: DatabaseUser;
};

export type DeleteDatabaseChildParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  dbName: string;
};
export type DeleteDatabaseChildResponse = void;

export type DeleteDatabaseUserParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  username: string;
};
export type DeleteDatabaseUserResponse = void;

export type SetDatabaseUserPermissionsParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  username: string;
  permissions: DatabasePermission[];
};
export type SetDatabaseUserPermissionsResponse = void;

export type ResetDatabaseUserPasswordParams = {
  serviceName: string;
  orgId: string;
  projId: string;
  id: string;
  username: string;
};

export type DatabaseEncodings = {
  charsets: string[];
  collations: string[];
};

export type GetDatabaseEncodingsParams = GetDatabaseParams;
export type GetDatabaseEncodingsResponse = DatabaseEncodings;

export type ResetDatabaseUserPasswordResponse = DatabaseResetPassword;
