import SvgIcon from "@mui/material/SvgIcon";
import { FC } from "react";

export const MegaphoneIcon: FC = () => (
  <SvgIcon viewBox={"0 0 118.4 122.9"}>
    <path
      fillRule={"evenodd"}
      d={
        "M75.8 99.8l-4.6 8.2-25.6-12.6.9-8.8 29.3 13.2zM19.7 45.7H0v29.7h19.7V45.7zM31.9 80l-2 19.2L78 122.9l10-17.6 30.4 14.8V0L24.2 45.2v31.4L32 80z"
      }
      clipRule={"evenodd"}
    />
  </SvgIcon>
);
