import actionCreatorFactory from "typescript-fsa";
import { GetNewsFeedPostsParams, GetNewsFeedPostsResponse } from "./types";

const actionCreator = actionCreatorFactory("NEWS");

export const getNewsFeedPosts = actionCreator.async<
  GetNewsFeedPostsParams,
  GetNewsFeedPostsResponse,
  unknown
>("GET_NEWS_FEED_POSTS");

export const markNewsFeedPostsAsRead = actionCreator.async<void, void, unknown>(
  "MARK_NEWS_FEED_POSTS_AS_READ"
);

export const clear = actionCreator<void>("CLEAR");
