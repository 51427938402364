import { Chip } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const TitleContainer = styled.div`
  flex-grow: 1;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoContainer = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const SectionTitle = styled(Typography)`
  font-weight: 500;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1rem;
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const InfoLabel = styled.span`
  font-weight: 500;
  margin-right: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.secondary
      : theme.palette.text.primary};
  font-size: 0.875rem;
`;

export const InfoValue = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  font-size: 0.875rem;
  word-break: break-all;
`;

export const Tag = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  height: 24px;
  font-size: 0.75rem;
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
`;

export const MetricsContainer = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const SummaryKeyValue = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const CronButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsTitle = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.9rem;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const DetailsInfoColored = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
  font-size: 0.9rem;
`;

export const DetailsInfoGray = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  font-weight: 450;
  font-size: 0.9rem;
`;

export const SummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const CopyableInfoValue = styled(InfoValue)`
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;

export const CopyIcon = styled.span`
  margin-left: ${({ theme }) => theme.spacing(1)};
  opacity: 0.5;
  transition: opacity 0.2s;

  ${CopyableInfoValue}:hover & {
    opacity: 1;
  }
`;
