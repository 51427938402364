export enum DATE_TYPES {
  START,
  END
}

export enum PROJECT_STATUSES {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED"
}

export enum DIALOG_TYPES {
  GENERATE_BILLING_REPORT
}
