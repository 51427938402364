import actionCreatorFactory from "typescript-fsa";
import {
  GenerateBillingDataReportParams,
  GetOrganizationBillingDataParams,
  GetOrganizationBillingDataResponse,
  GetProjectBillingDatabasesDataParams,
  GetProjectBillingDatabasesDataResponse,
  GetProjectBillingInstancesDataParams,
  GetProjectBillingInstancesDataResponse,
  GetProjectBillingS3DataParams,
  GetProjectBillingS3DataResponse,
  GetProjectBillingVolumesDataParams,
  GetProjectBillingVolumesDataResponse
} from "./types";

const actionCreator = actionCreatorFactory("BILLING");

export const getOrganizationBillingData = actionCreator.async<
  GetOrganizationBillingDataParams,
  GetOrganizationBillingDataResponse,
  unknown
>("GET_ORGANIZATION_BILLING_DATA");

export const getProjectBillingInstancesData = actionCreator.async<
  GetProjectBillingInstancesDataParams,
  GetProjectBillingInstancesDataResponse,
  unknown
>("GET_PROJECT_BILLING_INSTANCES_DATA");

export const getProjectBillingVolumesData = actionCreator.async<
  GetProjectBillingVolumesDataParams,
  GetProjectBillingVolumesDataResponse,
  unknown
>("GET_PROJECT_BILLING_VOLUMES_DATA");

export const getProjectBillingDatabasesData = actionCreator.async<
  GetProjectBillingDatabasesDataParams,
  GetProjectBillingDatabasesDataResponse,
  unknown
>("GET_PROJECT_BILLING_DATABASES_DATA");

export const getProjectBillingS3Data = actionCreator.async<
  GetProjectBillingS3DataParams,
  GetProjectBillingS3DataResponse,
  unknown
>("GET_PROJECT_BILLING_S3_DATA");

export const generateBillingDataReport = actionCreator.async<
  GenerateBillingDataReportParams,
  void,
  unknown
>("GENERATE_BILLING_DATA_REPORT");

export const clear = actionCreator<void>("CLEAR");
