import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { userSelector } from "modules/auth/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  invitationAcceptanceErrorSelector,
  invitationSelector,
  isInvitationAcceptingSelector,
  isInvitationLoadingSelector
} from "modules/enterprises/selectors";
import { Invitation, INVITATION_TYPES } from "modules/enterprises/types";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants";
import * as s from "./styles";
import { PageContent } from "./types";

const getPageContent = (invitation: Invitation | null): PageContent => {
  if (invitation) {
    const keycloakGroupType =
      invitation.type === INVITATION_TYPES.GROUP_MEMBER
        ? "group"
        : "organization";
    switch (invitation.type) {
      case INVITATION_TYPES.ORGANIZATION_OWNER:
        return {
          title: `Invitation to become owner of ${invitation.keycloakGroupNameToInvite}`,
          message: (
            <>
              <s.HighlightedText component={"span"}>
                {invitation.senderName}
              </s.HighlightedText>{" "}
              wants to transfer ownership of{" "}
              <s.HighlightedText component={"span"}>
                {invitation.keycloakGroupNameToInvite}
              </s.HighlightedText>{" "}
              {keycloakGroupType} to you
            </>
          ),
          buttonText: "Accept ownership"
        };
      default:
        return {
          title: `Invitation to ${invitation.keycloakGroupNameToInvite}`,
          message: (
            <>
              <s.HighlightedText component={"span"}>
                {invitation.senderName}
              </s.HighlightedText>{" "}
              invited you to join{" "}
              <s.HighlightedText component={"span"}>
                {invitation.keycloakGroupNameToInvite}
              </s.HighlightedText>{" "}
              {keycloakGroupType}
            </>
          ),
          buttonText: "Accept invitation"
        };
    }
  }

  return {
    message: <></>,
    buttonText: ""
  };
};

export const InvitationAcceptance: FC = () => {
  const matchParams = useParams<{
    organizationId: string;
    groupId?: string;
    invitationId: string;
  }>();
  const dispatch = useDispatch();
  const history = useNavigate();
  const invitation = useSelector(invitationSelector);
  const invitationAcceptanceError = useSelector(
    invitationAcceptanceErrorSelector
  );
  const isInvitationAcceptanceInProgress = useSelector(
    isInvitationAcceptingSelector
  );
  const isInvitationLoading = useSelector(isInvitationLoadingSelector);
  const previousIsInvitationAcceptanceInProgress = usePrevious(
    isInvitationAcceptanceInProgress
  );
  const user = useSelector(userSelector);

  useMount(() => {
    if (matchParams.groupId) {
      dispatch(
        enterprisesActions.getGroupInvitation.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId,
          invitationId: matchParams.invitationId!
        })
      );
    } else {
      dispatch(
        enterprisesActions.getOrganizationInvitation.started({
          organizationId: matchParams.organizationId!,
          invitationId: matchParams.invitationId!
        })
      );
    }
  });

  useUnmount(() => {
    dispatch(enterprisesActions.clear());
  });

  useEffect(() => {
    if (
      previousIsInvitationAcceptanceInProgress &&
      !isInvitationAcceptanceInProgress &&
      invitation &&
      !invitationAcceptanceError
    ) {
      history(
        generatePath(ROUTES.ORGANIZATION, {
          organizationId: matchParams.organizationId
        })
      );
    }
  }, [
    dispatch,
    history,
    invitation,
    previousIsInvitationAcceptanceInProgress,
    isInvitationAcceptanceInProgress,
    invitationAcceptanceError,
    matchParams
  ]);

  const handleAcceptInvitationButtonClick = () => {
    if (matchParams.groupId) {
      dispatch(
        enterprisesActions.acceptGroupInvitation.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId,
          invitationId: matchParams.invitationId!
        })
      );
    } else {
      dispatch(
        enterprisesActions.acceptOrganizationInvitation.started({
          organizationId: matchParams.organizationId!,
          invitationId: matchParams.invitationId!
        })
      );
    }
  };

  const content = getPageContent(invitation);

  return (
    <>
      <Head title={content.title} />
      {isInvitationLoading ? (
        <Loader text={"Loading data..."} />
      ) : (
        <s.Container>
          {invitation && user ? (
            <>
              {invitation.inviteeEmail === user.email ? (
                <>
                  <s.InvitationMessage>{content.message}</s.InvitationMessage>
                  <Button
                    onClick={handleAcceptInvitationButtonClick}
                    variant={"contained"}
                  >
                    {content.buttonText}
                  </Button>
                </>
              ) : (
                <Typography>
                  Not enough permissions to accept invitation
                </Typography>
              )}
            </>
          ) : (
            <Typography>Failed to get invitation</Typography>
          )}
        </s.Container>
      )}
    </>
  );
};
