import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import { GetNewsFeedPostsParams, GetNewsFeedPostsResponse } from "./types";

const serviceApiPath = "gotham-news/method/";

export function* getNewsFeedPostsSaga(
  action: Action<GetNewsFeedPostsParams>
): SagaIterator<void> {
  try {
    const { offset, limit, lastId } = action.payload;
    const response: AxiosResponse<GetNewsFeedPostsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}news-feed-posts`,
      {
        params: {
          offset,
          limit,
          lastId
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getNewsFeedPosts.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getNewsFeedPosts.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get news feed data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* markNewsFeedPostsAsReadSaga(): SagaIterator<void> {
  try {
    yield call(
      axiosInstance.post,
      `${serviceApiPath}news-feed-posts/markAsRead`
    );
    yield put(actions.markNewsFeedPostsAsRead.done({}));
  } catch (e) {
    yield put(actions.markNewsFeedPostsAsRead.failed({ error: e }));
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getNewsFeedPosts.started, getNewsFeedPostsSaga),
    takeEvery(
      actions.markNewsFeedPostsAsRead.started,
      markNewsFeedPostsAsReadSaga
    )
  ]);
}
