import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import copy from "copy-to-clipboard";
import * as notficationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { downloadFile } from "utils/downloadFile";
import { KeyPairDialogProps } from "./types";

export const KeyPairDialog: FC<KeyPairDialogProps> = ({
  isOpened,
  name,
  privateKey,
  onClose
}: KeyPairDialogProps) => {
  const dispatch = useDispatch();

  const handleCopyToClipboardButtonClick = useCallback(
    (privateKey: string) => () => {
      copy(privateKey);
      dispatch(
        notficationsActions.showNotification({
          type: NOTIFICATION_TYPES.INFO,
          title: "Private key has been copied to clipboard."
        })
      );
    },
    [dispatch]
  );

  const handleDownloadPEMFileButtonClick = useCallback(
    (name: string, privateKey: string) => () => {
      const blob = new Blob([privateKey], {
        type: "application/x-pem-file"
      });
      const objectURL = URL.createObjectURL(blob);
      downloadFile(objectURL, `${name}.pem`);
      URL.revokeObjectURL(objectURL);
    },
    []
  );

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Dialog open={isOpened}>
      <DialogTitle>Save {name} private key</DialogTitle>
      <DialogContent>
        <Typography>
          SSH key pair has been successfully created. Please save private key
          for the further use.
        </Typography>
        <TextField
          inputProps={{
            label: "Private key",
            readOnly: true
          }}
          multiline={true}
          fullWidth={true}
          value={privateKey}
          variant={"standard"}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={"secondary"}
          onClick={handleDownloadPEMFileButtonClick(name, privateKey)}
        >
          Download PEM file
        </Button>
        <Button
          color={"secondary"}
          onClick={handleCopyToClipboardButtonClick(privateKey)}
        >
          Copy to clipboard
        </Button>
        <Button color={"secondary"} onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
