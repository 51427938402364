import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  CreateClusterParams,
  CreateClusterResponse,
  DeleteClusterParams,
  DeleteClusterResponse,
  GetClusterParams,
  GetClusterResponse,
  GetClustersParams,
  GetClustersResponse,
  GetClusterTemplateParams,
  GetClusterTemplateResponse,
  GetClusterTemplatesParams,
  GetClusterTemplatesResponse,
  ResizeClusterParams,
  ResizeClusterResponse,
  UpgradeClusterParams,
  UpgradeClusterResponse
} from "./types";

export function* getClusterSaga(
  action: Action<GetClusterParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetClusterResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-kubernetes/method/${projectId}/clusters/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getCluster.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getCluster.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get cluster data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getClustersSaga(
  action: Action<GetClustersParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetClustersResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-kubernetes/method/${projectId}/clusters`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getClusters.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getClusters.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get clusters data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createClusterSaga(
  action: Action<CreateClusterParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to create cluster...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateClusterResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-kubernetes/method/${projectId}/clusters`,
      data
    );
    yield put(
      actions.createCluster.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to create cluster has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createCluster.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to create cluster",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* resizeClusterSaga(
  action: Action<ResizeClusterParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the cluster...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<ResizeClusterResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-kubernetes/method/${projectId}/clusters/${id}/actions/resize`,
      data
    );
    yield put(
      actions.resizeCluster.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Cluster has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.resizeCluster.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update cluster",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* upgradeClusterSaga(
  action: Action<UpgradeClusterParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Upgrading the cluster...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpgradeClusterResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-kubernetes/method/${projectId}/clusters/${id}/actions/upgrade`,
      data
    );
    yield put(
      actions.upgradeCluster.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Cluster has been successfully upgraded.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.upgradeCluster.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to upgrade cluster",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteClusterSaga(
  action: Action<DeleteClusterParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete cluster...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteClusterResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-kubernetes/method/${projectId}/clusters/${id}`
    );
    yield put(
      actions.deleteCluster.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete cluster has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteCluster.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete cluster",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getClusterTemplateSaga(
  action: Action<GetClusterTemplateParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetClusterTemplateResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-kubernetes/method/${projectId}/cluster-templates/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getClusterTemplate.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getClusterTemplate.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get cluster template",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getClusterTemplatesSaga(
  action: Action<GetClusterTemplatesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetClusterTemplatesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-kubernetes/method/${projectId}/cluster-templates`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getClusterTemplates.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getClusterTemplates.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get cluster templates",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getCluster.started, getClusterSaga),
    takeLeading(actions.getClusters.started, getClustersSaga),
    takeEvery(actions.createCluster.started, createClusterSaga),
    takeEvery(actions.resizeCluster.started, resizeClusterSaga),
    takeEvery(actions.upgradeCluster.started, upgradeClusterSaga),
    takeEvery(actions.deleteCluster.started, deleteClusterSaga),
    takeLeading(actions.getClusterTemplate.started, getClusterTemplateSaga),
    takeLeading(actions.getClusterTemplates.started, getClusterTemplatesSaga)
  ]);
}
