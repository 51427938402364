import { RootReducer } from "../../reducers";
import { Cluster, ClusterTemplate, TableCluster } from "./types";

export const clusterSelector = (state: RootReducer): Cluster | null =>
  state.clusters.cluster;

export const tableClustersSelector = (
  state: RootReducer
): TableCluster[] | null =>
  state.clusters.clusters?.map((cluster) => {
    const masterFlavor = (state.instances.flavors || []).find(
      (flavor) => flavor.id === cluster.master_flavor_id
    );

    const nodeFlavor = (state.instances.flavors || []).find(
      (flavor) => flavor.id === cluster.flavor_id
    );

    const totalvCPUs =
      (masterFlavor?.vcpus || 0) * cluster.master_count +
      (nodeFlavor?.vcpus || 0) * cluster.node_count;

    const totalMemory =
      ((masterFlavor?.ram || 0) * cluster.master_count +
        (nodeFlavor?.ram || 0) * cluster.node_count) /
      1024;

    return {
      name: cluster.name,
      status: cluster.status,
      id: cluster.id,
      masterCount: cluster.master_count,
      nodeCount: cluster.node_count,
      clusterTemplateId: cluster.cluster_template_id,
      kubeVersion: cluster.labels.kube_version,
      kubeTag: cluster.labels.kube_tag,
      totalvCPUs: totalvCPUs,
      totalMemory: `${totalMemory} GiB`
    };
  }) || null;

export const isClusterCreatingSelector = (state: RootReducer): boolean =>
  state.clusters.isClusterCreating;

export const isClusterResizingSelector = (state: RootReducer): boolean =>
  state.clusters.isClusterResizing;

export const isClusterDeletingSelector = (state: RootReducer): boolean =>
  state.clusters.isClusterDeleting;

export const areClustersLoadingSelector = (state: RootReducer): boolean =>
  state.clusters.areClustersLoading;

export const clusterTemplatesSelector = (
  state: RootReducer
): ClusterTemplate[] | null => state.clusters.clusterTemplates;

export const areClusterTemplatesLoadingSelector = (
  state: RootReducer
): boolean => state.clusters.areClusterTemplatesLoading;
