import actionCreatorFactory from "typescript-fsa";
import {
  AddPublicKeyParams,
  AddPublicKeyResponse,
  CreateKeyPairParams,
  CreateKeyPairResponse,
  DeleteKeyPairParams,
  DeleteKeyPairResponse,
  GetKeyPairsParams,
  GetKeyPairsResponse
} from "./types";

const actionCreator = actionCreatorFactory("SECURITY");

export const getKeyPairs = actionCreator.async<
  GetKeyPairsParams,
  GetKeyPairsResponse,
  unknown
>("GET_KEY_PAIRS");

export const createKeyPair = actionCreator.async<
  CreateKeyPairParams,
  CreateKeyPairResponse,
  unknown
>("CREATE_KEY_PAIR");

export const addPublicKey = actionCreator.async<
  AddPublicKeyParams,
  AddPublicKeyResponse,
  unknown
>("ADD_PUBLIC_KEY");

export const deleteKeyPair = actionCreator.async<
  DeleteKeyPairParams,
  DeleteKeyPairResponse,
  unknown
>("DELETE_KEY_PAIR");

export const clear = actionCreator<void>("CLEAR");

export const clearKeyPair = actionCreator<void>("CLEAR_KEY_PAIR");
