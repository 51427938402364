export const formatSize = (bytes: number): string => {
  if (bytes === 0) {
    return "0 B";
  }

  const k = 1024;
  const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];

  const i: number = Math.floor(Math.log(bytes) / Math.log(k));
  const fileSize: number = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${fileSize} ${sizes[i]}`;
};
