import actionCreatorFactory from "typescript-fsa";
import {
  CreateClusterParams,
  CreateClusterResponse,
  DeleteClusterParams,
  DeleteClusterResponse,
  GetClusterParams,
  GetClusterResponse,
  GetClustersParams,
  GetClustersResponse,
  GetClusterTemplateParams,
  GetClusterTemplateResponse,
  GetClusterTemplatesParams,
  GetClusterTemplatesResponse,
  ResizeClusterParams,
  ResizeClusterResponse,
  UpgradeClusterParams,
  UpgradeClusterResponse
} from "./types";

const actionCreator = actionCreatorFactory("CLUSTERS");

export const getCluster = actionCreator.async<
  GetClusterParams,
  GetClusterResponse,
  unknown
>("GET_CLUSTER");

export const getClusters = actionCreator.async<
  GetClustersParams,
  GetClustersResponse,
  unknown
>("GET_CLUSTERS");

export const createCluster = actionCreator.async<
  CreateClusterParams,
  CreateClusterResponse,
  unknown
>("CREATE_CLUSTER");

export const resizeCluster = actionCreator.async<
  ResizeClusterParams,
  ResizeClusterResponse,
  unknown
>("RESIZE_CLUSTER");

export const upgradeCluster = actionCreator.async<
  UpgradeClusterParams,
  UpgradeClusterResponse,
  unknown
>("UPGRADE_CLUSTER");

export const deleteCluster = actionCreator.async<
  DeleteClusterParams,
  DeleteClusterResponse,
  unknown
>("DELETE_CLUSTER");

export const getClusterTemplate = actionCreator.async<
  GetClusterTemplateParams,
  GetClusterTemplateResponse,
  unknown
>("GET_CLUSTER_TEMPLATE");

export const getClusterTemplates = actionCreator.async<
  GetClusterTemplatesParams,
  GetClusterTemplatesResponse,
  unknown
>("GET_CLUSTER_TEMPLATES");

export const clear = actionCreator<void>("CLEAR");
