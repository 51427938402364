import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  CorsPolicy,
  Credentials,
  S3Bucket,
  S3Files,
  S3FilesTotal
} from "./types";

export type Reducer = {
  credentialsList: Credentials[] | null;
  isCredentialsListLoading: boolean;
  credentials: Credentials | null;
  areCredentialsLoading: boolean;
  areCredentialsCreating: boolean;
  areCredentialsRegenerating: boolean;
  areCredentialsDeleting: boolean;
  bucketsList: S3Bucket[] | null;
  bucket: S3Bucket | null;
  isBucketLoading: boolean;
  isBucketsListLoading: boolean;
  isBucketCreating: boolean;
  isBucketDeleting: boolean;
  filesList: S3FilesTotal | null;
  // filesListFlat: S3FilesTotal | null;
  isFilesListLoading: boolean;
  isFlatFilesListLoading: boolean;
  isFlatFilesListByPrefixLoading: boolean;
  isFlatFilesListRefreshing: boolean;
  isFileDeleting: boolean;
  areFilesDeleting: boolean;
  isFileDownloading: boolean;
  ifFileSignedDownloadLinkGenerating: boolean;
  fileSignedDownloadLink: string;
  isFileUploading: boolean;
  isUploadSuccessful: boolean;
  uploadProgress: number;
  isBucketVisibilityChanging: boolean;
  corsPolicy: CorsPolicy | null;
  isCorsPolicyChecking: boolean;
  isCorsPolicySetting: boolean;
  isFolderCreating: boolean;
};

const initialState: Reducer = {
  credentialsList: null,
  isCredentialsListLoading: false,
  credentials: null,
  areCredentialsLoading: false,
  areCredentialsCreating: false,
  areCredentialsRegenerating: false,
  areCredentialsDeleting: false,
  bucketsList: null,
  bucket: null,
  isBucketLoading: false,
  isBucketsListLoading: false,
  isBucketCreating: false,
  isBucketDeleting: false,
  filesList: null,
  // filesListFlat: null,
  isFilesListLoading: false,
  isFlatFilesListLoading: false,
  isFlatFilesListByPrefixLoading: false,
  isFlatFilesListRefreshing: false,
  isFileDeleting: false,
  areFilesDeleting: false,
  isFileDownloading: false,
  ifFileSignedDownloadLinkGenerating: false,
  fileSignedDownloadLink: "",
  isFileUploading: false,
  isUploadSuccessful: false,
  uploadProgress: 0,
  isBucketVisibilityChanging: false,
  corsPolicy: null,
  isCorsPolicyChecking: false,
  isCorsPolicySetting: false,
  isFolderCreating: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getCredentialsList.started,
    (state): Reducer => ({
      ...state,
      isCredentialsListLoading: true
    })
  )
  .case(
    actions.getCredentialsList.done,
    (state, payload): Reducer => ({
      ...state,
      credentialsList: payload.result,
      isCredentialsListLoading: false
    })
  )
  .case(
    actions.getCredentialsList.failed,
    (state): Reducer => ({
      ...state,
      isCredentialsListLoading: false
    })
  )
  .case(
    actions.getCredentials.started,
    (state): Reducer => ({
      ...state,
      areCredentialsLoading: true
    })
  )
  .case(
    actions.getCredentials.done,
    (state, payload): Reducer => ({
      ...state,
      credentials: payload.result,
      areCredentialsLoading: false
    })
  )
  .case(
    actions.getCredentials.failed,
    (state): Reducer => ({
      ...state,
      areCredentialsLoading: false
    })
  )
  .case(
    actions.createCredentials.started,
    (state): Reducer => ({
      ...state,
      areCredentialsCreating: true
    })
  )
  .case(
    actions.createCredentials.done,
    (state, payload): Reducer => ({
      ...state,
      credentials: payload.result,
      areCredentialsCreating: false
    })
  )
  .case(
    actions.createCredentials.failed,
    (state): Reducer => ({
      ...state,
      areCredentialsCreating: false
    })
  )
  .case(
    actions.regenerateCredentials.started,
    (state): Reducer => ({
      ...state,
      areCredentialsRegenerating: true
    })
  )
  .case(
    actions.regenerateCredentials.done,
    (state, payload): Reducer => ({
      ...state,
      credentials: payload.result,
      areCredentialsRegenerating: false
    })
  )
  .case(
    actions.regenerateCredentials.failed,
    (state): Reducer => ({
      ...state,
      areCredentialsRegenerating: false
    })
  )
  .case(
    actions.deleteCredentials.started,
    (state): Reducer => ({
      ...state,
      areCredentialsDeleting: true
    })
  )
  .case(
    actions.deleteCredentials.done,
    (state): Reducer => ({
      ...state,
      areCredentialsDeleting: false
    })
  )
  .case(
    actions.deleteCredentials.failed,
    (state): Reducer => ({
      ...state,
      areCredentialsDeleting: false
    })
  )

  .case(
    actions.getBuckets.started,
    (state): Reducer => ({
      ...state,
      isBucketsListLoading: true
    })
  )
  .case(
    actions.getBuckets.done,
    (state, payload): Reducer => ({
      ...state,
      bucketsList: payload.result,
      isBucketsListLoading: false
    })
  )
  .case(
    actions.getBuckets.failed,
    (state): Reducer => ({
      ...state,
      isBucketsListLoading: false
    })
  )

  .case(
    actions.getBucket.started,
    (state): Reducer => ({
      ...state,
      isBucketLoading: true
    })
  )
  .case(
    actions.getBucket.done,
    (state, payload): Reducer => ({
      ...state,
      bucket: payload.result,
      isBucketLoading: false
    })
  )
  .case(
    actions.getBucket.failed,
    (state): Reducer => ({
      ...state,
      isBucketLoading: false
    })
  )

  .case(
    actions.createBucket.started,
    (state): Reducer => ({
      ...state,
      isBucketCreating: true
    })
  )
  .case(
    actions.createBucket.done,
    (state): Reducer => ({
      ...state,
      isBucketCreating: false
    })
  )
  .case(
    actions.createBucket.failed,
    (state): Reducer => ({
      ...state,
      isBucketCreating: false
    })
  )

  .case(
    actions.deleteBucket.started,
    (state): Reducer => ({
      ...state,
      isBucketDeleting: true
    })
  )
  .case(
    actions.deleteBucket.done,
    (state): Reducer => ({
      ...state,
      isBucketDeleting: false,
      bucket: null
    })
  )
  .case(
    actions.deleteBucket.failed,
    (state): Reducer => ({
      ...state,
      isBucketDeleting: false
    })
  )

  .case(
    actions.getFiles.started,
    (state): Reducer => ({
      ...state,
      isFilesListLoading: true
    })
  )
  .case(
    actions.getFiles.done,
    (state, payload): Reducer => ({
      ...state,
      filesList: payload.result,
      isFilesListLoading: false
    })
  )
  .case(
    actions.getFiles.failed,
    (state): Reducer => ({
      ...state,
      isFilesListLoading: false
    })
  )

  .case(
    actions.getFlatFiles.started,
    (state): Reducer => ({
      ...state,
      isFlatFilesListLoading: true
    })
  )
  .case(
    actions.getFlatFiles.done,
    (state, payload): Reducer => ({
      ...state,
      filesList: payload.result,
      // filesListFlat: payload.result,
      isFlatFilesListLoading: false
    })
  )
  .case(
    actions.getFlatFiles.failed,
    (state): Reducer => ({
      ...state,
      isFlatFilesListLoading: false
    })
  )

  .case(
    actions.getFlatFilesByPrefix.started,
    (state): Reducer => ({
      ...state,
      isFlatFilesListByPrefixLoading: true
    })
  )
  .case(
    actions.getFlatFilesByPrefix.done,
    (state, payload): Reducer => ({
      ...state,
      filesList: payload.result,
      // filesListFlat: payload.result,
      isFlatFilesListByPrefixLoading: false
    })
  )
  .case(
    actions.getFlatFilesByPrefix.failed,
    (state): Reducer => ({
      ...state,
      isFlatFilesListByPrefixLoading: false
    })
  )

  .case(
    actions.getFlatFilesRefreshed.started,
    (state): Reducer => ({
      ...state,
      isFlatFilesListRefreshing: true
    })
  )
  .case(
    actions.getFlatFilesRefreshed.done,
    (state, payload): Reducer => ({
      ...state,
      filesList: payload.result,
      // filesListFlat: payload.result,
      isFlatFilesListRefreshing: false
    })
  )
  .case(
    actions.getFlatFilesRefreshed.failed,
    (state): Reducer => ({
      ...state,
      isFlatFilesListRefreshing: false
    })
  )

  .case(actions.clearFiles, (state): Reducer => {
    return {
      ...state,
      filesList: null,
      isFilesListLoading: true
    };
  })

  .case(
    actions.deleteFile.started,
    (state): Reducer => ({
      ...state,
      isFileDeleting: true
    })
  )
  .case(
    actions.deleteFile.done,
    (state): Reducer => ({
      ...state,
      isFileDeleting: false
    })
  )
  .case(
    actions.deleteFile.failed,
    (state): Reducer => ({
      ...state,
      isFileDeleting: false
    })
  )

  .case(
    actions.deleteFiles.started,
    (state): Reducer => ({
      ...state,
      areFilesDeleting: true
    })
  )
  .case(
    actions.deleteFiles.done,
    (state): Reducer => ({
      ...state,
      areFilesDeleting: false
    })
  )
  .case(
    actions.deleteFiles.failed,
    (state): Reducer => ({
      ...state,
      areFilesDeleting: false
    })
  )

  .case(
    actions.changeBucketVisibility.started,
    (state): Reducer => ({
      ...state,
      isBucketVisibilityChanging: true
    })
  )
  .case(
    actions.changeBucketVisibility.done,
    (state): Reducer => ({
      ...state,
      isBucketVisibilityChanging: false
    })
  )
  .case(
    actions.changeBucketVisibility.failed,
    (state): Reducer => ({
      ...state,
      isBucketVisibilityChanging: false
    })
  )

  .case(
    actions.getFileSignedDownloadLink.started,
    (state): Reducer => ({
      ...state,
      ifFileSignedDownloadLinkGenerating: true
    })
  )
  .case(
    actions.getFileSignedDownloadLink.done,
    (state, payload): Reducer => ({
      ...state,
      fileSignedDownloadLink: payload.result,
      ifFileSignedDownloadLinkGenerating: false
    })
  )
  .case(
    actions.getFileSignedDownloadLink.failed,
    (state): Reducer => ({
      ...state,
      ifFileSignedDownloadLinkGenerating: false
    })
  )

  .case(
    actions.downloadFile.started,
    (state): Reducer => ({
      ...state,
      isFileDownloading: true
    })
  )
  .case(
    actions.downloadFile.done,
    (state): Reducer => ({
      ...state,
      isFileDownloading: false
    })
  )
  .case(
    actions.downloadFile.failed,
    (state): Reducer => ({
      ...state,
      isFileDownloading: false
    })
  )

  .case(
    actions.checkCorsPolicy.started,
    (state): Reducer => ({
      ...state,
      isCorsPolicyChecking: true
    })
  )
  .case(
    actions.checkCorsPolicy.done,
    (state, payload): Reducer => ({
      ...state,
      corsPolicy: payload.result,
      isCorsPolicyChecking: false
    })
  )
  .case(
    actions.checkCorsPolicy.failed,
    (state): Reducer => ({
      ...state,
      isCorsPolicyChecking: false
    })
  )

  .case(
    actions.setCorsPolicy.started,
    (state): Reducer => ({
      ...state,
      isCorsPolicySetting: true
    })
  )
  .case(
    actions.setCorsPolicy.done,
    (state): Reducer => ({
      ...state,
      isCorsPolicySetting: false
    })
  )
  .case(
    actions.setCorsPolicy.failed,
    (state): Reducer => ({
      ...state,
      isCorsPolicySetting: false
    })
  )

  .case(
    actions.uploadFile.started,
    (state): Reducer => ({
      ...state,
      isFileUploading: true,
      isUploadSuccessful: false
    })
  )
  .case(
    actions.uploadFile.done,
    (state): Reducer => ({
      ...state,
      isFileUploading: false,
      isUploadSuccessful: true,
      uploadProgress: 0
    })
  )
  .case(
    actions.uploadFile.failed,
    (state): Reducer => ({
      ...state,
      isFileUploading: false,
      isUploadSuccessful: false,
      uploadProgress: 0
    })
  )

  .case(
    actions.uploadProgress,
    (state, payload): Reducer => ({
      ...state,
      uploadProgress: payload
    })
  )

  .case(
    actions.createFolder.started,
    (state): Reducer => ({
      ...state,
      isFolderCreating: true
    })
  )
  .case(
    actions.createFolder.done,
    (state): Reducer => ({
      ...state,
      isFolderCreating: false
    })
  )
  .case(
    actions.createFolder.failed,
    (state): Reducer => ({
      ...state,
      isFolderCreating: false
    })
  )

  .case(
    actions.clearCredentials,
    (state): Reducer => ({
      ...state,
      credentials: null
    })
  )

  .case(actions.clear, (state): Reducer => {
    const { isFileUploading, uploadProgress } = state;
    return {
      ...initialState,
      isFileUploading,
      // isUploadSuccessful,
      uploadProgress
    };
  });
