import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
// import * as s from "./styles";
import { SmallCircularProgressWithLabelProps } from "./types";

const ANIMATION_FREQUENCY = 50;
const ANIMATION_PERCENTAGE_STEP = 5;

export const SmallCircularProgressWithLabel: FC<
  SmallCircularProgressWithLabelProps
> = ({ value, maxValue }: SmallCircularProgressWithLabelProps) => {
  const [progress, setProgress] = useState(0);
  const [timerId, setTimerId] = useState<number | null>(null);
  const percentageValue = (value / maxValue) * 100;

  useEffect(() => {
    const timerId = (window as Window).setInterval(() => {
      setProgress((prevProgress) =>
        Math.min(
          100,
          prevProgress +
            Math.min(ANIMATION_PERCENTAGE_STEP, percentageValue - prevProgress)
        )
      );
    }, ANIMATION_FREQUENCY);
    setTimerId(timerId);

    return () => {
      clearInterval(timerId);
    };
  }, [percentageValue]);

  useEffect(() => {
    if (timerId && progress === percentageValue) {
      clearInterval(timerId);
    }
  }, [progress, percentageValue, timerId]);

  return (
    <Box
      position={"relative"}
      display={"inline-flex"}
      style={{ marginRight: "10px" }}
    >
      <CircularProgress variant={"determinate"} value={progress} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position={"absolute"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography
          variant="caption"
          component={"div"}
          color={"textSecondary"}
        >{`${Math.round(percentageValue)}%`}</Typography>
      </Box>
    </Box>
  );
};
