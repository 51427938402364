import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { useMount } from "hooks/useMount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as nlpActions from "modules/nlp/actions";
import { EmotionsPredictionValueSelector } from "modules/nlp/selectors";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { ChangeEvent, FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { capitalize } from "utils/capitalize";
import { ROUTES } from "../../constants";
import * as s from "./styles";

const curlExample = `curl -X POST ${window.origin}/process/ -H "Content-Type: application/json" -d "{\\"articles\\":[{\\"text\\": \\"text to process\\"}]}"`;

const title = "NLP (alpha)";

export const Nlp: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const emotionsPrediction = useSelector(EmotionsPredictionValueSelector);
  const [textToProcess, setTextToProcess] = useState("");

  const handleTextToProcessChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTextToProcess(event.target.value);
  };

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "NLP (alpha)",
      url: generatePath(ROUTES.NLP, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
  });

  const handleGetEmotionsResultButtonClick = useCallback(() => {
    dispatch(
      nlpActions.getEmotionsPrediction.started({
        text: textToProcess
      })
    );
  }, [dispatch, textToProcess]);

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <s.ContentContainer>
        <s.TextAreasContainer>
          <s.TextArea
            label={"Text to process"}
            inputProps={{
              title: "Text to process"
            }}
            multiline={true}
            minRows={10}
            maxRows={10}
            fullWidth={true}
            onChange={handleTextToProcessChange}
          />
          <s.TextArea
            label={"Result"}
            multiline={true}
            minRows={10}
            maxRows={10}
            inputProps={{
              readOnly: true,
              title: "Result"
            }}
            value={
              emotionsPrediction
                ? Object.keys(emotionsPrediction)
                    .map(
                      (key) =>
                        `${capitalize(key)}: ${
                          emotionsPrediction[key] as number
                        }`
                    )
                    .sort()
                    .join("\n")
                : ""
            }
            fullWidth={true}
          />
        </s.TextAreasContainer>
        <s.GetPredictionButton
          onClick={handleGetEmotionsResultButtonClick}
          variant={"contained"}
        >
          Get emotions prediction
        </s.GetPredictionButton>
        <TextField
          label={"Example of a API call via curl"}
          inputProps={{
            readOnly: true,
            title: "Example of a API call via curl"
          }}
          value={curlExample}
          fullWidth={true}
          size={"small"}
        />
      </s.ContentContainer>
    </>
  );
};
