import actionCreatorFactory from "typescript-fsa";
import {
  CreateCredentialsParams,
  CreateCredentialsResponse,
  DeleteCredentialsParams,
  GetCredentialsListParams,
  GetCredentialsListResponse,
  GetCredentialsParams,
  GetCredentialsResponse,
  RegenerateCredentialsParams,
  RegenerateCredentialsResponse,
  GetBucketsParams,
  GetBucketsResponse,
  CreateBucketParams,
  CreateBucketResponse,
  DeleteBucketParams,
  GetFilesParams,
  GetFilesResponse,
  UploadFileParams,
  UploadFileResponse,
  DeleteFileParams,
  DeleteFilesParams,
  DownloadFileParams,
  DownloadFileResponse,
  GetFileSignedDownloadLinkParams,
  GetFileSignedDownloadLinkResponse,
  ChangeBucketVisibilityParams,
  GetBucketParams,
  GetBucketResponse,
  SetCorsPolicyParams,
  CheckCorsPolicyParams,
  CheckCorsPolicyResponse,
  CreateFolderParams,
  GetFlatFilesParams
} from "./types";

const actionCreator = actionCreatorFactory("CREDENTIALS");

export const getCredentials = actionCreator.async<
  GetCredentialsParams,
  GetCredentialsResponse,
  unknown
>("GET_CREDENTIALS");

export const getCredentialsList = actionCreator.async<
  GetCredentialsListParams,
  GetCredentialsListResponse,
  unknown
>("GET_CREDENTIALS_LIST");

export const createCredentials = actionCreator.async<
  CreateCredentialsParams,
  CreateCredentialsResponse,
  unknown
>("CREATE_CREDENTIALS");

export const regenerateCredentials = actionCreator.async<
  RegenerateCredentialsParams,
  RegenerateCredentialsResponse,
  unknown
>("REGENERATE_CREDENTIALS");

export const deleteCredentials = actionCreator.async<
  DeleteCredentialsParams,
  void,
  unknown
>("DELETE_CREDENTIALS");

export const getBuckets = actionCreator.async<
  GetBucketsParams,
  GetBucketsResponse,
  unknown
>("GET_BUCKETS");

export const getBucket = actionCreator.async<
  GetBucketParams,
  GetBucketResponse,
  unknown
>("GET_BUCKET");

export const createBucket = actionCreator.async<
  CreateBucketParams,
  CreateBucketResponse,
  unknown
>("CREATE_BUCKET");

export const createFolder = actionCreator.async<
  CreateFolderParams,
  void,
  unknown
>("CREATE_FOLDER");

export const deleteBucket = actionCreator.async<
  DeleteBucketParams,
  void,
  unknown
>("DELETE_BUCKET");

export const getFiles = actionCreator.async<
  GetFilesParams,
  GetFilesResponse,
  unknown
>("GET_FILES");

export const getFlatFiles = actionCreator.async<
  GetFlatFilesParams,
  GetFilesResponse,
  unknown
>("GET_FLAT_FILES");

export const getFlatFilesByPrefix = actionCreator.async<
  GetFlatFilesParams,
  GetFilesResponse,
  unknown
>("GET_FLAT_FILES_BY_PREFIX");

export const getFlatFilesRefreshed = actionCreator.async<
  GetFlatFilesParams,
  GetFilesResponse,
  unknown
>("GET_FLAT_FILES_REFRESHED");

export const uploadFile = actionCreator.async<
  UploadFileParams,
  UploadFileResponse,
  unknown
>("UPLOAD_FILE");
export const uploadProgress = actionCreator<number>("UPLOAD_PROGRESS");

export const deleteFile = actionCreator.async<DeleteFileParams, void, unknown>(
  "DELETE_FILE"
);

export const deleteFiles = actionCreator.async<
  DeleteFilesParams,
  void,
  unknown
>("DELETE_FILES");

export const downloadFile = actionCreator.async<
  DownloadFileParams,
  DownloadFileResponse,
  unknown
>("DOWNLOAD_FILE");

export const getFileSignedDownloadLink = actionCreator.async<
  GetFileSignedDownloadLinkParams,
  GetFileSignedDownloadLinkResponse,
  unknown
>("GET_FILE_SIGNED_DOWNLOAD_LINK");

export const changeBucketVisibility = actionCreator.async<
  ChangeBucketVisibilityParams,
  void,
  unknown
>("CHANGE_BUCKET_VISIBILITY");

export const checkCorsPolicy = actionCreator.async<
  CheckCorsPolicyParams,
  CheckCorsPolicyResponse,
  unknown
>("CHECK_CORS_POLICY");

export const setCorsPolicy = actionCreator.async<
  SetCorsPolicyParams,
  void,
  unknown
>("SET_CORS_POLICY");

export const clear = actionCreator<void>("CLEAR");

export const clearCredentials = actionCreator<void>("CLEAR_CREDENTIALS");

export const clearFiles = actionCreator<void>("CLEAR_FILES");
