import Paper from "@mui/material/Paper";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ContentContainer = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2)};
  word-break: break-word;
`;
