import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  CreateSnapshotParams,
  CreateSnapshotResponse,
  CreateVolumeParams,
  CreateVolumeResponse,
  DeleteSnapshotParams,
  DeleteSnapshotResponse,
  DeleteVolumeParams,
  DeleteVolumeResponse,
  DeleteVolumesParams,
  DeleteVolumesResponse,
  ExtendVolumeParams,
  ExtendVolumeResponse,
  GetSnapshotParams,
  GetSnapshotResponse,
  GetSnapshotsParams,
  GetSnapshotsResponse,
  GetVolumeLimitsParams,
  GetVolumeLimitsResponse,
  GetVolumeParams,
  GetVolumeResponse,
  GetVolumeTypeParams,
  GetVolumeTypeResponse,
  GetVolumeTypesParams,
  GetVolumeTypesResponse,
  GetVolumesParams,
  GetVolumesResponse,
  UpdateSnapshotParams,
  UpdateSnapshotResponse,
  UpdateVolumeParams,
  UpdateVolumeResponse
} from "./types";

export function* getVolumeSaga(
  action: Action<GetVolumeParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetVolumeResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-volume/method/${projectId}/volumes/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getVolume.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getVolume.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get volume data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getVolumesSaga(
  action: Action<GetVolumesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetVolumesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-volume/method/${projectId}/volumes`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getVolumes.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getVolumes.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get volumes data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createVolumeSaga(
  action: Action<CreateVolumeParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the volume...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateVolumeResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-volume/method/${projectId}/volumes`,
      data
    );
    yield put(
      actions.createVolume.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Volume has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createVolume.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create volume",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateVolumeSaga(
  action: Action<UpdateVolumeParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the volume...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateVolumeResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-volume/method/${projectId}/volumes/${id}`,
      data
    );
    yield put(
      actions.updateVolume.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Volume has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateVolume.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update volume",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* extendVolumeSaga(
  action: Action<ExtendVolumeParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Extending the volume...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<ExtendVolumeResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-volume/method/${projectId}/volumes/${id}`,
      data
    );
    yield put(
      actions.extendVolume.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Volume has been successfully extended.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.extendVolume.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to extend volume",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteVolumeSaga(
  action: Action<DeleteVolumeParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete volume...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteVolumeResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-volume/method/${projectId}/volumes/${id}`
    );
    yield put(
      actions.deleteVolume.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete volume has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteVolume.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete volume",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteVolumesSaga(
  action: Action<DeleteVolumesParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete volumes...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<DeleteVolumesResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-volume/method/${projectId}/volumes/multiple/delete`,
      { volume_ids: data }
    );

    const entries = Object.entries(response.data);

    const formattedEntries = entries.map(
      ([volumeId, Message]: [string, string]) => {
        const MessageWithoutDetails = Message.replace(/\. .*$/, "");
        return `${volumeId} - ${MessageWithoutDetails}`;
      }
    );

    const { deletedVolumes, failedToDeleteVolumes } = formattedEntries.reduce(
      (result, entry) => {
        if (entry.includes("deleted")) {
          result.deletedVolumes.push(entry);
        } else {
          result.failedToDeleteVolumes.push(entry);
        }
        return result;
      },
      { deletedVolumes: [] as string[], failedToDeleteVolumes: [] as string[] }
    );

    const resultText = `${deletedVolumes.join("\n")}\n${
      failedToDeleteVolumes.length > 0
        ? `${failedToDeleteVolumes.join(
            "\n"
          )}\n\nNotice: You cannot delete volumes that are in reserved or in-use status, migrating, attached, belong to a group, have associated snapshots, or need to be disassociated from snapshots after a volume transfer.`
        : ""
    }`;

    yield put(
      actions.deleteVolumes.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: `Open to see results...`,
        text: `${resultText}`,
        type: NOTIFICATION_TYPES.INFO
      })
    );
  } catch (e) {
    yield put(
      actions.deleteVolumes.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete volume",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getVolumeLimitsSaga(
  action: Action<GetVolumeLimitsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetVolumeLimitsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-volume/method/${projectId}/volumes/limits`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getVolumeLimits.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getVolumeLimits.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get volume limits data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getSnapshotSaga(
  action: Action<GetSnapshotParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetSnapshotResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-volume/method/${projectId}/snapshots/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getSnapshot.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getSnapshot.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get snapshot data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getSnapshotsSaga(
  action: Action<GetSnapshotsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetSnapshotsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-volume/method/${projectId}/snapshots`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getSnapshots.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getSnapshots.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get snapshots data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createSnapshotSaga(
  action: Action<CreateSnapshotParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the snapshot...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateSnapshotResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-volume/method/${projectId}/snapshots`,
      data
    );
    yield put(
      actions.createSnapshot.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Snapshot has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createSnapshot.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create snapshot",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateSnapshotSaga(
  action: Action<UpdateSnapshotParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the snapshot...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateSnapshotResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-volume/method/${projectId}/snapshots/${id}`,
      data
    );
    yield put(
      actions.updateSnapshot.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Snapshot has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateSnapshot.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update snapshot",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteSnapshotSaga(
  action: Action<DeleteSnapshotParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete snapshot...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteSnapshotResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-volume/method/${projectId}/snapshots/${id}`
    );
    yield put(
      actions.deleteSnapshot.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete snapshot has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteSnapshot.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete snapshot",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getVolumeTypeSaga(
  action: Action<GetVolumeTypeParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetVolumeTypeResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-volume/method/${projectId}/volumetypes/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getVolumeType.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getVolumeType.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get volume type data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getVolumeTypesSaga(
  action: Action<GetVolumeTypesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetVolumeTypesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-volume/method/${projectId}/volumetypes`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getVolumeTypes.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getVolumeTypes.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get volume types data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getVolume.started, getVolumeSaga),
    takeLeading(actions.getVolumes.started, getVolumesSaga),
    takeEvery(actions.createVolume.started, createVolumeSaga),
    takeEvery(actions.updateVolume.started, updateVolumeSaga),
    takeEvery(actions.extendVolume.started, extendVolumeSaga),
    takeEvery(actions.deleteVolume.started, deleteVolumeSaga),
    takeEvery(actions.deleteVolumes.started, deleteVolumesSaga),
    takeLeading(actions.getVolumeLimits.started, getVolumeLimitsSaga),
    takeLeading(actions.getSnapshot.started, getSnapshotSaga),
    takeLeading(actions.getSnapshots.started, getSnapshotsSaga),
    takeEvery(actions.createSnapshot.started, createSnapshotSaga),
    takeEvery(actions.updateSnapshot.started, updateSnapshotSaga),
    takeEvery(actions.deleteSnapshot.started, deleteSnapshotSaga),
    takeLeading(actions.getVolumeType.started, getVolumeTypeSaga),
    takeLeading(actions.getVolumeTypes.started, getVolumeTypesSaga)
  ]);
}
