export enum DIALOG_TYPES {
  CREATE_FOLDER,
  MAKE_BUCKET_PUBLIC,
  MAKE_BUCKET_PRIVATE,
  SHOW_BUCKET_URL,
  DELETE_BUCKET,
  DOWNLOAD_FILE,
  SHOW_FILE_URL,
  GET_SIGNED_DOWNLOADING_LINK,
  DELETE_FILES,
  DELETE_FILE
  // FLAT_LIST_INFO
}
