import actionCreatorFactory from "typescript-fsa";
import {
  CreateSnapshotParams,
  CreateSnapshotResponse,
  CreateVolumeParams,
  CreateVolumeResponse,
  DeleteSnapshotParams,
  DeleteSnapshotResponse,
  DeleteVolumeParams,
  DeleteVolumeResponse,
  DeleteVolumesParams,
  DeleteVolumesResponse,
  ExtendVolumeParams,
  ExtendVolumeResponse,
  GetSnapshotParams,
  GetSnapshotResponse,
  GetSnapshotsParams,
  GetSnapshotsResponse,
  GetVolumeLimitsParams,
  GetVolumeLimitsResponse,
  GetVolumeParams,
  GetVolumeResponse,
  GetVolumesParams,
  GetVolumesResponse,
  GetVolumeTypeParams,
  GetVolumeTypeResponse,
  GetVolumeTypesParams,
  GetVolumeTypesResponse,
  UpdateSnapshotParams,
  UpdateSnapshotResponse,
  UpdateVolumeParams,
  UpdateVolumeResponse
} from "./types";

const actionCreator = actionCreatorFactory("STORAGE");

export const getVolume = actionCreator.async<
  GetVolumeParams,
  GetVolumeResponse,
  unknown
>("GET_VOLUME");

export const getVolumes = actionCreator.async<
  GetVolumesParams,
  GetVolumesResponse,
  unknown
>("GET_VOLUMES");

export const createVolume = actionCreator.async<
  CreateVolumeParams,
  CreateVolumeResponse,
  unknown
>("CREATE_VOLUME");

export const updateVolume = actionCreator.async<
  UpdateVolumeParams,
  UpdateVolumeResponse,
  unknown
>("UPDATE_VOLUME");

export const extendVolume = actionCreator.async<
  ExtendVolumeParams,
  ExtendVolumeResponse,
  unknown
>("EXTEND_VOLUME");

export const deleteVolume = actionCreator.async<
  DeleteVolumeParams,
  DeleteVolumeResponse,
  unknown
>("DELETE_VOLUME");

export const deleteVolumes = actionCreator.async<
  DeleteVolumesParams,
  DeleteVolumesResponse,
  unknown
>("DELETE_VOLUMES");

export const getVolumeLimits = actionCreator.async<
  GetVolumeLimitsParams,
  GetVolumeLimitsResponse,
  unknown
>("GET_VOLUME_LIMITS");

export const getSnapshot = actionCreator.async<
  GetSnapshotParams,
  GetSnapshotResponse,
  unknown
>("GET_SNAPSHOT");

export const getSnapshots = actionCreator.async<
  GetSnapshotsParams,
  GetSnapshotsResponse,
  unknown
>("GET_SNAPSHOTS");

export const createSnapshot = actionCreator.async<
  CreateSnapshotParams,
  CreateSnapshotResponse,
  unknown
>("CREATE_SNAPSHOT");

export const updateSnapshot = actionCreator.async<
  UpdateSnapshotParams,
  UpdateSnapshotResponse,
  unknown
>("UPDATE_SNAPSHOT");

export const deleteSnapshot = actionCreator.async<
  DeleteSnapshotParams,
  DeleteSnapshotResponse,
  unknown
>("DELETE_SNAPSHOT");

export const getVolumeType = actionCreator.async<
  GetVolumeTypeParams,
  GetVolumeTypeResponse,
  unknown
>("GET_VOLUME_TYPE");

export const getVolumeTypes = actionCreator.async<
  GetVolumeTypesParams,
  GetVolumeTypesResponse,
  unknown
>("GET_VOLUME_TYPES");

export const clearVolumes = actionCreator<void>("CLEAR_VOLUMES");
export const clearSnapshots = actionCreator<void>("CLEAR_SNAPSHOTS");
export const clear = actionCreator<void>("CLEAR");
