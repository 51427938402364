import actionCreatorFactory from "typescript-fsa";
import { SubmitTicketParams } from "./types";

const actionCreator = actionCreatorFactory("SUPPORT");

export const submitTicket = actionCreator.async<
  SubmitTicketParams,
  void,
  unknown
>("SUBMIT_TICKET");
