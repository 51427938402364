import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Cluster, ClusterTemplate } from "./types";

export type Reducer = {
  clusters: Cluster[] | null;
  cluster: Cluster | null;
  isClusterLoading: boolean;
  areClustersLoading: boolean;
  isClusterCreating: boolean;
  isClusterResizing: boolean;
  isClusterUpgrading: boolean;
  isClusterDeleting: boolean;
  clusterTemplates: ClusterTemplate[] | null;
  clusterTemplate: ClusterTemplate | null;
  isClusterTemplateLoading: boolean;
  areClusterTemplatesLoading: boolean;
};

const initialState: Reducer = {
  clusters: null,
  cluster: null,
  isClusterLoading: false,
  areClustersLoading: false,
  isClusterCreating: false,
  isClusterResizing: false,
  isClusterUpgrading: false,
  isClusterDeleting: false,
  clusterTemplates: null,
  clusterTemplate: null,
  isClusterTemplateLoading: false,
  areClusterTemplatesLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getCluster.started,
    (state): Reducer => ({
      ...state,
      isClusterLoading: true
    })
  )
  .case(
    actions.getCluster.done,
    (state, payload): Reducer => ({
      ...state,
      cluster: payload.result,
      isClusterLoading: false
    })
  )
  .case(
    actions.getCluster.failed,
    (state): Reducer => ({
      ...state,
      isClusterLoading: false
    })
  )
  .case(
    actions.getClusters.started,
    (state): Reducer => ({
      ...state,
      areClustersLoading: true
    })
  )
  .case(
    actions.getClusters.done,
    (state, payload): Reducer => ({
      ...state,
      clusters: payload.result,
      areClustersLoading: false
    })
  )
  .case(
    actions.getClusters.failed,
    (state): Reducer => ({
      ...state,
      areClustersLoading: false
    })
  )
  .case(
    actions.createCluster.started,
    (state): Reducer => ({
      ...state,
      isClusterCreating: true
    })
  )
  .case(
    actions.createCluster.done,
    (state): Reducer => ({
      ...state,
      isClusterCreating: false
    })
  )
  .case(
    actions.createCluster.failed,
    (state): Reducer => ({
      ...state,
      isClusterCreating: false
    })
  )
  .case(
    actions.resizeCluster.started,
    (state): Reducer => ({
      ...state,
      isClusterResizing: true
    })
  )
  .case(
    actions.resizeCluster.done,
    (state): Reducer => ({
      ...state,
      isClusterResizing: false
    })
  )
  .case(
    actions.resizeCluster.failed,
    (state): Reducer => ({
      ...state,
      isClusterResizing: false
    })
  )

  .case(
    actions.upgradeCluster.started,
    (state): Reducer => ({
      ...state,
      isClusterUpgrading: true
    })
  )
  .case(
    actions.upgradeCluster.done,
    (state): Reducer => ({
      ...state,
      isClusterUpgrading: false
    })
  )
  .case(
    actions.upgradeCluster.failed,
    (state): Reducer => ({
      ...state,
      isClusterUpgrading: false
    })
  )

  .case(
    actions.deleteCluster.started,
    (state): Reducer => ({
      ...state,
      isClusterDeleting: true
    })
  )
  .case(
    actions.deleteCluster.done,
    (state): Reducer => ({
      ...state,
      isClusterDeleting: false,
      cluster: null
    })
  )
  .case(
    actions.deleteCluster.failed,
    (state): Reducer => ({
      ...state,
      isClusterDeleting: false
    })
  )
  .case(
    actions.getClusterTemplate.started,
    (state): Reducer => ({
      ...state,
      isClusterTemplateLoading: true
    })
  )
  .case(
    actions.getClusterTemplate.done,
    (state, payload): Reducer => ({
      ...state,
      clusterTemplate: payload.result,
      isClusterTemplateLoading: false
    })
  )
  .case(
    actions.getClusterTemplate.failed,
    (state): Reducer => ({
      ...state,
      isClusterTemplateLoading: false
    })
  )
  .case(
    actions.getClusterTemplates.started,
    (state): Reducer => ({
      ...state,
      areClusterTemplatesLoading: true
    })
  )
  .case(
    actions.getClusterTemplates.done,
    (state, payload): Reducer => ({
      ...state,
      clusterTemplates: payload.result,
      areClusterTemplatesLoading: false
    })
  )
  .case(
    actions.getClusterTemplates.failed,
    (state): Reducer => ({
      ...state,
      areClusterTemplatesLoading: false
    })
  )
  .case(actions.clear, (): Reducer => initialState);
