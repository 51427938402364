export enum DIALOG_TYPES {
  EDIT_GROUP,
  DELETE_GROUP,
  LEAVE_GROUP,
  ADD_USER,
  REMOVE_USER,
  ASSIGN_GROUP,
  REMOVE_GROUP,
  RESEND_INVITATION,
  DELETE_INVITATION
}
