export enum TABS {
  FIREWALLS,
  SSH_KEYS,
  CLI_USERS
}

export enum DIALOG_TYPES {
  CREATE_FIREWALL,
  EDIT_FIREWALL,
  DELETE_FIREWALL,
  CREATE_KEY_PAIR,
  ADD_PUBLIC_KEY,
  DELETE_KEY_PAIR,
  CREATE_CLI_USER,
  EDIT_CLI_USER,
  CHANGE_CLI_USER_PASSWORD,
  DELETE_CLI_USER
}
