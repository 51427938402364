import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { KeyPair, PublicKey } from "./types";

export type Reducer = {
  keyPair: KeyPair | null;
  publicKey: PublicKey | null;
  keyPairs: KeyPair[] | null;
  areKeyPairsLoading: boolean;
  isKeyPairLoading: boolean;
  isKeyPairCreating: boolean;
  isPublicKeyAdding: boolean;
  isKeyPairDeleting: boolean;
};

const initialState: Reducer = {
  keyPair: null,
  publicKey: null,
  keyPairs: null,
  areKeyPairsLoading: false,
  isKeyPairCreating: false,
  isPublicKeyAdding: false,
  isKeyPairDeleting: false,
  isKeyPairLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getKeyPairs.started,
    (state): Reducer => ({
      ...state,
      areKeyPairsLoading: true
    })
  )
  .case(
    actions.getKeyPairs.done,
    (state, payload): Reducer => ({
      ...state,
      keyPairs: payload.result,
      areKeyPairsLoading: false
    })
  )
  .case(
    actions.getKeyPairs.failed,
    (state): Reducer => ({
      ...state,
      areKeyPairsLoading: false
    })
  )
  .case(
    actions.createKeyPair.started,
    (state): Reducer => ({
      ...state,
      isKeyPairCreating: true
    })
  )
  .case(
    actions.createKeyPair.done,
    (state, payload): Reducer => ({
      ...state,
      keyPair: payload.result,
      isKeyPairCreating: false
    })
  )
  .case(
    actions.createKeyPair.failed,
    (state): Reducer => ({
      ...state,
      isKeyPairCreating: false
    })
  )
  .case(
    actions.addPublicKey.started,
    (state): Reducer => ({
      ...state,
      isPublicKeyAdding: true
    })
  )
  .case(
    actions.addPublicKey.done,
    (state, payload): Reducer => ({
      ...state,
      publicKey: payload.result,
      isPublicKeyAdding: false
    })
  )
  .case(
    actions.addPublicKey.failed,
    (state): Reducer => ({
      ...state,
      isPublicKeyAdding: false
    })
  )
  .case(
    actions.deleteKeyPair.started,
    (state): Reducer => ({
      ...state,
      isKeyPairDeleting: true
    })
  )
  .case(
    actions.deleteKeyPair.done,
    (state): Reducer => ({
      ...state,
      isKeyPairDeleting: false
    })
  )
  .case(
    actions.deleteKeyPair.failed,
    (state): Reducer => ({
      ...state,
      isKeyPairDeleting: false
    })
  )
  .case(actions.clear, (): Reducer => initialState)
  .case(
    actions.clearKeyPair,
    (state): Reducer => ({
      ...state,
      keyPair: null
    })
  );
