import * as Sentry from "@sentry/react";
import SentryRRWeb from "@sentry/rrweb";
import { Integration } from "@sentry/types";
import { App } from "components/App";
import { createRoot } from "react-dom/client";
import { appConfig } from "./appConfig";
import { ELEMENT_IDS } from "./constants";

const root = createRoot(
  document.getElementById(ELEMENT_IDS.REACT_ROOT) as HTMLElement
);

if (appConfig.sentry.dsn) {
  Sentry.init({
    dsn: appConfig.sentry.dsn,
    integrations: [new SentryRRWeb({}) as unknown as Integration],
    tracesSampleRate: 0.2,
    environment: appConfig.sentry.environment
  });
}

if (appConfig.hubSpot.isChatWidgetEnabled) {
  const scriptEl = document.createElement("script");
  scriptEl.setAttribute("type", "text/javascript");
  scriptEl.setAttribute("id", "hs-script-loader");
  scriptEl.setAttribute("async", "true");
  scriptEl.setAttribute("defer", "true");
  scriptEl.setAttribute(
    "src",
    `https://js.hs-scripts.com/${String(appConfig.hubSpot.portalId)}.js`
  );
  document.body.appendChild(scriptEl);
}

root.render(<App />);
