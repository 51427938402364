export enum DIALOG_TYPES {
  EDIT_FIREWALL,
  DELETE_FIREWALL,
  CREATE_FIREWALL_RULE,
  DELETE_FIREWALL_RULE
}

export enum DIRECTIONS {
  INGRESS = "ingress",
  EGRESS = "egress"
}

export enum PORT_OPTIONS_VALUES {
  PORT = "PORT",
  PORT_RANGE = "PORT_RANGE",
  ALL_PORTS = "ALL_PORTS"
}

export enum REMOTE_OPTIONS_VALUES {
  CIDR = "CIDR",
  FIREWALL = "FIREWALL"
}
