import Button from "@mui/material/Button";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as objectStorageActions from "modules/objectStorage/actions";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import * as notificationsActions from "modules/notifications/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { ERROR_MESSAGES, REGEX, ROUTES } from "../../constants";
import { DIALOG_TYPES } from "./types";
import { TableS3Buckets } from "modules/objectStorage/types";
import {
  isBucketCreatingSelector,
  isBucketDeletingSelector,
  isBucketVisibilityChangingSelector,
  tableBucketsListSelector
} from "modules/objectStorage/selectors";
import { string } from "yup";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import copy from "copy-to-clipboard";

const POLL_ID_PREFIX = "S3_BUCKETS";

const POLL_IDS = {
  credentialsList: "CREDENTIALS_LIST",
  bucketsList: "BUCKETS_LIST"
};

const tableColumns: TableColumn<TableS3Buckets>[] = [
  { key: "name", label: "Name" },
  { key: "visibility", label: "Visibility" },
  {
    key: "creationDate",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const title = "S3 Buckets";

export const S3Buckets: FC = () => {
  const dispatch = useDispatch();

  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    credsId: string;
  }>();

  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const bucketsList = useSelector(tableBucketsListSelector);

  const isBucketCreating = useSelector(isBucketCreatingSelector);
  const isBucketDeleting = useSelector(isBucketDeletingSelector);
  const isBucketVisibilityChanging = useSelector(
    isBucketVisibilityChangingSelector
  );
  const isBucketOperationInProgress =
    isBucketCreating || isBucketDeleting || isBucketVisibilityChanging;
  const previousIsBucketOperationInProgress = usePrevious(
    isBucketOperationInProgress
  );

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.DELETE, isOpened: false });

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  // to add name to the delete confirmation window
  const previousSelectedItemId = usePrevious(selectedItemId);
  const selectedItemName = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const selectedItemURL = bucketsList?.find(
    (bucket) => bucket.name === selectedItemName
  )?.url;

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const generateTableItemURL = useCallback(
    (name: string) =>
      generatePath(ROUTES.S3_BUCKET, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        credsId: matchParams.credsId,
        bucketName: name
      }),
    [
      matchParams.organizationId,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.credsId
    ]
  );

  const handleCreateBucketButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleMakePrivateBucketMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.MAKE_PRIVATE,
      isOpened: true
    });
  }, []);

  const handleMakePublicBucketMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.MAKE_PUBLIC,
      isOpened: true
    });
  }, []);

  const handleShowPublicUrlMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.SHOW_PUBLIC_URL,
      isOpened: true
    });
  }, []);

  const handleDeleteBucketMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem<TableS3Buckets>[] = [
    {
      label: "Make private",
      handler: handleMakePrivateBucketMenuItemClick,
      isDisabled: (bucket) => bucket.visibility !== "public"
    },
    {
      label: "Make public",
      handler: handleMakePublicBucketMenuItemClick,
      isDisabled: (bucket) => bucket.visibility === "public"
    },
    {
      label: "Show URL",
      handler: handleShowPublicUrlMenuItemClick,
      isDisabled: (bucket) => bucket.visibility !== "public"
    },
    {
      label: "Delete",
      handler: handleDeleteBucketMenuItemClick
    }
  ];

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Storage",
      url: generatePath(ROUTES.STORAGE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "S3-Buckets",
      url: generatePath(ROUTES.S3_BUCKETS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        credsId: matchParams.credsId
      })
    }
  ];

  useMount(() => {
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.bucketsList}`,
        action: objectStorageActions.getBuckets.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
  });

  useEffect(() => {
    if (previousIsBucketOperationInProgress && !isBucketOperationInProgress) {
      dispatch(
        objectStorageActions.getBuckets.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.credsId,
    previousIsBucketOperationInProgress,
    isBucketOperationInProgress
  ]);

  const handleConfirmCreateBucket = useCallback(
    (data: { name: string }) => {
      dispatch(
        objectStorageActions.createBucket.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          data
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.credsId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleConfirmChangeBucketVisibility = useCallback(
    (visibility) => {
      if (selectedItemId) {
        dispatch(
          objectStorageActions.changeBucketVisibility.started({
            regionId: matchParams.regionId!,
            projId: matchParams.projectId!,
            credsId: matchParams.credsId!,
            bucketName: selectedItemId,
            visibility
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      handleCloseDialog,
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.credsId
    ]
  );

  const handleConfirmDeleteBucket = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        objectStorageActions.deleteBucket.started({
          regionId: matchParams.regionId!,
          projId: matchParams.projectId!,
          credsId: matchParams.credsId!,
          name: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId,
    matchParams.credsId
  ]);

  const handleCopyBucketUrl = useCallback(() => {
    if (selectedItemURL) {
      copy(selectedItemURL);
      dispatch(
        notificationsActions.showNotification({
          type: NOTIFICATION_TYPES.INFO,
          title: "Bucket URL has been copied to clipboard."
        })
      );
    }
  }, [dispatch, selectedItemURL]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateBucket,
      title: "Create bucket",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .matches(REGEX.BUCKET_NAME, ERROR_MESSAGES.BUCKET_NAME)
            .min(3, ERROR_MESSAGES.MIN_BUCKET_NAME)
            .max(63, ERROR_MESSAGES.MAX_BUCKET_NAME)
            .test(
              "sameNameCheckFormat",
              "This name already exists",
              (value) => {
                const name = bucketsList?.find(
                  (bucket) => bucket.id === value
                )?.name;
                return Boolean(value && !name);
              }
            )
        }
      ]
    },
    [DIALOG_TYPES.MAKE_PRIVATE]: {
      onConfirm: () => {
        handleConfirmChangeBucketVisibility("private");
      },
      title: `Are you sure you want to make bucket "${
        selectedItemName ?? "selected"
      }" private?`,
      confirmButtonLabel: "Make Private"
    },
    [DIALOG_TYPES.MAKE_PUBLIC]: {
      onConfirm: () => {
        handleConfirmChangeBucketVisibility("public");
      },
      title: `Are you sure you want to make bucket "${
        selectedItemName ?? "selected"
      }" public?`,
      confirmButtonLabel: "Make Public"
    },
    [DIALOG_TYPES.SHOW_PUBLIC_URL]: {
      onConfirm: handleCopyBucketUrl,
      title: `Bucket URL`,
      fields: [
        {
          name: "url",
          type: FIELD_TYPES.LABEL,
          label: "Bucket url",
          link: `${selectedItemURL ?? ""}`
        }
      ],
      confirmButtonLabel: "Copy to clipboard"
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteBucket,
      title: `Are you sure you want to delete "${
        selectedItemName ?? "selected"
      }" bucket?`,
      fields: [
        {
          name: "nonEmptyWarning",
          type: FIELD_TYPES.NOTES,
          label: "⚠️ Deletion of bucket is only possible if it is empty."
        }
      ],
      confirmButtonLabel: "Delete"
    }
  };

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Table
        title={title}
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={bucketsList || []}
        columns={tableColumns}
        actions={tableActions}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!bucketsList}
        toolbarItems={
          <Button
            onClick={handleCreateBucketButtonClick}
            variant={"contained"}
            disabled={false}
          >
            Create Bucket
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
