import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";

export type Reducer = {
  pendingPolls: string[];
};

const initialState: Reducer = {
  pendingPolls: []
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.startPolling,
    (state, payload): Reducer => ({
      ...state,
      pendingPolls: state.pendingPolls.find((pollId) => pollId === payload.id)
        ? state.pendingPolls
        : [...state.pendingPolls, payload.id]
    })
  )
  .case(
    actions.stopPolling,
    (state, payload): Reducer => ({
      ...state,
      pendingPolls: state.pendingPolls.filter((pollId) => pollId !== payload.id)
    })
  );
