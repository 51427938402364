export const formatRegion = (region: string): string =>
  region
    .split("-")
    .map((part) => `${part[0].toUpperCase()}${part.slice(1)}`)
    .join("-");

// parse __GROUP_REGIONS_MAP into Map<string, { group: string; isHidden: boolean }>
export function parseGroupRegionMap(
  input: string
): Map<string, { group: string; isHidden: boolean }> {
  if (!input) {
    input = `[{"upper-austria-dev-1": {"group": "Austria", "isHidden": false}}, {"upper-austria-dev-2": {"group": "Switzerland", "isHidden": false}}]`;
  }
  const jsonGroupRegionArray: string[] = JSON.parse(input);
  const groupRegionMapFormated = new Map<
    string,
    { group: string; isHidden: boolean }
  >();
  jsonGroupRegionArray.forEach((item: string) => {
    const key = Object.keys(item)[0];
    const value: { group: string; isHidden: boolean } = item[key];
    groupRegionMapFormated.set(key, value);
  });

  return groupRegionMapFormated;
}
