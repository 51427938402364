import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { KeyPairDialog } from "components/common/KeyPairDialog";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import copy from "copy-to-clipboard";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import {
  isFirewallCreatingSelector,
  isFirewallDeletingSelector,
  isFirewallUpdatingSelector,
  tableFirewallsSelector
} from "modules/networks/selectors";
import { TableFirewall } from "modules/networks/types";
import * as notficationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import {
  cliUsersSelector,
  isCLIUserCreatingSelector,
  isCLIUserDeletingSelector,
  isCLIUserUpdatingSelector,
  projectSelector
} from "modules/projects/selectors";
import { CLIUser } from "modules/projects/types";
import * as securityActions from "modules/security/actions";
import {
  isKeyPairCreatingSelector,
  isKeyPairDeletingSelector,
  isPublicKeyAddingSelector,
  keyPairSelector,
  keyPairsSelector
} from "modules/security/selectors";
import { TableKeyPair } from "modules/security/types";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isString } from "typeGuards/isString";
import { downloadFile } from "utils/downloadFile";
import { generateSearchString } from "utils/generateSearchString";
import { validateName } from "utils/validateName";
import { ref, string } from "yup";
import { API_PATH } from "../../axios";
import {
  CLI_USER_NAME_LENGTH,
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  REGEX,
  ROUTES,
  SPECIAL_NAMES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";

const POLL_ID_PREFIX = "SECURITY";

const POLL_IDS = {
  firewalls: "FIREWALLS",
  keyPairs: "KEY_PAIRS",
  cliUsers: "CLI_USERS"
};

const keyPairTableColumns: TableColumn<TableKeyPair>[] = [
  { key: "name", label: "Name" },
  { key: "fingerprint", label: "Fingerprint" },
  { key: "region", label: "Region" }
];

const firewallTableColumns: TableColumn<TableFirewall>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "description", label: "Description" }
];

const cliUserTableColumns: TableColumn<CLIUser>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" }
];

export const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.FIREWALLS]: "Firewalls",
  [TABS.SSH_KEYS]: "SSH Keys",
  [TABS.CLI_USERS]: "CLI Users"
};

const title = "Security";

export const Security: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const firewalls = useSelector(tableFirewallsSelector);
  const cliUsers = useSelector(cliUsersSelector);
  const keyPair = useSelector(keyPairSelector);
  const previousKeyPair = usePrevious(keyPair);
  const keyPairs = useSelector(keyPairsSelector);
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.FIREWALLS) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_FIREWALL, isOpened: false });
  const [isKeyPairDialogOpened, setIsKeyPairDialogOpened] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isFirewallCreating = useSelector(isFirewallCreatingSelector);
  const isFirewallUpdating = useSelector(isFirewallUpdatingSelector);
  const isFirewallDeleting = useSelector(isFirewallDeletingSelector);
  const isFirewallOperationInProgress =
    isFirewallCreating || isFirewallUpdating || isFirewallDeleting;
  const previousIsFirewallOperationInProgress = usePrevious(
    isFirewallOperationInProgress
  );
  const isKeyPairCreating = useSelector(isKeyPairCreatingSelector);
  const isKeyPairDeleting = useSelector(isKeyPairDeletingSelector);
  const isPublicKeyAdding = useSelector(isPublicKeyAddingSelector);
  const isKeyOperationInProgress =
    isKeyPairCreating || isKeyPairDeleting || isPublicKeyAdding;
  const previousIsKeyOperationInProgress = usePrevious(
    isKeyOperationInProgress
  );

  const isCLIUserCreating = useSelector(isCLIUserCreatingSelector);
  const isCLIUserUpdating = useSelector(isCLIUserUpdatingSelector);
  const isCLIUserDeleting = useSelector(isCLIUserDeletingSelector);
  const isCLIUserOperationInProgress =
    isCLIUserCreating || isCLIUserUpdating || isCLIUserDeleting;
  const previousIsCLIUserOperationInProgress = usePrevious(
    isCLIUserOperationInProgress
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Security",
      url: generatePath(ROUTES.SECURITY, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  const handleDownloadRCFileMenuItemClick = useCallback(
    (id: string) =>
      downloadFile(
        `${API_PATH}gotham-${matchParams.regionId}-identity/method/${matchParams.projectId}/cli-users/${id}/rcfile`
      ),
    [matchParams.regionId, matchParams.projectId]
  );

  const handleCopyPublicKey = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      const publicKey = keyPairs?.find((key) => key.id === id)?.public_key;
      if (publicKey) {
        copy(publicKey);
        dispatch(
          notficationsActions.showNotification({
            type: NOTIFICATION_TYPES.INFO,
            title: "Public key has been copied to clipboard."
          })
        );
      }
      setSelectedItemId(null);
    },
    [dispatch, keyPairs]
  );

  const handleCreateKeyPairButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_KEY_PAIR,
      isOpened: true
    });
  }, []);

  const handleAddPublicKeyButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.ADD_PUBLIC_KEY,
      isOpened: true
    });
  }, []);

  const handleDeleteKeypairMenuItemClick = useCallback((name: string) => {
    setSelectedItemId(name);
    setDialog({
      type: DIALOG_TYPES.DELETE_KEY_PAIR,
      isOpened: true
    });
  }, []);

  const handleCreateFirewallButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_FIREWALL,
      isOpened: true
    });
  }, []);

  const handleEditFirewallMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_FIREWALL,
      isOpened: true
    });
  }, []);

  const handleDeleteFirewallMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_FIREWALL,
      isOpened: true
    });
  }, []);

  const handleCreateCLIUserButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_CLI_USER,
      isOpened: true
    });
  }, []);

  const handleEditCLIUserMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_CLI_USER,
      isOpened: true
    });
  }, []);

  const handleChangeCLIUserPasswordMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.CHANGE_CLI_USER_PASSWORD,
      isOpened: true
    });
  }, []);

  const handleDeleteCLIUserMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_CLI_USER,
      isOpened: true
    });
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const keyPairTableActions: TableRowActionsMenuItem<TableKeyPair>[] = [
    {
      label: "Copy public key to clipboard",
      handler: handleCopyPublicKey
    },
    {
      label: "Delete",
      handler: handleDeleteKeypairMenuItemClick
    }
  ];

  const firewallTableActions: TableRowActionsMenuItem<TableFirewall>[] = [
    {
      label: "Edit",
      handler: handleEditFirewallMenuItemClick,
      isDisabled: (firewall) => firewall.name == SPECIAL_NAMES.FIREWALL
    },
    {
      label: "Delete",
      handler: handleDeleteFirewallMenuItemClick,
      isDisabled: (firewall) => firewall.name == SPECIAL_NAMES.FIREWALL
    }
  ];

  const cliUsersTableActions: TableRowActionsMenuItem<CLIUser>[] = [
    {
      label: "Download RC File",
      handler: handleDownloadRCFileMenuItemClick
    },
    {
      label: "Edit",
      handler: handleEditCLIUserMenuItemClick
    },
    {
      label: "Change password",
      handler: handleChangeCLIUserPasswordMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteCLIUserMenuItemClick
    }
  ];

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.FIREWALL, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        firewallId: id
      }),
    [matchParams.projectId, matchParams.organizationId, matchParams.regionId]
  );

  const tabContent = [
    <Table<TableFirewall>
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"firewallTable"}
      rows={firewalls || []}
      columns={firewallTableColumns}
      actions={firewallTableActions}
      itemLink={{
        column: "name",
        getURL: generateTableItemURL
      }}
      toolbarItems={
        <Button onClick={handleCreateFirewallButtonClick} variant={"contained"}>
          Create firewall
        </Button>
      }
      isLoading={!firewalls}
    />,
    <Table<TableKeyPair>
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"keyPairTable"}
      rows={keyPairs || []}
      columns={keyPairTableColumns}
      actions={keyPairTableActions}
      toolbarItems={
        <>
          <s.CreateKeyPairButton
            onClick={handleCreateKeyPairButtonClick}
            variant={"contained"}
          >
            Create SSH key pair
          </s.CreateKeyPairButton>
          <Button onClick={handleAddPublicKeyButtonClick} variant={"contained"}>
            Add public key
          </Button>
        </>
      }
      isLoading={!keyPairs}
    />,
    <Table<CLIUser>
      isSearchEnabled={true}
      isSortingEnabled={true}
      key={"CLIUserTable"}
      rows={cliUsers || []}
      columns={cliUserTableColumns}
      actions={cliUsersTableActions}
      toolbarItems={
        <Button onClick={handleCreateCLIUserButtonClick} variant={"contained"}>
          Create CLI User
        </Button>
      }
      isLoading={!cliUsers}
    />
  ];

  const handleConfirmCreateFirewall = useCallback(
    (data: { name: string; description: string }) => {
      dispatch(
        networksActions.createFirewall.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            ...data,
            description: data.description.trim()
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmEditFirewall = useCallback(
    (data: { name: string; description: string }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateFirewall.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              ...data,
              description: data.description.trim()
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteFirewall = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.deleteFirewall.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmCreateKeyPair = useCallback(
    (data: { name: string }) => {
      dispatch(
        securityActions.createKeyPair.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleAddPublicKey = useCallback(
    (data: { name: string; public_key: string }) => {
      dispatch(
        securityActions.addPublicKey.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmDeleteKeyPair = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        securityActions.deleteKeyPair.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          name: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmCreateCLIUser = useCallback(
    (data: { name: string; password: string }) => {
      dispatch(
        projectsActions.createCLIUser.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            ...data,
            password: data.password
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmEditCLIUser = useCallback(
    (data: { name: string }) => {
      if (selectedItemId) {
        dispatch(
          projectsActions.updateCLIUser.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmChangeCLIUserPassword = useCallback(
    (data: { password: string }) => {
      if (selectedItemId) {
        dispatch(
          projectsActions.updateCLIUser.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              password: data.password
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteCLIUser = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        projectsActions.deleteCLIUser.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (
      previousIsFirewallOperationInProgress &&
      !isFirewallOperationInProgress
    ) {
      dispatch(
        networksActions.getFirewalls.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsFirewallOperationInProgress,
    isFirewallOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (previousIsKeyOperationInProgress && !isKeyOperationInProgress) {
      dispatch(
        securityActions.getKeyPairs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsKeyOperationInProgress,
    isKeyOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (previousKeyPair !== keyPair && keyPair) {
      setIsKeyPairDialogOpened(true);
    }
  }, [previousKeyPair, keyPair]);

  useEffect(() => {
    if (previousIsCLIUserOperationInProgress && !isCLIUserOperationInProgress) {
      dispatch(
        projectsActions.getCLIUsers.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsCLIUserOperationInProgress,
    isCLIUserOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
  });

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingFirewallName = firewalls?.find(
    (firewall) => firewall.id === deletingItemId
  )?.name;
  const deletingKeyPairName = keyPairs?.find(
    (keyPair) => keyPair.id === deletingItemId
  )?.name;
  const deletingCliUserName = cliUsers?.find(
    (cliUser) => cliUser.id === deletingItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_FIREWALL]: {
      onConfirm: handleConfirmCreateFirewall,
      title: "Create firewall",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.EDIT_FIREWALL]: {
      onConfirm: handleConfirmEditFirewall,
      title: "Edit firewall",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            firewalls?.find((firewall) => firewall.id === selectedItemId)
              ?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue:
            firewalls?.find((firewall) => firewall.id === selectedItemId)
              ?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_FIREWALL]: {
      onConfirm: handleConfirmDeleteFirewall,
      title: `Are you sure you want to delete "${
        deletingFirewallName ?? "selected"
      }" firewall?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_KEY_PAIR]: {
      onConfirm: handleConfirmCreateKeyPair,
      title: "Create SSH key pair",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.KEYPAIR_NAME, ERROR_MESSAGES.KEYPAIR_NAME)
            .test(
              "sameNameCheckFormat",
              "This name already exists",
              (value) => {
                const name = keyPairs?.find(
                  (keypair) => keypair.id === value
                )?.name;
                return Boolean(value && !name);
              }
            )
        }
      ]
    },
    [DIALOG_TYPES.ADD_PUBLIC_KEY]: {
      onConfirm: handleAddPublicKey,
      title: "Add public key",
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.KEYPAIR_NAME, ERROR_MESSAGES.KEYPAIR_NAME)
        },
        {
          name: "public_key",
          type: FIELD_TYPES.TEXT,
          label: "Public key",
          rules: string().required()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_KEY_PAIR]: {
      onConfirm: handleConfirmDeleteKeyPair,
      title: `Are you sure you want to delete "${
        deletingKeyPairName ?? "selected"
      }" key pair?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_CLI_USER]: {
      onConfirm: handleConfirmCreateCLIUser,
      title: "Create CLI user",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(CLI_USER_NAME_LENGTH)
            })
        },
        {
          name: "password",
          type: FIELD_TYPES.PASSWORD,
          label: "Password",
          autocomplete: "new-password",
          rules: string()
            .required()
            .matches(REGEX.PASSWORD, ERROR_MESSAGES.PASSWORD)
        },
        {
          name: "confirmPassword",
          type: FIELD_TYPES.PASSWORD,
          label: "Confirm password",
          autocomplete: "new-password",
          rules: string().oneOf(
            [ref("password")],
            ERROR_MESSAGES.PASSWORDS_MUST_MATCH
          )
        }
      ]
    },
    [DIALOG_TYPES.EDIT_CLI_USER]: {
      onConfirm: handleConfirmEditCLIUser,
      title: "Edit CLI user",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            cliUsers?.find((user) => user.id === selectedItemId)?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(CLI_USER_NAME_LENGTH)
            })
        }
      ]
    },
    [DIALOG_TYPES.CHANGE_CLI_USER_PASSWORD]: {
      onConfirm: handleConfirmChangeCLIUserPassword,
      title: "Change password",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "password",
          type: FIELD_TYPES.PASSWORD,
          label: "New password",
          autocomplete: "new-password",
          rules: string()
            .required()
            .matches(REGEX.PASSWORD, ERROR_MESSAGES.PASSWORD)
        },
        {
          name: "confirmPassword",
          type: FIELD_TYPES.PASSWORD,
          label: "Confirm password",
          autocomplete: "new-password",
          rules: string().oneOf(
            [ref("password")],
            ERROR_MESSAGES.PASSWORDS_MUST_MATCH
          )
        }
      ]
    },
    [DIALOG_TYPES.DELETE_CLI_USER]: {
      onConfirm: handleConfirmDeleteCLIUser,
      title: `Are you sure you want to delete "${
        deletingCliUserName ?? "selected"
      }" CLI user?`,
      confirmButtonLabel: "Delete"
    }
  };

  useEffect(() => {
    Object.values([
      POLL_IDS.firewalls,
      POLL_IDS.keyPairs,
      POLL_IDS.cliUsers
    ]).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    switch (activeTabIndex) {
      case TABS.FIREWALLS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.FIREWALLS]
          })
        });
        dispatch(networksActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.firewalls}`,
            action: networksActions.getFirewalls.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
      case TABS.SSH_KEYS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.SSH_KEYS]
          })
        });
        dispatch(securityActions.clear());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.keyPairs}`,
            action: securityActions.getKeyPairs.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
      case TABS.CLI_USERS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.CLI_USERS]
          })
        });
        dispatch(projectsActions.clearCLIUsers());
        dispatch(
          pollingActions.startPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.cliUsers}`,
            action: projectsActions.getCLIUsers.started({
              regionId: matchParams.regionId!,
              projectId: matchParams.projectId!
            })
          })
        );
        break;
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(securityActions.clear());
    dispatch(networksActions.clear());
  });

  const handleCloseKeyPairDialog = useCallback(() => {
    setIsKeyPairDialogOpened(false);
    dispatch(securityActions.clearKeyPair());
  }, [dispatch]);

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Tabs value={activeTabIndex} onChange={handleChangeTab}>
        {Object.values(TAB_TITLES).map((tabTitle) => (
          <Tab key={tabTitle} label={tabTitle} />
        ))}
      </Tabs>
      {tabContent[activeTabIndex]}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
      {keyPair && isString(keyPair.private_key) && (
        <KeyPairDialog
          isOpened={isKeyPairDialogOpened}
          name={keyPair.name}
          privateKey={keyPair.private_key}
          onClose={handleCloseKeyPairDialog}
        />
      )}
    </>
  );
};
