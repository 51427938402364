export enum DIALOG_TYPES {
  EDIT,
  UPGRADE,
  DELETE
}

export enum TABS {
  INFO,
  NODES,
  MISCELLANEOUS,
  LABELS
}
