import MuiAlert from "@mui/material/Alert";
import MuiAlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

export const Alert = styled(MuiAlert)`
  & .MuiAlert-action {
    align-items: flex-start;
    padding-top: 5px;
    padding-bottom: 7px;
  }
`;

export const AlertTitle = styled(MuiAlertTitle)`
  margin-bottom: 0;
`;

export const ProgressNotificationContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Spinner = styled(CircularProgress)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  flex-shrink: 0;
`;
