export enum TABS {
  NETWORKS,
  VOLUMES,
  SNAPSHOTS,
  LOG,
  ACTION_LOG
}

export enum DIALOG_TYPES {
  EDIT,
  RESIZE,
  DELETE,
  EDIT_VOLUME,
  EXTEND_VOLUME,
  ATTACH_VOLUME,
  DETACH_VOLUME,
  CREATE_SNAPSHOT,
  EDIT_SNAPSHOT,
  DELETE_SNAPSHOT,
  ADD_INTERFACE,
  REMOVE_INTERFACE,
  EDIT_INTERFACE_FIREWALLS,
  SOFT_REBOOT,
  HARD_REBOOT,
  STOP,
  DOWNLOAD_RDP_FILE,
  ASSOCIATE_FLOATING_IP,
  DISASSOCIATE_FLOATING_IP
}
