import { TestContext, ValidationError } from "yup";
import { ERROR_MESSAGES, REGEX } from "../constants";
import { isUndefined } from "../typeGuards/isUndefined";

export const validateName =
  (nameLength: number, isEmptyStringAllowed?: boolean) =>
  (
    value: string | undefined,
    context: TestContext
  ): boolean | ValidationError | Promise<boolean | ValidationError> => {
    if (isUndefined(value)) {
      return context.createError({ message: ERROR_MESSAGES.REQUIRED });
    }
    if (isEmptyStringAllowed && !value) {
      return true;
    }
    if ([...value].length > nameLength) {
      return context.createError({
        message: `Name must be at most ${nameLength} characters long`
      });
    }
    if (!REGEX.NAME_DOES_NOT_START_WITH_WHITESPACE.test(value)) {
      return context.createError({
        message: ERROR_MESSAGES.NAME_DOES_NOT_START_WITH_WHITESPACE
      });
    }
    if (!REGEX.NAME_DOES_NOT_END_WITH_WHITESPACE.test(value)) {
      return context.createError({
        message: ERROR_MESSAGES.NAME_DOES_NOT_END_WITH_WHITESPACE
      });
    }
    if (!REGEX.NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES.test(value)) {
      return context.createError({
        message: ERROR_MESSAGES.NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES
      });
    }

    return true;
  };
