import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  AddRouteParams,
  AddRouteResponse,
  AddRouterInterfaceParams,
  AddRouterInterfaceResponse,
  CreateFirewallParams,
  CreateFirewallResponse,
  CreateFirewallRuleParams,
  CreateFirewallRuleResponse,
  CreateFloatingIPParams,
  CreateFloatingIPResponse,
  CreateNetworkParams,
  CreateNetworkResponse,
  CreateRouterParams,
  CreateRouterResponse,
  CreateSubnetParams,
  CreateSubnetResponse,
  DeleteFirewallParams,
  DeleteFirewallResponse,
  DeleteFirewallRuleParams,
  DeleteFirewallRuleResponse,
  DeleteFloatingIPParams,
  DeleteFloatingIPResponse,
  DeleteNetworkParams,
  DeleteNetworkResponse,
  DeleteRouteParams,
  DeleteRouteResponse,
  DeleteRouterInterfaceParams,
  DeleteRouterInterfaceResponse,
  DeleteRouterParams,
  DeleteRouterResponse,
  DeleteSubnetParams,
  DeleteSubnetResponse,
  EditInterfaceFirewallsParams,
  EditInterfaceFirewallsResponse,
  GetFirewallParams,
  GetFirewallResponse,
  GetFirewallRuleParams,
  GetFirewallRuleResponse,
  GetFirewallRulesParams,
  GetFirewallRulesResponse,
  GetFirewallsParams,
  GetFirewallsResponse,
  GetFloatingIPsParams,
  GetFloatingIPsResponse,
  GetInterfacesParams,
  GetInterfacesResponse,
  GetNetworkLimitsParams,
  GetNetworkLimitsResponse,
  GetNetworkParams,
  GetNetworkResponse,
  GetNetworksParams,
  GetNetworksResponse,
  GetNetworkSubnetsParams,
  GetNetworkSubnetsResponse,
  GetRouterInterfacesParams,
  GetRouterInterfacesResponse,
  GetRouterParams,
  GetRouterResponse,
  GetRoutersParams,
  GetRoutersResponse,
  GetSubnetParams,
  GetSubnetResponse,
  GetSubnetsParams,
  GetSubnetsResponse,
  UpdateFirewallParams,
  UpdateFirewallResponse,
  UpdateFloatingIPResponse,
  UpdateNetworkParams,
  UpdateNetworkResponse,
  UpdateRouterParams,
  UpdateRouterResponse,
  UpdateSubnetParams,
  UpdateSubnetResponse
} from "./types";

export function* getNetworkSaga(
  action: Action<GetNetworkParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetNetworkResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/networks/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getNetwork.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getNetwork.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get network data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getNetworksSaga(
  action: Action<GetNetworksParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetNetworksResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/networks`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getNetworks.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getNetworks.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get networks data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createNetworkSaga(
  action: Action<CreateNetworkParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the network...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateNetworkResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/networks`,
      data
    );
    yield put(
      actions.createNetwork.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Network has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createNetwork.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create network",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateNetworkSaga(
  action: Action<UpdateNetworkParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the network...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateNetworkResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-network/method/${projectId}/networks/${id}`,
      data
    );
    yield put(
      actions.updateNetwork.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Network has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateNetwork.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update network",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteNetworkSaga(
  action: Action<DeleteNetworkParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete network...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteNetworkResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-network/method/${projectId}/networks/${id}`
    );
    yield put(
      actions.deleteNetwork.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete network has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteNetwork.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete network",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getRouterSaga(
  action: Action<GetRouterParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetRouterResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/routers/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getRouter.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getRouter.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get router data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getRoutersSaga(
  action: Action<GetRoutersParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetRoutersResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/routers`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getRouters.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getRouters.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get routers data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createRouterSaga(
  action: Action<CreateRouterParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the router...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateRouterResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/routers`,
      data
    );
    yield put(
      actions.createRouter.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Router has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createRouter.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create router",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateRouterSaga(
  action: Action<UpdateRouterParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the router...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateRouterResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-network/method/${projectId}/routers/${id}`,
      data
    );
    yield put(
      actions.updateRouter.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Router has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateRouter.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update router",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteRouterSaga(
  action: Action<DeleteRouterParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete router...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteRouterResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-network/method/${projectId}/routers/${id}`
    );
    yield put(
      actions.deleteRouter.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete router has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteRouter.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete router",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInterfacesSaga(
  action: Action<GetInterfacesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetInterfacesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/interfaces`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInterfaces.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInterfaces.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get interfaces data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getRouterInterfacesSaga(
  action: Action<GetRouterInterfacesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, routerId } = action.payload;
    const response: AxiosResponse<GetRouterInterfacesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/devices/${routerId}/interfaces`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getRouterInterfaces.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getRouterInterfaces.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get interfaces data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* addRouterInterfaceSaga(
  action: Action<AddRouterInterfaceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Adding the interface...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, routerId, data } = action.payload;
    const response: AxiosResponse<AddRouterInterfaceResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/routers/${routerId}/interfaces`,
      data
    );
    yield put(
      actions.addRouterInterface.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Interface has been successfully added.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.addRouterInterface.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to add interface",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteRouterInterfaceSaga(
  action: Action<DeleteRouterInterfaceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete interface...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, routerId, id, data } = action.payload;
    const response: AxiosResponse<DeleteRouterInterfaceResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/routers/${routerId}/interfaces/${id}`,
      data
    );
    yield put(
      actions.deleteRouterInterface.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete interface has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteRouterInterface.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete interface",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* editInterfaceFirewallsSaga(
  action: Action<EditInterfaceFirewallsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Editing the interface's firewalls...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<EditInterfaceFirewallsResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-network/method/${projectId}/interfaces/${id}`,
      data
    );
    yield put(
      actions.editInterfaceFirewalls.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Interface's firewalls list has been successfully edited.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.editInterfaceFirewalls.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to edit interface's firewall list",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getNetworkLimitsSaga(
  action: Action<GetNetworkLimitsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetNetworkLimitsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/networks/quotas/detailed`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getNetworkLimits.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getNetworkLimits.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get network limits data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getSubnetSaga(
  action: Action<GetSubnetParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetSubnetResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/subnets/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getSubnet.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getSubnet.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get subnet data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getSubnetsSaga(
  action: Action<GetSubnetsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetSubnetsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/subnets`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getSubnets.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getSubnets.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get subnets data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getNetworkSubnetsSaga(
  action: Action<GetNetworkSubnetsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, networkId } = action.payload;
    const response: AxiosResponse<GetNetworkSubnetsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/networks/${networkId}/subnets`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getNetworkSubnets.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getNetworkSubnets.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get network subnets data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createSubnetSaga(
  action: Action<CreateSubnetParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the subnet...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateSubnetResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/subnets`,
      data
    );
    yield put(
      actions.createSubnet.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Subnet has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createSubnet.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create subnet",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateSubnetSaga(
  action: Action<UpdateSubnetParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the subnet...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateSubnetResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-network/method/${projectId}/subnets/${id}`,
      data
    );
    yield put(
      actions.updateSubnet.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Subnet has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateSubnet.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update subnet",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteSubnetSaga(
  action: Action<DeleteSubnetParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete subnet...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteSubnetResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-network/method/${projectId}/subnets/${id}`
    );
    yield put(
      actions.deleteSubnet.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete subnet has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteSubnet.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete subnet",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFirewallSaga(
  action: Action<GetFirewallParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetFirewallResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/firewalls/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFirewall.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getFirewall.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get firewall data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFirewallsSaga(
  action: Action<GetFirewallsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetFirewallsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/firewalls`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFirewalls.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFirewalls.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get firewalls data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createFirewallSaga(
  action: Action<CreateFirewallParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the firewall...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateFirewallResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/firewalls`,
      data
    );
    yield put(
      actions.createFirewall.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Firewall has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createFirewall.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create firewall",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateFirewallSaga(
  action: Action<UpdateFirewallParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the firewall...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateFirewallResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-network/method/${projectId}/firewalls/${id}`,
      data
    );
    yield put(
      actions.updateFirewall.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Firewall has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateFirewall.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update firewall",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteFirewallSaga(
  action: Action<DeleteFirewallParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete firewall...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteFirewallResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-network/method/${projectId}/firewalls/${id}`
    );
    yield put(
      actions.deleteFirewall.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete firewall has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteFirewall.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete firewall",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFirewallRuleSaga(
  action: Action<GetFirewallRuleParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetFirewallRuleResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/firewall-rules/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFirewallRule.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFirewallRule.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get firewall rule data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFirewallRulesSaga(
  action: Action<GetFirewallRulesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetFirewallRulesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/firewalls/${id}/firewall-rules`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFirewallRules.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFirewallRules.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get firewall rules data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createFirewallRuleSaga(
  action: Action<CreateFirewallRuleParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the firewall rule...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateFirewallRuleResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/firewall-rules`,
      data
    );
    yield put(
      actions.createFirewallRule.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Firewall rule has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createFirewallRule.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create firewall rule",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteFirewallRuleSaga(
  action: Action<DeleteFirewallRuleParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete firewall rule...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteFirewallRuleResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-network/method/${projectId}/firewall-rules/${id}`
    );
    yield put(
      actions.deleteFirewallRule.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete firewall rule has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteFirewallRule.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete firewall rule",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* addRouteSaga(
  action: Action<AddRouteParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Adding the route...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, routerId, data } = action.payload;
    const response: AxiosResponse<AddRouteResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/routers/${routerId}/routes`,
      data
    );
    yield put(
      actions.addRoute.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Route has been successfully added.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.addRoute.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to add route",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteRouteSaga(
  action: Action<DeleteRouteParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete route...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, routerId, data } = action.payload;
    const routeID = data.destination_cidr.replace(/[^0-9]/g, ""); // generate a compatible id for the route
    const response: AxiosResponse<DeleteRouteResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/routers/${routerId}/routes/${routeID}`,
      data
    );
    yield put(
      actions.deleteRoute.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete route has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.deleteRoute.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete route",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFloatingIPsSaga(
  action: Action<GetFloatingIPsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetFloatingIPsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/floatingips`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFloatingIPs.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getFloatingIPs.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get floating IPs' data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createFloatingIPSaga(
  action: Action<CreateFloatingIPParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the floating IP...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateFloatingIPResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-network/method/${projectId}/floatingips`,
      data
    );
    yield put(
      actions.createFloatingIP.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Floating IP has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createFloatingIP.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create floating IP",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateFloatingIPSaga(
  action: Action<UpdateNetworkParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the floating IP...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateFloatingIPResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-network/method/${projectId}/floatingips/${id}`,
      data
    );
    yield put(
      actions.updateFloatingIP.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Floating IP has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateFloatingIP.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update floating IP",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteFloatingIPSaga(
  action: Action<DeleteFloatingIPParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to release floating IP...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteFloatingIPResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-network/method/${projectId}/floatingips/${id}`
    );
    yield put(
      actions.deleteNetwork.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to release floating IP has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteFloatingIP.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to release floating IP",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getNetwork.started, getNetworkSaga),
    takeLeading(actions.getNetworks.started, getNetworksSaga),
    takeEvery(actions.createNetwork.started, createNetworkSaga),
    takeEvery(actions.updateNetwork.started, updateNetworkSaga),
    takeEvery(actions.deleteNetwork.started, deleteNetworkSaga),
    takeLeading(actions.getFloatingIPs.started, getFloatingIPsSaga),
    takeEvery(actions.createFloatingIP.started, createFloatingIPSaga),
    takeEvery(actions.updateFloatingIP.started, updateFloatingIPSaga),
    takeEvery(actions.deleteFloatingIP.started, deleteFloatingIPSaga),
    takeLeading(actions.getRouter.started, getRouterSaga),
    takeLeading(actions.getRouters.started, getRoutersSaga),
    takeEvery(actions.createRouter.started, createRouterSaga),
    takeEvery(actions.updateRouter.started, updateRouterSaga),
    takeEvery(actions.deleteRouter.started, deleteRouterSaga),
    takeEvery(actions.getInterfaces.started, getInterfacesSaga),
    takeEvery(actions.deleteRouterInterface.started, deleteRouterInterfaceSaga),
    takeEvery(actions.addRouterInterface.started, addRouterInterfaceSaga),
    takeEvery(actions.getRouterInterfaces.started, getRouterInterfacesSaga),
    takeLeading(actions.getNetworkLimits.started, getNetworkLimitsSaga),
    takeLeading(actions.getSubnet.started, getSubnetSaga),
    takeLeading(actions.getSubnets.started, getSubnetsSaga),
    takeLeading(actions.getNetworkSubnets.started, getNetworkSubnetsSaga),
    takeEvery(actions.createSubnet.started, createSubnetSaga),
    takeEvery(actions.updateSubnet.started, updateSubnetSaga),
    takeEvery(actions.deleteSubnet.started, deleteSubnetSaga),
    takeLeading(actions.getFirewall.started, getFirewallSaga),
    takeLeading(actions.getFirewalls.started, getFirewallsSaga),
    takeEvery(actions.createFirewall.started, createFirewallSaga),
    takeEvery(actions.updateFirewall.started, updateFirewallSaga),
    takeEvery(actions.deleteFirewall.started, deleteFirewallSaga),
    takeLeading(actions.getFirewallRule.started, getFirewallRuleSaga),
    takeLeading(actions.getFirewallRules.started, getFirewallRulesSaga),
    takeEvery(actions.createFirewallRule.started, createFirewallRuleSaga),
    takeEvery(actions.deleteFirewallRule.started, deleteFirewallRuleSaga),
    takeEvery(actions.addRoute.started, addRouteSaga),
    takeEvery(actions.deleteRoute.started, deleteRouteSaga),
    takeEvery(
      actions.editInterfaceFirewalls.started,
      editInterfaceFirewallsSaga
    )
  ]);
}
